import axios from 'axios'

class Session {
  constructor() {
    this.onSessionReady = new Promise((resolve, reject) => {
      this.resolveSession = resolve

      console.log('[Session] Session resolved')
    })
  }

  async fetchWidgetSession() {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    })

    axios.get('/api/v1/token/widget', {
      params: {
        token: params.token,
      },
    })
      .then(({ data }) => {
        console.log('[Session] Widget auth success')

        const { token } = data.data

        this.apiToken = token
      })
      .catch(() => {
        console.log('[Session] No widget auth')
      })
      .finally(() => {
        this.resolveSession()
      })

    return this.onSessionReady
  }

  async fetchSession() {
    axios.get('/api/v1/session/token', {
      params: {
        spa_page: 'dashboard',
      },
    })
      .then(({ data }) => {
        if (data.success === false) {
          return Promise.reject(data)
        }

        console.log('[Session] Auth success')

        const {
          api_token,
          // TODO: перевод на реальный SPA
          // csrf_token,
          // user_id,
          // user_language,
        } = data.data

        this.apiToken = api_token
        // TODO: перевод на реальный SPA
        // this.csrfToken = csrf_token
        // this.userId = user_id
        // this.userLanguage = user_language

        // LEGACY ---> TODO: перевод на реальный SPA
        // window.token_dashboard_viewer = api_token
        // window.token_dashboard_streamer = api_token
        // window.csrf_token = csrf_token
        // window.document_language = user_language
        // window.user_id = user_id
        // <--- LEGACY
      })
      .catch(() => {
        console.log('[Session] No auth')
      })
      .finally(() => {
        this.resolveSession()
      })

    return this.onSessionReady
  }

  getApiToken() {
    return this.apiToken
  }

  // TODO: перевод на реальный SPA
  // getCsrfToken() {
  //   return this.csrfToken
  // }
  //
  // getUserId() {
  //   return this.userId
  // }
  //
  // getUserLanguage() {
  //   return this.userLanguage
  // }

  onReady() {
    return this.onSessionReady
  }
}

export default new Session()
