<style lang="scss" scoped>
  .gradient-picker {
    .colors-list {
      .color-control + .color-control {
        margin-top: 5px;
      }
    }

    .gradient-picker-block + .gradient-picker-block {
      margin-top: 10px;
    }
  }
</style>

<template>
  <div class="gradient-picker">
    <div class="gradient-picker-block">
      <div ref="colors-list" class="colors-list">
        <div class="color-control">
          <color-picker v-model="colors[0]"/>
        </div>
        <div class="color-control">
          <color-picker v-model="colors[1]"/>
        </div>
      </div>
    </div>
    <div class="gradient-picker-block">
      <base-range class="angle-picker" v-model="angle" :max="360" :step="1" input-addon="°" input/>
    </div>
  </div>
</template>

<script>

export default {
  name: 'GradientPicker',
  props: {
    value: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      angle: 0,
      colors: ['#ffffff', '#000000'],
    }
  },
  computed: {
    output() {
      return {
        colors: this.colors,
        angle: this.angle,
      }
    },
  },
  created() {
    if (this.value) {
      const { colors, angle, hard } = this.value

      this.angle = angle ?? this.angle
      this.colors = _.cloneDeep(colors) ?? this.colors
      this.hard = hard ?? this.hard
    }
  },
  watch: {
    output(value) {
      this.$emit('input', value)
    },
  },
}
</script>
