<template>
  <div style="margin-top: 10px;" >
    <loader
      size="md"
      v-if="isGlobalLoading"
    />

    <div v-else >
      <div class="list__header">
        <div>
          <span class="list__header-count" v-if="offers.length">{{$t('offers.advertisersAccount.promotionalOffersFound')}}: {{ offers.length }}</span>
          <span class="list__header-count" v-else>{{$t('offers.advertisersAccount.noPromotionalOffersFound')}}</span>
        </div>
      </div>

      <div class="list">
        <!-- <div class="list__item" @click="toggleModal">
          <new-offer-button />
        </div> -->

        <div
          v-for="offer in offers"
          :key="offer.id"
          class="list__item"
        ><offer-card
          :offer="offer"
          @toggleModal="toggleModal"
        /></div>
      </div>

      <!-- <offer-modal
        :isShow="showModal"
        @toggleModal="toggleModal"
      /> -->

      <users-modal
        v-if="showModal"
        :isShow="showModal"
        :offer="currentOffer"
        @toggleModal="toggleModal"
        @showMessageModal="toggleMessageModal"
      />

      <message-modal
        v-if="showMessageModal"
        :isShow="showMessageModal"
        :advUserId="currentUserId"
        :collections="currentCollections"
        @toggleModal="toggleMessageModal"
      />
    </div>
  </div>
</template>

<script>
import OfferCard from '@components/Dashboard/SendOffers/OfferCard'
import NewOfferButton from '@components/Dashboard/SendOffers/NewOfferButton'
import UsersModal from '@components/Dashboard/SendOffers/UsersModal'
import MessageModal from '@components/Dashboard/SendOffers/MessageModal'

export default {
  components: {
    OfferCard,
    NewOfferButton,
    UsersModal,
    MessageModal,
  },

  data() {
    return {
      showModal: false,
      currentOffer: null,

      showMessageModal: false,
      currentUserId: null,
      currentCollections: null,
    }
  },

  created() {
    this.fetchOffers()
  },

  computed: {
    ...mapGetters('sendOffers/offers', [
      'isGlobalLoading',
      'offers',
    ]),
  },

  methods: {
    ...mapActions('sendOffers/offers', ['fetchOffers']),

    toggleModal(id) {
      if (id) {
        if (this.showModal) {
          this.currentOffer = null
          this.showModal = false
        } else {
          this.currentOffer = this.offers.filter(o => o.id === id)[0]
          this.showModal = true
        }
      } else {
        this.currentOffer = null
        this.showModal = !this.showModal
      }
    },

    toggleMessageModal(id) {
      if (this.showMessageModal) {
        this.showMessageModal = false
        this.currentUserId = null
        this.currentCollections = null
      } else {
        if (id) {
          this.showMessageModal = true
          this.currentUserId = id
          this.currentCollections = this.currentOffer.options.collections
        } else {
          this.showMessageModal = false
        }
      }
    },
  },
}
</script>

<style scoped lang="scss">
.list {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;

  &__header {
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 14px;

    &-count {
      color: #838383;
    }
  }

  &__item {
    padding: 10px;
    width: 20%;
  }
}

.button-group {
  padding-bottom: 20px;
  margin-top: 40px;

  button {
    margin-right: 10px;
    border-radius: 8px;
  }
}
</style>
