import { defineStore } from 'pinia'

export const useGlobalStore = defineStore('global', {
  state: () => ({}),
  
  actions: {
    async fetchRegions() {
      const { data } = await api.get(`/region`)

      return data
    },

    async fetchCountries() {
      const { data } = await api.get(`/region`, { params:  {
        only_countries: 1
      }})
      
      return data
    },
  },
})