<style scoped lang="scss">
  .roulette-decor-settings {
    
    .settings-area {
      @include media(max, md) {
        grid-template-columns: auto !important;
      }
    }

  }
</style>

<template>
  <div class="roulette-decor-settings">
    <grid columns="2" gap="40" class="settings-area">
      <div>
        <form-group inline :label="$t('global.settings.labels.type')">
          <dropdown-select v-model="decorType" :options="decorTypeOptions"/>
        </form-group>
        <template v-if="decorType">
          <template v-if="decorType === 'image'">
            <form-group inline :label="$t('roulette.visualSettings.decor.decor')">
              <file-manager v-model="decorImage" widget-element="roulette_decor" file-type="image"/>
            </form-group>
            <form-group>
              <info-box class="small" :show-icon="false">
                {{ $t('roulette.visualSettings.decor.decorSizePrompt') }}
              </info-box>
            </form-group>
            <template v-if="decorImage">
              <form-group inline :label="$t('global.settings.labels.hueRotation')">
                <base-range :min="0" :max="360" v-model="decorImageHue" input input-addon="°"/>
              </form-group>
              <form-group inline :label="$t('global.settings.labels.saturate')">
                <base-range :min="0" :max="300" v-model="decorImageSaturate" input input-addon="%"/>
              </form-group>
            </template>
          </template>
        </template>
      </div>
      <div>
        <form-group v-if="decorType" inline :label="$t('global.settings.labels.shadow')">
          <shadow-styler v-model="decorShadow" class="left"/>
        </form-group>
      </div>
    </grid>
  </div>
</template>

<script>
import ShadowStyler from '@components/BaseComponents/ShadowStyler/ShadowStyler'

import FileManager from '@components/BaseComponents/FileManager/FileManager'

export default {
  name: 'RouletteDecorSettings',
  components: {
    FileManager,

    ShadowStyler,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      decorType: null,

      decorImage: null,
      decorImageHue: 0,
      decorImageSaturate: 100,

      decorShadow: null,
    }
  },
  computed: {
    decorTypeOptions() {
      return [
        {
          value: null,
          icon: 'cross',
        },
        {
          value: 'image',
          icon: 'image',
        },
      ].map(option => ({
        ...option,
        label: option.value ? this.$t(`global.settings.labels.types.${option.value}`) : null,
      }))
    },

    output() {
      return {
        _version: Math.random().toString(36).slice(2),
        decorType: this.decorType,
        decorImage: this.decorImage,
        decorImageHue: this.decorImageHue,
        decorImageSaturate: this.decorImageSaturate,
        decorSize: this.decorSize,
        decorShadow: this.decorShadow,
      }
    },
  },
  created() {
    const storedValue = _.cloneDeep(this.value)

    if (storedValue) {
      Object.keys(storedValue).forEach(key => {
        this[key] = storedValue[key] ?? this[key]
      })
    }
  },
  watch: {
    output(value) {
      this.$emit('input', value)
    },
    decorImage(image, oldImage) {
      if (oldImage && oldImage !== image) {
        this.decorImageHue = 0
        this.decorImageSaturate = 100
      }
    },
  },
}
</script>
