<template>
  <div class="offer__description">
    <div class="offer__imgage-col">
      <router-link
        class="offer__imgage"
        v-if="image"
        :to="linkTo"
      >
        <img :src="image">
      </router-link>
    </div>
    <div class="offer__text-col">
      <router-link
        class="offer__brand-name"
        :to="linkTo"
      >{{ title }}</router-link>
      <p>
        <slot />
      </p>
    </div>
  </div>
</template>

<script>
export default {

  name: 'Advertiser',

  props: {
    image: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    linkTo: {
      type: Object,
      default: null
    },
  },

}

</script>

<style scoped lang="scss">
.offer {
  &__description {

    @media (min-width: 960px) {
      display: flex;
    }
  }

  &__imgage {
    display: block;
    overflow: hidden;
    width: 140px;
    border-radius: 5px;
    margin: 15px 0;
    background: #242424;

    img {
      display: block;
      width: 100%;
    }

    @media (min-width: 720px) {
      margin: 0 15px 15px 0;
    }

    @media (min-width: 960px) {
      margin: 0 15px 0 0;
    }
  }

  &__text-col {

    p {
      margin-top: 10px;
      opacity: .8;
      word-break: break-word;
    }
  }

  &__brand-name {
    color: inherit;
  }
}
</style>
