<style scoped lang="scss">
  .roulette-pin-settings {
    
    .settings-area {
      @include media(max, md) {
        grid-template-columns: auto !important;
      }
    }

  }
</style>

<template>
  <div class="roulette-pin-settings">
    <grid columns="2" gap="40" class="settings-area">
      <div>
        <form-group inline :label="$t('global.settings.labels.type')">
          <dropdown-select v-model="pinType" :options="pinTypeOptions"/>
        </form-group>
        <template v-if="pinType">
          <template v-if="pinType === 'image'">
            <form-group inline :label="$t('roulette.visualSettings.pin.pin')">
              <file-manager v-model="pinImage" widget-element="roulette_pin" file-type="image"/>
            </form-group>
            <form-group>
              <info-box class="small" :show-icon="false">
                {{ $t('roulette.visualSettings.pin.pinSizePrompt') }}
              </info-box>
            </form-group>
            <form-group inline :label="$t('global.settings.labels.hueRotation')">
              <base-range :min="0" :max="360" v-model="pinImageHue" input input-addon="°"/>
            </form-group>
            <form-group inline :label="$t('global.settings.labels.saturate')">
              <base-range :min="0" :max="300" v-model="pinImageSaturate" input input-addon="%"/>
            </form-group>
          </template>
          <template v-if="pinType === 'svg'">
            <form-group inline :label="$t('roulette.visualSettings.pin.pin')">
              <file-manager v-model="pinSvg" widget-element="roulette_pin" file-type="vector"/>
            </form-group>
            <form-group inline :label="$t('global.settings.labels.color')">
              <color-gradient-picker v-model="pinSvgColor"/>
            </form-group>
          </template>
        </template>
      </div>
      <div>
        <template v-if="pinType">
          <form-group inline :label="$t('global.settings.labels.shadow')">
            <shadow-styler v-model="pinShadow" class="left"/>
          </form-group>
          <form-group inline :label="$t('global.settings.labels.animation')">
            <dropdown-select v-model="pinAnimation" :options="pinAnimationOptions"/>
          </form-group>
          <template v-if="pinType === 'svg'">
            <form-group inline :label="$t('global.settings.labels.borderWidth')">
              <base-range v-model="pinSvgStrokeWidth" :min="0" :max="20" :step="1" input input-addon="px"/>
            </form-group>
            <form-group v-if="pinSvgStrokeWidth" inline :label="$t('global.settings.labels.borderColor')">
              <color-picker v-model="pinSvgStrokeColor"/>
            </form-group>
          </template>
        </template>
      </div>
    </grid>
  </div>
</template>

<script>
import ShadowStyler from '@components/BaseComponents/ShadowStyler/ShadowStyler'
import ColorGradientPicker from '@components/BaseComponents/ColorPicker/ColorGradientPicker'
import FileManager from '@components/BaseComponents/FileManager/FileManager'

export default {
  name: 'pinSettings',
  components: {
    FileManager,
    ColorGradientPicker,
    ShadowStyler,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      pinType: null,

      pinImage: null,
      pinImageHue: 0,
      pinImageSaturate: 100,

      pinSvg: null,
      pinSvgColor: {
        useGradient: true,
        color: '#f59c07',
        gradient: {
          colors: ['#f59c07', '#f57507'],
          angle: 135,
        },
      },
      pinSvgStrokeWidth: 10,
      pinSvgStrokeColor: '#ffffff',

      pinAnimation: null,

      pinShadow: null,
    }
  },
  computed: {
    pinAnimationOptions(){
      return [
        null,
        'spin',
        'shake',
      ].map(option => ({
        value: option,
        label: option ? this.$t(`roulette.visualSettings.pin.animationTypes.${option}`) : null,
      }))
    },

    pinTypeOptions() {
      return [
        {
          value: null,
          icon: 'cross',
        },
        {
          value: 'image',
          icon: 'image',
        },
        {
          value: 'svg',
          icon: 'vector-square',
        },
      ].map(option => ({
        ...option,
        label: option.value ? this.$t(`global.settings.labels.types.${option.value}`) : null,
      }))
    },

    output() {
      return {
        _version: Math.random().toString(36).slice(2),
        pinType: this.pinType,
        pinImage: this.pinImage,
        pinImageHue: this.pinImageHue,
        pinImageSaturate: this.pinImageSaturate,
        pinSvg: this.pinSvg,
        pinSvgColor: this.pinSvgColor,
        pinShadow: this.pinShadow,
        pinAnimation: this.pinAnimation,
        pinSvgStrokeWidth: this.pinSvgStrokeWidth,
        pinSvgStrokeColor: this.pinSvgStrokeColor,
      }
    },
  },
  created() {
    const storedValue = _.cloneDeep(this.value)

    if (storedValue) {
      Object.keys(storedValue).forEach(key => {
        this[key] = storedValue[key] ?? this[key]
      })
    }
  },
  watch: {
    output(value) {
      this.$emit('input', value)
    },
    pinImage(image, oldImage) {
      if (oldImage && oldImage !== image) {
        this.pinImageHue = 0
        this.pinImageSaturate = 100
      }
    },
  },
}
</script>
