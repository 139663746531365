import gsap from 'gsap'

export const stopAnimation = animation => {
  if (animation) {
    animation.pause()
    animation.seek(0)
  }
}

export const killAnimation = animation => {
  if (animation) {
    stopAnimation(animation)
    animation.kill()
  }
}

export { gsap as gsap }
