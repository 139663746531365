export const defaultLayoutSettings = () => ({
  'sliderSettings': {
    'slideDuration': 10,
    'animation': 'fade',
  },
  layoutSettings: {
    groupActions: true,
    columns: 1,
    rows: 4,
  },
})

export const defaultDesignSettings = () => ({
    'backgroundSettings': {
      'backgroundColor': {
        'useGradient': false,
        'color': 'rgba(0,0,0,0.7)',
        'gradient': {
          'colors': [
            '#ffffff',
            '#009dff',
          ],
          'angle': 0,
        },
      },
      'borderRadius': 8,
      'borderWidth': 2,
      'borderColor': {
        'useGradient': false,
        'color': '#f57d07',
        'gradient': {
          'colors': [
            '#ffffff',
            '#009dff',
          ],
          'angle': 0,
        },
      },
      'shadow': null,
    },
    'titleSettings': {
      'text': null,
      'backgroundColor': {
        'useGradient': false,
        'color': '#f57d07',
        'gradient': {
          'colors': [
            '#ffffff',
            '#009dff',
          ],
          'angle': 0,
        },
      },
      'textStyles': {
        'color': '#f1f1f1',
        'textTransform': 'uppercase',
        'textAlign': 'left',
        'fontWeight': 'bold',
        'fontStyle': 'normal',
        'textDecoration': 'none',
        'textShadow': null,
        'strokeWidth': 0,
        'strokeColor': '#ffffff',
        'fontFamily': 'Rubik',
        'fontSize': 10,
        'lineHeight': 1,
        'letterSpacing': 0,
        'wordSpacing': 1,
        'animation': [],
      },
    },
    'actionsSettings': {
      'actionTextStyles': {
        'color': '#f1f1f1',
        'textTransform': 'uppercase',
        'textAlign': 'left',
        'fontWeight': 'regular',
        'fontStyle': 'normal',
        'textDecoration': 'none',
        'textShadow': null,
        'strokeWidth': 0,
        'strokeColor': '#ffffff',
        'fontFamily': 'Rubik',
        'fontSize': 10,
        'lineHeight': 1,
        'letterSpacing': 0,
        'wordSpacing': 1,
        'animation': [],
      },
      'priceTextStyles': {
        'color': '#f1f1f1',
        'textTransform': 'none',
        'textAlign': 'left',
        'fontWeight': 'bold',
        'fontStyle': 'normal',
        'textDecoration': 'none',
        'textShadow': null,
        'strokeWidth': 0,
        'strokeColor': '#ffffff',
        'fontFamily': 'Rubik',
        'fontSize': 10,
        'lineHeight': 1.27,
        'letterSpacing': 0,
        'wordSpacing': 1,
        'animation': [],
      },
    },
    'separatorSettings': {
      'size': 1,
      'backgroundColor': {
        'useGradient': false,
        'color': 'rgba(255,255,255,0.2)',
        'gradient': {
          'colors': [
            '#ffffff',
            '#009dff',
          ],
          'angle': 0,
        },
      },
    },
  }
)
