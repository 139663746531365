<style scoped lang="scss">
  .goal-settings-modal {

  }
</style>

<template>
  <modal class="goal-settings-modal" v-model="modalOpened">
    <template slot="title">{{ $t('roulette.settings.goalSettings') }}</template>
    <template slot="body-content">
      <goal-settings v-if="modalOpened" v-model="goalSettings"/>
      <div class="errors-box" v-if="errors.length">
        <info-box
          v-for="(error, errorIndex) in errors"
          :key="errorIndex"
          variant="error">{{ error.message }}
        </info-box>
      </div>
    </template>
    <template slot="controls">
      <btn @click="save" :is-loading="loadingState.save" variant="primary-action">
        {{ $t('global.common.save') }}
      </btn>
      <btn @click="cancel" variant="secondary-action">
        {{ $t('global.common.cancel') }}
      </btn>
    </template>
  </modal>
</template>

<script>

import GoalSettings from './GoalSettings'

export default {
  name: 'GoalSettingsModal',
  components: { GoalSettings },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      modalOpened: false,

      loadingState: {
        save: false,
      },

      goalSettings: {},

      errors: [],
    }
  },
  computed: {
    ...mapState('roulette', {
      $goalSettings: 'goalSettings',
    }),
  },
  methods: {
    ...mapActions('roulette', ['updateGoalSettings']),

    closeModal() {
      this.modalOpened = false
    },

    save() {
      this.loadingState.save = true
      this.errors = []

      this.updateGoalSettings(this.goalSettings)
        .then(() => {
          this.closeModal()
        })
        .catch(({ errors }) => {
          this.errors = errors
        })
        .finally(() => {
          this.loadingState.save = false
        })
    },

    cancel() {
      this.reset()
      this.closeModal()
    },

    reset() {
      this.errors = []
      this.goalSettings = _.cloneDeep(this.$goalSettings)
    },
  },
  watch: {
    value: {
      handler(value) {
        this.modalOpened = value

        if (this.modalOpened) {
          this.reset()
        }
      },
      immediate: true,
    },
    modalOpened(value) {
      this.$emit('input', value)
    },
    $goalSettings: {
      handler: 'reset',
      immediate: true,
    },
  },
}
</script>
