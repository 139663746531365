<template>
  <modal v-model="modalOpened">
    <template slot="title">
      <template v-if="sendMessageSuccess">{{$t('offers.advertisersAccount.messageSent')}}</template>
      <template v-if="sendMessageError">{{$t('offers.advertisersAccount.anErrorHasOccurred')}}</template>
      <template v-if="!sendMessageSuccess && !sendMessageError">{{$t('offers.advertisersAccount.sendMessage')}}</template>
    </template>
    <template slot="body-content">
      <div v-if="sendMessageSuccess" class="success-block">
        <icon name="check-round"/>
      </div>

      <div v-if="sendMessageError" class="error-block">
        <icon name="info-circle"/>
      </div>

      <div v-if="!sendMessageSuccess && !sendMessageError">
        <form-group
          :label="$t('offers.advertisersAccount.preset') + '*'"
          inline
        >
          <dropdown-select 
            v-model="preset"
            :options="presetOptions"
            style="width: 200px"
          />
        </form-group>

        <form-group
          :label="$t('offers.advertisersAccount.message')"
          inline
        >
          <base-textarea
            style="height: 140px;"
             v-model="comment"
          />
          <span
            v-if="comment"
            class="comment-counter"
            :class="{ 'comment-counter_red': comment.length > 300 }"
          >{{ comment.length }} / 300</span>
        </form-group>

        <div class="debug" v-if="false">
          <div>adv_user_id: <span>{{ advUserId }}</span></div>
          <div>collection_id: <span>{{ preset }}</span></div>
          <div>comment_length: <span>{{ comment.length }}</span></div>
        </div>

        <div class="button-group">
          <base-button 
            variant="primary-action"
            :isLoading="isSendLoading"
            @click="sendMessage"
            :disabled="!preset || comment.length > 300"
          >{{$t('offers.send')}}</base-button>

          <base-button 
            variant="secondary-action" 
            @click="undoClickHandler"
          >{{$t('offers.undo')}}</base-button>
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
import Modal from '@components/BaseComponents/Modal'
import BaseButton from '@components/BaseComponents/BaseButton'
import BaseTextarea from '@components/BaseComponents/Form/BaseTextarea'
import FormGroup from '@components/BaseComponents/Form/FormGroup'

export default {
  components: {
    Modal,
    BaseButton,
    FormGroup,
    BaseTextarea,
  },

  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    advUserId: {
      type: Number,
      dafault: null,
    },
    collections: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      preset: null,
      comment: '',
    }
  },

  computed: {
    ...mapGetters('sendOffers/users', [
      'isSendLoading',
      'sendMessageSuccess',
      'sendMessageError',
    ]),

    modalOpened: {
      get() { return this.isShow },
      set(value) { return !value && this.undoClickHandler() },
    },

    presetOptions() {
      return this.collections
        .map(option => ({
          value: option.id,
          label: option.name,
        }))
    },
  },

  methods: {
    ...mapActions('sendOffers/users', ['fetchSendDonation', 'clearStatuses']),

    ...mapActions('profile', ['fetchCurrentProfile']),

    undoClickHandler() {
      this.$emit('toggleModal') ;
    },

    sendMessage() {
      this.fetchSendDonation({
        advUserId: this.advUserId,
        collectionId: this.preset,
        comment: this.comment,
      })
      .finally(() => {
        this.fetchCurrentProfile()
      })
    }
  },

  beforeDestroy() {
    this.clearStatuses()
  }
}
</script>

<style scoped lang="scss">
.button-group {
  padding-bottom: 20px;
  margin-top: 40px;

  button {
    margin-right: 10px;
    border-radius: 8px;
  }
}

.debug {
  margin: 20px 0 10px 0;
  padding: 10px;
  border-radius: 8px;
  border: 1px dashed #838383;
  color: #838383;

  & > div {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  span {
    color: #f1f1f1;
    font-weight: bold;
  }
}

.success-block {
  padding: 30px;
  text-align: center;
  min-width: 300px;

  svg {
    font-size: 60px;
    color: #59A840;
    margin-bottom: 30px;
  }
}

.error-block {
  padding: 30px;
  text-align: center;
  min-width: 300px;

  svg {
    font-size: 60px;
    color: #D63A3A;
    margin-bottom: 30px;
  }
}

.comment-counter {
  display: block;
  text-align: right;
  font-size: 12px;
  line-height: 20px;
  color: #838383;
  padding: 5px 0;

  &_red {
    color: red;
  }
}
</style>