<style lang="scss" scoped>
  .legacy-partnership-page {

  }
</style>

<template>
  <div class="legacy-partnership-page">
    <transition name="fade-up">
      <div v-show="!loadingState.page" ref="legacy"/>
    </transition>
  </div>
</template>

<script>

import { loadLegacyPage } from '@src/services/legacyLoader'
import { i18n } from '@src/localization/config'

export default {
  name: 'LegacyPartnershipPage',
  data() {
    return {
      loadingState: {
        page: false,
      },
    }
  },
  metaInfo() {
    return {
      title: this.$t('dashboard.pages.partnership'),
    }
  },
  computed: {
    ...mapState('application', ['isAstrumApp']),
  },
  mounted() {
    this.loadingState.page = true

    loadLegacyPage('/dashboard/old-page-offers', this.$refs.legacy)
      .finally(() => {
        this.loadingState.page = false
      })
  },
}
</script>
