<style scoped lang="scss">
  .file-manager-form {
    .file-manager-body {
      width: 1000px;
      height: 700px;
      display: flex;
      background-color: $black;
      border-radius: 5px;

      .file-manager-control-panel {
        flex: 0 0 auto;
        width: 250px;
        background-color: $additional-3;
        display: flex;
        flex-direction: column;
        border-radius: 5px 0 0 5px;

        .file-manager-category {
          padding: 30px 0 0 30px;
          display: flex;
          align-items: center;
          color: $warm-grey-two;

          .category-type-name {
            margin-left: 10px;
            text-transform: uppercase;
            font-weight: 600;
          }
        }

        .file-manager-categories {
          margin-top: 15px;
          margin-bottom: auto;

          .file-category {
            border-left: 3px solid transparent;
            padding: 5px 0 5px 27px;
            cursor: pointer;
            font-weight: 500;
            display: flex;
            align-items: center;

            .file-category-files-count {
              margin-left: .3em;
              color: $warm-grey;
            }

            &.current {
              border-left-color: var(--theme-color-additional);
            }
          }
        }

        .file-manager-upload-status {
          position: relative;

          .upload-status-error, .upload-status-success {
            @include position(absolute, auto, 20px, 10px, 20px);
            padding: 10px 15px;
            border-radius: 5px;
            font-size: 12px;
          }

          .upload-status-error {
            background: #ad0000;
          }

          .upload-status-success {
            background: green;
          }
        }

        .file-manager-upload {
          height: 80px;
          text-transform: uppercase;
          font-weight: 600;
          color: $warm-grey;
          display: flex;
          align-items: center;
          justify-content: center;
          @include margin-x(20px);
          border: 3px dashed rgba(white, .05);
          border-radius: 5px;
          cursor: pointer;

          &:hover {
            border-color: rgba(white, .2);
          }

          .upload-icon {
            margin-right: 10px;
            font-size: 24px;
          }

          .upload-text {
            max-width: 110px;
          }

          .upload-input {
            display: none;
          }
        }

        .file-manager-space {
          margin-top: 30px;
          @include margin-x(20px);

          .space-text {
            text-align: center;
            color: $warm-grey;

            .space-taken-text {
              color: white;
            }
          }

          .space-progress {
            margin-top: 10px;
            height: 6px;
            border-radius: 4px;
            background-color: $black-seven;
            position: relative;

            .space-progress-filler {
              @include position(absolute, 0, auto, 0, 0);
              background-color: white;
              border-radius: 4px;
            }
          }
        }

        .file-manager-controls {
          margin-top: 30px;
          padding: 20px;
          display: flex;
          flex-direction: column;

          .button + .button {
            margin-top: 20px;
          }
        }
      }

      .file-manager-content-panel {
        flex-grow: 1;
        padding-left: 20px;
        position: relative;
        display: flex;
        flex-direction: column;

        .content-header {
          flex-shrink: 0;
          padding-right: 60px;
          position: relative;
          z-index: 5;
          height: 60px;
          display: flex;
          align-items: center;
          font-weight: 500;
          font-size: 18px;
          //background-image: linear-gradient(to bottom, rgba($black, 1), rgba($black, 1) calc(100% - 20px), rgba($black, 0));
        }

        .content-wrapper {
          min-height: 0;
          position: relative;
          z-index: 4;
          overflow-y: auto;
          border-radius: 3px;
          padding-right: 7px;
          margin-right: 7px;
          //margin-top: -20px;

          .content-list {
            display: grid;
            grid-gap: 10px;
            //@include padding-y(20px);
            position: relative;

            .file {
              background-color: $additional-2;
              border-radius: 5px;
              border: solid 2px transparent;
              position: relative;
              display: flex;
              cursor: pointer;
              user-select: none;

              .file-controls {
                opacity: 0;
                @include position(absolute, 0, 0, auto, 0);
                display: flex;
                align-items: center;

                .file-delete-button {
                  margin-left: auto;
                  background-color: $additional-3;
                  cursor: pointer;
                  color: rgba(white, .3);
                  border-radius: 4px;
                  width: 30px;
                  height: 30px;
                  display: flex;
                  align-items: center;
                  justify-content: center;

                  &:hover {
                    background-color: $black-seven;
                    color: rgba(white, .5);
                  }
                }
              }

              &:hover {
                .file-controls {
                  opacity: 1;
                }
              }

              &.selected {
                border-color: var(--theme-color-additional);
              }

              .file-content {

              }
            }

            &.sound {
              grid-template-columns: 1fr;

              .file {
                align-items: center;

                .file-content {
                  padding: 8px;
                }

                .file-name {
                  margin-left: 8px;
                }
              }
            }

            &.image, &.vector {
              padding-bottom: 20px;
              grid-template-columns: repeat(4, 170px);
              grid-auto-rows: 190px;

              .file {
                flex-direction: column;
                align-items: center;
                justify-content: center;

                .file-content {
                  display: flex;
                  align-items: center;
                  width: 100%;
                  height: 150px;
                  padding: 13px 13px 0 13px;
                  justify-content: center;
                  flex-grow: 0;

                  .file-image {
                    max-width: 100%;
                    max-height: 100%;
                  }

                  .file-video {
                    max-width: 100%;
                    max-height: 100%;
                  }

                  .file-image-svg {
                    max-width: 100%;
                    max-height: 100%;
                    fill: white;
                  }
                }

                .file-name {
                  width: 100%;
                  flex-grow: 1;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  padding: 0 10px;
                  text-align: center;
                  font-size: 11px;
                  word-break: break-all;
                }
              }
            }
          }
        }

        .content-controls {
          padding-right: 20px;
          margin-top: auto;
          @include padding-y(20px);
          flex-shrink: 0;
        }
      }
    }
  }
</style>

<template>
  <div class="file-manager-form">
    <div class="file-manager-body">
      <div class="file-manager-control-panel">
        <div class="file-manager-category">
          <icon :name="fileType.icon"/>
          <div class="category-type-name">{{ fileType.name }}</div>
        </div>
        <div class="file-manager-categories">
          <div
            v-for="category in categories"
            :key="category.id"
            class="file-category"
            :class="{current: category.id === currentCategoryId}"
            @click="selectCategory(category.id)">
            <div class="file-category-name">{{ category.name }}</div>
            <div class="file-category-files-count">({{ category.filesCount }})</div>
          </div>
        </div>
        <template v-if="uploadAvailable">
          <div class="file-manager-upload-status">
            <transition name="fade">
              <div v-if="displayError" @click="hideUploadMessages" class="upload-status-error">
                <div
                  v-for="(error, errorIndex) in uploadErrors"
                  :key="errorIndex">{{ error }}
                </div>
              </div>
            </transition>
            <transition name="fade">
              <div v-if="displaySuccess" @click="hideUploadMessages" class="upload-status-success">
                {{ $t('global.fileManager.panel.uploadSuccessMessage') }}
              </div>
            </transition>
          </div>
          <div
            ref="uploader"
            class="file-manager-upload"
            @click="addAttachment">
            <div class="upload-icon">
              <loader v-if="uploadingInProgress"/>
              <icon v-else name="upload"/>
            </div>
            <div class="upload-text">
              <template v-if="uploadingInProgress">
                {{ $t('global.fileManager.panel.uploading') }}
              </template>
              <template v-else>
                {{ $t('global.fileManager.panel.uploadFile') }}
              </template>
            </div>
            <input
              class="upload-input"
              ref="upload-input"
              type="file"
              @change="uploadAttachment">
          </div>
          <div class="file-manager-space">
            <div class="space-text">
              <span class="space-taken-text">{{ userSpaceInfo.data.taken }}</span>
              / {{ userSpaceInfo.data.limit }} {{ $t('global.fileManager.panel.megabytes') }}
            </div>
            <div class="space-progress">
              <div class="space-progress-filler" :style="userSpaceInfo.styles"/>
            </div>
          </div>
        </template>
        <div class="file-manager-controls">
          <btn
            variant="primary-action"
            icon-left="check"
            :disabled="!selectedFileId"
            @click="submitFile">{{ $t('global.fileManager.panel.chooseFile') }}
          </btn>
          <btn
            variant="secondary-action"
            @click="closeFileManager">{{ $t('global.common.cancel') }}
          </btn>
        </div>
      </div>
      <div class="file-manager-content-panel">
        <div class="content-header">{{ $t('global.fileManager.panel.gallery') }}</div>
        <div class="content-wrapper" ref="content-wrapper">
          <div class="content-list" :class="{[fileType.code]: true}">
            <div
              v-for="file in categoryFiles"
              ref="files"
              :key="file.id"
              class="file"
              :class="{selected: file.id === selectedFileId}"
              @click="selectFile(file.id)"
              @dblclick="chooseFile(file.id)">
              <div v-if="!file.category" class="file-controls">
                <div class="file-delete-button" @click="removeFile(file.id)">
                  <icon name="trash"/>
                </div>
              </div>
              <div class="file-content">
                <template v-if="['vector', 'image'].includes(fileType.code)">
                  <video
                    v-if="file.ext === 'webm'"
                    class="file-video"
                    autoplay
                    loop
                    muted
                    :src="file.url"/>
                  <img
                    v-if="['webp', 'gif', 'jpg', 'jpeg', 'png'].includes(file.ext)"
                    class="file-image"
                    :src="file.url"/>
                  <svg-image-external
                    v-if="file.ext === 'svg'"
                    :src="file.url"
                    class="file-image-svg"/>
                </template>
                <template v-if="fileType.code === 'sound'">
                  <audio-button :src="file.url" :volume="audioVolume"/>
                </template>
              </div>
              <div class="file-name">{{ file.name }}</div>
            </div>
          </div>
        </div>
        <div v-if="fileType.code === 'sound'" class="content-controls audio-controls">
          <form-group inline :label="$t('global.fileManager.panel.volume')">
            <base-range v-model="audioVolume" :min="0" :max="100" :step="1" input input-addon="%"/>
          </form-group>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import SvgImageExternal from '@components/BaseComponents/SvgImageExternal'
import AudioButton from '@components/BaseComponents/AudioButton'

const preventFileOpening = (e) => {
  e.preventDefault()
  e.stopPropagation()
}

export default {
  name: 'FileManagerForm',
  components: {
    AudioButton,
    SvgImageExternal,
  },
  data() {
    return {
      selectedFileId: null,
      currentCategoryId: null,

      audioVolume: 50,

      dragAndDropCapable: false,
      droppedFiles: [],
      dragOver: false,
      attachments: {},
      mode: 'input',
      uploadingInProgress: false,

      uploadErrors: [],
      displayError: false,
      displaySuccess: false,
      uploadMessagesTimeout: null,
    }
  },
  computed: {
    ...mapState('fileManager', [
      'fileManagerFileId',
      'fileManagerFileType',
      'fileManagerWidgetElement',
    ]),
    ...mapGetters('fileManager', [
      'getFile',
      'getFiles',
      'getUserSpaceInfo',
    ]),

    userSpaceInfo() {
      const { limit, taken } = this.getUserSpaceInfo

      const finalLimit = _.round(limit / 1000000)
      const finalTaken = _.round(taken / 1000000)

      return {
        data: {
          limit: finalLimit,
          taken: finalTaken,
        },
        styles: {
          width: `${_.round((finalTaken / finalLimit) * 100)}%`,
        },
      }
    },

    uploadAvailable() {
      return this.fileManagerFileType !== 'vector'
    },

    fileType() {
      const fileTypeIcons = {
        image: 'image',
        video: 'image',
        vector: 'vector-square',
        sound: 'music',
      }

      const fileType = this.fileManagerFileType

      return {
        code: fileType,
        icon: fileTypeIcons[fileType],
        name: this.$t(`global.fileManager.types.${fileType}`),
      }
    },

    files() {
      return this.getFiles
    },

    categories() {
      const categories = {}

      this.files.forEach(file => {
        const { category } = file

        if (category) {
          if (!categories[category.id]) {
            categories[category.id] = {
              ...category,
              filesCount: 0,
            }
          }

          categories[category.id].filesCount++
        }
      })

      const categoriesList = Object.values(categories)

      if (this.uploadAvailable) {
        categoriesList.push({
          id: null,
          name: this.$t('global.fileManager.panel.uploadedFiles'),
          filesCount: this.files.filter(file => !file.category).length,
        })
      }

      return categoriesList
    },

    categoryFiles() {
      return this.currentCategoryId
        ? this.files
          .filter(file => file.category && file.category.id === this.currentCategoryId)
        : this.files
          .filter(file => !file.category)
    },
  },
  methods: {
    ...mapActions('fileManager', [
      'chooseFile',
      'closeFileManager',
      'uploadFile',
      'fetchFiles',
      'deleteFile',
    ]),

    hideUploadMessages() {
      this.displayError = false
      this.displaySuccess = false
    },

    selectCategory(categoryId) {
      this.currentCategoryId = categoryId

      const contentWrapperElement = this.$refs['content-wrapper']

      contentWrapperElement.scrollTo({
        top: 0,
      })
    },

    selectFile(fileId) {
      this.selectedFileId = fileId

      this.hideUploadMessages()
    },

    submitFile() {
      this.chooseFile(this.selectedFileId)

      this.hideUploadMessages()
    },

    scrollToSelectedFile() {
      if (!this.selectedFileId) {
        return
      }

      const selectedFileIndex = this.categoryFiles.findIndex(file => file.id === this.selectedFileId)

      if (selectedFileIndex >= 0) {
        this.$nextTick(() => {
          const selectedFileElement = this.$refs.files[selectedFileIndex]
          const contentWrapperElement = this.$refs['content-wrapper']

          const { height: contentWrapperHeight } = contentWrapperElement.getBoundingClientRect()
          const { height: selectedFileHeight } = selectedFileElement.getBoundingClientRect()

          contentWrapperElement.scrollTo({
            top: selectedFileElement.offsetTop - Math.round(contentWrapperHeight / 2) + Math.round(
              selectedFileHeight / 2),
          })
        })
      }
    },

    onDragOver() {
      this.dragOver = true
    },

    onDragLeave() {
      this.dragOver = false
    },

    onDrop(e) {
      this.hideUploadMessages()

      this.mode = 'drag'
      this.dragOver = false

      if (this.multiple) {
        this.droppedFiles = e.dataTransfer.files
      } else {
        this.droppedFiles = [_.head(e.dataTransfer.files)]
      }
    },

    determineDragAndDropCapable() {
      const div = document.createElement('div')

      return (('draggable' in div)
        || ('ondragstart' in div && 'ondrop' in div))
        && 'FormData' in window
        && 'FileReader' in window
    },

    addAttachment() {
      if (!this.uploadAvailable) {
        return
      }

      this.mode = 'input'
      this.$refs['upload-input'].click()
    },

    uploadAttachment() {
      if (!this.uploadAvailable) {
        return
      }

      const files = this.mode === 'input' ? this.$refs['upload-input'].files : this.droppedFiles

      const file = files[0]

      if (!file) {
        return
      }

      this.uploadingInProgress = true

      const formData = new FormData()
      formData.append('file', file)
      formData.append('widget_element_code', this.fileManagerWidgetElement)

      this
        .uploadFile({
          formData,
          // progressHandler: (percent) => {
          //   console.log(`Uploading ${percent}%`)
          // },
        })
        .then((file) => {
          this.currentCategoryId = null
          this.selectFile(file.id)
          this.scrollToSelectedFile()

          this.displaySuccess = true
        })
        .catch(({ errors = [] }) => {
          this.uploadErrors = errors.map(error => error.message)
          this.displayError = true
        })
        .finally(() => {
          this.uploadingInProgress = false

          this.uploadMessagesTimeout = setTimeout(this.hideUploadMessages, 4000)
        })
    },

    removeFile(fileId) {
      const confirmed = confirm(this.$t('global.fileManager.panel.confirmDelete'))

      if (confirmed) {
        this.deleteFile(fileId)
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.scrollToSelectedFile()
    })

    if (this.$refs.uploader) {
      this.dragAndDropCapable = this.determineDragAndDropCapable()

      if (this.dragAndDropCapable) {
        ['drag', 'dragstart', 'dragend', 'dragover', 'dragenter', 'dragleave', 'drop'].forEach(
          (evt) => {
            this.$refs.uploader.addEventListener(evt, preventFileOpening, false)
            window.addEventListener(evt, preventFileOpening, false)
          },
        )

        this.$refs.uploader.addEventListener('dragover', this.onDragOver)
        this.$refs.uploader.addEventListener('dragleave', this.onDragLeave)
        this.$refs.uploader.addEventListener('drop', this.onDrop)
      }
    }
  },
  beforeDestroy() {
    if (this.$refs.uploader) {
      ['drag', 'dragstart', 'dragend', 'dragover', 'dragenter', 'dragleave', 'drop'].forEach(
        (evt) => {
          this.$refs.uploader.removeEventListener(evt, preventFileOpening)
          window.removeEventListener(evt, preventFileOpening, false)
        },
      )

      this.$refs.uploader.removeEventListener('dragover', this.onDragOver)
      this.$refs.uploader.removeEventListener('dragleave', this.onDragLeave)
      this.$refs.uploader.removeEventListener('drop', this.onDrop)
    }
  },
  watch: {
    fileManagerFileId: {
      handler(fileId) {
        this.selectedFileId = fileId

        this.currentCategoryId = _.head(this.categories).id

        if (fileId) {
          const file = this.getFile(fileId)

          if (file) {
            this.currentCategoryId = file.category ? file.category.id : null
          }
        }
      },
      immediate: true,
    },
    droppedFiles() {
      this.uploadAttachment('drop')
    },
  },
}
</script>
