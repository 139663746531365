<style lang="scss" scoped>
  .base-switch {
    display: flex;
    align-items: center;
    user-select: none;

    .switch-label + .switch,
    .switch + .switch-label {
      margin-left: 10px;
    }

    .switch-label {
      font-weight: 500;
      color: $warm-grey;
      cursor: pointer;
      display: flex;
      align-items: center;
      position: relative;

      .icon {
        font-size: 16px;
        margin-right: 7px;
      }

      &.left {
        color: $bright;
      }

      .label-addon {
        @include position(absolute, -5px, auto, auto, 100%);
        color: white;
        margin-left: -5px;
        font-size: 9px;
        font-weight: bold;
        text-transform: uppercase;
        padding: 2px 5px;
        border-radius: 8px;
        background-image: linear-gradient(to right, var(--theme-color-main), var(--theme-color-additional));
      }
    }

    &.active {
      .switch-label {
        &.left {
          color: $warm-grey;
        }

        &.right {
          color: $bright;
        }
      }
    }

    @mixin switch($switch-width, $switch-padding, $switch-toggle-size) {
      width: $switch-width;
      height: $switch-toggle-size + $switch-padding * 2;

      .toggle {
        top: $switch-padding;
        left: $switch-padding;
        width: $switch-toggle-size;
        height: $switch-toggle-size;
      }

      &.active {
        .toggle {
          transform: translateX($switch-width - $switch-toggle-size - $switch-padding * 2);
        }
      }
    }

    .switch {
      position: relative;
      border-radius: 18px;
      background-color: $brownish-grey;
      cursor: pointer;

      .toggle {
        position: absolute;
        border-radius: 100%;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.39);
        background-color: #ffffff;
        transition: transform .1s;
      }

      &.active {
        background-image: linear-gradient(to right, var(--theme-color-main), var(--theme-color-additional));
      }

      @include switch(55px, 2px, 28px)
    }

    &.small {
      .switch {
        @include switch(40px, 3px, 15px)
      }

      .switch-label {
        font-size: 14px;
        font-weight: 600;
        text-transform: uppercase;
      }
    }
  }
</style>

<template>
  <div class="base-switch" :class="classes">
    <div
      v-if="leftOption.label || leftOption.icon"
      class="switch-label left"
      @click="toggle(0)">
      <icon v-if="leftOption.icon" :name="leftOption.icon" gradient/>
      {{leftOption.label}}
      <div v-if="leftOption.addon" class="label-addon">{{leftOption.addon}}</div>
    </div>
    <div
      class="switch"
      :class="classes"
      @click="toggle(null)">
      <div class="toggle"></div>
    </div>
    <div
      v-if="rightOption.label || rightOption.icon"
      class="switch-label right"
      @click="toggle(1)">
      <icon v-if="rightOption.icon" :name="rightOption.icon" gradient/>
      {{rightOption.label}}
      <div v-if="rightOption.addon" class="label-addon">{{rightOption.addon}}</div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    value: {
      default: null,
    },
    options: {
      type: Array,
      default: () => ([false, true]),
    },
    small: {
      type: Boolean,
      default: false,
    },
    tabs: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    toggle(index = null) {
      let activeIndex = null
      if (index === null) {
        activeIndex = Number(!this.activeIndex)
      } else {
        activeIndex = index
      }

      this.$emit('input', this.optionValues[activeIndex])
    },
  },
  computed: {
    classes() {
      return {
        active: !!this.activeIndex,
        small: this.small,
        tabs: this.tabs,
      }
    },

    leftOption() {
      return this.options[0]
    },

    rightOption() {
      return this.options[1]
    },

    optionValues() {
      return this.options.map(option => _.isObject(option) ? option.value : option)
    },
    activeIndex() {
      return this.optionValues.indexOf(this.value)
    }
  },
}
</script>
