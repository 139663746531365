import * as clipboard from 'clipboard-polyfill'

export const copyToClipboard = (text) => clipboard.writeText(text)

export const discharge = (amount) => amount.toString().replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ')

export const openWindow = ({ url, name, params }) => {
  const prepare = (value) => value === true ? 'yes' : (value === false ? 'no' : value)
  window.open(url, name, Object.keys(params).reduce((acc, key) => {
    acc.push([key, prepare(params[key])].join('='))
    return acc
  }, []).join(','))
}

export const numbersToUnits = (obj, unit = 'px') => {
  return obj ? Object.keys(obj)
    .reduce((acc, key) => {
      acc[key] = [
        'borderRadius',
        'borderWidth',
        'fontSize',
        'letterSpacing',
        'wordSpacing',
      ].includes(key) ? `${obj[key]}px` : obj[key]
      return acc
    }, {}) : null
}

export const textToNumbers = (obj, keys = []) => {
  return obj ? Object.keys(obj)
    .reduce((acc, key) => {
      acc[key] = keys.includes(key) ? Number(obj[key]) : obj[key]
      return acc
    }, {}) : null
}

export const createCssFilters = (filters) => {
  const output = []

  if (filters.shadow) {
    output.push(`drop-shadow(${filters.shadow})`)
  }

  if (!_.isNil(filters.hue)) {
    output.push(`hue-rotate(${filters.hue}deg)`)
  }

  if (!_.isNil(filters.saturate)) {
    output.push(`saturate(${filters.saturate}%)`)
  }

  return output.join(' ')
}

export const createGradient = (colors, hard = false) => {
  const gradient = []

  const step = 100 / (colors.length - 1 + (hard ? 1 : 0))
  let currentOffset = 0

  const createStop = percent => `${percent}%`

  for (let i = 0; i < colors.length - 1; i++) {
    currentOffset = step * i

    gradient.push({
      offset: createStop(step * i),
      color: colors[i],
    })

    if (hard) {
      currentOffset = step * (i + 1) - .00001

      gradient.push({
        offset: createStop(currentOffset),
        color: colors[i],
      })
    }
  }

  if (hard) {
    gradient.push({
      offset: createStop(currentOffset + .00001),
      color: _.last(colors),
    })
  }

  gradient.push({
    offset: createStop(100),
    color: _.last(colors),
  })

  return gradient
}

export const createGradientString = (colors, hard = false) => {
  return createGradient(colors, hard).reduce((acc, stop) => {
    acc.push(`${stop.color} ${stop.offset}`)
    return acc
  }, []).join(', ')
}

export const parseGradientColor = gradientObj => {
  const { color = null, gradient = {}, useGradient = false } = gradientObj
  const { colors = [], angle = 0, hard = false } = gradient

  return {
    color,
    colors,
    angle,
    hard,
  }
}

export const getInRange = (value, range) => {
  return value < range[0] ? range[0] : (value > range[1] ? range[1] : value)
}

export const mapFields = (data, fieldsMap, sourceIsForeign = false) => {
  return Object.entries(fieldsMap)
    .reduce((acc, [key, foreignKey]) => {
      const [sourceKey, destKey] = sourceIsForeign ? [foreignKey, key] : [key, foreignKey]

      if (data[sourceKey] !== undefined) {
        acc[destKey] = data[sourceKey]
      }
      return acc
    }, {})
}

export const mapValue = (data, valuesMap, sourceIsForeign = false) => {
  return sourceIsForeign ? _.invert(valuesMap)[data] : valuesMap[data]
}

export const validateEmail = (email) => {
  return /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(email)
}

export const isNumberBtn = (evt) => {
  let charCode = (evt.which) ? evt.which : evt.keyCode
  
  if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
    evt.preventDefault()
  } else {
    return true
  }
}
