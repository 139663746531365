<template>
  <a class="file" :href="link" download>
    <div class="file__title">{{ name }}</div>

    <div v-if="size" class="file__size">
      <icon name="download" />
      <span>{{ formatBytes(size) }}</span>
    </div>
  </a>
</template>

<script>
export default {
  name: 'File',

  props: {
    link: {
      type: String,
      default: '#',
    },
    name: {
      type: String,
      default: ''
    },
    size: {
      type: Number,
      default: 0
    },
  },

  methods: {
    formatBytes(bytes, decimals = 2) {
      if (bytes === 0) return '0 Bytes';

      // const k = 1024;
      const k = 1000;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

      const i = Math.floor(Math.log(bytes) / Math.log(k));

      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }
  }
}

</script>

<style scoped lang="scss">
.file {
  display: inline-block;
  padding: 15px;
  background-color: #262626;
  border-radius: 5px;
  text-decoration: none;
  cursor: pointer;
  font-size: 14px;
  color: #f1f1f1;
  user-select: none;

  &__title {
    font-weight: 500;
    margin-bottom: 5px;
  }

  &__size {
    & > svg {
      margin-right: 10px;
      vertical-align: middle;
      fill: url(#theme_svg_gradient);
    }

    & > span {
      vertical-align: middle;
      opacity: .5;
    }
  }
}
</style>
