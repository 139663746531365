<style lang="scss" scoped>
  .widget-stream-credits {

  }
</style>

<template>
  <div class="widget-stream-credits">
    <chat-bot-wrapper>
      <stream-credits/>
    </chat-bot-wrapper>
  </div>
</template>

<script>

import ChatBotWrapper from '@pages/Dashboard/ChatBot/ChatBotWrapper'

export default {
  name: 'WidgetStreamCredits',
  components: { ChatBotWrapper },
  metaInfo() {
    const title = [
      this.$t('dashboard.pages.widgets.streamCredits'),
      this.$t('dashboard.pages.widgets.widgets'),
    ]

    return {
      title: title.join(' - '),
    }
  },
}
</script>
