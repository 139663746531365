<template>
  <div>
    <loader
      size="md"
      v-if="isSettingsLoading"
    />
    <div v-else>
      <stub v-if="!settings">
        <template v-slot:title>
          <Title type="2">{{ $t('bft.settings.stubTitle') }}</Title>
        </template>

        <template v-slot:content>
          <p>{{ $t('bft.settings.stubContent') }}</p>

          <div class="button-container">
            <bft-download-link/>
          </div>
        </template>
      </stub>

      <div v-else>
        <form-group
          class="form-group"
          :label="$t('bft.settings.enabledLabel')"
          inline
        >
          <base-switch
            :options="enabledOptions"
            :value="!!this.settings.is_enabled"
            @input="e => this.updateIsEnabledSettings(e)"
          />
        </form-group>

        <!-- <form-group
          class="form-group"
          :label="$t('bft.settings.showLabel')"
          inline
        >
          <base-switch
            :options="donationpageOptions"
            :value="!!this.settings.is_on_donationpage"
            @input="e => this.updateIsOnDonationpageSettings(e)"
          />
        </form-group> -->

        <form-group
          class="form-group"
          :label="$t('bft.settings.linkLabel')"
          inline
        >
          <copy-link
            :src="profile && profile.name ? `https://www.donationalerts.com/r/${ profile.name }` : ''"
            style="max-width: 420px;"
          />
        </form-group>

        <GameInfo/>
      </div>
    </div>
  </div>
</template>

<script>
import Title from '@components/BaseComponents/Title'
import FormGroup from '@components/BaseComponents/Form/FormGroup'
import BaseSwitch from '@components/BaseComponents/BaseSwitch'
import BaseButton from '@components/BaseComponents/BaseButton'

import CopyLink from '@components/Dashboard/Bft/CopyLink'
import Stub from '@components/Dashboard/Bft/Stub'
import GameInfo from '@components/Dashboard/Bft/GameInfo'
import BftDownloadLink from '@pages/Dashboard/Bft/BftDownloadLink'


export default {
  name: 'ChaosTricksSettingsPage',

  components: {
    BftDownloadLink,
    Title,
    FormGroup,
    BaseSwitch,
    CopyLink,
    Stub,
    GameInfo,
    BaseButton,
  },

  data() {
    return {}
  },

  metaInfo() {
    return {
      title: [
        this.$t('dashboard.pages.bft.settings'),
        this.$t('dashboard.pages.bft.bft'),
      ].join(' - '),
    }
  },

  watch: {},

  created() {
    this.fetchCurrentProfile()
    this.getStats()
    this.getSettings()
  },

  computed: {
    ...mapGetters('profile', {
      profile: 'getCurrentProfile',
    }),

    ...mapGetters('bft/settings', [
      'isSettingsLoading',
      'settings',
    ]),

    enabledOptions() {
      return [
        { value: false, label: this.$t(`bft.settings.off`) },
        { value: true, label: this.$t(`bft.settings.on`) },
      ]
    },
  },

  methods: {
    ...mapActions('profile', ['fetchCurrentProfile']),

    ...mapActions('bft/settings', ['getSettings', 'updateSettings', 'getStats']),

    updateIsEnabledSettings(val) {
      if (val !== !!this.settings.is_enabled) {
        this.updateSettings({
          is_enabled: val,
          // is_on_donationpage: !!this.settings.is_on_donationpage,
        })
      }
    },
  },

  mounted() {
    this.$tmr.goal({ goal: 'chaosTricks_tab_open' }, {
      tab: 'settings',
    })
  },
}
</script>

<style scoped lang="scss">
  .form-group {
    margin-top: 30px;
  }

  .button-container {
    margin-top: 20px;
  }
</style>
