<style scoped lang="scss">
  .bft-history-page {
    position: relative;
    min-height: 120px;

    .loader-container {
      @include position-all(absolute, 0);
      z-index: 15;
      background: rgba($black, .9);
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 15px;
    }
  }

  .filter-options {
    display: flex;
    align-items: center;

    .dropdown-select {
      width: 230px;
    }

    .dropdown-select + .dropdown-select {
      margin-left: 15px;
    }
  }

  .history-content {
    margin-top: 15px;
  }

  .history-table {
    width: 100%;

    .history-table-header {
      th {
        padding: 10px 15px;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #838383;
      }
    }

    .history-table-row {
      &:nth-child(odd) {
        .history-table-cell {
          background: #262626;

          &:first-child {
            border-radius: 8px 0 0 8px;
          }

          &:last-child {
            border-radius: 0 8px 8px 0;
          }
        }
      }

      .history-table-cell {
        padding: 10px 15px;

        &.amounts {
          width: 200px;
          padding-right: 50px;
        }

        .history-actions-list {
          .history-action {
            display: flex;
            align-items: center;

            .history-action-count {
              margin-left: .2em;
              color: #838383;
            }
          }

          .history-action + .history-action {
            margin-top: 5px;
          }
        }
      }
    }
  }

  .amounts-table {
    width: 100%;

    .amount-row {
      display: flex;
      align-items: center;
      white-space: nowrap;

      .amount-row-gross {
        margin-right: 15px;
        font-weight: 400;
        line-height: 18px;
        color: var(--theme-color-main);
      }

      .amount-row-commission {
        font-weight: 400;
        font-size: 10px;
        line-height: 16px;
        color: #838383;
      }

      .amount-row-commission {
        margin-left: auto;
      }

      &.details {
        .amount-row-gross {
          font-size: 12px;
        }
      }

      &.total {
        margin-top: 4px;
        padding-top: 4px;
        border-top: 1px solid #363636;

        .amount-row-gross {
          font-size: 14px;
        }
      }
    }

    .amount-row + .amount-row {
      margin-top: 4px;
    }
  }

  .history-filter-link {
    padding-bottom: 4px;
    border-bottom: 1px dashed #F1F1F1;
    cursor: pointer;

    &:hover {
      color: var(--theme-color-main);
      border-color: currentColor;
    }
  }

  .sort-link {
    cursor: pointer;
    user-select: none;

    .symbol {
      visibility: hidden;
    }

    &.active {
      color: var(--theme-color-main);

      .symbol {
        visibility: visible;
      }
    }
  }

  .pagination {
    justify-content: center;
    margin-top: 40px;
  }

  .history-empty-info {
    margin-top: 15px;
  }
</style>

<template>
  <div class="bft-history-page">
    <transition name="fade">
      <div v-if="loadingState.history" class="loader-container">
        <loader size="lg"/>
      </div>
    </transition>
    <div class="filter-options">
      <dropdown-select v-model="filterPeriod" :options="filterPeriodOptions"/>
      <dropdown-select v-model="filterGame" :options="filterGameOptions"/>
      <dropdown-select
        v-if="filterGame && filterActionOptions.length"
        v-model="filterAction"
        :options="filterActionOptions"/>
    </div>

    <div class="history-content" v-if="rows.length">
      <table class="history-table">
        <tr class="history-table-header">
          <th class="history-table-header-cell date">
            <span
              class="sort-link"
              :class="{active: sort === 'created_at'}"
              @click="toggleSort('created_at')">{{ $t('bft.history.date') }} <span class="symbol">{{
                sortSymbol
              }}</span></span>
          </th>
          <th class="history-table-header-cell actions">{{ $t('bft.history.boughtActions') }}</th>
          <th class="history-table-header-cell game">{{ $t('bft.history.game') }}</th>
          <th class="history-table-header-cell amounts">
            <span
              class="sort-link"
              :class="{active: sort === 'cost'}"
              @click="toggleSort('cost')">{{ $t('bft.history.amount') }} <span class="symbol">{{
                sortSymbol
              }}</span></span>
          </th>
        </tr>

        <tbody>
        <tr v-for="(row, rowIndex) in rows" :key="rowIndex" class="history-table-row">
          <td class="history-table-cell date">{{ row.date }}</td>
          <td class="history-table-cell actions">
            <div class="history-actions-list">
              <div v-for="(action, actionIndex) in row.actions" :key="actionIndex" class="history-action">
                <div class="history-action-title">{{ action.title }}</div>
                <div v-if="action.count > 1" class="history-action-count">({{ action.count }})
                </div>
              </div>
            </div>
          </td>
          <td class="history-table-cell game">
            <span class="history-filter-link" @click="setFilterGame(row.game.id)">{{
                row.game.title
              }}</span>
          </td>
          <td class="history-table-cell amounts">
            <div class="amounts-table">
              <div class="amount-row details">
                <div class="amount-row-gross">{{ row.amount.alert.gross }}</div>
                <div class="amount-row-commission">-{{ row.amount.alert.commission }}</div>
              </div>
              <div class="amount-row details">
                <div class="amount-row-gross">{{ row.amount.bft.gross }}</div>
                <div class="amount-row-commission">-{{ row.amount.bft.commission }}</div>
              </div>
              <div class="amount-row total">
                <div class="amount-row-gross">{{ row.amount.total.gross }}</div>
                <div class="amount-row-commission">-{{ row.amount.total.commission }}</div>
              </div>
            </div>
          </td>
        </tr>
        </tbody>
      </table>

      <div class="pagination" v-if="rows.length > 0">
        <pagination
          v-if="pagesCount > 1"
          v-model="page"
          :disabled="loadingState.history"
          :current-page="page"
          :pages-count="pagesCount"
        />
      </div>
    </div>
    <info-box class="history-empty-info" v-else-if="!loadingState.initial">
      {{ $t('bft.history.noActionsFound') }}
    </info-box>
  </div>
</template>

<script>
import Pagination from '@components/BaseComponents/Pagination'

export default {
  name: 'ChaosTricksHistoryPage',
  components: {
    Pagination,
  },
  metaInfo() {
    return {
      title: [
        this.$t('dashboard.pages.bft.statistics'),
        this.$t('dashboard.pages.bft.bft'),
      ].join(' - '),
    }
  },
  data() {
    return {
      loadingState: {
        initial: true,
        history: true,
      },

      page: 1,
      perPage: 4,
      pagesCount: null,
      total: null,

      rows: [],

      filterPeriod: 'allTime',
      filterGame: null,
      filterAction: null,

      sort: 'created_at',
      sortOrder: 'desc',
    }
  },
  computed: {
    ...mapGetters('bft/history', ['getGamesList', 'getActionsList', 'getAction']),

    filterPeriodOptions() {
      return [
        'allTime',
        'day',
        'week',
        'month',
      ].map(option => ({
        value: option,
        label: this.$t(`bft.history.periodFilter.${option}`),
      }))
    },

    filterGameOptions() {
      return [
        {
          value: null,
          label: this.$t('bft.history.gameFilter.allGames'),
        },
        ...this.getGamesList.map(game => ({
          value: game.id,
          label: game.title,
        })),
      ]
    },

    filterActionOptions() {
      return this.filterGame ? [
        {
          value: null,
          label: this.$t('bft.history.actionFilter.allActions'),
        },
        ...this.getActionsList
          .filter(action => action.gameId === this.filterGame)
          .map(action => ({
            value: action.id,
            label: action.title,
          })),
      ] : []
    },

    filter() {
      return {
        period: this.filterPeriod,
        gameId: this.filterGame,
        actionId: this.filterAction,
      }
    },

    sortSymbol() {
      return this.sortOrder === 'desc' ? ' ↓' : ' ↑'
    },

    sortString() {
      return this.sort && this.sortOrder ? `${this.sort},${this.sortOrder}` : null
    },
  },
  methods: {
    ...mapActions('bft/history', ['fetchActionsHistory']),

    toggleSort(sort) {
      if (this.sort === sort) {
        if (this.sortOrder === 'asc') {
          this.sort = null
          this.sortOrder = null
        } else if (this.sortOrder === 'desc') {
          this.sortOrder = 'asc'
        } else {
          this.sortOrder = 'desc'
        }
      } else {
        this.sort = sort
        this.sortOrder = 'desc'
      }
    },

    setFilterGame(gameId) {
      this.filterGame = gameId
    },

    getActionsHistory() {
      this.loadingState.history = true

      this.fetchActionsHistory({
        page: this.page,
        perPage: this.perPage,
        filter: this.filter,
        sort: this.sortString ? [this.sortString] : null,
      })
        .then(({ rows, pagination }) => {
          this.rows = rows

          this.page = pagination.page
          this.perPage = pagination.perPage
          this.pagesCount = pagination.pagesCount
          this.total = pagination.total
        })
        .finally(() => {
          this.loadingState.initial = false
          this.loadingState.history = false
        })
    },
  },
  created() {
    this.getActionsHistory()
  },
  watch: {
    page: 'getActionsHistory',
    sortString: 'getActionsHistory',
    filterGame(value) {
      if (this.filterAction) {
        const action = this.getAction(this.filterAction)

        if (action.gameId !== value) {
          this.filterAction = null
        }
      }
    },
    filter() {
      this.page = 1

      this.getActionsHistory()
    },
  },
}
</script>
