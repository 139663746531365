import { render, staticRenderFns } from "./GoalTextSettings.vue?vue&type=template&id=43c29ae1&scoped=true"
import script from "./GoalTextSettings.vue?vue&type=script&lang=js"
export * from "./GoalTextSettings.vue?vue&type=script&lang=js"
import style0 from "./GoalTextSettings.vue?vue&type=style&index=0&id=43c29ae1&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "43c29ae1",
  null
  
)

export default component.exports