<style lang="scss">
  .widget-roulette-page {
    flex: 1 0 auto;
    min-height: 100%;
  }
</style>

<template>
  <div class="widget-roulette-page">
    <roulette-config v-if="loadingState.config"/>
  </div>
</template>

<script>

import RouletteConfig from '@components/Dashboard/Roulette/RouletteConfig'

export default {
  name: 'WidgetRoulettePage',
  components: {
    RouletteConfig,
  },
  data() {
    return {
      loadingState: {
        config: false,
      },
    }
  },
  metaInfo() {
    const title = [
      this.$t('dashboard.pages.widgets.roulette'),
      this.$t('dashboard.pages.widgets.widgets'),
    ]

    return {
      title: title.join(' - '),
    }
  },
  methods: {
    ...mapActions('roulette', ['initRouletteConfig']),
  },
  created() {
    this.initRouletteConfig()
      .then(() => {
        this.loadingState.config = true
      })
  },
}
</script>
