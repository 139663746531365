<style scoped lang="scss">
  .bft-widget-preview {
    position: relative;
    padding-left: 20px;
    padding-right: 20px;

    .widget-preview-title {
      position: absolute;
      left: 20px;
      top: 20px;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
    }

    .widget-scroller {
      @include position-all(absolute, 0);
      padding: 70px 20px;
      overflow: auto;
      display: flex;
      flex-direction: column;

      .widget-bft-actions {
        margin: auto auto;
      }
    }
  }
</style>

<template>
  <div class="bft-widget-preview">
    <div class="widget-preview-title">
      {{ $t('bft.widget.preview.title') }}
    </div>
    <div class="widget-scroller">
      <widget-bft-actions
        :settings="settings"
        :categories="categories"
        :actions="actions"/>
    </div>
  </div>
</template>

<script>
import WidgetBftActions from '@components/Widgets/WidgetBftActions/WidgetBftActions'

const actionNames = [
  'Восстановить здоровье',
  'Восстановить броню',
  'Временные мегапрыжки',
  'Временная суперскорость',
  'Полная броня',
  'Набор винтовок',
  'Набор пистолетов',
  'Телепорт к фонтану',
  'Выдать $10000',
  'Забрать $10000',
]

export default {
  name: 'BftWidgetPreview',
  components: {
    WidgetBftActions,
  },
  props: {
    settings: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      categories: Array.from({ length: 4 }, (v, k) => {
        return {
          id: k + 1,
          name: `Категория #${k + 1}`,
        }
      }),

      actions: Array.from({ length: 47 }, (v, k) => {
        return {
          id: k + 1,
          categoryId: _.random(1, 4),
          name: _.sample(actionNames),
          price: _.random(50, 1337),
        }
      }),
    }
  },
  computed: {},
}
</script>
