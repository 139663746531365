import Vue from 'vue'
import { dataTypeMap, mapWidgetSettings, subscribersTypesMap } from './helper'
import { mapValue } from '@utils/utils'

export const state = {
  widgets: {},
}

export const getters = {
  getWidget: state => id => state.widgets[id],
  getWidgetsList: state => Object.values(state.widgets),
}

export const mutations = {
  UPDATE_WIDGETS(state, widgets) {
    widgets.forEach(widget => Vue.set(state.widgets, widget.id, widget))
  },

  DELETE_WIDGET(state, widgetId) {
    Vue.delete(state.widgets, widgetId)
  },
}

export const actions = {
  storeWidgets({ commit }, widgets) {
    const widgetsMapped = widgets.map(widget => {
      return {
        ...widget,
        json: {
          ...widget.json,
          settings: mapWidgetSettings(widget.json.settings, true),
        },
      }
    })

    commit('UPDATE_WIDGETS', widgetsMapped)

    return widgetsMapped
  },

  fetchWidgetsList({ commit, dispatch }) {
    return api.get('/widgets/stat')
      .then(({ data }) => {
        return dispatch('storeWidgets', data.filter(w => w.json !== null))
      })
  },

  fetchWidget({ commit, dispatch }, widgetId) {
    return api.get(`/widgets/stat/${widgetId}`)
      .then(({ data }) => {
        return dispatch('storeWidgets', [data])
          .then(data => {
            return _.head(data)
          })
      })
  },

  createWidget({ commit, dispatch }, { title, settings, design }) {
    return api.post('/widgets/stat', {
      title,
      json: {
        settings: mapWidgetSettings(settings),
        design,
      },
    })
      .then(({ data }) => {
        return dispatch('storeWidgets', [data])
          .then(data => {
            return _.head(data)
          })
      })
  },

  updateWidget({ commit, dispatch }, { id, title, settings, design }) {
    return api.put(`/widgets/stat/${id}`, {
      title,
      json: {
        settings: mapWidgetSettings(settings),
        design,
      },
    })
      .then(({ data }) => {
        return dispatch('storeWidgets', [data])
          .then(data => {
            return _.head(data)
          })
      })
  },

  deleteWidget({ commit }, widgetId) {
    return api.delete(`/widgets/stat/${widgetId}`)
      .then(({ data }) => {
        commit('DELETE_WIDGET', widgetId)

        return data
      })
  },

  fetchWidgetData({}, widgetId) {
    return api.get(`/widgets/stat/${widgetId}/stat`)
      .then(({ data }) => {
        const dataType = mapValue(data.display_type, dataTypeMap, true)

        let output

        switch (dataType) {
          case 'lastDonations':
            output = data.donations.map((row, index) => ({
              amount: _.round(row.origin_amount, 2),
              username: row.donator_info.name,
              currency: row.origin_currency,
              order: index,
            }))
            break
          case 'topDonations':
            output = data.donations.map(row => ({
              amount: _.round(row.origin_amount, 2),
              username: row.donator_info.name,
              currency: row.origin_currency,
              order: -row.amount,
            }))
            break
          case 'topDonators':
            output = data.donations.map(row => ({
              amount: _.round(row.amount, 2),
              username: row.donator_info.name,
              currency: data.currency,
              order: -row.amount,
            }))
            break
          case 'lastSubscribers':
            output = data.subscribers.map((row, index) => ({
              type: mapValue(row.alert_type, subscribersTypesMap, true),
              username: row.donator_info.name,
              order: index,
            }))
            break
          case 'subscriptionsNumber':
            output = `${data.alerts.reduce((acc, row) => acc + row.count, 0)}`
            break
          case 'overallDonationsAmount':
            output = {
              amount: _.round(data.amount, 2),
              currency: data.currency,
            }
            break
          case 'productsSoldNumber':
            output = `${data.count}`
            break
        }

        return output
      })
  },

  fetchTwitchSubscribersNumber({}, source) {
    return api.get(`/widgets/stat/twitch_total_subs`, {
      params: {
        type: {
          twitchFollower: 'twitch_follower',
          twitchSubscriber: 'twitch_subscriber',
        }[source],
      },
    }).then(({ data }) => data.total)
  },
}
