<style scoped lang="scss">
  .category-behavior-settings {

  }
</style>

<template>
  <div class="category-behavior-settings">
    <form-group inline :label="$t('global.settings.labels.animation')">
      <dropdown-select v-model="animationType" :options="animationOptions"/>
    </form-group>
    <form-group v-if="animationType" inline :label="$t('roulette.category.visualSettings.behavior.animationPlays')">
      <base-switch v-model="animationIteration" :options="animationIterationOptions"/>
    </form-group>
    <form-group v-if="animationType" inline :label="$t('roulette.category.visualSettings.behavior.animationDuration')">
      <base-range
        v-model="animationDuration" :min="1" :max="3" :step=".1" input
        :input-addon="$t('global.settings.helpers.seconds')"/>
    </form-group>
  </div>
</template>

<script>

export default {
  name: 'CategoryBehaviorSettings',
  props: {
    value: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      animationOptions: [
        null,
        {
          label: 'Zoom',
          value: 'card-zoom',
        },
        {
          label: 'Pulse',
          value: 'card-pulse',
        },
        {
          label: 'Shake',
          value: 'card-shake',
        },
        {
          label: 'Tada',
          value: 'card-tada',
        },
        {
          label: 'Bounce',
          value: 'card-bounce',
        },
        {
          label: 'Swing',
          value: 'card-swing',
        },
        {
          label: 'Wobble',
          value: 'card-wobble',
        },
        {
          label: 'Jello',
          value: 'card-jello',
        },
      ],
      animationType: null,

      animationIteration: 'infinite',
      animationDuration: 1,
    }
  },
  computed: {
    output() {
      return {
        _version: Math.random().toString(36).slice(2),
        animationType: this.animationType,
        animationIteration: this.animationIteration,
        animationDuration: this.animationDuration,
      }
    },

    animationIterationOptions() {
      return [
        'once',
        'infinite',
      ].map(option => ({
        value: option,
        label: this.$t(`roulette.category.visualSettings.behavior.animationOptions.${option}`),
      }))
    },
  },
  created() {
    if (this.value) {
      Object.keys(this.value).forEach(key => {
        this[key] = this.value[key] ?? this[key]
      })
    }
  },
  watch: {
    output(value) {
      this.$emit('input', value)
    },
  },
}
</script>
