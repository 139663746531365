<style scoped lang="scss">
  .btn_back-wrapper {
    margin-bottom: 20px;

    &._disabled {
      pointer-events: none;
    }
  }

  .btn_back {
    color: #ffffff;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;
    user-select: none;
    cursor: pointer;
    transition: color .2s;

    & > svg {
      margin-right: 10px;
      vertical-align: middle;
      fill: url(#theme_svg_gradient);
      transition: transform .2s;
    }

    & > span {
      vertical-align: middle;
    }

    &:hover {
      color: var(--theme-color-main);

      & > svg {
        transform: translateX(-5px);
      }
    }
  }
</style>

<template>
  <div class="btn_back-wrapper" :class="{ _disabled: disabled }">
    <router-link class="tabs__item" :to="route" exact>
      <span class="btn_back">
        <icon name="back"/>
        <span>{{$t('offers.back')}}</span>
      </span>
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'BackBtn',
  props: {
    route: {
      type: Object,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },
}
</script>
