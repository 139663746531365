<template>
  <div class="bft-page">
    <div class="bft-page__header">
      <div>
        <Title class="bft-page__title">{{ $t('dashboard.pages.bft.bft') }}</Title>

        <p class="bft-page__description">{{ $t('bft.description') }}</p>
      </div>

      <div class="bft-page__stats">
        <Stats/>
      </div>
    </div>

    <NavBar/>

    <div class="page">
      <router-view/>
    </div>
  </div>
</template>

<script>
import Title from '@components/BaseComponents/Title'
import NavBar from '@components/Dashboard/Bft/NavBar'
import Stats from '@components/Dashboard/Bft/Stats'

export default {
  name: 'ChaosTricksPage',

  components: {
    Title,
    NavBar,
    Stats,
  },

  metaInfo() {
    return {
      title: this.$t('dashboard.pages.bft.bft'),
    }
  },

  data() {
    return {}
  },

  mounted() {
    this.$tmr.goal({ goal: 'chaosTricks_page_open' })
  },
}
</script>

<style scoped lang="scss">
  .bft-page {

    &__header {
      margin-bottom: 20px;

      @media (min-width: 720px) {
        display: flex;
        justify-content: space-between;
      }
    }

    &__stats {
      margin-top: 20px;

      @media (min-width: 720px) {
        margin-top: 0;
        margin-left: 20px;
      }
    }

    &__title {
      margin-bottom: 10px !important;
    }

    &__description {
      color: #838383;
      font-size: 14px;
      line-height: 1.5;
    }

  }

  .page {
    padding-bottom: 40px;
  }
</style>
