import Vue, { watch, ref } from 'vue'
import VueI18n from 'vue-i18n'
import languages from './languages'
import axios from 'axios'
// import session from '@services/session'

const STORAGE_LOCALE_MESSAGE = 'da2.dashboard.localeMessage'

Vue.use(VueI18n)

const fallbackLocales = ['en_US', 'ru_RU']

const i18n = new VueI18n({
  fallbackLocale: fallbackLocales,
  locale: 'en_US',
  silentFallbackWarn: PRODUCTION_MODE,
  pluralizationRules: {
    'ru_RU': function (n) {
      return (n % 10 === 1 && n % 100 !== 11) ? 0 : n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20) ? 1 : 2
    },
  },
})

Object.keys(languages).forEach(locale => {
  i18n.setLocaleMessage(locale, storage.getItem(`${STORAGE_LOCALE_MESSAGE}.${locale}`) ?? {})
})

const _cachedLocales = {}

const fetchLocale = locale => {
  if (_cachedLocales[locale]) {
    return Promise.resolve()
  }

  _cachedLocales[locale] = true

  return axios.get(`${process.env.VUE_APP_LOCALES_PATH}/${locale}.json?t=${+Date.now()}`)
    .then(({ data }) => {
      storage.setItem(`${STORAGE_LOCALE_MESSAGE}.${locale}`, data)
      i18n.setLocaleMessage(locale, data)
    })
}

const setLocale = locale => {
  fetchLocale(locale)
  i18n.locale = locale
}

fallbackLocales.forEach(fetchLocale)

setLocale(window.document_language)

// TODO: перевод на реальный SPA
// session.onReady()
//   .then(() => {
//     setLocale(session.getUserLanguage())
//   })

export { i18n, languages, setLocale }
