<style lang="scss" scoped>
  .time-picker {
    position: relative;

    .base-input {
      width: 75px;
    }

    .list-options {
      position: absolute;
      left: 0;
      margin-top: 5px;
      z-index: 5;
      max-height: 180px;
      width: 140px;
      @include padding-y(5px);
      overflow: auto;
      background-color: $additional-2;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
      border-radius: 5px;

      .list-option {
        padding: 6px 15px;
        cursor: pointer;
        white-space: nowrap;

        &:hover {
          color: var(--theme-color-additional);
        }
      }
    }

    &.right {
      .list-options {
        left: auto;
        right: 0;
      }
    }
  }
</style>

<template>
  <div class="time-picker" v-click-outside="onListBlurred">
    <base-input
      :class="inputClasses"
      placeholder="00:00"
      v-model="timeInput"
      v-mask="'##:##'"
      @mousedown="onInputFocused"
      @focus="onInputFocused"
      @change="onInputChange"/>
    <transition name="fade-up">
      <div v-if="listFocused" ref="dropdown" class="list-options">
        <div
          v-for="(option, index) in timeOptions"
          :key="index"
          class="list-option"
          @click="select(option.value)">{{option.label}}
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
const getWithZero = num => num > 9 ? num : `0${num > 0 ? num : 0}`

export default {
  name: 'TimePicker',
  props: {
    value: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      time: 0,
      timeInput: null,

      listFocused: false,

      timeOptions: _.range(0, 24).reduce((acc, h) => {
        _.range(0, 6).forEach(r => {
          const m = r * 10

          acc.push({
            value: h * 60 + m,
            label: [getWithZero(h), getWithZero(m)].join(':'),
          })
        })

        return acc
      }, []),
    }
  },
  watch: {
    timeInput() {
      this.calculateTimeFromInput()
    },

    time(time) {
      this.$emit('input', time)
    },

    value: {
      handler(value, oldValue) {
        if (value !== oldValue) {
          this.time = value
          this.calculateInputFromTime()
        }
      },
      immediate: true,
    },
  },
  computed: {
    inputClasses() {
      return {
        focus: this.listFocused,
      }
    },
  },
  methods: {
    select(time) {
      this.time = time
      this.calculateInputFromTime()

      this.listFocused = false
    },

    onInputFocused() {
      this.listFocused = true
    },

    onListBlurred() {
      this.listFocused = false
    },

    calculateTimeFromInput() {
      if (!this.timeInput || this.timeInput.length === 0) {
        this.time = null
        return
      } else if (this.timeInput.length !== 5) {
        return
      }

      const [h, m] = this.timeInput.split(':').map(Number)
      this.time = h * 60 + m
    },

    calculateInputFromTime() {
      if (this.time !== null) {
        const m = this.time % 60
        const h = (this.time - m) / 60
        this.timeInput = [getWithZero(h), getWithZero(m)].join(':')
      } else {
        this.timeInput = ''
      }
    },

    onInputChange() {
      this.time = this.time || 0
    },
  },
}
</script>
