import { render, staticRenderFns } from "./FontSelect.vue?vue&type=template&id=78f54b64&scoped=true"
import script from "./FontSelect.vue?vue&type=script&lang=js"
export * from "./FontSelect.vue?vue&type=script&lang=js"
import style0 from "./FontSelect.vue?vue&type=style&index=0&id=78f54b64&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "78f54b64",
  null
  
)

export default component.exports