<style scoped lang="scss">
  .active-goals-list {
    position: relative;

    .loader-container {
      @include position-all(absolute, 0);
      z-index: 15;
      background: rgba($black, .9);
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 30px;
    }

    .goals-list {
      .list-item + .list-item {
        margin-top: 20px;
      }

      .list-item {
        .active-goal {
          min-height: 120px;
        }
      }
    }
  }
</style>

<template>
  <div class="active-goals-list">
    <transition name="fade">
      <div v-if="activeGoalsLoading" class="loader-container">
        <loader size="lg"/>
      </div>
    </transition>
    <transition-group
      class="goals-list"
      tag="div"
      name="fade-up-slow">
      <div
        v-for="goalId in activeGoalsList"
        :key="goalId"
        class="list-item">
        <active-goal :goal-id="goalId"/>
      </div>
    </transition-group>
  </div>
</template>

<script>

import ActiveGoal from '@components/Dashboard/GoalSettings/ActiveGoal'

export default {
  name: 'ActiveGoalsList',
  components: { ActiveGoal },
  data() {
    return {
      goals: [],
    }
  },
  created() {
    this.loadActiveGoals()
      .then(({ goals }) => {
        this.goals = goals.slice()
      })
  },
  methods: {
    ...mapActions('goal/settings', ['loadActiveGoals']),
  },
  computed: {
    ...mapState('goal/settings', ['activeGoalsLoading', 'activeGoalsList']),
  },
}
</script>
