<style scoped lang="scss">
  .category-lootbox-settings {

  }
</style>

<template>
  <div class="category-lootbox-settings">
    <form-group inline :label="$t('roulette.category.visualSettings.lootbox.lootbox')">
      <base-switch v-model="lootboxEnabled"/>
    </form-group>
    <template v-if="lootboxEnabled">
      <form-group inline :label="$t('roulette.category.visualSettings.lootbox.face')">
        <file-manager v-model="lootboxImage" widget-element="roulette_card_background" file-type="image"/>
      </form-group>
      <template v-if="lootboxImage">
        <form-group inline :label="$t('global.settings.labels.hueRotation')">
          <base-range :min="0" :max="360" v-model="lootboxImageHue" input input-addon="°"/>
        </form-group>
        <form-group inline :label="$t('global.settings.labels.saturate')">
          <base-range :min="0" :max="300" v-model="lootboxImageSaturate" input input-addon="%"/>
        </form-group>
        <form-group inline :label="$t('global.settings.labels.borderRadius')">
          <base-range :min="0" :max="100" v-model="lootboxBorderRadius" input input-addon="px"/>
        </form-group>
        <form-group inline :label="$t('global.settings.labels.size')">
          <base-range :min="10" :max="200" v-model="lootboxSize" input input-addon="px"/>
        </form-group>
        <form-group inline :label="$t('global.settings.labels.shadow')">
          <shadow-styler v-model="lootboxShadow" class="left"/>
        </form-group>
      </template>
      <hr>
      <form-group inline label="Поворот на превью">
        <base-range :min="0" :max="180" v-model="lootboxPreviewRotation" input input-addon="°"/>
      </form-group>
    </template>
    <form-group>
      <info-box class="small" :show-icon="false">
        {{ $t('roulette.category.visualSettings.lootbox.lootboxText') }}
      </info-box>
    </form-group>
  </div>
</template>

<script>

import ShadowStyler from '@components/BaseComponents/ShadowStyler/ShadowStyler'

export default {
  name: 'CategoryLootboxSettings',
  components: { ShadowStyler },
  props: {
    value: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      lootboxEnabled: false,
      lootboxImage: null,
      lootboxImageHue: 0,
      lootboxImageSaturate: 100,
      lootboxBorderRadius: 0,
      lootboxSize: 160,
      lootboxShadow: null,
      lootboxPreviewRotation: 180,
    }
  },
  computed: {
    output() {
      return {
        _version: Math.random().toString(36).slice(2),
        lootboxEnabled: this.lootboxEnabled,
        lootboxImage: this.lootboxImage,
        lootboxImageHue: this.lootboxImageHue,
        lootboxImageSaturate: this.lootboxImageSaturate,
        lootboxBorderRadius: this.lootboxBorderRadius,
        lootboxSize: this.lootboxSize,
        lootboxShadow: this.lootboxShadow,
        lootboxPreviewRotation: this.lootboxPreviewRotation,
      }
    },
  },
  created() {
    if (this.value) {
      Object.keys(this.value).forEach(key => {
        this[key] = this.value[key] ?? this[key]
      })

      this.lootboxPreviewRotation = 180
    }
  },
  watch: {
    output(value) {
      this.$emit('input', value)
    },

    lootboxImage(image, oldImage) {
      if (oldImage && oldImage !== image) {
        this.lootboxImageHue = 0
        this.lootboxImageSaturate = 100
      }
    },
  },
}
</script>
