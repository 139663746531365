<template>
  <div class="stats" v-if="stats">
    <div class="stats__item">
      <div class="stats__res">{{ stats.total_count }}</div>
      <div class="stats__sign">{{ $t('bft.stats.actions') }}</div>
    </div>

    <div class="stats__item">
      <div class="stats__res">{{ costTransform(stats.total_profit.amount, stats.total_profit.currency) }}</div>
      <div class="stats__sign">{{ $t('bft.stats.amount') }}</div>
    </div>
  </div>
</template>

<script>
import currency from 'currency.js'


export default {

  name: 'Stats',

  data() {
    return {}
  },

  watch: {},

  created() {},

  computed: {
    ...mapGetters('bft/settings', [
      'stats',
    ]),
  },

  methods: {
    costTransform(cost, amount) {
      const _cost = currency(Math.abs(cost), {
        separator: ' ',
        decimal: ','
      }).format()

      const _amount = amount
        .replace('USD', '$')
        .replace('RUB', '₽')

      return `${ _cost } ${ _amount }`
    },
  },

}

</script>

<style scoped lang="scss">
.stats {
  background: #303030;
  border-radius: 8px;
  padding: 5px 15px;

   @media (min-width: 720px) {
    // max-width: 600px;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    padding: 15px 5px;
  }

  &__item {
    padding: 15px 0;
    border-top: 1px solid #464646;

    &:first-child {
      padding-top: 10px;
      border-top: 0;
    }

    &:last-child {
      padding-bottom: 10px;
    }

    @media (min-width: 720px) {
      padding: 0 15px;
      border-top: 0;
      border-left: 1px solid #464646;
      flex: 1 1 auto;

      &:first-child {
        padding-top: 0;
        padding-left: 10px;
        border-left: 0;
      }

      &:last-child {
        padding-bottom: 0;
        padding-right: 10px;
      }
    }
  }

  &__res {
    color: #F1F1F1;
    font-size: 20px;
    line-height: 1.5;
    white-space: nowrap;
  }

  &__sign {
    color: #838383;
    font-size: 14px;
    line-height: 1.5;
  }
}
</style>
