<style scoped lang="scss">
  .goal-modal {

  }
</style>

<template>
  <modal v-model="modalOpened">
    <template slot="title">
      <template v-if="openedGoalId">{{$t('goals.goalModal.editGoal')}}</template>
      <template v-else>{{$t('goals.goalModal.newGoal')}}</template>
    </template>
    <template slot="body-content">
      <goal-form @close="closeGoalModal"/>
    </template>
  </modal>
</template>

<script>
import GoalForm from '@components/Dashboard/GoalSettings/GoalForm'

export default {
  name: 'GoalModal',
  components: { GoalForm },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('goal/settings', [
      'goalModalOpened',
      'openedGoalId',
    ]),

    modalOpened: {
      get() {
        return this.goalModalOpened
      },
      set(value) {
        if (!value) {
          this.closeModal()
        }
      },
    },
  },
  methods: {
    ...mapActions('goal/settings', ['closeGoalModal']),

    closeModal() {
      this.closeGoalModal()
    },
  },
}
</script>
