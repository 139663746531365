<style scoped lang="scss">
  .goal-config {
    .active-goals-section {
      .section-controls {
        display: flex;

        .controls-text {
          padding-right: 100px;
        }

        .controls-button {
          flex-shrink: 0;
          margin-left: auto;
        }

        @include media(max, md) {
          flex-direction: column;

          .controls-text {
            margin-top: 10px;
            padding-right: 0;
          }

          .controls-button {
            margin-top: 25px;
          }
        }
      }

      .main-goal-controls {
        max-width: 500px;
        margin-top: 30px;
      }

      .active-goals-list {
        margin-top: 20px;
      }
    }

    .widget-design-section {
      margin-top: 50px;

      @include media(max, md) {
        margin-top: 30px;
      }

      .widget-design-wrapper {
        margin-top: 20px;
        position: relative;

        .widget-design-loading {
          @include position-all(absolute, 0);
          z-index: 9;
          background-color: rgba($black, .85);
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .widget-design-restriction {
          @include position-all(absolute, 0);
          z-index: 10;
          background-color: rgba($black, .85);
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          @include media(max, md) {
            @include margin-x(-$gutter-mobile);
            padding: $gutter-mobile;
            justify-content: flex-start;
          }

          .restriction-text {
            width: 400px;
            text-align: center;
            font-weight: 300;
            font-size: 18px;

            @include media(max, md) {
              width: 100%;
            }
          }

          .restriction-controls {
            margin-top: 30px;
          }
        }

        .goal-presets-manager {
        }

        .goal-design-settings {
          margin-top: 30px;
        }
      }
    }

    .goals-history-section {
      margin-top: 60px;

      @include media(max, md) {
        margin-top: 30px;
      }

      .past-goals-list {
        margin-top: 20px;

        @include media(max, md) {
          margin-top: 5px;
        }
      }
    }
  }
</style>

<template>
  <div class="goal-config">

    <section class="active-goals-section">
      <h2 class="section-title">{{ $t('goals.settingsPage.activeGoals') }}</h2>
      <div class="section-controls">
        <div class="controls-text helper-text">
          {{ $t('goals.settingsPage.widgetDescriptionMessage') }}
        </div>
        <div class="controls-button">
          <btn
            variant="primary-action"
            icon-left="plus"
            @click="showGoalModel">{{ $t('goals.settingsPage.buttonCreateGoal') }}
          </btn>
        </div>
      </div>
      <div class="main-goal-controls">
        <form-group inline :label="$t('goals.settingsPage.defaultGoal')">
          <dropdown-select v-model="mainGoalId" :options="defaultGoalIdOptions"/>
        </form-group>
      </div>
      <active-goals-list/>
    </section>

    <section class="widget-design-section">
      <h2 class="section-title">{{ $t('goals.settingsPage.widgetDesign') }}</h2>
      <div class="widget-design-wrapper">
        <transition name="fade">
          <div v-if="loadingState.widgetSettings" class="widget-design-loading">
            <loader size="lg"/>
          </div>
        </transition>
        <transition name="fade">
          <div v-if="widgetRestrictionVisible" class="widget-design-restriction">
            <div class="restriction-text">{{ $t('goals.settingsPage.designAccessMessage') }}</div>
            <div class="restriction-controls">
              <btn
                variant="primary-action"
                icon-left="plus"
                @click="showGoalModel">{{ $t('goals.settingsPage.buttonCreateGoal') }}
              </btn>
            </div>
          </div>
        </transition>
        <goal-presets-manager/>
        <goal-design-settings/>
      </div>
    </section>

    <section class="goals-history-section">
      <h2 class="section-title">{{ $t('goals.settingsPage.goalHistory') }}</h2>
      <past-goals-list/>
    </section>

    <goal-modal/>
  </div>
</template>

<script>
import GoalPresetsManager from '@components/Dashboard/GoalSettings/GoalPresetsManager'
import ActiveGoalsList from '@components/Dashboard/GoalSettings/ActiveGoalsList'
import PastGoalsList from '@components/Dashboard/GoalSettings/PastGoalsList'
import GoalDesignSettings
  from '@components/Dashboard/GoalSettings/DesignSettings/GoalDesignSettings'
import GoalModal from '@components/Dashboard/GoalSettings/GoalModal'

export default {
  name: 'GoalConfig',
  components: {
    GoalModal,
    GoalDesignSettings,
    PastGoalsList,
    ActiveGoalsList,
    GoalPresetsManager,
  },
  data() {
    return {
      loadingState: {
        initial: true,
        widgetSettings: true,
      },
    }
  },
  computed: {
    ...mapState('goal/settings', ['activeGoalsLoading', 'activeGoalsList']),
    ...mapGetters('goal/goals', ['getGoal']),
    ...mapState('goal/goals', ['defaultGoalId']),

    widgetRestrictionVisible() {
      return !this.loadingState.initial && !this.activeGoalsList.length
    },

    defaultGoalIdOptions() {
      return [
        ...this.activeGoalsList.map(goalId => {
          const goal = this.getGoal(goalId)

          return {
            label: goal.title,
            value: goal.id,
          }
        }),
        null,
      ]
    },

    mainGoalId: {
      get() {
        return this.defaultGoalId
      },
      set(value) {
        this.updateDefaultGoalId(value)
      },
    },
  },
  methods: {
    ...mapActions('goal/settings', ['openGoalModal']),
    ...mapActions('goal/presets', ['fetchUserSettings']),
    ...mapActions('goal/goals', ['updateDefaultGoalId']),

    showGoalModel() {
      this.openGoalModal()
    },
  },
  created() {
    this.fetchUserSettings()
      .finally(() => {
        this.loadingState.widgetSettings = false
      })
  },
  watch: {
    activeGoalsLoading(value) {
      if (!value) {
        this.loadingState.initial = false
      }
    },
  },
}
</script>
