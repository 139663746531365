<style scoped lang="scss">
  .category-select-modal {

    .category-select-modal-body {
      width: 300px;
    }

    .modal-controls {
      display: flex;
      justify-content: space-between;
    }
  }
</style>

<template>
  <modal v-model="modalOpened" class="category-select-modal">
    <template slot="title">{{ $t('roulette.category.settings.designImport.title') }}</template>
    <div slot="body-content" class="category-select-modal-body">
      <form-group :label="$t('roulette.category.settings.designImport.sourceCategory')">
        <dropdown-select
          v-model="targetCategoryId"
          :options="categories"
          label-key="name"
          value-key="id"/>
      </form-group>
    </div>
    <div slot="controls">
      <btn
        variant="primary-action"
        icon-left="copy"
        @click="selectCategory">{{ $t('global.common.select') }}
      </btn>
      <btn
        variant="secondary-action"
        @click="closeModal">{{ $t('global.common.cancel') }}
      </btn>
    </div>
  </modal>
</template>

<script>

export default {
  name: 'CategorySelectModal',
  props: {
    value: {
      type: Boolean,
    },
    excludeCategories: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      targetCategoryId: null,

      modalOpened: false,
    }
  },
  computed: {
    ...mapGetters('roulette', ['getCategoriesList']),

    categories() {
      return this.getCategoriesList
        .filter(category => !this.excludeCategories.includes(category.id))
    },
  },
  methods: {
    selectCategory() {
      this.$emit('select', this.targetCategoryId)
      this.closeModal()
    },

    closeModal() {
      this.modalOpened = false
    },
  },
  watch: {
    value: {
      handler(value) {
        this.modalOpened = value
      },
      immediate: true,
    },
    modalOpened(value) {
      this.$emit('input', value)
    },
  },
}
</script>
