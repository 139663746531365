<style scoped lang="scss">
  .widget-goal {
    position: relative;
    @include margin-x(auto);
    letter-spacing: normal;

    &.dark-background {
      background-color: rgba(black, .6);
      padding: 1.5vw 2vw;
      border-radius: 1vw;
    }

    .goal-body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
      min-height: 7vw;
      @include padding-x(1.5vw);
      @include padding-y(1.5vw);

      .goal-progress-bar {
        overflow: hidden;
        @include position-all(absolute, 0);
        border-style: solid;
        border-width: 0;
        background-clip: padding-box;

        .progress-bar-filler {
          @include position(absolute, auto, auto, 0, 0);
          z-index: 5;

          &.horizontal {
            top: 0;
          }

          &.vertical {
            right: 0;
          }
        }
      }

      .goal-frame {
        text-align: center;
      }
    }

    &.horizontal {
      width: 70vw;

      .goal-footer {
        position: relative;

        .goal-frames {
          width: 100%;
          display: flex;
          justify-content: space-between;

          &.absolute {
            position: absolute;
          }

          .goal-frame {
            width: auto;
            flex: 0 1 auto;

            &.start {
              text-align: left;
            }

            &.start {
              text-align: right;
            }
          }
        }
      }
    }

    &.vertical {
      width: 25vw;

      &.dark-background {
        width: 29vw;
      }

      .goal-body {
        min-height: 29vw;

        .goal-text + .goal-text {
          margin-top: 1.5vw;
        }

        .goal-frame {
          &.end {
            margin-bottom: auto;
          }

          &.start {
            margin-top: auto;
          }
        }
      }
    }

    .goal-text {
      width: 100%;
      position: relative;
      z-index: 10;
    }

    .goal-title {
      font-size: 40px;
      line-height: 1.3;
    }

    .goal-progress-text {
      font-size: 26px;
      line-height: 1.3;
    }

    .goal-amount-start {
      white-space: nowrap;
      font-size: 22px;
    }

    .goal-amount-end {
      white-space: nowrap;
      font-size: 22px;
    }

    .goal-time-left {
      font-size: 22px;
    }

    .goal-header + .goal-body {
      margin-top: .5vw;
    }

    .goal-header + .goal-footer,
    .goal-body + .goal-footer {
      margin-top: .3vw;
    }

    .goal-footer {
      width: 100%;
      position: relative;
    }
  }
</style>

<script>
import TextWrapper from './TextWrapper'
import TimeLeft from './TimeLeft'
import {
  createCssFilters,
  discharge,
} from '@utils/utils'

const numbersToVw = (obj) => {
  return obj ? Object.keys(obj)
    .reduce((acc, key) => {
      acc[key] = [
        'borderRadius',
        'borderWidth',
        'minHeight',
        'fontSize',
        'letterSpacing',
        'wordSpacing',
      ].includes(key) ? `${obj[key]}vw` : obj[key]
      return acc
    }, {}) : null
}

export default {
  name: 'WidgetGoal',
  components: {
    TextWrapper,
    TimeLeft,
  },
  props: {
    goalData: {
      type: Object,
      default: () => ({}),
      required: true,
    },
    config: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      timeLeftVisible: true,
    }
  },
  computed: {
    settings() {
      return {
        positions: _.get(this.config, 'settings.positions', {
          title: 'header',
          progressText: 'body',
          timeLeft: 'footer',
        }),
        progressTextTemplate: _.get(this.config, 'settings.progressTextTemplate', '{raised} {currency}\n(50%)'),
        framesEnabled: _.get(this.config, 'settings.framesEnabled', false),
        backgroundEnabled: _.get(this.config, 'settings.backgroundEnabled', false),
      }
    },

    styles() {
      return _.get(this.config, 'styles', {})
    },

    scheme() {
      return this.config.scheme
    },

    schemeIs() {
      return {
        horizontal: this.scheme === 'horizontal',
        vertical: this.scheme === 'vertical',
        circle: this.scheme === 'circle',
        circular: this.scheme === 'circular',
      }
    },

    progressBarSettings() {
      const parameter = this.schemeIs.horizontal ? 'right' : 'top'

      const {
        progressBar = {},
        filler = {},
      } = this.styles

      const fillerGradientColors = _.get(filler, 'gradient.colors', [])
      const progressGradientColors = _.get(progressBar, 'gradient.colors', [])

      const gradientAngle = {
        right: 90,
        bottom: 180,
        left: 270,
        top: 0,
      }

      const fillerGradientDirection = _.get(filler, 'gradient.direction', parameter)
      const fillerGradientAngle = _.get(filler, 'gradient.angle', gradientAngle[fillerGradientDirection])
      const progressGradientDirection = _.get(progressBar, 'gradient.direction', parameter)
      const progressGradientAngle = _.get(progressBar, 'gradient.angle', gradientAngle[progressGradientDirection])

      const fillerGradient = `linear-gradient(${fillerGradientAngle}deg, ${fillerGradientColors.join(',')})`
      const progressGradient = `linear-gradient(${progressGradientAngle}deg, ${progressGradientColors.join(',')})`

      return {
        framesEnabled: this.settings.framesEnabled,
        progressBar: {
          ...numbersToVw(progressBar),
          backgroundImage: progressGradientColors.length ? progressGradient : null,
          backgroundColor: progressGradientColors.length ? null : progressBar.backgroundColor,
        },
        filler: {
          ...filler,
          backgroundImage: fillerGradientColors.length ? fillerGradient : null,
          backgroundColor: fillerGradientColors.length ? null : filler.backgroundColor,
          [parameter]: `${100 - this.goalData.progressPercentage}%`,
          transition: `${parameter} 1s`,
        },
      }
    },

    textStyles() {
      return {
        title: numbersToVw(this.styles.title) || {},
        progress: numbersToVw(this.styles.progress) || {},
        timeLeft: numbersToVw(this.styles.timeLeft) || {},
        frames: numbersToVw(this.styles.frames) || {},
      }
    },

    bodyStyles() {
      const {
        progressBar = {},
      } = this.styles

      return {
        minHeight: _.get(numbersToVw(progressBar), 'minHeight', null),
      }
    },

    progressText() {
      const textVariables = {
        raised: discharge(this.goalData.raisedAmount),
        goal: discharge(this.goalData.goalAmount),
        currency: this.goalData.currency,
        progress: this.goalData.progressPercentage,
      }

      return Object.keys(textVariables).reduce((acc, key) => {
        return acc.replace(`{${key}}`, textVariables[key])
      }, this.settings.progressTextTemplate).split('\n')
    },

    goalTimeOut() {
      return this.goalData.expiresAt && this.goalData.expiresAt <= this.$timestamp
    },
  },
  render: function (createElement) {
    const elements = {}

    const wrapText = ({
      text,
      animation,
    }) => createElement(
      'text-wrapper', {
        props: {
          text,
          animation,
          flat: this.config.isPreview,
        },
      },
    )

    const { textShadow: titleShadow } = this.textStyles.title

    elements.title = createElement(
      'div', {
        class: 'goal-title goal-text',
        style: {
          ...this.textStyles.title,
          fontFamily: `"${this.textStyles.title.fontFamily}"`,
          filter: createCssFilters({ shadow: titleShadow }),
        },
      },
      [
        wrapText({
          text: this.goalData.title,
          animation: this.textStyles.title.animation,
        }),
      ],
    )

    const { textShadow: timeLeftShadow } = this.textStyles.timeLeft

    elements.timeLeft = createElement(
      'div', {
        class: 'goal-time-left goal-text',
        style: {
          ...this.textStyles.timeLeft,
          fontFamily: `"${this.textStyles.timeLeft.fontFamily}"`,
          filter: createCssFilters({ shadow: timeLeftShadow }),
        },
      },
      [
        this.goalTimeOut ? this.$t('goals.goalTimer.goalComplete') : createElement(
          'time-left', {
            props: {
              to: this.goalData.expiresAt,
              animation: this.textStyles.timeLeft.animation,
            },
          },
        ),
      ],
    )

    let progressText = this.progressText

    if (this.schemeIs.horizontal) {
      progressText = [
        wrapText({
          text: progressText.join(' '),
          animation: this.textStyles.progress.animation,
        }),
      ]
    } else {
      progressText = progressText.map(text =>
        wrapText({
          text,
          animation: this.textStyles.progress.animation,
        }))
    }

    const { textShadow: progressShadow } = this.textStyles.progress

    elements.progressText = createElement(
      'div', {
        class: 'goal-progress-text goal-text',
        style: {
          ...this.textStyles.progress,
          fontFamily: `"${this.textStyles.progress.fontFamily}"`,
          filter: createCssFilters({ shadow: progressShadow }),
        },
      },
      progressText,
    )

    const progressBarBlock = createElement('div', {
      class: 'goal-progress-bar',
      style: this.progressBarSettings.progressBar,
    }, [
      createElement('div', {
        class: `progress-bar-filler ${this.scheme}`,
        style: this.progressBarSettings.filler,
      }),
    ])

    const sections = {
      header: [],
      body: [progressBarBlock],
      footer: [],
    }

    const positions = this.settings.positions

    if (positions['title']) {
      sections[positions['title']].push(elements['title'])
    }
    if (positions['progressText']) {
      sections[positions['progressText']].push(elements['progressText'])
    }

    if (positions['timeLeft'] && this.goalData.expiresAt) {
      sections[positions['timeLeft']].push(elements['timeLeft'])
    }

    if (this.progressBarSettings.framesEnabled) {
      const { textShadow: framesShadow } = this.textStyles.frames

      const frames = {
        start: createElement('div', {
            class: 'goal-text goal-frame start',
            style: {
              ...this.textStyles.frames,
              fontFamily: `"${this.textStyles.frames.fontFamily}"`,
              filter: createCssFilters({ shadow: framesShadow }),
            },
          },
          [
            wrapText({
              text: 0,
              animation: this.textStyles.frames.animation,
            }),
          ],
        ),
        end: createElement('div', {
            class: 'goal-text goal-frame end',
            style: {
              ...this.textStyles.frames,
              fontFamily: `"${this.textStyles.frames.fontFamily}"`,
              filter: createCssFilters({ shadow: framesShadow }),
            },
          },
          [
            wrapText({
              text: discharge(this.goalData.goalAmount),
              animation: this.textStyles.frames.animation,
            }),
          ],
        ),
      }

      if (this.schemeIs.vertical) {
        sections.body.push(frames.start)
        sections.body.unshift(frames.end)
      }

      if (this.schemeIs.horizontal) {
        sections.footer.unshift(createElement('div', {
          class: [
            'goal-frames', {
              absolute: !!sections.footer.length,
            },
          ],
        }, [
          frames.start,
          frames.end,
        ]))
      }
    }

    return createElement(
      'div', {
        class: {
          'widget-goal': true,
          [this.scheme]: true,
          'dark-background': this.settings.backgroundEnabled,
        },
      },
      [
        createElement('div', { class: 'goal-header' }, sections.header),
        createElement('div',
          {
            class: 'goal-body',
            style: this.bodyStyles,
          },
          sections.body),
        createElement('div', { class: 'goal-footer' }, sections.footer),
      ],
    )
  },
}
</script>
