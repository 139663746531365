<style lang="scss" scoped>
  .activity-feed-page {
    .tabs-content {
      margin-top: 25px;
    }
  }
</style>

<template>
  <div class="activity-feed-page">
    <tabs v-model="currentTab" :list="tabs"/>

    <div class="tabs-content">
      <transition name="fade-up">
        <div v-show="!loadingState.page" ref="legacy"/>
      </transition>
    </div>
  </div>
</template>

<script>
import { loadLegacyPage } from '@src/services/legacyLoader'
import $ from 'jquery'

export default {
  name: 'ActivityFeedPage',
  props: {
    section: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loadingState: {
        page: false,
      },
    }
  },
  metaInfo() {
    const title = [
      this.$t(`dashboard.pages.activityFeed.${this.section}`),
      this.$t('dashboard.pages.activityFeed.activityFeed'),
    ]

    return {
      title: title.join(' - '),
    }
  },
  computed: {
    tabs() {
      return [
        {
          icon: 'chat',
          key: 'donations',
        },
        {
          icon: 'person',
          key: 'subscriptions',
        },
        {
          icon: 'list',
          key: 'panel',
        },
      ].map(tab => ({
        ...tab,
        title: this.$t(`dashboard.pages.activityFeed.${tab.key}`),
      }))
    },

    currentTab: {
      get() {
        return this.section
      },
      set(value) {
        this.$router.push({
          name: 'dashboard-activity-feed-section',
          params: { section: value },
        })
      },
    },

    currentPageUrl() {
      return {
        panel: '/dashboard/old-page-lastdonations-widget',
        donations: '/dashboard/old-page-donations',
        subscriptions: '/dashboard/old-page-subscribers',
      }[this.currentTab]
    },
  },
  methods: {
    loadContent() {
      this.loadingState.page = true

      loadLegacyPage(this.currentPageUrl, this.$refs.legacy)
        .then(() => {
          if (this.currentTab === 'panel') {
            $('#_1337block-moderation').hide()
            $('.s-last-alerts-run').css('margin-top', '30px')
          }
        })
        .finally(() => {
          this.loadingState.page = false
        })
    },
  },
  mounted() {
    this.loadContent()
  },
  watch: {
    currentTab: 'loadContent',
  },
}
</script>
