<script setup>
import BaseButton from '@components/BaseComponents/BaseButton'
import Title from '@components/BaseComponents/Title'
import { computed } from 'vue';

const props = defineProps({
  platformId: {
    type: Number,
    default: null
  } 
})

const titleText = computed(() => props.platformId ? 'offers.platforms.updated' : 'offers.platforms.added')
const descText = computed(() => props.platformId ? 'offers.platforms.updatedSuccess' : 'offers.platforms.addedSuccess')
</script>

<template>
  <div class="platform-form">
    <div>
      <img src="@assets/img/success.png" />
    </div>
    <Title>{{ $t(titleText) }}</Title>
    <div class="helper-text">
      {{ $t(descText) }}
    </div>
    <div class="form-controls">
      <base-button
        variant="primary-action"
        @click="$emit('close:modal')"
      >
        {{ $t('global.common.continue') }}
      </base-button>
    </div>
  </div>
</template>

<style lang="scss">
.platform-form {
  width: 550px;
  min-height: 240px;
  position: relative;
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include media(max, md) {
    width: 100%;
  }

  .form-controls {
    margin-top: 50px;
    display: flex;
    align-items: center;

    .button + .button {
      margin-left: 20px;
    }
  }
}
</style>
