import { defaultLayoutSettings } from '@src/config/bft/defaultValues'

export const state = {
  widgetId: null,

  widgetSettings: {},

  presets: {},
  currenPresetId: null,
}

export const getters = {
  getPresetsList: state => Object.values(state.presets),
}

export const mutations = {
  SET_WIDGET_ID(state, id) {
    state.widgetId = id
  },

  SET_WIDGET_SETTINGS(state, settings) {
    state.widgetSettings = settings
  },

  SET_CURRENT_PRESET_ID(state, presetId) {
    state.currenPresetId = presetId
  },

  SET_WIDGET_PRESETS(state, presets) {
    state.presets = _.keyBy(presets, 'id')
  },
}

export const actions = {
  applyPreset({ commit, state }, presetId) {
    const { json: design } = _.cloneDeep(state.presets[presetId])

    commit('SET_CURRENT_PRESET_ID', presetId)

    return commit('SET_WIDGET_SETTINGS', {
      ...state.widgetSettings,
      ...design,
    })
  },

  fetchWidgetSettings({ commit }) {
    return api.get('/bft/widget')
      .then(({ data }) => {
        const design = data.json ?? data.preset.json
        const layout = data.json_layout ?? defaultLayoutSettings()

        const settings = {
          ...design,
          ...layout,
        }

        if (data.preset) {
          commit('SET_CURRENT_PRESET_ID', data.preset.id)
        }

        commit('SET_WIDGET_ID', data.id)
        commit('SET_WIDGET_SETTINGS', settings)

        return settings
      })
  },

  updateWidgetSettings({ state, commit }, { design, layout }) {
    return api.put('/bft/widget', {
      json: design,
      json_layout: layout,
      preset_id: state.currenPresetId,
    })
      .then(({ data }) => {
        const design = data.json ?? data.preset.json
        const layout = data.json_layout ?? defaultLayoutSettings()

        const settings = {
          ...design,
          ...layout,
        }

        commit('SET_WIDGET_ID', data.id)
        commit('SET_WIDGET_SETTINGS', settings)

        return settings
      })
  },

  fetchWidgetPresets({ commit }) {
    return api.get('/widgets/presets', {
      params: {
        type: 'bft',
      },
    })
      .then(({ data }) => {
        return commit('SET_WIDGET_PRESETS', data)
      })
  },
}
