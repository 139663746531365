import fromUnixTime from 'date-fns/fromUnixTime'

export const state = {
  goals: {},

  defaultGoalId: null,
}

export const getters = {
  getGoal: (state, getters, rootState, rootGetters) => id => {
    const goal = state.goals[id]

    if (!goal) {
      return undefined
    }

    let percentage = goal.raisedAmount / goal.goalAmount * 100

    if (percentage < 1) {
      percentage = percentage > 0 ? 1 : 0
    } else {
      percentage = Math.round(percentage)
    }

    const timeZone = rootGetters['profile/getTimezone']

    const expiresAt = goal.expiresAt ? +dates.utcToZonedTime(fromUnixTime(goal.expiresAt), timeZone) / 1000 : null
    const startedAt = goal.startedAt ? +dates.utcToZonedTime(fromUnixTime(goal.startedAt), timeZone) / 1000 : null

    return {
      ...goal,
      expiresAt,
      startedAt,
      expiresAtFormatted: goal.expiresAt ? dates.unixToFormat(expiresAt, dates.DEFAULT_TIME_FORMAT) : null,
      startedAtFormatted: goal.startedAt ? dates.unixToFormat(startedAt, dates.DEFAULT_TIME_FORMAT) : null,
      progressPercentage: percentage,
      cappedProgressPercentage: percentage > 100 ? 100 : percentage,
    }
  },
}

export const mutations = {
  UPDATE_GOALS(state, goals) {
    const mergedGoals = goals.reduce((acc, goal) => {
      acc[goal.id] = {
        ...state.goals[goal.id],
        ...goal,
      }

      return acc
    }, {})

    state.goals = {
      ...state.goals,
      ...mergedGoals,
    }
  },

  SET_DEFAULT_GOAL_ID(state, goalId) {
    state.defaultGoalId = goalId

    console.log('SET_DEFAULT_GOAL_ID', goalId)
  },
}

export const actions = {
  fetchGoals({ dispatch }, {
    page = 1,
    perPage,
    isActive = null,
  } = {}) {
    return api.get('/donationgoal', {
      params: {
        page,
        per_page: perPage,
        is_active: isActive,
        include_timestamps: 1,
      },
    })
      .then(async ({ data, meta }) => {
        const goals = await dispatch('storeGoals', data)


        return {
          goals: goals.map(goal => goal.id),
          pagination: {
            page: meta.current_page,
            pagesCount: meta.last_page,
            perPage: meta.per_page,
            total: meta.total,
          },
        }
      })
  },

  fetchGoal({ dispatch }, id) {
    return api.get(`/donationgoal/${id}`, {
      params: {
        include_timestamps: 1,
      },
    })
      .then(({ data }) => {
        return dispatch('storeGoal', data)
      })
  },

  storeGoal({ dispatch }, goal) {
    return dispatch('storeGoals', [goal]).then(_.head)
  },

  storeGoals({ commit, rootGetters }, goals) {
    commit('UPDATE_GOALS', goals.map(goal => {
      if (goal.is_default) {
        commit('SET_DEFAULT_GOAL_ID', goal.id)
      }

      return {
        id: goal.id,
        title: goal.title,
        isActive: goal.is_active,
        isDefault: goal.is_default,
        currency: goal.currency,
        goalAmount: goal.goal_amount,
        raisedAmount: goal.raised_amount,
        startAmount: goal.start_amount,
        expiresAt: goal.expires_at_ts,
        startedAt: goal.started_at_ts,
      }
    }))

    return goals
  },

  updateDefaultGoalId({ commit, dispatch }, goalId) {
    return api.post('/donationgoal/default', {
      goal_id: goalId,
    })
      .then(async ({ data }) => {
        if (data) {
          await dispatch('storeGoal', data)
          return
        }

        return commit('SET_DEFAULT_GOAL_ID', null)
      })
  },

  createGoal({ dispatch, rootGetters }, {
    title,
    expiresAt,
    startAmount,
    goalAmount,
    currency,
  } = {}) {
    const timeZone = rootGetters['profile/getTimezone']

    return api.post('/donationgoal', {
      title,
      expires_at_ts: expiresAt ? +dates.zonedTimeToUtc(fromUnixTime(expiresAt), timeZone) / 1000 : null,
      start_amount: startAmount || 0,
      goal_amount: goalAmount,
      currency,
    })
      .then(({ data }) => {
        return dispatch('storeGoal', data)
      })
  },

  updateGoal({ dispatch, rootGetters }, {
    id,
    title,
    expiresAt,
    startAmount,
    goalAmount,
    currency,
    isActive,
  }) {
    const timeZone = rootGetters['profile/getTimezone']

    return api.put(`/donationgoal/${id}`, {
      title,
      expires_at_ts: expiresAt ? +dates.zonedTimeToUtc(fromUnixTime(expiresAt), timeZone) / 1000 : null,
      start_amount: startAmount,
      goal_amount: goalAmount,
      currency,
      is_active: isActive,
    })
      .then(({ data }) => {
        return dispatch('storeGoal', data)
      })
  },

  stopGoal({ dispatch }, id) {
    return api.put(`/donationgoal/${id}/finish`)
      .then(({ data }) => {
        return dispatch('storeGoal', data)
      })
  },
}
