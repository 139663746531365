<style lang="scss" scoped>
  .general-settings-account-page {

  }
</style>

<template>
  <div class="general-settings-account-page">
    <transition name="fade-up">
      <div v-show="!loadingState.page" ref="legacy"/>
    </transition>
  </div>
</template>

<script>

import $ from 'jquery'

import { loadLegacyPage } from '@src/services/legacyLoader'

export default {
  name: 'GeneralSettingsAccountPage',
  data() {
    return {
      loadingState: {
        page: true,
      },
    }
  },
  metaInfo() {
    return {
      title: [
        this.$t('dashboard.pages.generalSettings.account'),
        this.$t('dashboard.pages.generalSettings.generalSettings'),
      ].join(' - '),
    }
  },
  mounted() {
    loadLegacyPage('/dashboard/old-page-general', this.$refs.legacy, () => {
      $('#_1337block-authorized-apps').remove()
    })
      .then(() => {
        $('#_1337block-connections').hide()
        $('#_1337block-black-list').hide()
      })
      .finally(() => {
        this.loadingState.page = false
      })
  },
}
</script>
