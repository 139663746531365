<style scoped lang="scss">
  .elements-settings-form {
  }
</style>

<template>
  <div class="elements-settings-form">
    <form-group inline :label="$t('goals.designForm.elementsSettings.goalTitle')">
      <dropdown-select v-model="titlePosition" :options="positionOptions"/>
    </form-group>
    <form-group inline :label="$t('goals.designForm.elementsSettings.progressPosition')">
      <dropdown-select v-model="progressPosition" :options="positionOptions"/>
    </form-group>
    <form-group inline :label="$t('goals.designForm.elementsSettings.progressTemplate')">
      <dropdown-select v-model="progressTextTemplate" :options="progressTemplates"/>
    </form-group>
    <form-group inline :label="$t('goals.designForm.elementsSettings.timeLeftPosition')">
      <dropdown-select v-model="timeLeftPosition" :options="positionOptions"/>
    </form-group>
    <form-group inline :label="$t('goals.designForm.elementsSettings.goalFrames')">
      <base-switch v-model="goalFramesEnabled" :options="goalFramesOptions"/>
    </form-group>
    <form-group inline :label="$t('goals.designForm.elementsSettings.widgetBackground')">
      <base-switch v-model="backgroundEnabled" :options="goalFramesOptions"/>
    </form-group>
    <form-group inline :label="$t('goals.designForm.elementsSettings.pageBackgroundColor')">
      <color-picker v-model="pageBackgroundColor"/>
    </form-group>
  </div>
</template>

<script>
import { discharge } from '@utils/utils'

export default {
  name: 'ElementsSettingsForm',
  data() {
    return {
      positionOptionsData: [
        {
          value: 'header',
          label: 'atop',
        },
        {
          value: 'body',
          label: 'inside',
        },
        {
          value: 'footer',
          label: 'below',
        },
        {
          value: null,
          label: 'dontDisplay',
        },
      ],
      goalFramesOptionsData: [
        {
          label: 'disabled',
          value: false,
        },
        {
          label: 'enabled',
          value: true,
        },
      ],
      progressTemplateVariants: [
        '{progress}%',
        '{raised} {currency}',
        '{raised} {currency}\n({progress}%)',
        '{raised} / {goal} {currency}',
        '{raised} / {goal} {currency}\n({progress}%)',
      ],

      titlePosition: null,
      progressPosition: null,
      timeLeftPosition: null,
      progressTextTemplate: null,
      goalFramesEnabled: false,
      backgroundEnabled: false,
      pageBackgroundColor: '#00ff00',
    }
  },
  watch: {
    widgetSettings: {
      handler(value, oldValue) {
        if (JSON.stringify(value) !== JSON.stringify(oldValue)) {
          const { positions, framesEnabled, progressTextTemplate, backgroundEnabled, pageBackgroundColor } = _.cloneDeep(value)

          this.titlePosition = positions.title
          this.progressPosition = positions.progressText
          this.timeLeftPosition = positions.timeLeft
          this.progressTextTemplate = progressTextTemplate
          this.goalFramesEnabled = framesEnabled || false
          this.backgroundEnabled = backgroundEnabled || false
          this.pageBackgroundColor = pageBackgroundColor || '#00ff00'
        }
      },
      immediate: true,
    },
    output(value) {
      this.setSettings(value)
    },
  },
  computed: {
    ...mapGetters('goal/settings', ['getPreviewGoal']),
    ...mapState('goal/presets', ['widgetSettings']),

    positionOptions() {
      return this.positionOptionsData.map(option => ({
        ...option,
        label: this.$t(`global.common.${option.label}`),
      }))
    },

    goalFramesOptions() {
      return this.goalFramesOptionsData.map(option => ({
        ...option,
        label: this.$t(`global.common.${option.label}`),
      }))
    },

    progressTemplates() {
      const { raisedAmount, currency, goalAmount, progressPercentage } = this.getPreviewGoal

      const textVariables = {
        raised: discharge(raisedAmount),
        goal: discharge(goalAmount),
        currency: currency,
        progress: progressPercentage,
      }

      return this.progressTemplateVariants.map(template => ({
        value: template,
        label: Object.keys(textVariables).reduce((acc, key) => {
          return acc.replace(`{${key}}`, textVariables[key])
        }, template),
      }))
    },

    output() {
      return {
        positions: {
          title: this.titlePosition,
          progressText: this.progressPosition,
          timeLeft: this.timeLeftPosition,
        },
        framesEnabled: this.goalFramesEnabled,
        backgroundEnabled: this.backgroundEnabled,
        pageBackgroundColor: this.pageBackgroundColor,
        progressTextTemplate: this.progressTextTemplate,
      }
    },
  },
  methods: {
    ...mapMutations('goal/presets', {
      'setSettings': 'SET_SETTINGS',
    }),
  },
}
</script>
