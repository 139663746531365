<style scoped lang="scss">
  .category-sound-settings {

  }
</style>

<template>
  <div class="category-sound-settings">
    <form-group inline :label="$t('roulette.category.visualSettings.sound.winSound')">
      <file-manager v-model="winSound" widget-element="roulette_sound" file-type="sound"/>
    </form-group>
    <form-group v-show="winSound" inline :label="$t('roulette.category.visualSettings.sound.winVolume')">
      <base-range v-model="winSoundVolume" :min="0" :max="100" :step="1" input input-addon="%"/>
    </form-group>
  </div>
</template>

<script>



export default {
  name: 'CategorySoundSettings',
  props: {
    value: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      winSound: null,
      winSoundVolume: 50,
    }
  },
  computed: {
    output() {
      return {
        _version: Math.random().toString(36).slice(2),
        winSound: this.winSound,
        winSoundVolume: this.winSoundVolume,
      }
    },
  },
  created() {
    if (this.value) {
      Object.keys(this.value).forEach(key => {
        this[key] = this.value[key] ?? this[key]
      })
    }
  },
  watch: {
    output(value) {
      this.$emit('input', value)
    },
  },
}
</script>
