<style scoped lang="scss">
  .text-wrapper {
    text-decoration: inherit;
    position: relative;

    .flat-text {
      word-break: break-word;
      text-decoration: inherit;
    }

    .word + .word {
      &:before {
        content: ' ';
      }
    }

    .word {
      display: inline;
      white-space: nowrap;

      .symbol {
        display: inline-block;
      }
    }

    .text-front {
      position: relative;
      z-index: 10;

      &, .word, .word .symbol {
        text-shadow: none;
        text-decoration: inherit;
      }
    }

    .shadow-back {
      @include position-all(absolute, 0);
      z-index: 9;
      text-decoration: none;

      .word .symbol {
        color: transparent;
      }
    }
  }
</style>

<template>
  <div class="text-wrapper" :class="wrapperClasses">
    <div v-if="isFlat" class="flat-text">{{text}}</div>
    <template v-else>
      <div class="text-front">
        <div
          v-for="(word, index) in words"
          :key="`word_${index}`"
          class="word">
          <div
            v-for="(symbol, index) in word"
            :key="`symbol_${index}`"
            :style="symbol.styles"
            class="symbol"
            :class="symbol.classes">{{symbol.symbol}}
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>

export default {
  name: 'TextWrapper',
  props: {
    text: {
      type: [String, Number],
      required: true,
    },
    animation: {
      type: Object,
      default: () => ({}),
    },
    flat: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isFlat() {
      const { style, variant } = this.animation || {}
      return this.flat || !style || variant === 'text'
    },

    wrapperClasses() {
      const { style, variant } = this.animation || {}
      return !this.flat && variant === 'text' ? [style, variant, 'animated', 'infinite'] : null
    },

    words() {
      const { style, variant } = this.animation || {}
      const delay = variant === 'letter-queue' ? .1 : 0
      const letterClasses = variant === 'text' ? null : [style, variant, 'animated', 'infinite']

      return String(this.text).split(' ')
        .map(word => word.split('')
          .map((symbol, index) => ({
              symbol,
              styles: {
                animationDelay: `${index * delay}s`,
              },
              classes: letterClasses,
            }),
          ))
    },
  },
}
</script>
