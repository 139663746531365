export const state = {
  sort: 'desc',
  isLoading: false,
  data: [],
  result: {},
  pagination: {
    page: 1,
    pagesCount: null,
    perPage: null,
    total: null,
  },
}

export const getters = {
  sort: ({ sort }) => sort,
  isLoading: ({ isLoading }) => isLoading,
  data: ({ data }) => data,
  pagination: ({ pagination }) => pagination,
  result: ({ result, }) => result,
}

export const mutations = {
  startLoading(state) {
    state.isLoading = true
  },

  endLoading(state) {
    state.isLoading = false
  },

  setSentDonations(state, { data, result }) {
    state.data = data
    state.result = result
  },

  setPagination(state, pagination) {
    state.pagination = pagination
  },

  setSort(state, newValue) {
    state.sort = newValue
  },
}

export const actions = {
  async fetchSentDonations({ state, commit }, opt = {}) {
    commit('startLoading')

    const params = {
      'include_timestamps': 1,
      'sort[]': 'created_at,' + state.sort,
      'page': state.pagination.page,
      'sent': 1,
    }

    if (opt.date_from) params['date_from'] = opt.date_from
    if (opt.page && opt.page > 0) params['page'] = opt.page

    try {
      const { data, links, meta } = await api.get('/alerts/donations', { params })

      commit('setSentDonations', { data, result: {
        total: meta.total,
        amount: meta.total_amount,
        currency: meta.total_amount_currency,
      }, })
      commit('setPagination', {
        page: meta.current_page,
        pagesCount: meta.last_page,
        perPage: meta.per_page,
        total: meta.total,
      })
      commit('endLoading')
    } catch (error) {
      commit('endLoading')
      throw new Error(error)
    }
  },

  async toggleSort({ state, commit, dispatch }) {
    if (state.sort === 'desc') {
      commit('setSort', 'asc')
    } else {
      commit('setSort', 'desc')
    }

    dispatch('fetchSentDonations')
  },
}