<style scoped lang="scss">
  .roulette-card {
    width: 200px;
    height: 200px;
    position: relative;
    perspective: 500px;

    .card-wrapper {
      width: 100%;
      height: 100%;
      transform-style: preserve-3d;

      &.lootbox {
        transform: rotateY(180deg);

        &.animated {
          transform: rotateY(0deg);
          transition: 1s;
        }
      }

      .roulette-card-lootbox {
        @include position-all(absolute, 0);
        backface-visibility: hidden;
        transform: rotateY(180deg);
      }

      .card-content {
        width: 100%;
        height: 100%;
        position: relative;
        backface-visibility: hidden;
        transform: rotateY(0deg);

        .roulette-card-background {
          @include position-all(absolute, 0);
        }

        .roulette-card-frame {
          @include position-all(absolute, 0);
        }

        .roulette-card-decor {
          @include position-all(absolute, 0);
        }

        .roulette-card-text {
          @include position-all(absolute, 0);
        }
      }
    }

    &.highlight-text {
      .roulette-card-text ::v-deep .card-text {
        &:hover {
          background-color: rgba(white, .1);
          outline: 1px solid rgba(black, .1);
        }
      }
    }
  }
</style>

<template>
  <div class="roulette-card" :class="cardClasses">
    <div class="card-wrapper" :class="wrapperClasses" :style="wrapperStyles">
      <roulette-card-lootbox :settings="lootboxSettings"/>
      <div class="card-content" :class="contentClasses" :style="contentStyles">
        <roulette-card-background :settings="backgroundSettings"/>
        <roulette-card-frame :settings="frameSettings"/>
        <roulette-card-decor :settings="decorSettings"/>
        <roulette-card-text :settings="textSettings" :content="text"/>
      </div>
    </div>

  </div>
</template>

<script>
import RouletteCardBackground from './RouletteCardBackground'
import RouletteCardFrame from './RouletteCardFrame'
import RouletteCardDecor from './RouletteCardDecor'
import RouletteCardText from './RouletteCardText'
import RouletteCardLootbox from './RouletteCardLootbox'

const flexAlign = {
  top: 'flex-start',
  middle: 'center',
  bottom: 'flex-end',
}

const flexJustify = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end',
}

export default {
  name: 'RouletteCard',
  components: {
    RouletteCardLootbox,
    RouletteCardText,
    RouletteCardDecor,
    RouletteCardFrame,
    RouletteCardBackground,
  },
  props: {
    settings: {
      type: Object,
      required: true,
    },
    text: {
      type: String,
      default: null,
    },
    animated: {
      type: Boolean,
      default: false,
    },
    preview: {
      type: Boolean,
      default: false,
    },
    animationPreview: {
      type: Boolean,
      default: false,
    },
    lootboxPreview: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      backgroundSettings: null,
      frameSettings: null,
      decorSettings: null,
      textSettings: null,
      lootboxSettings: null,
      behaviorSettings: null,
    }
  },
  computed: {
    cardClasses() {
      return {
        'highlight-text': this.preview,
      }
    },

    wrapperClasses() {
      const {
        lootboxEnabled,
      } = this.lootboxSettings ?? {}

      return {
        animated: this.animated,
        lootbox: lootboxEnabled && (!this.preview || (this.preview && this.lootboxPreview)),
      }
    },

    wrapperStyles() {
      const styles = {}

      const {
        lootboxEnabled,
        lootboxPreviewRotation,
      } = this.lootboxSettings ?? {}

      if (lootboxEnabled && this.lootboxPreview) {
        styles.transform = `rotateY(${lootboxPreviewRotation}deg)`
        styles.transition = '0s'
      }

      return styles
    },

    contentClasses() {
      return {}
    },

    contentStyles() {
      const {
        lootboxEnabled,
      } = this.lootboxSettings ?? {}

      const {
        animationType,
        animationIteration,
        animationDuration,
      } = this.behaviorSettings

      let styles = {}

      if (!!animationType && (this.animationPreview || this.animated)) {
        styles.animation = [
          animationType,
          `${animationDuration}s`,
          'ease',
          this.animationPreview ? 0 : (lootboxEnabled ? '1s' : '0s'),
          animationIteration === 'once' ? 1 : 'infinite',
          'alternate',
          'forwards',
        ].join(' ')
      }

      return styles
    },
  },
  watch: {
    settings: {
      handler(settings) {
        if (settings) {
          Object.keys(settings).forEach(key => {
            const localVersion = _.get(this[key], '_version', null)
            const externalVersion = _.get(settings[key], '_version', null)
            let different = true

            if (externalVersion && externalVersion === localVersion) {
              different = false
            }

            if (different) {
              this[key] = settings[key] ?? this[key]
            }
          })
        }
      },
      immediate: true,
    },
  },
}
</script>
