<style lang="scss" scoped>
  .icon {
    &.inline {
      height: 1em;
      width: 1em;
    }

    fill: currentColor;

    &.gradient {
      fill: url(#theme_svg_gradient);
    }
  }
</style>

<template>
  <svg v-if="svg.id" class="icon" :class="classes" v-on="$listeners">
    <use :xlink:href="`#${svg.id}`"/>
  </svg>
</template>

<script>

export default {
  data() {
    return {
      svg: {},
    }
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    inline: {
      type: Boolean,
      default: true,
    },
    gradient: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes() {
      return {
        inline: this.inline,
        gradient: this.gradient,
      }
    },
  },
  watch: {
    name: {
      handler() {
        try {
          const icon = require(`@assets/svg/icons-sprite/${this.name}.svg`)
          this.svg = icon.default
        } catch (e) {
          this.svg = {}
          this.$emit('error')
        }
      },
      immediate: true,
    },
  },
}
</script>
