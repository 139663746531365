<style scoped lang="scss">
  .bft-presets-library {
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    position: relative;

    &:after {
      z-index: 2;
      content: '';
      position: absolute;
      bottom: 0;
      left: 30px;
      right: 30px;
      height: 30px;
      background-image: linear-gradient(to top, $additional-3, rgba($additional-3, 0));
      pointer-events: none;
    }

    .library-title {
      flex: 0 0 auto;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;

      @include media(max, sm) {
        padding-left: 30px;
      }
    }

    .presets-list {
      //height: 100%;
      overflow: auto;
      margin-top: 20px;
      padding-right: 30px;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 20px;

      @include media(max, sm) {
        grid-template-columns: repeat(2, 1fr);
        padding-left: 30px;
      }

      @include media(max, xs) {
        grid-template-columns: 1fr;
      }

      @include media(between, sm, md) {
        grid-template-columns: repeat(1, 1fr);
      }

      @include media(between, md, lg) {
        grid-template-columns: repeat(2, 1fr);
      }

      @include media(between, lg, xl) {
        grid-template-columns: repeat(2, 1fr);
      }

      @include media(min, xl) {
        grid-template-columns: repeat(3, 1fr);
      }

      &:after {
        content: '';
        height: 10px;
        grid-column-start: 1;
        grid-column-end: 4;

        @include media(max, md) {
          grid-column-end: 2;
        }

        @include media(between, lg, xl) {
          grid-column-end: 2;
        }

        @include media(between, md, lg) {
          grid-column-end: 3;
        }

        @include media(min, xl) {
          grid-column-end: 4;
        }
      }

      .preset {
        height: 176px;
        background: #212121;
        border: 1px solid #383838;
        border-radius: 8px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        &.active {
          border-color: var(--theme-color-additional);
        }

        .widget-bft-actions {
          position: absolute;
          z-index: 2;
          transform-origin: left top;
          top: 50%;
          left: 50%;
          scale: .75;
          transform: translate(-50%, -50%);
        }

        .apply-button {
          position: absolute;
          z-index: 3;
          opacity: 0;
          align-self: flex-end;
          margin-bottom: 5px;
        }

        &:hover {
          .apply-button {
            opacity: 1;
          }
        }
      }
    }
  }
</style>

<template>
  <div class="bft-presets-library">
    <div class="library-title">
      {{ $t('bft.widget.preview.library') }}
    </div>
    <div class="presets-list">
      <div
        v-for="(preset, presetIndex) in presets"
        :key="presetIndex"
        class="preset">
        <widget-bft-actions
          :categories="categories"
          :actions="actions"
          :settings="preset.settings"/>
        <btn size="sm" class="apply-button" @click="applyPreset(preset.id)">
          {{ $t('global.common.apply') }}
        </btn>
      </div>
    </div>
  </div>
</template>

<script>
import WidgetBftActions from '@components/Widgets/WidgetBftActions/WidgetBftActions'

const actionNames = [
  'Восстановить здоровье',
  'Восстановить броню',
  'Временные мегапрыжки',
  'Временная суперскорость',
  'Полная броня',
  'Набор винтовок',
  'Набор пистолетов',
  'Телепорт к фонтану',
  'Выдать $10000',
  'Забрать $10000',
]

export default {
  name: 'BftPresetsLibrary',
  components: { WidgetBftActions },
  data() {
    return {
      categories: [
        {
          id: 1,
          name: 'Превью',
        },
      ],

      actions: Array.from({ length: 4 }, (v, k) => {
        return {
          id: k + 1,
          categoryId: 1,
          name: _.sample(actionNames),
          price: _.random(50, 1337),
        }
      }),
    }
  },
  computed: {
    ...mapGetters('bft/widget', ['getPresetsList']),

    presets() {
      return this.getPresetsList.map(preset => ({
        id: preset.id,
        settings: preset.json,
      }))
    },
  },
  methods: {
    ...mapActions('bft/widget', ['applyPreset']),
  },
}
</script>
