<template>
  <div 
    class="pin"
    :class="'pin_' + type"
   >
    <icon v-if="type === 'new'" name="new"/>

    <icon v-if="type === 'active'" name="play"/>

    <icon v-if="type === 'closed'" name="cross"/>

    <div class="pin__tooltip">
      <span v-if="type === 'new'">{{$t('offers.advertisersAccount.new')}}</span>

      <span v-if="type === 'active'">{{$t('offers.advertisersAccount.active')}}</span>

      <span v-if="type === 'closed'">{{$t('offers.advertisersAccount.completed')}}</span>
    </div>
  </div>
</template>

<script>
export default {
  components: {},

  data() {
    return {}
  },

  props: {
    type: {
      type: String,
      default: '',
    }
  },
}
</script>

<style scoped lang="scss">
.pin {
  margin: 0 0 0 5px;
  width: 30px;
  height: 40px;
  border-radius: 0 0 8px 8px;
  background: #F59C07 linear-gradient(200deg, #F59C07 0%, #F57507 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  position: relative;

  &_new {
    background: #25C94C linear-gradient(180deg, #25C94C 0%, #1EB141 100%);

    .pin__tooltip {
      background: #1EB141;

      &:after {
        border-top: 8px solid #1EB141;
      }
    }
  }

  &_active {
    background: #F59C07 linear-gradient(200deg, #F59C07 0%, #F57507 100%);

    .pin__tooltip {
      background: #F57507;

      &:after {
        border-top: 8px solid #F57507;
      }
    }
  }

  &_closed {
    background: #D63A3A linear-gradient(180deg, #D63A3A 0%, #b12f2f 100%);

    .pin__tooltip {
      background: #b12f2f;

      &:after {
        border-top: 8px solid #b12f2f;
      }
    }
  }

  &__tooltip {
    position: absolute;
    top: -13px;
    left: 50%;
    background: #F59C07;
    border-radius: 8px;
    color: #F1F1F1;
    font-size: 13px;
    max-width: 260px;
    padding: 5px 10px;
    transform: translate(-50%, -100%);
    display: none;

    &:after {
      content: '';
      position: absolute;
      display: block;
      width: 0;
      height: 0;
      border-top: 8px solid #F59C07;
      border-bottom: 8px solid transparent; 
      border-right: 10px solid transparent; 
      border-left: 10px solid transparent;
      bottom: -16px;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &:hover {
    .pin__tooltip {
      display: block;
    }
  }
}
</style>