import storage from '@services/storage'
import cookies from '@services/cookies'

const debug = storage.getItem('da.donation.debug.tmr')

let isAstrumApp = false

window._tmr = window._tmr || []

window._tmr.push({
  type: 'onready',
  callback: function () {
    const tmrLvid = _tmr.getClientID()
    cookies.setItem('my_tracker_user_id', tmrLvid, Infinity, '/')
  },
})

const getTrackerID = () => isAstrumApp ? window._tmr_astrum_tracker_id : window._tmr_da_tracker_id

class TMR {
  push(payload) {
    if (debug) {
      console.log('[TMR]', payload)
    }

    window._tmr.push(payload)
  }

  goal({ goal, value }, params = {}) {
    const payload = {
      id: getTrackerID(),
      type: 'reachGoal',
      goal,
      value,
      params: {
        source_url: window.location.pathname,
        ...params,
      },
    }

    this.push(payload)
  }

  pageView() {
    const payload = {
      id: getTrackerID(),
      type: 'pageView',
      start: (new Date()).getTime(),
    }

    this.push(payload)
  }
}

export const tmr = new TMR()

export default {
  install: (Vue, options = {}) => {
    isAstrumApp = !!options.astrum
    Vue.tmr = tmr
    Vue.prototype.$tmr = Vue.tmr
  },
}
