<style scoped lang="scss">
  .stream-stats-config {
    display: flex;
    position: relative;

    .widget-config {
      flex-grow: 1;
      display: flex;
      flex-direction: column;

      .stream-stats-settings-form {
        margin-top: 15px;
      }

      .config-controls {
        margin-top: auto;
        padding-top: 15px;
      }
    }

    .widget-preview {
      margin-left: 30px;
      width: 600px;
      flex-shrink: 0;
      flex-grow: 0;

      .preview-title {
        height: 52px;
        font-size: 18px;
        display: flex;
        align-items: center;
      }

      .stream-stats-preview {
        margin-top: 15px;
        height: 600px;
      }
    }

    .widget-config-loader {
      @include position-all(absolute, 0);
      z-index: 10;
      background-color: rgba($black, .5);
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
</style>

<template>
  <div class="stream-stats-config">
    <transition name="fade">
      <div v-if="loadingState.widget" class="widget-config-loader">
        <loader size="lg"/>
      </div>
    </transition>
    <template v-if="!loadingState.widget">
      <div class="widget-config">
        <tabs v-model="currentTab" :list="tabs"/>

        <div class="tabs-content">
          <div v-show="currentTab === 'settings'">
            <form-group :label="$t('streamStats.labels.title')" inline>
              <base-input v-model="title" :placeholder="previewTitle"/>
            </form-group>
            <stream-stats-settings-form v-model="widgetSettings"/>
          </div>
          <stream-stats-design-form
            v-show="currentTab === 'design'"
            v-model="widgetDesign"
            :settings="widgetSettings"/>
        </div>

        <div class="config-controls">
          <btn
            variant="primary-action"
            @click="save">
            {{ saveButtonText }}
          </btn>
        </div>
      </div>
      <div class="widget-preview">
        <div class="preview-title">
          {{ $t('streamStats.labels.widgetTitle', { title: previewTitle }) }}
        </div>
        <stream-stats-preview :widget-settings="widgetSettings" :widget-design="widgetDesign"/>
      </div>
    </template>
  </div>
</template>

<script>

import StreamStatsPreview from './StreamStatsPreview'
import StreamStatsSettingsForm from './StreamStatsSettingsForm'
import StreamStatsDesignForm from './StreamStatsDesignForm'

import dataTypeSettings from '@src/config/streamStats/dataTypeSettings'

import { defaultWidgetDesign, defaultWidgetSettings } from '@src/config/streamStats/defaultValues'

export default {
  name: 'StreamStatsConfig',
  components: { StreamStatsDesignForm, StreamStatsSettingsForm, StreamStatsPreview },
  props: {
    id: {
      type: [Number, String],
      default: null,
    },
  },
  data() {
    return {
      loadingState: {
        widget: true,
      },

      currentTab: 'settings',

      title: null,
      widgetSettings: null,
      widgetDesign: null,
    }
  },
  computed: {
    ...mapGetters('streamStats/widget', ['getWidget']),
    ...mapState('dashboard', ['newDashboard']),

    sourceWidgetId: (vm) => vm.$route.query?.sourceWidgetId || null,

    newWidget() {
      return this.id === 'create'
    },

    tabs() {
      return [
        {
          key: 'settings',
        },
        {
          key: 'design',
        },
      ].map(tab => ({
        ...tab,
        title: this.$t(`streamStats.tabs.${tab.key}`),
      }))
    },

    previewTitle() {
      const dataType = this.widgetSettings.dataType
      const title = dataTypeSettings[dataType].allowPeriod
        ? `titlePeriod.${this.widgetSettings.dataPeriod}` : 'title'
      const customPeriod = '22.04.2022 - 04.05.2022'

      return this.$t(`streamStats.dataType.${dataType}.${title}`, { customPeriod: customPeriod })
    },

    saveButtonText() {
      const textPath = this.newWidget ? 'createWidget' : 'saveChanges'

      return this.$t(`streamStats.${textPath}`)
    },

    currentWidget() {
      return this.getWidget(this.id)
    },
  },
  methods: {
    ...mapActions('streamStats/widget', ['fetchWidget', 'createWidget', 'updateWidget']),

    save() {
      const action = this.newWidget ? this.createWidget : this.updateWidget

      action({
        id: this.id,
        title: this.title && this.title.length ? this.title : this.previewTitle,
        settings: this.widgetSettings,
        design: this.widgetDesign,
      })
        .then(() => {
          if (this.newDashboard) {
            this.$router.push({ name: 'dashboard-widget-stream-stats-list' })
          } else {
            this.$router.push({ name: 'stream-stats' })
          }

        })
        .finally(() => {
          this.loadingState.widget = false
        })
    },
  },
  created() {
    if (!this.newWidget || this.sourceWidgetId) {
      this.loadingState.widget = true

      const id = this.newWidget ? this.sourceWidgetId : this.id

      this.fetchWidget(id)
        .then((widget) => {
          const { title, json } = _.cloneDeep(widget)
          const { design, settings } = json

          this.title = title
          this.widgetSettings = settings
          this.widgetDesign = design
        })
        .finally(() => {
          this.loadingState.widget = false
        })
    } else {
      this.loadingState.widget = false

      this.widgetSettings = defaultWidgetSettings()
      this.widgetDesign = defaultWidgetDesign()
    }
  },
}
</script>
