<style scoped lang="scss">
  .meme-alerts-banner {
    height: 100px;
    position: relative;
    background: linear-gradient(90deg, #FF6001 0%, #FF8801 100%);
    overflow: hidden;
    border-radius: 8px;
    display: flex;
    align-items: center;

    @include media(max, sm) {
      flex-direction: column;
      height: 140px;
      justify-content: center;
    }

    .pixel {
      position: fixed;
      width: 0;
      height: 0;
      top: -10000px;
    }

    .lines {
      position: absolute;
      z-index: 0;
      top: 0;
      right: 0;
      bottom: 0;
      width: 159px;
      background-image: appAsset('/images/banners/meme-alerts/bg-lines.png');

      @include mediaDPI() {
        background-image: appAsset('/images/banners/meme-alerts/bg-lines@2x.png');
        background-size: contain;
      }

      @include media(max, sm) {
        width: 111px;
        background-image: appAsset('/images/banners/meme-alerts/bg-lines-1.svg');
        background-size: 210%;
      }
    }

    .memes {
      position: absolute;
      z-index: 0;
      top: 0;
      left: 0;
      bottom: 0;
      width: 160px;
      background-image: appAsset('/images/banners/meme-alerts/bg-memes.png');

      @include mediaDPI() {
        background-image: appAsset('/images/banners/meme-alerts/bg-memes@2x.png');
        background-size: contain;
      }

      @include media(max, sm) {
        width: 80px;
      }
    }

    .content {
      position: relative;
      z-index: 1;
      margin-left: 149px;

      @include media(max, sm) {
        margin-left: 0;
      }

      .title {
        width: 229px;
        height: 35px;
        background-image: appAsset('/images/banners/meme-alerts/bg-logo.png');

        @include mediaDPI() {
          background-image: appAsset('/images/banners/meme-alerts/bg-logo@2x.png');
          background-size: contain;
        }
      }

      .text {
        margin-top: 8px;
        font-weight: 600;
        font-size: 20px;
        line-height: 108.52%;
        color: #FFFFFF;

        @include media(max, sm) {
          font-size: 16px;
          text-shadow: 0 0 4px black;
        }
      }
    }

    .control {
      position: relative;
      z-index: 1;
      margin-left: auto;
      background: #FFFFFF;
      border-radius: 8px;
      padding: 8px 31px;
      font-weight: 600;
      font-size: 12px;
      line-height: 20px;
      text-align: center;
      text-transform: uppercase;
      color: #000000;
      margin-right: 26px;

      @include media(max, sm) {
        margin-right: 0;
        margin-left: 0;
        margin-top: 10px;
      }
    }
  }
</style>

<template>
  <div class="meme-alerts-banner">
    <img class="pixel" src="https://trk.mail.ru/i/f22un4"/>
    <div class="lines"/>
    <div class="memes"/>
    <div class="content">
      <div class="title"/>
      <div class="text">{{ $t('dashboard.banners.memeAlerts.text') }}</div>
    </div>
    <a href="https://trk.mail.ru/c/f22un4" target="_blank" class="control">
      {{ $t('dashboard.banners.memeAlerts.button') }}
    </a>
  </div>
</template>

<script>

export default {
  name: 'MemeAlertsBanner',
}
</script>
