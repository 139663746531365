<style lang="scss" scoped>
  .color-picker {
    position: relative;

    .color-input {
      background-color: $black;
      border-radius: $control-border-radius;
      position: relative;

      .base-input {
        position: relative;
        z-index: 2;
        background-color: transparent;
        padding-right: $control-height;
      }

      .color-button {
        @include position(absolute, 5px, 5px);
        width: calc(#{$control-height} - 10px);
        height: calc(#{$control-height} - 10px);
        border-radius: $control-border-radius - 2px;
        pointer-events: none;
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAIAAADZF8uwAAAAGUlEQVQYV2M4gwH+YwCGIasIUwhT25BVBADtzYNYrHvv4gAAAABJRU5ErkJggg==);
        overflow: hidden;
        z-index: 1;

        .current-color {
          width: 100%;
          height: 100%;
        }
      }
    }

    .color-palette {
      @include position(absolute, calc(100% + 10px), auto, auto, 0);
      z-index: 50;
    }

    &.top {
      .color-palette {
        top: auto;
        bottom: calc(100% + 10px);
      }
    }
  }
</style>

<template>
  <div class="color-picker" :class="pickerClasses" ref="picker">
    <div class="color-input">
      <base-input
        :class="inputClasses"
        v-model="color"
        ref="input"
        @blur="onInputBlurred"
        @focus="onInputFocused"/>
      <div class="color-button">
        <div class="current-color" :style="currentColorStyles"></div>
      </div>
    </div>
    <transition name="fade-up">
      <color-palette
        v-if="paletteVisible"
        ref="palette"
        v-model="color"
        @drag-start="onDragStart"
        @drag-stop="onDragStop"/>
    </transition>
  </div>
</template>

<script>
import ColorPalette from '@components/BaseComponents/ColorPicker/ColorPalette'

export default {
  name: 'ColorPicker',
  components: { ColorPalette },
  props: {
    value: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      color: null,

      inputFocused: false,
      paletteFocused: false,

      timeout: null,

      palettePosition: 'bottom',
    }
  },
  watch: {
    color(color) {
      this.$emit('input', color)
    },

    value: {
      handler(value) {
        this.color = value
      },
      immediate: true,
    },

    $scrollTop: 'calculatePalettePosition',
    paletteVisible: 'calculatePalettePosition',
  },
  computed: {
    pickerClasses() {
      return {
        [this.palettePosition]: !!this.palettePosition,
      }
    },

    paletteVisible() {
      return this.inputFocused || this.paletteFocused
    },

    currentColorStyles() {
      return {
        backgroundColor: this.color,
      }
    },

    inputClasses() {
      return {
        focus: this.paletteVisible,
      }
    },
  },
  methods: {
    calculatePalettePosition() {
      this.$nextTick(() => {
        if (this.paletteVisible && this.$refs.palette) {
          const paletteRect = this.$refs.palette.$el.getBoundingClientRect()
          const pickerRect = this.$refs.picker.getBoundingClientRect()

          if (this.palettePosition === 'bottom') {
            this.palettePosition = window.innerHeight - paletteRect.bottom < 0 ? 'top' : 'bottom'
          } else {
            this.palettePosition = window.innerHeight - paletteRect.bottom < paletteRect.height + pickerRect.height + 20 ? 'top' : 'bottom'
          }
        }
      })
    },

    focusInput() {
      this.$refs.input.$el.focus()
    },

    onInputFocused() {
      this.inputFocused = true
    },

    onInputBlurred() {
      this.inputFocused = false
    },

    onDragStart() {
      this.paletteFocused = true
    },

    onDragStop() {
      this.focusInput()
      this.paletteFocused = false
    },
  },
}
</script>
