<style scoped lang="scss">
  .roulette-behavior-controls {
    .button + .button {
      margin-left: 10px;

      @include media(max, md) {
        margin-left: 5px;
      }
    }

    .button {
      @include media(max, md) {
        padding: 6px 8px
      }
    }
  }
</style>

<template>
  <div class="roulette-behavior-controls">
    <btn @click="appear">{{ $t('roulette.behaviorControls.appear') }}</btn>
    <btn @click="roll">{{ $t('roulette.behaviorControls.spin') }}</btn>
    <btn @click="disappear">{{ $t('roulette.behaviorControls.disappear') }}</btn>
    <btn @click="fullCycle">{{ $t('roulette.behaviorControls.cycle') }}</btn>
  </div>
</template>

<script>

export default {
  name: 'RouletteBehaviorControls',
  methods: {
    appear() {
      this.$root.$emit('roulette.widget.appear')
    },

    disappear() {
      this.$root.$emit('roulette.widget.disappear')
    },

    roll() {
      this.$root.$emit('roulette.widget.roll')
    },

    refresh() {
      this.$root.$emit('roulette.widget.refresh')
    },

    fullCycle() {
      this.$root.$emit('roulette.widget.full-cycle')
    },
  },
}
</script>
