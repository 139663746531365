<style scoped lang="scss">
  .slider-settings {

  }
</style>

<template>
  <div class="slider-settings">
    <form-group inline :label="$t('global.settings.labels.speed')">
      <base-range
        v-model="slideDuration"
        :min="1"
        :max="15"
        input
        :input-addon="$t('global.settings.helpers.seconds')"/>
    </form-group>
    <form-group inline :label="$t('global.settings.labels.animation')">
      <dropdown-select v-model="animation" :options="animationOptions"/>
    </form-group>
  </div>
</template>

<script>

import { defaultLayoutSettings } from '@src/config/bft/defaultValues'

const { sliderSettings } = defaultLayoutSettings()

export default {
  name: 'SliderSettings',
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      slideDuration: sliderSettings.slideDuration,
      animation: sliderSettings.animation,
    }
  },
  computed: {
    animationOptions() {
      return [
        {
          value: 'fade',
        },
        {
          value: 'slide',
        },
      ].map(option => ({
        ...option,
        label: option.value ? option.value : null,
      }))
    },

    output() {
      return {
        slideDuration: this.slideDuration,
        animation: this.animation,
      }
    },
  },
  watch: {
    output: {
      handler(value) {
        this.$emit('input', value)
      },
      immediate: true,
    },

    value: {
      handler(value) {
        if (value) {
          Object.keys(value).forEach(key => {
            this[key] = value[key] ?? this[key]
          })
        }
      },
      immediate: true,
    },
  },
}
</script>
