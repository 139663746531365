<style lang="scss" scoped>
  .widget-last-donations-page {

  }
</style>

<template>
  <div class="widget-last-donations-page">
    <div ref="legacy"/>
  </div>
</template>

<script>
import { loadLegacyPage } from '@src/services/legacyLoader'

export default {
  name: 'WidgetLastDonationsPage',
  data() {
    return {}
  },
  mounted() {
    loadLegacyPage('/dashboard/old-page-lastdonations-widget', this.$refs.legacy)
  },
}
</script>
