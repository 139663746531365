<style lang="scss" scoped>
  .shadow-styler {
    display: inline-flex;
    justify-content: center;
    flex-direction: column;
    position: relative;

    @include media(max, md) {
      display: flex;
    }

    .shadow-settings-popover {
      @include position(absolute, 100%, auto, auto, 0);
      z-index: 50;
      width: 520px;
      padding: 20px;

      @include media(max, md) {
        width: 100%;
        padding: 0 $gutter-mobile $gutter-mobile $gutter-mobile;
      }
    }

    &.top {
      .shadow-settings-popover {
        top: auto;
        bottom: 100%;
      }
    }

    &.left {
      .shadow-settings-popover {
        left: auto;
        right: 0;
      }
    }
  }
</style>

<template>
  <div ref="select" class="shadow-styler" :class="stylerClasses" v-click-outside="hidePopover">
    <btn icon-left="shadow" @click="togglePopover">
      {{ $t('global.shadowStyler.adjustShadow') }}
    </btn>
    <transition name="fade-up">
      <shadow-settings-popover
        v-if="popoverVisible"
        ref="dropdown"
        @close="hidePopover"
        :value="value"
        @input="onInput"
        :units="units"/>
    </transition>
  </div>
</template>

<script>
import ShadowSettingsPopover from '@components/BaseComponents/ShadowStyler/ShadowSettingsPopover'

export default {
  name: 'ShadowStyler',
  components: { ShadowSettingsPopover },
  props: {
    value: {
      type: String,
      default: null,
    },
    popoverPosition: {
      type: String,
      default: 'bottom',
    },
    units: {
      type: String,
      default: 'px',
    },
  },
  data() {
    return {
      popoverVisible: false,

      dropdownPosition: 'bottom',
    }
  },
  computed: {
    stylerClasses() {
      return {
        [this.dropdownPosition]: !!this.dropdownPosition,
      }
    },
  },
  watch: {
    $scrollTop: 'calculateDropdownPosition',
    popoverVisible: 'calculateDropdownPosition',
  },
  methods: {
    calculateDropdownPosition() {
      this.$nextTick(() => {
        if (this.popoverVisible && this.$refs.dropdown) {
          const dropdownRect = this.$refs.dropdown.$el.getBoundingClientRect()
          const selectRect = this.$refs.select.getBoundingClientRect()

          if (this.dropdownPosition === 'bottom') {
            this.dropdownPosition = window.innerHeight - dropdownRect.bottom < 0 ? 'top' : 'bottom'
          } else {
            this.dropdownPosition = window.innerHeight - dropdownRect.bottom < dropdownRect.height + selectRect.height + 20 ? 'top' : 'bottom'
          }
        }
      })
    },

    onInput(value) {
      this.$emit('input', value)
    },

    togglePopover() {
      this.popoverVisible = !this.popoverVisible
    },

    hidePopover() {
      this.popoverVisible = false
    },
  },
}
</script>
