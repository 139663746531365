<style scoped lang="scss">
  .sent-donations-page {
    .link {
      color: var(--theme-color-main);
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }

    .filters {
      display: flex;
      align-items: center;
      margin-bottom: 15px;

      .dd {
        width: 240px;
      }

      .result-text {
        margin-left: 20px;
        color: #838383;
      }
    }
  }
</style>

<template>
  <div class="sent-donations-page">
    <Title>{{$t('sentDonations.paymentHistory')}}</Title>

    <div
      v-if="pagination.pagesCount"
      class="filters"
    >
      <dropdown-select
        class="dd"
        v-model="titlePeriod"
        :options="periodOptions"
      />

      <span class="result-text">
          {{$tc('sentDonations.result_plural', result.total)}} {{transformAmount(result.amount)}} {{transformCurrency(result.currency)}}
        </span>
    </div>

    <loader
      size="md"
      v-if="isLoading && data.length === 0"
      style="margin: 10vh auto;"
    />

    <div
      v-if="data.length > 0"
      :class="{ 'grid_disabled': isLoading }"
    >
      <data-grid>
        <data-grid-header>
          <data-grid-col size="20">#</data-grid-col>
          <data-grid-col size="20">{{$t('sentDonations.payee')}}</data-grid-col>
          <data-grid-col size="20">{{$t('sentDonations.amount')}}</data-grid-col>
          <data-grid-col size="20">{{$t('sentDonations.system')}}</data-grid-col>
          <data-grid-col size="20">
              <span
                class="link"
                @click="toggleSort"
              >{{ $t('sentDonations.datetime') + (sort === 'desc' ? ' ↓' : ' ↑') }}</span>
          </data-grid-col>
        </data-grid-header>

        <data-grid-row
          v-for="(item, i) in data"
          :key="i"
        >
          <data-grid-col>
            <span style="opacity: .5">{{ item.id }}</span>
          </data-grid-col>
          <data-grid-col>
            <span>{{ item.recipient_name }}</span>
          </data-grid-col>
          <data-grid-col>
            <span>{{ transformAmount(item.amount) + ' ' + transformCurrency(item.currency) }}</span>
          </data-grid-col>
          <data-grid-col>
              <span
                v-if="item.payin_system"
              >{{ item.payin_system.title }}</span>
          </data-grid-col>
          <data-grid-col>
            <span style="opacity: .5">{{ transformDate(item.created_at_ts) }}</span>
          </data-grid-col>
        </data-grid-row>
      </data-grid>

      <pagination
        v-if="pagination.pagesCount && pagination.pagesCount > 1"
        v-model="page"
        :disabled="pastGoalsLoading"
        :current-page="page"
        :pages-count="pagination.pagesCount"
        style="margin: 20px 0;"
      />
    </div>
    <div
      v-if="!isLoading && data.length < 1"
    >
      {{$t('sentDonations.noData')}}
    </div>
  </div>
</template>

<script>
import fromUnixTime from 'date-fns/fromUnixTime'
import subDays from 'date-fns/subDays'

import currency from 'currency.js'
import Title from '@components/BaseComponents/Title'
import DG from '@components/Dashboard/DataGrid'
import Pagination from '@components/BaseComponents/Pagination'

export default {
  name: 'SentDonationsPage',
  components: {
    Title,
    DataGrid: DG.DataGrid,
    DataGridHeader: DG.DataGridHeader,
    DataGridRow: DG.DataGridRow,
    DataGridCol: DG.DataGridCol,
    Pagination,
  },

  data() {
    return {
      page: 1,
      pastGoalsLoading: false,
      titlePeriod: 'allTime',
      periodOptionsData: [
        { value: 'allTime', label: 'allTime' },
        { value: 'past24Hours', label: 'past24Hours' },
        { value: 'past7Days', label: 'past7Days' },
        { value: 'past30Days', label: 'past30Days' },
        { value: 'currentMonth', label: 'currentMonth' },
        { value: 'currentYear', label: 'currentYear' },
      ],
    }
  },
  metaInfo() {
    return {
      title: this.$t('dashboard.pages.sentDonations'),
    }
  },
  watch: {
    page: {
      handler(newVal, oldVal) {
        if (oldVal) this.updateSentDonations()
      },
      immediate: true,
    },
    titlePeriod: {
      async handler(newVal, oldVal) {
        if (!oldVal) return

        let date, rawDate

        switch(newVal) {
          case 'currentYear':
            date = new Date().toISOString().substr(0, 4) + '-01-01 00:00:00'
            break
          case 'currentMonth':
            date = new Date().toISOString().substr(0, 7) + '-01 00:00:00'
            break
          case 'past30Days':
            rawDate = subDays(new Date(), 30)
            date = new Date(rawDate).toISOString().substr(0, 10) + ' 00:00:00'
            break
          case 'past7Days':
            rawDate = subDays(new Date(), 7)
            date = new Date(rawDate).toISOString().substr(0, 10) + ' 00:00:00'
            break
          case 'past24Hours':
            rawDate = subDays(new Date(), 1)
            date = new Date(rawDate).toISOString().substr(0, 10) + ' ' + new Date(rawDate).toISOString().substr(11, 8)
            break
          default:
            date = null
        }

        await this.fetchSentDonations({
          'date_from': date
        })
      },
      immediate: true,
    },
  },

  created() {
    this.fetchSentDonations()
  },

  methods: {
    ...mapActions('sentDonations', [
      'fetchSentDonations',
      'toggleSort',
    ]),

    async updateSentDonations() {
      await this.fetchSentDonations({ page: this.page })
    },

    transformDate(val) {
      const timeZone = this.getTimezone

      const result = dates.unixToFormat(dates.utcToZonedTime(fromUnixTime(val), timeZone) / 1000, dates.DEFAULT_TIME_FORMAT)

      return result
    },

    transformAmount(val) {
      return currency(Math.abs(val), {
        separator: ' ',
        decimal: ','
      }).format()
    },

    transformCurrency(val) {
      return val
    }
  },

  computed: {
    ...mapGetters('profile', [
      'getTimezone',
    ]),

    ...mapGetters('sentDonations', [
      'sort',
      'isLoading',
      'data',
      'result',
      'pagination',
    ]),

    periodOptions() {
      return this.periodOptionsData.map(option => ({
        ...option,
        label: this.$t(`sentDonations.filter.${option.label}`),
      }))
    },
  },
}
</script>
