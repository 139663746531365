<style scoped lang="scss">
  .confirm-modal {

  }
</style>

<template>
  <modal class="confirm-modal" v-model="modalOpened">
    <template slot="title">{{ title }}</template>
    <template slot="body-content">
      {{ message }}
    </template>
    <template slot="controls">
      <btn @click="confirm" variant="primary-action">
        {{ $t('global.common.confirm') }}
      </btn>
      <btn @click="cancel" variant="secondary-action">
        {{ $t('global.common.cancel') }}
      </btn>
    </template>
  </modal>
</template>

<script>

export default {
  name: 'ConfirmModal',
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      default: null,
    },
    message: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      modalOpened: false,
    }
  },
  methods: {
    cancel() {
      this.$emit('cancel')
      this.modalOpened = false
    },

    confirm() {
      this.$emit('confirm')
      this.modalOpened = false
    },
  },
  watch: {
    value: {
      handler(value) {
        this.modalOpened = value
      },
      immediate: true,
    },
    modalOpened(value) {
      this.$emit('input', value)
    },
  },
}
</script>
