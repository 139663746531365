<style scoped lang="scss">
  .boosty-link-settings {

  }

</style>

<template>
  <div class="boosty-link-settings">
    <Title>{{ $t('accountSettings.boosty.title') }}</Title>

    <template v-if="!loadingState.page">
      <form-group>
        <base-checkbox
          v-model="bannerVisible"
          :label="$t('accountSettings.boosty.bannerVisible')"/>
      </form-group>
    </template>
  </div>
</template>

<script>
import Title from '@components/BaseComponents/Title'

export default {
  name: 'BoostyLinkSettings',
  components: {
    Title,
  },
  data() {
    return {
      loadingState: {
        page: true,
      },

      bannerVisible: false,
    }
  },
  created() {
    this.fetchSettings()
  },
  methods: {
    fetchSettings() {
      return api.get('/boostysetting')
        .then(({ data }) => {
          this.bannerVisible = data.is_shown_banner
        })
        .finally(() => {
          this.loadingState.page = false
        })
    },

    updateSettings() {
      return api.put('/boostysetting', {
        is_shown_banner: this.bannerVisible,
      })
    },
  },
  watch: {
    bannerVisible() {
      this.updateSettings()
    },
  },
}
</script>
