// Register global components

import Vue from 'vue'


import { VPopover } from 'v-tooltip'

Vue.component('v-popover', VPopover)

// Layout
import DashboardLayout from '@layouts/DashboardLayout'
import CabLayout from '@layouts/CabLayout'

Vue.component('dashboard-layout', DashboardLayout)
Vue.component('cab-layout', CabLayout)

// Grid
import Container from '@components/BaseComponents/Grid/Container'
import Grid from '@components/BaseComponents/Grid/Grid'

Vue.component('container', Container)
Vue.component('grid', Grid)

// Form
import FormGroup from '@components/BaseComponents/Form/FormGroup'
import BaseInput from '@components/BaseComponents/Form/BaseInput'
import BaseTextarea from '@components/BaseComponents/Form/BaseTextarea'
import InputAddon from '@components/BaseComponents/Form/InputAddon'
import BaseSelect from '@components/BaseComponents/Form/BaseSelect'

Vue.component('form-group', FormGroup)
Vue.component('base-input', BaseInput)
Vue.component('base-textarea', BaseTextarea)
Vue.component('input-addon', InputAddon)
Vue.component('base-select', BaseSelect)

// Select
import DropdownSelect from '@components/BaseComponents/Select/DropdownSelect'

Vue.component('dropdown-select', DropdownSelect)

// Misc
import Icon from '@components/BaseComponents/Icon'

Vue.component('icon', Icon)

// Time
import TimeDiff from '@components/BaseComponents/TimeDiff'

Vue.component('time-diff', TimeDiff)

import BaseButton from '@components/BaseComponents/BaseButton'
import BaseSwitch from '@components/BaseComponents/BaseSwitch'
import BaseCheckbox from '@components/BaseComponents/BaseCheckbox'
import BaseRange from '@components/BaseComponents/BaseRange'
import TagReplacer from '@components/BaseComponents/TagReplacer'

Vue.component('btn', BaseButton)
Vue.component('base-switch', BaseSwitch)
Vue.component('base-checkbox', BaseCheckbox)
Vue.component('base-range', BaseRange)
Vue.component('tag-replacer', TagReplacer)

// Pickers
import TimePicker from '@components/BaseComponents/DateTimePicker/TimePicker'
import DatePicker from '@components/BaseComponents/DateTimePicker/DatePicker'
import DateTimePicker from '@components/BaseComponents/DateTimePicker/DateTimePicker'
import ColorPicker from '@components/BaseComponents/ColorPicker/ColorPicker'
import GradientPicker from '@components/BaseComponents/ColorPicker/GradientPicker'

Vue.component('date-time-picker', DateTimePicker)
Vue.component('time-picker', TimePicker)
Vue.component('date-picker', DatePicker)
Vue.component('color-picker', ColorPicker)
Vue.component('gradient-picker', GradientPicker)

import Modal from '@components/BaseComponents/Modal'
import Loader from '@components/BaseComponents/Loader'
import InfoBox from '@components/BaseComponents/InfoBox'
import Tabs from '@components/BaseComponents/Tabs/Tabs'
import VerticalTabs from '@components/BaseComponents/Tabs/VerticalTabs'
import FileManager from '@components/BaseComponents/FileManager/FileManager'

Vue.component('modal', Modal)
Vue.component('info-box', InfoBox)
Vue.component('loader', Loader)
Vue.component('tabs', Tabs)
Vue.component('vertical-tabs', VerticalTabs)
Vue.component('file-manager', FileManager)

// Common
import ConfirmModal from '@components/Dashboard/_CommonComponents/ConfirmModal'

Vue.component('confirm-modal', ConfirmModal)
