<style scoped lang="scss">
  .fonts-settings-form {

  }
</style>

<template>
  <div class="fonts-settings-form">
    <form-group v-if="positions.title" inline :label="$t('goals.designForm.fontSettings.goalTitle')">
      <text-styler v-model="titleStyles" with-animation units="vw"/>
    </form-group>
    <form-group v-if="positions.progressText" inline :label="$t('goals.designForm.fontSettings.progressText')">
      <text-styler v-model="progressStyles" with-animation units="vw"/>
    </form-group>
    <form-group v-if="positions.timeLeft" inline :label="$t('goals.designForm.fontSettings.timeLeft')">
      <text-styler v-model="timeLeftStyles" :popover-position="popoverPositions.timeLeft" with-animation units="vw"/>
    </form-group>
    <form-group v-if="widgetSettings.framesEnabled" inline :label="$t('goals.designForm.fontSettings.goalFrames')">
      <text-styler v-model="framesStyles" :popover-position="popoverPositions.frames" with-animation units="vw"/>
    </form-group>
  </div>
</template>

<script>

import TextStyler from '@components/BaseComponents/TextStyler/TextStyler'

export default {
  name: 'FontsSettingsForm',
  components: { TextStyler },
  computed: {
    ...mapState('goal/presets', [
      'widgetSettings',
      'widgetStyles',
    ]),

    popoverPositions() {
      const linesCount = [
        !!this.positions.title,
        !!this.positions.progressText,
        !!this.positions.timeLeft,
        !!this.widgetSettings.framesEnabled,
      ].filter(l => l).length

      return {
        timeLeft: linesCount <= 3 ? 'bottom' : 'top',
        frames: linesCount < 3 ? 'bottom' : 'top',
      }
    },

    positions() {
      return this.widgetSettings.positions || {}
    },

    ...[
      'title',
      'progress',
      'timeLeft',
      'frames',
    ].reduce((acc, block) => {
      acc[`${block}Styles`] = {
        get() {
          return this.widgetStyles[block]
        },
        set(style) {
          this.setBlockStyle({
            block,
            style,
          })
        },
      }
      return acc
    }, {}),
  },
  methods: {
    ...mapMutations('goal/presets', {
      'setBlockStyle': 'SET_BLOCK_STYLE',
    }),
  },
}
</script>
