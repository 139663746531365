export const state = {
  isGlobalLoading: false,
  isLocalLoading: false,
  presets: [],
}

export const getters = {
  isGlobalLoading: ({ isGlobalLoading }) => isGlobalLoading,
  isLocalLoading: ({ isLocalLoading }) => isLocalLoading,
  presets: ({ presets }) => presets,
}

export const mutations = {
  startGlobalLoading(state) {
    state.isGlobalLoading = true
  },

  endGlobalLoading(state) {
    state.isGlobalLoading = false
  },

  startLocalLoading(state) {
    state.isLocalLoading = true
  },

  endLocalLoading(state) {
    state.isLocalLoading = false
  },

  setPresets(state, { data }) {
    state.presets = data
  },

  clearPresets(state) {
    state.presets = []
  },

  addPreset(state, { data }) {
    state.presets.push(data)
  },

  updatePreset(state, { data }) {
    state.presets = state.presets.map(p => {
      if (p.id === data.id) {
        return data
      } else {
        return p
      }
    })
  },

  setPreviews(state, data) {
    const obj = {}
    data.forEach(item => {
      obj[item.data.id] = item.data.url
    })

    state.presets = state.presets.map(item => {
      return {
        ...item,
        preview: item.json.image ? obj[ item.json.image ] : null
      }
    })
  },
}

export const actions = {
  async fetchPresets({ state, commit, dispatch }, type) {
    commit('startGlobalLoading')

    try {
      const { data } = await api.get(`/widgets/presets?type=${ type }&mine=1`)

      commit('setPresets', { data })
      commit('endGlobalLoading')

      dispatch('getAllPreview')
    } catch (error) {
      commit('endGlobalLoading')
      throw new Error(error)
    }
  },

  clearOffers({ commit }) {
    commit('clearPresets')
  },

  async fetchNewPreset({ state, commit, dispatch }, preset) {
    commit('startLocalLoading')
    try {
      const { data } = await api.post(`/widgets/presets`, {
        type: 'alerts',
        title: { en_US: preset.title },
        json: { image: preset.image, sound: preset.sound, duration: preset .duration},
      })

      commit('addPreset', { data })
      commit('endLocalLoading')
      dispatch('getAllPreview')
      // window.addStatusMessage('success', 'Пресет создан!')
    } catch (error) {
      commit('endLocalLoading')
      // window.addStatusMessage('error', 'Что-то пошло не так :(')
      throw new Error(error)
    }
  },

  async fetchUpdatePreset({ state, commit, dispatch }, preset) {
    commit('startLocalLoading')
    try {
      const { data } = await api.put(`/widgets/presets/${ preset.id }`, {
        type: 'alerts',
        title: { en_US: preset.title },
        json: { image: preset.image, sound: preset.sound, duration: preset .duration},
      })

      commit('updatePreset', { data })
      commit('endLocalLoading')
      dispatch('getAllPreview')
      // window.addStatusMessage('success', 'Пресет обновлен!')
    } catch (error) {
      commit('endLocalLoading')
      // window.addStatusMessage('error', 'Что-то пошло не так :(')
      throw new Error(error)
    }
  },

  async getAllPreview({ state, commit }) {
    const requests = state.presets
      .map(p => p.json.image)
      .filter(id => id)
      .map(id => {
        return api.get('/filemanager2/files/' + id)
      })

    try {
      const data = await Promise.all(requests)
      commit('setPreviews', data)
    } catch (error) {
      throw new Error(error)
    }
  },
}