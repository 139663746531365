<style scoped lang="scss">
  .roulette-frame-settings {

    .settings-area {
      @include media(max, md) {
        grid-template-columns: auto !important;
      }
    }

  }
</style>

<template>
  <div class="roulette-frame-settings">
    <grid columns="2" gap="40" class="settings-area">
      <div>
        <form-group inline :label="$t('global.settings.labels.type')">
          <dropdown-select v-model="frameType" :options="frameTypeOptions"/>
        </form-group>
        <template v-if="frameType">
          <template v-if="frameType === 'svg' || frameType === 'border'">
            <template v-if="frameType === 'border'">
              <form-group inline :label="$t('global.settings.labels.borderWidth')">
                <base-range :min="0" :max="50" v-model="borderWidth" input input-addon="px"/>
              </form-group>
              <form-group inline :label="$t('global.settings.labels.borderRadius')">
                <base-range :min="0" :max="100" v-model="borderRadius" input input-addon="px"/>
              </form-group>
            </template>
            <form-group inline :label="$t('global.settings.labels.borderColor')">
              <color-gradient-picker v-model="frameColor"/>
            </form-group>
          </template>
          <template v-if="frameType === 'image'">
            <form-group inline :label="$t('roulette.visualSettings.frame.frame')">
              <file-manager v-model="frameImage" widget-element="roulette_frame" file-type="image"/>
            </form-group>
            <template v-if="frameImage">
              <form-group inline :label="$t('global.settings.labels.hueRotation')">
                <base-range :min="0" :max="360" v-model="frameImageHue" input input-addon="°"/>
              </form-group>
              <form-group inline :label="$t('global.settings.labels.saturate')">
                <base-range :min="0" :max="300" v-model="frameImageSaturate" input input-addon="%"/>
              </form-group>
            </template>
          </template>
        </template>
      </div>
      <div>
        <form-group v-if="frameType" inline :label="$t('global.settings.labels.shadow')">
          <shadow-styler v-model="frameShadow" class="left"/>
        </form-group>
      </div>
    </grid>
  </div>
</template>

<script>
import ShadowStyler from '@components/BaseComponents/ShadowStyler/ShadowStyler'

import ColorGradientPicker from '@components/BaseComponents/ColorPicker/ColorGradientPicker'
import FileManager from '@components/BaseComponents/FileManager/FileManager'

export default {
  name: 'RouletteFrameSettings',
  components: {
    FileManager,
    ColorGradientPicker,

    ShadowStyler,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      frameType: null,

      frameImage: null,
      frameImageHue: 0,
      frameImageSaturate: 100,

      borderWidth: 6,
      borderRadius: 10,
      frameColor: {
        useGradient: true,
        color: '#f59c07',
        gradient: {
          colors: ['#f59c07', '#f57507'],
          angle: 135,
        },
      },
      frameShadow: null,
    }
  },
  computed: {
    frameTypeOptions() {
      return [
        {
          value: null,
          icon: 'cross',
        },
        {
          value: 'border',
          icon: 'circle',
        },
        // {
        //   value: 'image',
        //   icon: 'image',
        // },
      ].map(option => ({
        ...option,
        label: option.value ? this.$t(`global.settings.labels.types.${option.value}`) : null,
      }))
    },

    output() {
      return {
        _version: Math.random().toString(36).slice(2),
        borderWidth: this.borderWidth,
        borderRadius: this.borderRadius,
        frameColor: this.frameColor,
        frameShadow: this.frameShadow,
        frameType: this.frameType,
        frameImage: this.frameImage,
        frameImageHue: this.frameImageHue,
        frameImageSaturate: this.frameImageSaturate,
      }
    },
  },
  created() {
    const storedValue = _.cloneDeep(this.value)

    if (storedValue) {
      Object.keys(storedValue).forEach(key => {
        this[key] = storedValue[key] ?? this[key]
      })
    }
  },
  watch: {
    output(value) {
      this.$emit('input', value)
    },
    frameImage(image, oldImage) {
      if (oldImage && oldImage !== image) {
        this.frameImageHue = 0
        this.frameImageSaturate = 100
      }
    },
  },
}
</script>
