export const state = {
  isLoading: false,
  isSendLoading: false,
  users: [],
  sendMessageSuccess: false,
  sendMessageError: false,
}

export const getters = {
  isLoading: ({ isLoading }) => isLoading,
  isSendLoading: ({ isSendLoading }) => isSendLoading,
  users: ({ users }) => users,
  sendMessageSuccess: ({ sendMessageSuccess }) => sendMessageSuccess,
  sendMessageError: ({ sendMessageError }) => sendMessageError,
}

export const mutations = {
  startLoading(state) {
    state.isLoading = true
  },

  endLoading(state) {
    state.isLoading = false
  },

  startSendLoading(state) {
    state.isSendLoading = true
  },

  endSendLoading(state) {
    state.isSendLoading = false
  },

  setMessageSuccess(state) {
    state.sendMessageSuccess = true
  },

  clearMessageSuccess(state) {
    state.sendMessageSuccess = false
  },

  setMessageError(state) {
    state.sendMessageError = true
  },

  clearMessageError(state) {
    state.sendMessageError = false
  },

  clearStatuses(state) {
    state.sendMessageSuccess = false
    state.sendMessageError = false
  },

  setUsers(state, { data }) {
    state.users = data
  },

  clearUsers(state) {
    state.users = []
  },
}

export const actions = {
  async fetchUsers({ state, commit }, id) {
    commit('startLoading')

    try {
      const { data } = await api.get(`/advuser?offer_id=${ id }`)

      commit('setUsers', { data })
      commit('endLoading')
    } catch (error) {
      commit('endLoading')
      throw new Error(error)
    }
  },

  clearUsers({ commit }) {
    commit('clearUsers')
  },

  async fetchSendDonation({ state, commit }, opt) {
    commit('startSendLoading')

    const params = { message_type: 'text' }
    params.adv_user_id = opt.advUserId
    params.collection_id = opt.collectionId

    if (opt.comment && opt.comment.length)
      params.comment = opt.comment

    return api
      .post(`/payin/donation/adv_internal_donation`, params)
      .then(() => {
        commit('endSendLoading')
        commit('setMessageSuccess')
      })
      .catch(() => {
        commit('endSendLoading')
        commit('setMessageError')
      })
  },

  clearStatuses({ state, commit }) {
    commit('clearStatuses')
  },
}