<style lang="scss" scoped>
  .input-addon {
    position: relative;
    border-radius: $control-border-radius;
    background-color: $black;
    overflow: hidden;

    .base-input {
      background-color: transparent;
      position: relative;
      z-index: 2;
    }

    .addon {
      @include position(absolute, 0, 0, 0, 0);
      z-index: 1;
      display: flex;
      align-items: center;
      @include padding-y($control-padding-y);
      @include padding-x($control-padding-x);
      font-size: $control-font-size;
      line-height: $control-line-height;
      letter-spacing: normal;
      user-select: none;

      .input-text {
        opacity: 0;
      }

      .addon-text {
        color: $control-color;
        margin-left: 7px;
        opacity: .5;
      }
    }
  }
</style>

<template>
  <div class="input-addon">
    <div class="addon">
      <div class="input-text">{{input}}</div>
      <div class="addon-text">{{addon}}</div>
    </div>
    <base-input
      v-bind="$attrs"
      v-on="$listeners"
      v-model="input"/>
  </div>
</template>

<script>

export default {
  inheritAttrs: false,
  props: {
    value: {
      default: null,
    },
    addon: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      input: null,
    }
  },
  watch: {
    input(value) {
      this.$emit('input', value)
    },

    value: {
      handler() {
        this.input = this.value
      },
      immediate: true,
    },
  },
}
</script>
