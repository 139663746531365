<style scoped lang="scss">
  .goal-presets-manager {
    height: 360px;
    display: flex;

    @include media(max, md) {
      height: auto;
      flex-direction: column;
    }

    .main-preview {
      width: 480px;
      height: 100%;
      flex-shrink: 0;
      flex-grow: 0;
      background-color: $additional-3;

      @include media(between, sm, md) {
        height: 320px;
        width: 100%;
      }

      @include media(max, sm) {
        height: 220px;
        width: 100%;
      }
    }

    .widget-presets {
      width: 100%;
      flex: 0 1 auto;
      padding: 20px 15px 0 30px;
      display: flex;
      flex-direction: column;
      position: relative;
      background-color: $additional-3;
      overflow: hidden;

      @include media(max, md) {
        padding-left: 15px;
        border-radius: 3px;
        margin-top: 10px;
      }

      &:after {
        z-index: 2;
        content: '';
        position: absolute;
        bottom: 0;
        left: 30px;
        right: 30px;
        height: 30px;
        background-image: linear-gradient(to top, $additional-3, rgba($additional-3, 0));
        pointer-events: none;

        @include media(max, md) {
          left: 0;
          right: 0;
          height: 70px;
        }
      }

      .presets-title {
        font-size: 16px;
        font-weight: 500;
        flex: 0 0 auto;
      }

      .presets-filters-list {
        flex: 0 0 auto;
        margin-top: 10px;
        @include margin-x(-5px);
        display: flex;
        flex-wrap: wrap;

        .filters-list-item {
          margin: 5px;
          display: flex;
          align-items: center;
          padding: 7px 15px;
          border-radius: 30px;
          background-color: #212121;
          font-size: 15px;
          cursor: pointer;

          .icon + .filter-name {
            margin-left: 10px;
          }

          &.active {
            background-color: $greyish-brown-two;
          }
        }
      }

      $preset-height: 140px;
      $preset-height-tablet: 120px;
      $preset-height-mobile: 100px;

      .presets-list {
        height: 100%;
        margin-top: 20px;
        padding-right: 10px;
        overflow: auto;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: $preset-height;
        grid-column-gap: 20px;
        grid-row-gap: 20px;

        @include media(max, md) {
          height: 270px;
          margin-top: 15px;
          grid-template-rows: $preset-height-tablet;
          grid-column-gap: 10px;
          grid-row-gap: 10px;
          padding-right: 0;
        }

        @include media(max, sm) {
          grid-template-columns: repeat(2, 1fr);
          grid-template-rows: $preset-height-mobile;
        }

        @include media(between, sm, md) {
          grid-template-columns: repeat(3, 1fr);
        }

        @include media(between, md, lg) {
          grid-template-columns: repeat(3, 1fr);
        }

        @include media(between, lg, xl) {
          grid-template-columns: repeat(2, 1fr);
          grid-template-rows: 170px;
        }

        @include media(min, xl) {
          grid-template-columns: repeat(3, 1fr);
        }

        &:after {
          content: '';
          height: 10px;
          grid-column-start: 1;
          grid-column-end: 4;

          @include media(max, md) {
            grid-column-end: 2;
          }

          @include media(between, lg, xl) {
            grid-column-end: 2;
          }

          @include media(between, md, lg) {
            grid-column-end: 3;
          }

          @include media(min, xl) {
            grid-column-end: 3;
          }
        }

        .presets-list-item {
          height: $preset-height;
          background-color: #212121;
          border-radius: 5px;
          border: 2px solid #383838;
          cursor: pointer;

          @include media(max, md) {
            height: $preset-height-tablet;
          }

          @include media(max, sm) {
            height: $preset-height-mobile;
          }

          @include media(between, lg, xl) {
            height: 170px;
          }

          &.active {
            border-color: var(--theme-color-additional);
          }
        }
      }
    }

    .modal-controls {
      display: flex;
      align-items: center;

      .button + .button {
        margin-left: 20px;
      }
    }
  }
</style>

<template>
  <div class="goal-presets-manager">
    <div class="main-preview">
      <goal-preview
        :preset-id="currentPresetId"
        current/>
    </div>
    <div class="widget-presets">
      <div class="presets-title">{{$t('goals.presets.presetsLibrary')}}</div>
      <div class="presets-filters-list">
        <div
          v-for="(filter, index) in presetsFilters"
          :key="index"
          class="filters-list-item"
          :class="{active: currentFilter === filter.code}"
          @click="setCurrentFilter(filter.code)">
          <icon v-if="filter.icon" :name="filter.icon" :style="filter.iconStyles"/>
          <div class="filter-name">{{filter.text}}</div>
        </div>
      </div>
      <div class="presets-list">
        <div
          v-for="(preset, index) in presets"
          :key="index"
          class="presets-list-item"
          :class="{active: currentPresetId === preset.id}">
          <goal-preview
            :preset-id="preset.id"
            @click.native="setCurrentPreset(preset.id)"/>
        </div>
      </div>
    </div>

    <modal v-model="presetChangeModalOpened">
      <template slot="title">{{$t('goals.presets.presetChanged')}}</template>
      <div slot="body">
        {{changeText[0]}}
        <br>{{changeText[1]}}
      </div>
      <div slot="footer">
        <div class="modal-controls">
          <btn
            variant="primary-action"
            icon-left="check"
            @click="forceNextPreset">{{$t('global.common.continue')}}
          </btn>
          <btn
            variant="secondary-action"
            @click="closePresetChangeModal">{{$t('global.common.cancel')}}
          </btn>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import GoalPreview from '@components/Dashboard/GoalSettings/GoalPreview'

export default {
  name: 'GoalPresetsManager',
  components: { GoalPreview },
  data() {
    return {
      currentFilter: null,

      presetChangeModalOpened: false,
      nextPreset: null,
    }
  },
  computed: {
    ...mapGetters('goal/presets', ['getPresets', 'getCurrentDesign', 'getCurrentPreset']),
    ...mapState('goal/presets', ['currentPresetId']),

    presets() {
      return this.getPresets.filter(preset => !this.currentFilter || preset.scheme === this.currentFilter)
    },

    changeText() {
      return this.$t('goals.presets.presetChangeText').split('%s')
    },

    presetsFilters() {
      return [
        {
          text: this.$t('goals.presets.tabs.all'),
          code: null,
        },
        {
          icon: 'progress',
          text: this.$t('goals.presets.tabs.horizontal'),
          code: 'horizontal',
        },
        {
          icon: 'progress',
          iconStyles: {
            transform: 'rotate(-90deg)',
          },
          text: this.$t('goals.presets.tabs.vertical'),
          code: 'vertical',
        },
      ]
    },
  },
  methods: {
    ...mapActions('goal/presets', ['setDesignPreset', 'getChanges']),

    setCurrentFilter(filter) {
      this.currentFilter = filter
    },

    async setCurrentPreset(presetId) {
      if (this.currentPresetId === presetId) {
        return
      }

      this.nextPreset = presetId

      const changes = await this.getChanges()

      if (Object.keys(changes).length) {
        this.presetChangeModalOpened = true
      } else {
        this.forceNextPreset()
      }
    },

    forceNextPreset() {
      this.setDesignPreset(this.nextPreset)
      this.closePresetChangeModal()
    },

    closePresetChangeModal() {
      this.presetChangeModalOpened = false
    },
  },
}
</script>
