<style scoped lang="scss">
  .bft-widget-config-page {
    position: relative;

    .widget-preview-wrapper {
      height: 400px;
      display: flex;
      background-color: $additional-3;
      border-radius: 8px;

      .bft-widget-preview {
        width: 500px;
        background-color: $additional-3;
        background-image: appAsset('/images/goal-preview-bg.jpg');
        background-size: cover;
        background-position: center center;
        border-radius: 8px 0 0 8px;
      }

      .bft-presets-library {
        height: 100%;
        flex: 1 1 auto;
        margin-left: 30px;
      }

      @include media(max, lg) {
        .bft-widget-preview {
          width: 440px;
        }
      }

      @include media(max, sm) {
        height: auto;
        flex-direction: column;
        border-radius: 0 0 8px 8px;

        .bft-widget-preview {
          width: 100%;
          min-height: 360px;
          border-radius: 8px 8px 0 0;
        }

        .bft-presets-library {
          width: 100%;
          height: 300px;
          margin-top: 10px;
          margin-left: 0;
        }
      }
    }

    .widget-config {
      margin-top: 20px;
    }

    .loader-container {
      @include position-all(absolute, 0);
      min-height: 300px;
      z-index: 15;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .page-wrapper {
      margin-top: 20px;
    }

    .widget-link {
      max-width: 400px;
    }
  }
</style>

<template>
  <div class="bft-widget-config-page">
    <transition name="fade">
      <div v-if="loadingState.settings" class="loader-container">
        <loader size="lg"/>
      </div>
    </transition>

    <template v-if="!loadingState.settings && settings">
      <form-group inline :label="$t('bft.widget.labels.link')">
        <widget-link :link="widgetLink"/>
      </form-group>

      <div class="page-wrapper">
        <Title>{{ $t('bft.widget.title') }}</Title>

        <div class="widget-preview-wrapper">
          <bft-widget-preview :settings="settings"/>
          <bft-presets-library/>
        </div>

        <widget-config v-model="settings"/>
      </div>
    </template>
  </div>
</template>

<script>
import Title from '@components/BaseComponents/Title'
import WidgetConfig from '@components/Dashboard/Bft/WidgetConfig/WidgetConfig'
import WidgetBftActions from '@components/Widgets/WidgetBftActions/WidgetBftActions'
import BftWidgetPreview from '@components/Dashboard/Bft/BftWidgetPreview'
import WidgetLink from '@components/Dashboard/WidgetLink'
import BftPresetsLibrary from '@components/Dashboard/Bft/BftPresetsLibrary'

export default {
  name: 'ChaosTricksWidgetConfigPage',
  components: {
    BftPresetsLibrary,
    WidgetLink,
    BftWidgetPreview,
    WidgetBftActions,
    WidgetConfig,
    Title,
  },
  data() {
    return {
      loadingState: {
        page: true,
      },

      settings: null,
    }
  },
  metaInfo() {
    return {
      title: [
        this.$t('dashboard.pages.bft.widget'),
        this.$t('dashboard.pages.bft.bft'),
      ].join(' - '),
    }
  },
  computed: {
    ...mapState('bft/widget', ['widgetSettings', 'widgetId']),
    ...mapGetters('profile', ['getCurrentProfile']),

    widgetLink() {
      const link = `${window.location.origin}/widget/bft`

      const args = [
        `token=${this.getCurrentProfile.token}`,
      ]

      return [link, args.join('&')].join('?')
    },
  },
  methods: {
    ...mapActions('bft/widget', ['fetchWidgetSettings', 'fetchWidgetPresets']),
  },
  created() {
    Promise.all([
      this.fetchWidgetSettings(),
      this.fetchWidgetPresets(),
    ]).finally(() => {
      this.loadingState.page = false
    })
  },
  mounted() {
    this.$tmr.goal({ goal: 'chaosTricks_tab_open' }, {
      tab: 'widget',
    })
  },
  watch: {
    widgetSettings(value) {
      this.settings = value
    },
  },
}
</script>
