<style lang="scss" scoped>
  .progress-circle {
    position: relative;
    width: 1em;

    &:before {
      content: '';
      display: block;
      padding-top: 100%;
    }

    svg {
      transform: rotate(-90deg);
      transform-origin: center center;
      width: 100%;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;

      circle {
        stroke-width: .07em;
        fill: none;

        &.bg {
          stroke: rgba(white, .1);
        }

        &.fill {
          stroke: url(#theme_svg_gradient);
          stroke-linecap: round;
          stroke-dasharray: 100, 999;
        }
      }
    }
  }
</style>

<template>
  <div class="progress-circle">
    <svg viewBox="25 25 50 50">
      <circle class="bg" cx="50" cy="50" r="20"/>
      <circle :style="circleStyles" class="fill" cx="50" cy="50" :r="radius"/>
    </svg>
  </div>
</template>

<script>

export default {
  name: 'ProgressCircle',
  props: {
    percent: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      radius: 20,
    }
  },
  computed: {
    circleStyles() {
      const draw = this.percent * (2 * this.radius * Math.PI) / 100

      const styles = {
        strokeDasharray: `${draw}, 999`,
      }

      if (!draw) {
        styles.stroke = 'none'
      }

      return styles
    },
  },
}
</script>
