import { render, staticRenderFns } from "./CopyLink.vue?vue&type=template&id=1c3f8269&scoped=true"
import script from "./CopyLink.vue?vue&type=script&lang=js"
export * from "./CopyLink.vue?vue&type=script&lang=js"
import style0 from "./CopyLink.vue?vue&type=style&index=0&id=1c3f8269&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1c3f8269",
  null
  
)

export default component.exports