<template>
  <div style="margin-top: 40px;">
    <loader
      size="md"
      v-if="isInfoLoading"
    />

    <div v-else>
      <div v-if="!info.is_active">
        <Title type="2">{{ $t('bft.settings.gameStatusOffTitle') }}</Title>

        <p class="title-description">{{ $t('bft.settings.gameStatusDescription') }}</p>

        <bft-download-link/>
      </div>

      <div v-else>
        <Title type="2">{{ $t('bft.settings.gameStatusOnTitle') }}</Title>

        <GameLabel
          :text="info.game.title"
          fill
        />

        <div class="game-actions">
          <div
            class="game-action"
            v-for="(category, i) in info.actions"
            :key="i"
          >
            <Title type="2">{{ category.title }}</Title>

            <GameLabel
              v-for="(action, i) in category.actions"
              :key="i"
              :text="action.title"
              :amount="'' + getAmountWithCurrency(action.amount)"
            />
          </div>
        </div>
        <div class="game-running">
          <p class="title-description">{{ $t('bft.settings.application') }}</p>

          <bft-download-link/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Title from '@components/BaseComponents/Title'
import GameLabel from './GameLabel'
import BftDownloadLink from '@pages/Dashboard/Bft/BftDownloadLink'

export default {

  name: 'GameInfo',

  components: {
    BftDownloadLink,
    Title,
    GameLabel,
  },

  data() {
    return {}
  },

  watch: {},

  created() {
    this.getInfo()
  },

  computed: {
    ...mapGetters('bft/settings', [
      'isInfoLoading',
      'info'
    ]),
    ...mapGetters('currencies', ['getAmountWithCurrency']),
  },

  methods: {
    ...mapActions('bft/settings', ['getInfo']),
  },

}

</script>

<style scoped lang="scss">
.title-description {
  color: #838383;
  font-size: 14px;
}

.bft-download-link {
  margin-top: 20px;
}

.game-actions {
  margin-top: 30px;
  max-width: 780px;

  @media (min-width: 780px) {
    display: flex;
    flex-wrap: wrap;
    margin-right: 30px;
  }
}

.game-action {
  flex: 50%;
  margin-bottom: 40px;
}

.game-running {
  margin-top: 40px;
}
</style>
