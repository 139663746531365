<style scoped lang="scss">
  .progress-bar-style-form {
  }
</style>

<template>
  <div class="progress-bar-style-form">
    <form-group inline :label="$t('goals.designForm.indicatorSettings.barHeight')">
      <base-range :min="heightOptions.min" :max="heightOptions.max" :step=".1" v-model="minHeight" input input-addon="vw"/>
    </form-group>
    <form-group inline :label="$t('goals.designForm.indicatorSettings.barBorderRadius')">
      <base-range :max="10" :step=".1" v-model="borderRadius" input input-addon="vw"/>
    </form-group>
    <form-group inline :label="$t('goals.designForm.indicatorSettings.barBorderSize')">
      <base-range :max="1" :step=".1" v-model="borderWidth" input input-addon="vw"/>
    </form-group>
    <form-group v-if="Number(borderWidth)" inline :label="$t('goals.designForm.indicatorSettings.barBorderColor')">
      <color-picker v-model="borderColor"/>
    </form-group>

    <template v-if="useGradient">
      <form-group class="with-switcher" inline :label="$t('goals.designForm.indicatorSettings.barBackgroundGradient')">
        <gradient-picker v-model="gradient"/>
        <div slot="helper" class="text-link" @click="toggleGradient">
          {{ $t('global.color.usePlainColor') }}
        </div>
      </form-group>
    </template>
    <template v-else>
      <form-group class="with-switcher" inline :label="$t('goals.designForm.indicatorSettings.barBackgroundColor')">
        <color-picker v-model="backgroundColor"/>
        <div slot="helper" class="text-link" @click="toggleGradient">
          {{ $t('global.color.useColorGradient') }}
        </div>
      </form-group>
    </template>
  </div>
</template>

<script>

export default {
  name: 'ProgressBarStyleForm',
  props: {
    value: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      useGradient: false,

      backgroundColor: null,
      gradient: null,
      borderColor: null,
      borderWidth: null,
      borderRadius: null,
      minHeight: null,
    }
  },
  watch: {
    value: {
      handler(value, oldValue) {
        if (JSON.stringify(value) !== JSON.stringify(oldValue)) {
          if (value) {
            const scheme = _.get(this.getCurrentPreset, 'scheme', 'horizontal')

            const {
              backgroundColor,
              gradient,
              borderColor,
              borderWidth,
              borderRadius,
              minHeight,
              useGradient = null,
            } = _.cloneDeep(value)

            const gradientColors = _.get(gradient, 'colors', [backgroundColor, backgroundColor])
            this.backgroundColor = backgroundColor || gradientColors[0]
            this.gradient = {
              colors: gradientColors,
              direction: _.get(gradient, 'direction', 'top'),
              angle: _.get(gradient, 'angle', 0),
            }

            this.useGradient = useGradient === null ? !!gradient : useGradient
            this.borderColor = borderColor || this.borderColor
            this.borderWidth = borderWidth || 0
            this.borderRadius = borderRadius || 0
            this.minHeight = minHeight || {
              horizontal: 7,
              vertical: 29,
            }[scheme]
          }
        }
      },
      immediate: true,
    },
    output: {
      handler(value) {
        this.$emit('input', value)
      },
    },
  },
  computed: {
    ...mapGetters('goal/presets', ['getCurrentPreset']),

    heightOptions() {
      const scheme = _.get(this.getCurrentPreset, 'scheme', 'horizontal')

      return {
        min: 1,
        max: {
          horizontal: 30,
          vertical: 40,
        }[scheme],
      }
    },

    output() {
      return {
        backgroundColor: this.backgroundColor,
        gradient: this.gradient,
        useGradient: this.useGradient,
        borderColor: this.borderWidth ? this.borderColor : null,
        borderWidth: this.borderWidth,
        borderRadius: this.borderRadius,
        minHeight: this.minHeight,
      }
    },
  },
  methods: {
    toggleGradient() {
      this.useGradient = !this.useGradient
    },
  },
}
</script>
