<style scoped lang="scss">
  .roulette-card-decor {
    display: flex;
    align-items: center;
    justify-content: center;

    .card-decor-place {
      height: 0;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      .card-decor-image {
        position: absolute;
        bottom: 0;
        transform: translateY(50%);
      }

      .card-decor-video {
        position: absolute;
        bottom: 0;
        transform: translateY(50%);
      }

      .card-decor-svg {
        position: absolute;
        bottom: 0;
        transform: translateY(50%);
      }
    }
  }
</style>

<template>
  <div v-if="decor" class="roulette-card-decor">
    <div class="card-decor-place" :style="decor.placeStyles">
      <template v-if="decor.type === 'image' && decor.image">
        <img
          v-if="decor.image.type === 'image'"
          class="card-decor-image"
          :src="decor.image.src"
          :style="decor.image.styles"/>
        <video
          v-if="decor.image.type === 'video'"
          class="card-decor-video"
          autoplay
          loop
          :src="decor.image.src"
          :style="decor.image.styles"/>
      </template>
      <template v-if="decor.type === 'svg' && decor.svg">
        <svg-gradient
          v-if="decor.gradient"
          :name="decor.gradient.name"
          :colors="decor.gradient.colors"
          :angle="decor.gradient.angle"
          :hard="decor.gradient.hard"/>
        <svg-image-external
          v-if="decor.type === 'svg'"
          :src="decor.svg.src"
          :style="decor.svg.styles"
          class="card-decor-svg"/>
      </template>
    </div>
  </div>
</template>

<script>
import {
  createCssFilters,
} from '@utils/utils'
import SvgGradient from '@components/BaseComponents/SvgGradient'
import SvgImageExternal from '@components/BaseComponents/SvgImageExternal'

export default {
  name: 'RouletteCardDecor',
  components: {
    SvgImageExternal,
    SvgGradient,
  },
  props: {
    settings: {
      type: Object,
      default: null,
    },
  },
  computed: {
    decorImage() {
      const {
        decorImage,
      } = this.settings

      // eslint-disable-next-line
      this.fetchFile(decorImage)

      return this.getFile(decorImage)
    },

    decorSvg() {
      const {
        decorSvg,
      } = this.settings

      // eslint-disable-next-line
      this.fetchFile(decorSvg)

      return this.getFile(decorSvg)
    },


    decor() {
      if (!_.get(this.settings, 'decorType', null)) {
        return null
      }

      const {
        decorType,
        decorSvgColor = {},
        decorImageHue,
        decorImageSaturate,
        decorSize,
        decorShadow,
        decorOffset = 0,
      } = this.settings

      const { color = null, gradient = {}, useGradient = false } = decorSvgColor
      const { colors = [], angle = 0, hard = false } = gradient

      const gradientName = `_card_decor_${this.$componentId}`
      const gradientLink = `url(#${gradientName})`

      const decor = {
        type: decorType,
        placeStyles: {
          transform: `translateY(${decorOffset}px)`,
        },
      }

      if (decorType === 'image' && this.decorImage) {
        decor.image = {
          type: this.decorImage.type,
          src: this.decorImage.url,
          styles: {
            filter: createCssFilters({
              shadow: decorShadow,
              hue: decorImageHue,
              saturate: decorImageSaturate,
            }),
            maxWidth: `${decorSize}px`,
            maxHeight: `${decorSize}px`,
          },
        }
      }

      if (useGradient) {
        decor.gradient = {
          name: gradientName,
          link: gradientLink,
          colors,
          angle,
          hard,
        }
      }

      if (decorType === 'svg' && this.decorSvg) {
        decor.svg = {
          src: this.decorSvg.url,
          styles: {
            filter: createCssFilters({
              shadow: decorShadow,
            }),
            fill: useGradient ? gradientLink : color,
            width: `${decorSize}px`,
            height: `${decorSize}px`,
          },
        }
      }

      return decor
    },
  },
}
</script>
