<script>
import { computed, h, toRefs } from 'vue'

export default {
  name: 'TagReplacer',
  props: {
    text: {
      type: String,
      required: true,
    },
    tag: {
      type: String,
      default: 'div',
    },
  },
  setup(props, { slots }) {
    const { text, tag } = toRefs(props)

    const slotKeys = computed(() => Object.keys(slots))

    return () => {
      const elements = []

      const insertDividedString = str => str.split('\\n').forEach((dPart, index) => {
        if (index > 0) {
          elements.push(h('br'))
        }
        elements.push(dPart)
      })

      if (slotKeys.value.length) {
        const regex = new RegExp(
          slotKeys.value.map((key) => `(<${key}>.*<\/${key}>)`).join('|'),
          'gmu',
        )

        const contentString = text.value.replaceAll(regex, '%$&%')

        contentString.split('%').forEach((part) => {
          const partKey = slotKeys.value.find((key) => part.includes(`<${key}>`) && part.includes(`</${key}>`))

          if (partKey) {
            elements.push(slots[partKey]({
              content: part.replace(`<${partKey}>`, '').replace(`</${partKey}>`, ''),
            }))
          } else {
            insertDividedString(part)
          }
        })
      } else {
        insertDividedString(text.value)
      }

      return h(tag.value, elements)
    }
  },
}
</script>
