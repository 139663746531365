import { render, staticRenderFns } from "./CategoryBehaviorSettings.vue?vue&type=template&id=58bca0ba&scoped=true"
import script from "./CategoryBehaviorSettings.vue?vue&type=script&lang=js"
export * from "./CategoryBehaviorSettings.vue?vue&type=script&lang=js"
import style0 from "./CategoryBehaviorSettings.vue?vue&type=style&index=0&id=58bca0ba&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58bca0ba",
  null
  
)

export default component.exports