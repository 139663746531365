export const state = {
  presets: {},
}

export const getters = {
  getPresetsByType: state => type => Object.values(state.presets).filter(preset => preset.type === type),
  getDefaultPresetByType: (state, getters) => type => getters.getPresetsByType(type).find(preset => preset.is_default),
}

export const mutations = {
  UPDATE_PRESETS(state, presets) {
    state.presets = {
      ...state.presets,
      ..._.keyBy(presets, 'id'),
    }
  },
}

export const actions = {
  fetchPresets({ commit }, type) {
    return api
      .get('/widgets/presets', {
        params: {
          type,
        },
      })
      .then(({ data }) => {
        commit('UPDATE_PRESETS', data)
      })
  },
}
