export const state = {}

export const getters = {}

export const mutations = {}

export const actions = {
  emitWidgetEvent({}, { event, eventData = null }) {
    return api.post('/widgets/emit-event', {
      event,
      eventData,
    })
  },

  createCustomAlert({}, {
    // external_id - Уникальный ID уведомления в системе партнера.
    // header - Текст для основной строки оповещения.
    // message - Текст для вспомогательной строки оповещения.
    // image_url - URL картинки или видео, которая будет использована в оповещении. Поддерживаемые форматы: jpg, jpeg, gif, webm, png.
    // sound_url - URL звука, который будет использована в оповещении. Поддерживаемые форматы: mp3, wav, ogg.
    header,
    message,
    isShown: is_shown = 1,
  }) {
    return api.post('/custom_alert', {
      header,
      message,
      is_shown,
    })
  },
}
