<style lang="scss" scoped>
  .text-settings-popover {
    border-radius: 3px;
    box-shadow: 0 2px 19px rgba(0, 0, 0, .35);
    background-color: $additional-3;

    .text-settings-form {
      margin-top: 10px;
    }

    .form-group + .form-group {
      margin-top: 10px;
    }
  }
</style>

<template>
  <div class="text-settings-popover" :class="popoverClasses">
    <tabs v-model="currentTab" :list="tabs"/>
    <div class="text-settings-form">
      <div class="font-settings-tab" v-show="currentTab === 'font'">
        <form-group inline :label="$t('global.textStyler.labels.font')">
          <font-select v-model="fontFamily" :font-style="fontSelectStyles"/>
        </form-group>
        <form-group inline :label="$t('global.textStyler.labels.textColor')">
          <color-picker v-model="color"/>
        </form-group>
        <form-group inline :label="$t('global.textStyler.labels.textStyle')">
          <button-group v-model="textFormats" :options="textFormatOptions" multiple/>
        </form-group>
        <form-group inline :label="$t('global.textStyler.labels.transformation')">
          <dropdown-select v-model="textTransform" :options="textTransformOptions"/>
        </form-group>
        <form-group inline :label="$t('global.textStyler.labels.horizontalAlignment')">
          <dropdown-select v-model="textAlign" :options="textHorizontalAlignOptions"/>
        </form-group>
      </div>
      <div class="text-settings-tab" v-show="currentTab === 'text'">
        <form-group inline :label="$t('global.textStyler.labels.fontSize')">
          <base-range
            v-model="fontSize"
            :min="minMax.fontSize.min" :max="minMax.fontSize.max" :step="minMax.fontSize.step"
            input :input-addon="units"/>
        </form-group>
        <form-group inline :label="$t('global.textStyler.labels.lineHeight')">
          <base-range
            v-model="lineHeight"
            :min="minMax.lineHeight.min" :max="minMax.lineHeight.max" :step="minMax.lineHeight.step"
            input input-addon="u"/>
        </form-group>
        <form-group inline :label="$t('global.textStyler.labels.letterSpacing')">
          <base-range
            v-model="letterSpacing"
            :min="minMax.letterSpacing.min" :max="minMax.letterSpacing.max" :step="minMax.letterSpacing.step"
            input :input-addon="units"/>
        </form-group>
        <form-group inline :label="$t('global.textStyler.labels.wordSpacing')">
          <base-range
            v-model="wordSpacing"
            :min="minMax.wordSpacing.min" :max="minMax.wordSpacing.max" :step="minMax.wordSpacing.step"
            input :input-addon="units"/>
        </form-group>
      </div>
      <div class="shadow-settings-tab" v-show="currentTab === 'shadow'">
        <shadow-settings-form v-model="textShadow" :units="units"/>
      </div>
      <div class="stroke-settings-tab" v-show="currentTab === 'stroke'">
        <form-group inline :label="$t('global.textStyler.labels.strokeWidth')">
          <base-range v-model="strokeWidth" :min="0" :max="2" :step=".01" input :input-addon="units"/>
        </form-group>
        <form-group inline :label="$t('global.textStyler.labels.strokeColor')">
          <color-picker v-model="strokeColor"/>
        </form-group>
      </div>
      <div class="animation-settings-tab" v-show="currentTab === 'animation'">
        <form-group inline :label="$t('global.textStyler.labels.animation')">
          <dropdown-select v-model="animationStyle" :options="animationStyleOptions"/>
        </form-group>
        <form-group inline :label="$t('global.textStyler.labels.animate')">
          <dropdown-select v-model="animationVariant" :options="animationVariantOptions"/>
        </form-group>
      </div>
    </div>
  </div>
</template>

<script>

import ShadowSettingsForm from '@components/BaseComponents/ShadowStyler/ShadowSettingsForm'
import FontSelect from '@components/BaseComponents/Select/FontSelect'
import ButtonGroup from '@components/BaseComponents/ButtonGroup'

export default {
  name: 'TextSettingsPopover',
  components: {
    ButtonGroup,
    FontSelect,
    ShadowSettingsForm,
  },
  props: {
    value: {
      type: Object,
      default: null,
    },
    withAnimation: {
      type: Boolean,
      default: false,
    },
    units: {
      type: String,
      default: 'px',
    },
  },
  data() {
    return {
      currentTab: 'font',

      color: null,
      textTransform: null,
      textAlign: null,
      fontWeight: null,
      fontStyle: null,
      textDecoration: null,

      strokeWidth: 0,
      strokeColor: '#000000',

      textTransformOptions: [
        {
          value: 'none',
          label: 'None',
          icon: 'cross',
        },
        {
          value: 'uppercase',
          label: 'Uppercase',
          icon: 'angle-double-up',
        },
        {
          value: 'lowercase',
          label: 'Lowercase',
          icon: 'angle-double-down',
        },
      ],
      textHorizontalAlignOptions: [
        {
          value: 'left',
          label: 'Left',
          icon: 'font-align-left',
        },
        {
          value: 'center',
          label: 'Center',
          icon: 'font-align-center',
        },
        {
          value: 'right',
          label: 'Right',
          icon: 'font-align-right',
        },
      ],
      textFormatOptions: [
        {
          value: 'bold',
          icon: 'font-bold',
        },
        {
          value: 'italic',
          icon: 'font-italic',
        },
        {
          value: 'underline',
          icon: 'font-underline',
        },
      ],

      animationStyleOptions: [
        {
          label: 'None',
          value: null,
        },
        {
          label: 'Bounce',
          value: 'bounce',
        },
        {
          label: 'Shake',
          value: 'shake',
        },
        {
          label: 'Pulse',
          value: 'pulse',
        },
        {
          label: 'Wobble',
          value: 'wobble',
        },
        {
          label: 'Jello',
          value: 'jello',
        },
        {
          label: 'Tada',
          value: 'tada',
        },
      ],
      animationStyle: null,
      animationVariantOptionsData: [
        {
          label: 'alternatingByLetters',
          value: 'letter-queue',
        },
        {
          label: 'simultaneouslyByLetters',
          value: 'letter-simultaneously',
        },
        {
          label: 'allText',
          value: 'text',
        },
      ],
      animationVariant: null,

      fontFamily: null,
      fontSize: null,
      lineHeight: 1,
      letterSpacing: 0,
      wordSpacing: 0,

      textShadow: null,
    }
  },
  computed: {
    minMax() {
      return this.units === 'vw' ? {
        fontSize: {
          min: 1.5,
          max: 8,
          step: .05,
        },
        letterSpacing: {
          min: -.5,
          max: 4,
          step: .1,
        },
        wordSpacing: {
          min: 0,
          max: 8,
          step: .1,
        },
        lineHeight: {
          min: 0,
          max: 2,
          step: .1,
        },
      } : {
        fontSize: {
          min: 8,
          max: 40,
          step: 1,
        },
        letterSpacing: {
          min: -2,
          max: 3,
          step: .01,
        },
        wordSpacing: {
          min: 0,
          max: 8,
          step: .1,
        },
        lineHeight: {
          min: 0,
          max: 5,
          step: .01,
        },
      }
    },

    tabs() {
      const tabs = [
        {
          key: 'font',
          icon: 'fonts',
          title: 'font',
        },
        {
          key: 'text',
          icon: 'font',
          title: 'text',
        },
        {
          key: 'shadow',
          icon: 'shadow',
          title: 'shadow',
        },
      ]

      if (this.withAnimation) {
        tabs.push({
          key: 'animation',
          icon: 'spinner',
          title: 'animation',
        })
      } else {
        tabs.push({
          key: 'stroke',
          icon: 'adjust',
          title: 'stroke',
        })
      }

      return tabs.map(tab => ({
        ...tab,
        title: this.$t(`global.textStyler.tabs.${tab.title}`),
      }))
    },

    animationVariantOptions() {
      return this.animationVariantOptionsData.map(option => ({
        ...option,
        label: this.$t(`global.textStyler.dropdownVariants.${option.label}`),
      }))
    },

    textFormats: {
      get() {
        return [this.fontStyle, this.fontWeight, this.textDecoration]
      },
      set(value) {
        this.fontWeight = value.includes('bold') ? 'bold' : 'normal'
        this.fontStyle = value.includes('italic') ? 'italic' : 'normal'
        this.textDecoration = value.includes('underline') ? 'underline' : 'none'
      },
    },

    fontSelectStyles() {
      return {
        // fontWeight: this.fontWeight,
        // fontStyle: this.fontStyle,
        // textTransform: this.textTransform,
        // textDecoration: this.textDecoration,
      }
    },

    output() {
      return {
        color: this.color,
        textTransform: this.textTransform,
        textAlign: this.textAlign,
        fontWeight: this.fontWeight,
        fontStyle: this.fontStyle,
        textDecoration: this.textDecoration,

        textShadow: this.textShadow,

        strokeWidth: this.strokeWidth,
        strokeColor: this.strokeColor,

        fontFamily: this.fontFamily,
        fontSize: this.fontSize,
        lineHeight: this.lineHeight,
        letterSpacing: this.letterSpacing,
        wordSpacing: this.wordSpacing,

        animation: {
          style: this.animationStyle,
          variant: this.animationVariant,
        },
      }
    },

    popoverClasses() {
      return {
        // backdrop: this.$backdropSupport,
      }
    },
  },
  created() {
    if (this.value) {
      Object.keys(this.value).forEach(key => {
        this[key] = this.value[key] ?? this[key]

        const { style, variant } = (this.value.animation ?? {})

        this.animationStyle = style
        this.animationVariant = variant
      })
    }
  },
  watch: {
    output(value) {
      this.$emit('input', value)
    },
  },
}
</script>
