<template>
  <div class="link">
    <div class="link__button" @click="copyLink(link)">
      <icon name="copy" />
    </div>

    <div
      class="link__form"
      @click="copyLink(link)"
    >{{$t(label)}}</div>

    <transition name="fade">
      <div class="link__tooltip" v-if="tooltipVisible">
        <icon name="check" />
        <span>{{$t('offers.linkCopied')}}</span>
      </div>
    </transition>
  </div>
</template>


<script>
import { copyToClipboard } from '@utils/utils'

export default {

  name: 'CopyLink',

  props: {
    link: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: 'offers.copyLink',
    },
  },

  data() {
    return {
      tooltipVisible: false,
    }
  },

  methods: {
    copyLink(text) {
      copyToClipboard(text)

      if (!this.tooltipVisible) {
        this.tooltipVisible = true

        setTimeout(() => {
          this.tooltipVisible = false
        }, 2000)
      }
    },
  },
}
</script>


<style scoped lang="scss">
.link {
  margin: 0;
  display: inline-block;
  // overflow: hidden;
  position: relative;
  max-width: 90%;
  cursor: pointer;

  &__form {
    display: inline-block;
    vertical-align: middle;
    // width: 100%;
    line-height: 20px;
    padding: 0 30px 0 0;
    background-color: transparent;
    border: 0;
    color: var(--theme-color-main);
    white-space: nowrap;
  }

  &__button {
    display: inline-block;
    vertical-align: middle;
    // position: absolute;
    // top: 0;
    // right: 0;
    background-image: linear-gradient(90deg, transparent, #262626);
    // padding-left: 15px;
    margin-right: 10px;

    & > svg {
      display: block;
      fill: url(#theme_svg_gradient);
      height: 20px !important;
      width: 17px !important;
      cursor: pointer;
    }
  }

  &__tooltip {
    position: absolute;
    background: #191919;
    color: white;
    padding: 10px 15px;
    font-size: 12px;
    border-radius: 8px;
    top: -48px;
    white-space: nowrap;

    &:after {
      display: block;
      content: '';
      width: 0;
      height: 0;
      position: absolute;
      bottom: -16px;
      left: 50%;
      transform: translateX(-50%);
      border-top: 8px solid #191919;
      border-bottom: 8px solid transparent;
      border-right: 10px solid transparent;
      border-left: 10px solid transparent;
    }

    svg {
      width: 12px;
      margin-right: 10px;
    }

    span {
      vertical-align: middle;
    }
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
