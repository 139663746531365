<template>
  <span 
    class="label"
    :class="'label_' + type"
  >{{ text }}</span>
</template>

<script>
export default {

  name: 'Label',

  props: {
    type: {
      type: String,
      default: 'yellow',
    },
    text: {
      type: String,
      default: ''
    },
  },

}

</script>

<style scoped lang="scss">
.label {
  font-size: 14px;
  font-weight: 500;
  display: inline-block;
  vertical-align: top;
  background: #303030;
  border-radius: 5px;
  line-height: 30px;
  padding: 0 10px;
  cursor: default;
  user-select: none;
  white-space: nowrap;

  &:before {
    content: '';
    width: 8px;
    height: 8px;
    vertical-align: middle;
    display: inline-block;
    border-radius: 50%;
    margin-right: 10px;
  }

  &_green {
    &:before {
      background-color: #16d768;
    }
  }

  &_yellow {
    &:before {
      background-color: #ffbc00;
    }
  }

  &_red {
    &:before {
      background-color: #d63a3a;
    }
  }
}
</style>
