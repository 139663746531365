<template>
  <div>
    <loader
      size="md"
      v-if="linksLoading"
      style="margin: 10vh auto;"
    />

    <div v-if="!linksLoading && links && !links.length">
      <span class="empty-data">{{$t('offers.noData')}}</span>
    </div>

    <div v-if="!linksLoading && links && links.length">
      <div class="links-options">
        <div class="links-option">
          <span>{{$t('offers.landingPage')}}:</span>
          <dropdown-select
            v-model="landing"
            :options="landingOptions"
            style="width: 250px"
          />
        </div>
      </div>

      <data-grid style="margin-top: 20px;">
        <data-grid-header>
          <data-grid-col>
            <span
              class="link"
              @click="() => { sort('title', ( title === 'asc' ? 'desc' :  title === 'desc' ? null : 'asc')) }"
            >{{ $t('offers.landingPage') + ( title === 'asc' ? ' ↓' :  title === 'desc' ? ' ↑' : '') }}</span>
          </data-grid-col>

          <data-grid-col>
            <span
              class="link"
              @click="() => { sort('value', ( value === 'asc' ? 'desc' :  value === 'desc' ? null : 'asc')) }"
            >{{ $t('offers.value') + ( value === 'asc' ? ' ↓' :  value === 'desc' ? ' ↑' : '') }}</span>
          </data-grid-col>

          <data-grid-col>{{$t('offers.landingPage')}}</data-grid-col>

          <data-grid-col>{{$t('offers.linksToAdditionalSplits')}}</data-grid-col>

          <data-grid-col>
            <span
              class="link"
              @click="() => { sort('created_at_ts', ( created_at_ts === 'asc' ? 'desc' :  created_at_ts === 'desc' ? null : 'asc')) }"
            >{{ $t('offers.date') + ( created_at_ts === 'asc' ? ' ↓' :  created_at_ts === 'desc' ? ' ↑' : '') }}</span>
          </data-grid-col>

          <data-grid-col></data-grid-col>
        </data-grid-header>

        <data-grid-row
          v-for="link in links"
          :key="link.id"
        >
          <data-grid-col>{{ link.title }}</data-grid-col>
          <data-grid-col>{{ link.sub_id }}</data-grid-col>
          <data-grid-col>{{ link.landing }}</data-grid-col>
          <data-grid-col>
            <copy-link
              :link="link.redirect_link"
              :label="$t('offers.affiliateLink')"
            />

            <copy-link
              v-if="offer.options.source.code !== 'my_tracker'"
              :link="link.direct_link"
              :label="$t('offers.directLink')"
            />
          </data-grid-col>
          <data-grid-col>
            <span style="color: #838383;">{{ transformDate(link.created_at_ts) }}</span>
          </data-grid-col>
          <data-grid-col class="ar">
            <button
              class="action-btn"
              @click="() => showUpdateLink(link)"
            ><icon name="pencil" /></button>
          </data-grid-col>
        </data-grid-row>
      </data-grid>
    </div>

    <modal v-model="showPopup" class="update-link-modal">
      <template slot="title">
        <template>{{$t('offers.editingASplit')}}</template>
      </template>
      <template slot="body-content">
        <div>
          <FormGroup
            :label="$t('offers.linkName')"
            inline
          >
            <BaseInput
              v-model="currentLinkTitle"
            />
          </FormGroup>

          <FormGroup
            :label="$t('offers.valueOfSplit')"
            :description="$t('offers.valueOfSplitDesc')"
            inline
          >
            <BaseInput
              v-model="currentLinkValue"
              disabled
            />
          </FormGroup>

          <div>
            <div class="partner__block-item" v-if="currentRedirectLink && currentLinkValue">
              <div class="partner__title">{{$t('offers.affiliateLink')}}</div>
              <div class="partner__content" v-html="highlight(currentRedirectLink, currentLinkValue)" />

              <copy-link :link="currentRedirectLink" />
            </div>

            <div
              class="partner__block-item"
              v-if="currentDirectLink && currentLinkValue"
            >
              <div class="partner__title">{{$t('offers.directLink')}} <Tooltip :text="$t('offers.directLinkTooltip')" /></div>
              <div class="partner__content" v-html="highlight(currentDirectLink, currentLinkValue)" />

              <copy-link :link="currentDirectLink" />
            </div>
          </div>

          <div class="button-group">
            <div>
              <Button
                variant="primary-action"
                class="btn"
                @click="saveLink"
                :disabled="!currentLinkTitle || !currentLinkValue"
              >{{$t('offers.save')}}</Button>

              <Button
                variant="secondary-action"
                class="btn"
                style="margin-left: 14px"
                @click="showUpdateLink"
              >{{$t('offers.undo')}}</Button>
            </div>
          </div>
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
import fromUnixTime from 'date-fns/fromUnixTime'
import DG from '@components/Dashboard/DataGrid'
import { copyToClipboard } from '@utils/utils'
import BaseButton from '@components/BaseComponents/BaseButton'
import Modal from '@components/BaseComponents/Modal'
import FormGroup from '@components/BaseComponents/Form/FormGroup'
import BaseInput from '@components/BaseComponents/Form/BaseInput'
import CopyLink from '@components/Dashboard/Offers/CopyLink'
import Tooltip from '@components/Dashboard/Offers/Tooltip'

export default {
  name: 'OffersDetailsLinksPage',

  components: {
    DataGrid: DG.DataGrid,
    DataGridHeader: DG.DataGridHeader,
    DataGridRow: DG.DataGridRow,
    DataGridCol: DG.DataGridCol,
    Button: BaseButton,
    Modal: Modal,
    FormGroup: FormGroup,
    BaseInput: BaseInput,
    CopyLink,
    Tooltip,
  },

  data() {
    return {
      showPopup: false,
      currentLinkId: null,
      currentLinkTitle: null,
      currentLinkValue: null,
      currentRedirectLink: null,
      currentDirectLink: null,

      landing: null,
      title: null,
      created_at_ts: null,
      value: null,
    }
  },

  created() {
    this.fetchLinks({ id: this.offer.offer_user[0].id })
  },

  watch: {
    landing(next, prev) {
      if (next !== prev) this.getLinksByParams()
    },
  },

  computed: {
    ...mapGetters('offers/details', [
      'offer',
      'links',
      'linksLoading',
    ]),

    landingOptions() {
      const options = this.offer.options ? this.offer.offer_user[0].options.links : []

      const values = options
        .map(option => ({
          value: option.entry_point_id,
          label: option.title,
        }))

      values.push({
        value: null,
        label: this.$t('offers.allLandingPages'),
      })

      return values
    },
  },

  methods: {
    ...mapActions('offers/details', ['updateLink', 'fetchLinks']),

    getLinksByParams() {
      let params = ''

      if (this.landing) params += `&cpa_entry_point_id=${ this.landing }`
      if (this.title) params += `&sort[]=title,${ this.title }`
      if (this.created_at_ts) params += `&sort[]=created_at,${ this.created_at_ts }`
      if (this.value) params += `&sort[]=sub_id,${ this.value }`

      this.fetchLinks({ id: this.offer.offer_user[0].id, params })
    },

    sort(key, dir) {
      this.title = null
      this.created_at_ts = null
      this.value = null

      this[key] = dir

      this.getLinksByParams()
    },

    copyLink(text) {
      copyToClipboard(text)
    },

    transformDate(val) {
      const timeZone = this.getTimezone

      const result = dates.unixToFormat(dates.utcToZonedTime(fromUnixTime(val), timeZone) / 1000, dates.DEFAULT_TIME_FORMAT)

      return result
    },

    saveLink() {
      this.updateLink({
        id: this.currentLinkId,
        title: this.currentLinkTitle,
        value: this.currentLinkValue,
      })

      this.showUpdateLink()
    },

    showUpdateLink(link) {
      const { id, title, sub_id, redirect_link, direct_link } = link

      if (this.link || !id) {
        this.showPopup = false
        this.currentLinkId = null
        this.currentLinkTitle = null
        this.currentLinkValue = null
        this.currentRedirectLink = null
        this.currentDirectLink = null
      } else {
        this.showPopup = true
        this.currentLinkId = id
        this.currentLinkTitle = title
        this.currentLinkValue = sub_id
        this.currentRedirectLink = redirect_link
        this.currentDirectLink = direct_link
      }
    },

    highlight(source, part) {
      return source.replace(part, `<b style="color: #fff">${ part }</b>`)
    },
  },
}
</script>

<style scoped lang="scss">
.action-btn {
  cursor: pointer;
  background: transparent;
  border: 0;
  padding: 0;
  margin: 0 8px;
  color: #838383;
  font-size: 16px;
  line-height: 1;

  &:hover {
    color: #fff;
  }
}

.ar {
  text-align: right;
}


.update-link-modal {
  width: 600px;
  max-width: 600px;
  margin: auto;
}

.button-group {
  margin: 40px 0 30px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.btn {
  border-radius: 8px;
}

.link {
  color: var(--theme-color-main);
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.links-options {
  display: flex;
  margin: 20px 0 30px 0;
}

.links-option {
  margin-right: 15px;

  span {
    display: block;
    margin-bottom: 10px;
  }
}

.partner__block-item {
  margin-top: 20px;
}

.partner__title {
  margin-bottom: 10px;
}

.partner__content {
  color: #838383;
  overflow-wrap: anywhere;
  margin-bottom: 10px;
}

.link {
  margin: 0;
}
</style>
