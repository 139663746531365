<style lang="scss" scoped>
  .button-group {
    display: flex;
    align-items: center;

    .button + .button {
      margin-left: 1px;
    }

    .button.active {
      border: 1px solid rgba(white, .2);
    }
  }
</style>

<template>
  <div class="button-group">
    <btn
      v-for="(button, index) in buttons"
      :icon-left="button.icon"
      :key="index"
      :class="button.classes" @click="onClick(button.value)">
      <template v-if="button.label">{{button.label}}</template>
    </btn>
  </div>
</template>

<script>

export default {
  name: 'ButtonGroup',
  props: {
    value: {
      type: [Array, String, Number],
      default: null,
    },
    options: {
      type: Array,
      required: true,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activeButtons: [],
    }
  },
  watch: {
    value: {
      handler(value) {
        this.activeButtons = value
      },
      immediate: true,
    },
    activeButtons(value) {
      this.$emit('input', value)
    },
  },
  computed: {
    buttons() {
      return this.options.map((button, index) => {
        const value = button.value || index

        return {
          ...button,
          value,
          classes: {
            active: this.multiple ? this.activeButtons.includes(value) : this.activeButtons === value,
          },
        }
      })
    },
  },
  methods: {
    onClick(value) {
      if (this.multiple) {
        if (this.activeButtons.includes(value)) {
          this.$delete(this.activeButtons, this.activeButtons.indexOf(value))
        } else {
          this.activeButtons.push(value)
        }
      } else {
        this.activeButtons = value
      }
    },
  },
}
</script>
