<style scoped lang="scss">
  .goal-visual-settings {
    .goal-preview {
      width: 600px;
      min-height: 200px;
      @include padding-y(20px);
      background-color: rgba(white, .1);
      background-image: appAsset('/images/goal-preview-bg.jpg');
      margin-bottom: 20px;
      background-size: cover;
      background-position: center center;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .settings-content {
      margin-top: 20px;
      width: 600px;
    }

    .debug-box {
      width: 100%;
      padding: 10px 15px;
      border-radius: 5px;
      border: 1px solid rgba(white, .2);
    }
  }
</style>

<template>
  <div class="goal-visual-settings">
    <div class="goal-preview">
      <widget-roulette-goal :goal-data="previewGoal.data" :goal-settings="previewGoal.settings"/>
    </div>
    <tabs v-model="currentTab" :list="tabs"/>
    <div class="settings-content">
      <div v-show="currentTab === 'indicator'" class="indicator-settings-tab">
        <goal-indicator-settings v-model="indicatorSettings"/>
      </div>
      <div v-show="currentTab === 'background'" class="background-settings-tab">
        <goal-background-settings v-model="backgroundSettings"/>
      </div>
      <div v-show="currentTab === 'text'" class="text-settings-tab">
        <goal-text-settings v-model="textSettings"/>
      </div>
      <div v-show="currentTab === 'debug'">
        <pre class="debug-box" ref="debug-box" @click="selectCopyDebug">{{ output }}</pre>
      </div>
    </div>
  </div>
</template>

<script>

import GoalIndicatorSettings from './GoalIndicatorSettings'
import GoalBackgroundSettings from './GoalBackgroundSettings'
import GoalTextSettings from './GoalTextSettings'
import WidgetRouletteGoal from '@components/Widgets/WidgetRouletteGoal/WidgetRouletteGoal'
import { copyToClipboard } from '@utils/utils'

export default {
  name: 'GoalVisualSettings',
  components: {
    WidgetRouletteGoal,
    GoalTextSettings,
    GoalBackgroundSettings,
    GoalIndicatorSettings,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      currentTab: 'indicator',

      indicatorSettings: {},
      backgroundSettings: {},
      textSettings: {},
    }
  },
  computed: {
    previewGoal() {
      return {
        data: {
          discountVisible: false,
          discount: 50,
          discountEndsAt: null,
          raisedAmount: 250,
          goalAmount: 500,
        },
        settings: this.output,
      }
    },

    tabs() {
      const tabs = [
        {
          key: 'indicator',
          title: 'indicator',
          icon: 'progress',
        },
        {
          key: 'background',
          title: 'background',
          icon: 'shadow',
        },
        {
          key: 'text',
          title: 'text',
          icon: 'fonts',
        },
      ].map(tab => ({
        ...tab,
        title: this.$t(`roulette.goal.visualSettings.tabs.${tab.title}`),
      }))

      if (process.env.NODE_ENV !== 'production') {
        tabs.push({
          key: 'debug',
          title: this.$t('global.common.debug'),
        })
      }

      return tabs
    },

    output() {
      return {
        indicatorSettings: this.indicatorSettings,
        backgroundSettings: this.backgroundSettings,
        textSettings: this.textSettings,
      }
    },
  },
  methods: {
    selectCopyDebug() {
      window.getSelection().selectAllChildren(this.$refs['debug-box'])
      copyToClipboard(JSON.stringify(this.debugOutput))
    },
  },
  created() {
    const storedValue = _.cloneDeep(this.value)

    if (storedValue) {
      Object.keys(storedValue).forEach(key => {
        this[key] = storedValue[key] ?? this[key]
      })
    }
  },
  watch: {
    output(value) {
      this.$emit('input', value)
    },
  },
}
</script>
