<style scoped lang="scss">
  .stream-stats-settings-form {
    .period-picker {
      display: flex;
      align-items: center;

      .date-picker + .divider,
      .divider + .date-picker {
        margin-left: 10px;
      }
    }
  }
</style>

<template>
  <div class="stream-stats-settings-form">
    <form-group
      :label="$t('streamStats.labels.dataType')"
      :description="dataTypeDescription"
      inline>
      <dropdown-select v-model="dataType" :options="dataTypeOptions"/>
    </form-group>

    <form-group
      v-if="allowSubscribersTypes"
      :label="$t('streamStats.labels.include')"
      inline>
      <div class="checkbox-group">
        <base-checkbox
          v-for="(option, index) in subscribersTypesOptions"
          :key="index"
          v-model="subscribersTypes[option.key]"
          :label="option.label"/>
      </div>
    </form-group>

    <form-group
      v-if="allowSubscribersNumberSource"
      :label="$t('streamStats.labels.display')"
      inline>
      <dropdown-select v-model="subscribersNumberSource" :options="subscribersNumberSourceOptions"/>
    </form-group>

    <form-group
      v-if="allowViewersNumberSource"
      :label="$t('streamStats.labels.display')"
      inline>
      <dropdown-select v-model="viewersNumberSource" :options="viewersNumberSourceOptions"/>
    </form-group>

    <template v-if="allowPeriod">
      <form-group :label="$t('streamStats.labels.period')" inline>
        <dropdown-select v-model="dataPeriod" :options="dataPeriodOptions"/>
      </form-group>
      <form-group
        v-if="dataPeriod === 'custom'"
        :label="$t('streamStats.labels.dates')"
        inline>
        <div class="period-picker">
          <date-picker v-model="customPeriod.from"/>
          <div class="divider">-</div>
          <date-picker v-model="customPeriod.to"/>
        </div>
      </form-group>
    </template>

    <form-group
      v-if="allowRows"
      :label="$t('streamStats.labels.elementsNumber')"
      inline>
      <base-range v-model="rowsNumber" :min="1" :max="100" input/>
    </form-group>
    <template v-if="allowDisplayModes">
      <form-group
        :label="$t('streamStats.labels.displayMode')"
        inline>
        <dropdown-select v-model="displayMode" :options="displayModeOptions"/>
      </form-group>
      <form-group
        v-if="allowWidgetSpeed"
        :label="$t('streamStats.labels.widgetSpeed')"
        inline>
        <base-range v-model="widgetSpeed" :min="1" :max="100" input input-addon="%"/>
      </form-group>
    </template>

    <template v-if="allowTemplate">
      <form-group
        :label="$t('streamStats.labels.template')"
        :description="templateDescription"
        inline>
        <base-input v-model="template" :placeholder="templatePlaceholder"/>
      </form-group>
      <form-group
        v-if="allowAdditionalTemplate"
        :label="$t('streamStats.labels.additionalTemplate')"
        :description="templateDescription"
        inline>
        <base-input v-model="additionalTemplate"/>
      </form-group>
    </template>
  </div>
</template>

<script>
import dataTypeSettings from '@src/config/streamStats/dataTypeSettings'

import { dataTypeTemplates, defaultWidgetSettings } from '@src/config/streamStats/defaultValues'

const defaultSettings = defaultWidgetSettings()

export default {
  name: 'StreamStatsSettingsForm',
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dataType: null,
      dataPeriod: null,
      customPeriod: null,
      rowsNumber: null,
      displayMode: null,
      widgetSpeed: null,
      template: null,
      additionalTemplate: null,
      subscribersTypes: null,
      subscribersNumberSource: null,
      viewersNumberSource: null,

      dataTypeTemplates,
    }
  },
  computed: {
    output() {
      return {
        dataType: this.dataType,
        dataPeriod: this.dataPeriod,
        customPeriod: this.customPeriod,
        rowsNumber: this.rowsNumber,
        displayMode: this.displayMode,
        widgetSpeed: this.widgetSpeed,
        template: this.template,
        additionalTemplate: this.additionalTemplate,
        subscribersTypes: this.subscribersTypes,
        subscribersNumberSource: this.subscribersNumberSource,
        viewersNumberSource: this.viewersNumberSource,
      }
    },

    dataPeriodOptions() {
      return [
        'currentStream',
        'lastStream',
        'allTime',
        'today',
        'last24hours',
        'thisWeek',
        'last7days',
        'thisMonth',
        'last30days',
        'thisYear',
        'custom',
      ].map(option => ({
        label: this.$t(`streamStats.period.${option}`),
        value: option,
      }))
    },

    displayModeOptions() {
      return [
        'list',
        'slider',
        'marquee',
      ].map(option => ({
        label: this.$t(`streamStats.displayMode.${option}`),
        value: option,
      }))
    },

    allowWidgetSpeed() {
      return this.allowDisplayModes && [
        'slider',
        'marquee',
      ].includes(this.displayMode)
    },

    allowSubscribersTypes() {
      return [
        'lastSubscribers',
        'subscriptionsNumber',
      ].includes(this.dataType)
    },

    subscribersTypesOptions() {
      return Object.keys(this.subscribersTypes).map(key => ({
        key,
        label: this.$t(`streamStats.subscribersTypes.${key}`),
      }))
    },

    allowSubscribersNumberSource() {
      return [
        'subscribersNumber',
      ].includes(this.dataType)
    },

    subscribersNumberSourceOptions() {
      return [
        'twitchFollower',
        'twitchSubscriber',
        'vkLiveFollower',
        'vkLiveSubscriber',
      ].map(option => ({
        label: this.$t(`streamStats.subscribersTypes.${option}`),
        value: option,
      }))
    },

    allowViewersNumberSource() {
      return [
        'viewersNumber',
      ].includes(this.dataType)
    },

    viewersNumberSourceOptions() {
      return [
        'vkLiveViewer',
      ].map(option => ({
        label: this.$t(`streamStats.viewersTypes.${option}`),
        value: option,
      }))
    },

    dataTypeOptions() {
      return Object.keys(dataTypeSettings).map(key => ({
        label: this.$t(`streamStats.dataType.${key}.label`),
        value: key,
      }))
    },

    dataTypeSettings() {
      return dataTypeSettings[this.dataType]
    },

    currentTranslationPath() {
      return `streamStats.dataType.${this.dataType}`
    },

    dataTypeDescription() {
      return _.get(this.dataTypeSettings, 'description', false)
        ? this.$t(`${this.currentTranslationPath}.description`)
        : null
    },

    allowPeriod() {
      return _.get(this.dataTypeSettings, 'allowPeriod')
    },

    allowRows() {
      return _.get(this.dataTypeSettings, 'allowRows')
    },

    allowDisplayModes() {
      return _.get(this.dataTypeSettings, 'allowDisplayModes')
    },

    allowTemplate() {
      return _.get(this.dataTypeSettings, 'allowTemplate', false)
    },

    templateTags() {
      return _.get(this.dataTypeSettings, 'templateTags', [])
        .map(tag => `{${tag}}`)
        .join(', ')
    },

    templatePlaceholder() {
      return _.get(this.dataTypeSettings, 'templatePlaceholder')
    },

    templateDescription() {
      return this.$t(`streamStats.templateDescription`, { tags: this.templateTags })
    },

    allowAdditionalTemplate() {
      return _.get(this.dataTypeSettings, 'allowAdditionalTemplate', false)
        && this.displayMode === 'slider'
    },
  },
  watch: {
    dataType(value, oldValue) {
      if (oldValue && oldValue !== value) {
        const { template, additionalTemplate } = this.dataTypeTemplates[value]
        this.template = template
        this.additionalTemplate = additionalTemplate
      }
    },
    template(value) {
      this.dataTypeTemplates[this.dataType].template = value
    },
    additionalTemplate(value) {
      this.dataTypeTemplates[this.dataType].additionalTemplate = value
    },
    output(value) {
      this.$emit('input', value)
    },
    value: {
      handler(value) {
        if (value) {
          Object.keys(value).forEach(key => {
            if (this.hasOwnProperty(key)) {
              this[key] = value[key]
            }
          })
        }
      },
      immediate: true,
    },
  },
}
</script>
