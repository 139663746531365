export const state = {
  isSettingsLoading: false,
  settings: null,

  isInfoLoading: false,
  info: {},

  stats: null,
}

export const getters = {
  isSettingsLoading: ({ isSettingsLoading }) => isSettingsLoading,
  settings: ({ settings }) => settings,

  isInfoLoading: ({ isInfoLoading }) => isInfoLoading,
  info: ({ info }) => info,

  stats: ({ stats }) => stats,
}

export const mutations = {
  startSettingsLoading(state) {
    state.isSettingsLoading = true
  },

  endSettingsLoading(state) {
    state.isSettingsLoading = false
  },

  setSettings(state, { data }) {
    state.settings = data
  },

  startInfoLoading(state) {
    state.isInfoLoading = true
  },

  endInfoLoading(state) {
    state.isInfoLoading = false
  },

  setInfo(state, { data }) {
    state.info = data
  },

  setStats(state, { data }) {
    state.stats = data
  },
}

export const actions = {
  async getSettings({ commit }) {
    try {
      commit('startSettingsLoading')

      const { data } = await api.get(`/bftsetting`)
      commit('setSettings', { data })

      commit('endSettingsLoading')
    } catch (err) {
      commit('endSettingsLoading')
    }
  },

  async updateSettings({ commit, state }, opt) {
    try {
      const { data } = await api.put(`/bftsetting`, opt)
      commit('setSettings', { data })
    } catch (err) {
      window.addStatusMessage('error', 'Что-то пошло не так :(')
      commit('setSettings', { data: opt })
    }
  },

  async getInfo({ commit }) {
    try {
      commit('startInfoLoading')

      const { data } = await api.get(`/bft/auth`)
      commit('setInfo', { data })

      commit('endInfoLoading')
    } catch (err) {
      commit('endInfoLoading')
    }
  },

  async getStats({ state, commit }) {
    try {
      const { data } = await api.get(`/bft/auth/stat/total`)
      commit('setStats', { data })
    } catch (error) {
      throw new Error(error)
    }
  },
}
