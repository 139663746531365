<style scoped lang="scss">
  .roulette-card-lootbox {
    display: flex;
    align-items: center;
    justify-content: center;

    .card-face-image {
      max-width: 100%;
      max-height: 100%;
    }
  }
</style>

<template>
  <div v-if="lootbox" class="roulette-card-lootbox">
    <img
      v-if="lootbox.image"
      class="card-lootbox-image"
      :src="lootbox.image.src"
      :style="lootbox.image.styles"/>
  </div>
</template>

<script>
import {
  createCssFilters,
} from '@utils/utils'

export default {
  name: 'RouletteCardLootbox',
  props: {
    settings: {
      type: Object,
      default: null,
    },
  },
  computed: {
    ...mapGetters('fileManager', ['getFile']),

    lootboxImage() {
      const {
        lootboxImage,
      } = this.settings

      this.fetchFile(lootboxImage)

      return this.getFile(lootboxImage)
    },

    lootbox() {
      if (!_.get(this.settings, 'lootboxEnabled', null)) {
        return null
      }

      const {
        lootboxImageHue,
        lootboxImageSaturate,
        lootboxBorderRadius,
        lootboxSize,
        lootboxShadow,
      } = this.settings

      const lootbox = {}

      if (this.lootboxImage) {
        lootbox.image = {
          styles: {
            filter: createCssFilters({
              shadow: lootboxShadow,
              hue: lootboxImageHue,
              saturate: lootboxImageSaturate,
            }),
            borderRadius: `${lootboxBorderRadius}px`,
            maxWidth: `${lootboxSize}px`,
            maxHeight: `${lootboxSize}px`,
          },
          src: this.lootboxImage.url,
        }
      }

      return lootbox
    },
  },
}
</script>
