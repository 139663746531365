import TableContainer from './TableContainer'
import TableHeader from './TableHeader'
import TableRow from './TableRow'
import TableCell from './TableCell'

export default {
  TableContainer,
  TableHeader,
  TableRow,
  TableCell,
}
