<style scoped lang="scss">
  .goal-visual-settings-modal {

  }
</style>

<template>
  <modal class="goal-visual-settings-modal" v-model="modalOpened">
    <template slot="title">{{ $t('roulette.settings.goalDesign') }}</template>
    <goal-visual-settings slot="body-content" v-if="modalOpened" v-model="goalVisualSettings"/>
    <template slot="controls">
      <btn @click="save" :is-loading="loadingState.save" variant="primary-action">
        {{ $t('global.common.save') }}
      </btn>
      <btn @click="cancel" variant="secondary-action">
        {{ $t('global.common.cancel') }}
      </btn>
    </template>
  </modal>
</template>

<script>

import GoalVisualSettings from './GoalVisualSettings/GoalVisualSettings'

export default {
  name: 'GoalVisualSettingsModal',
  components: { GoalVisualSettings },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      modalOpened: false,

      loadingState: {
        save: false,
      },

      goalVisualSettings: {},
    }
  },
  computed: {
    ...mapState('roulette', {
      $goalVisualSettings: 'goalVisualSettings',
    }),
    ...mapGetters('widgets/presets', ['getDefaultPresetByType']),
  },
  methods: {
    ...mapActions('roulette', ['updateGoalVisualSettings']),

    closeModal() {
      this.modalOpened = false
    },

    save() {
      this.loadingState.save = true

      const { id } = this.getDefaultPresetByType('roulette')

      this.updateGoalVisualSettings({
        json: this.goalVisualSettings,
        presetId: id,
      }).then(() => {
        this.closeModal()
      }).finally(() => {
        this.loadingState.save = false
      })
    },

    cancel() {
      this.reset()
      this.closeModal()
    },

    reset() {
      this.goalVisualSettings = _.cloneDeep(this.$goalVisualSettings)
    },
  },
  watch: {
    value: {
      handler(value) {
        this.modalOpened = value

        if (this.modalOpened) {
          this.reset()
        }
      },
      immediate: true,
    },
    modalOpened(value) {
      this.$emit('input', value)
    },
    $goalVisualSettings: {
      handler: 'reset',
      immediate: true,
    },
  },
}
</script>
