<style scoped lang="scss">
  .category-decor-settings {

  }
</style>

<template>
  <div class="category-decor-settings">
    <form-group inline :label="$t('global.settings.labels.type')">
      <dropdown-select v-model="decorType" :options="decorTypeOptions"/>
    </form-group>
    <template v-if="decorType">
      <template v-if="decorType === 'image'">
        <form-group inline :label="$t('roulette.category.visualSettings.decor.decor')">
          <file-manager v-model="decorImage" widget-element="roulette_card_decor" file-type="image"/>
        </form-group>
        <template v-if="displayImageSettings">
          <form-group inline :label="$t('global.settings.labels.hueRotation')">
            <base-range :min="0" :max="360" v-model="decorImageHue" input input-addon="°"/>
          </form-group>
          <form-group inline :label="$t('global.settings.labels.saturate')">
            <base-range :min="0" :max="300" v-model="decorImageSaturate" input input-addon="%"/>
          </form-group>
        </template>
      </template>
      <template v-if="decorType === 'svg'">
        <form-group inline :label="$t('roulette.category.visualSettings.decor.decor')">
          <file-manager v-model="decorSvg" widget-element="roulette_card_decor" file-type="vector"/>
        </form-group>
        <template v-if="displaySvgSettings">
          <form-group inline :label="$t('global.settings.labels.color')">
            <color-gradient-picker v-model="decorSvgColor"/>
          </form-group>
        </template>
      </template>
      <template v-if="displayImageSettings || displaySvgSettings">
        <form-group inline :label="$t('global.settings.labels.size')">
          <base-range :min="10" :max="200" v-model="decorSize" input input-addon="px"/>
        </form-group>
        <form-group inline :label="$t('global.settings.labels.position')">
          <base-range :min="-100" :max="100" v-model="decorOffset" input input-addon="px"/>
        </form-group>
        <form-group inline :label="$t('global.settings.labels.shadow')">
          <shadow-styler v-model="decorShadow" class="left"/>
        </form-group>
      </template>
    </template>
  </div>
</template>

<script>

import ShadowStyler from '@components/BaseComponents/ShadowStyler/ShadowStyler'
import ColorGradientPicker from '@components/BaseComponents/ColorPicker/ColorGradientPicker'
import FileManager from '@components/BaseComponents/FileManager/FileManager'

export default {
  name: 'CategoryDecorSettings',
  components: {
    FileManager,
    ColorGradientPicker,
    ShadowStyler,
  },
  props: {
    value: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      decorType: null,

      decorOffset: 0,

      decorSvg: null,
      decorSvgColor: {
        useGradient: true,
        color: '#f59c07',
        gradient: {
          colors: ['#f59c07', '#f57507'],
          angle: 135,
        },
      },

      decorImage: null,
      decorImageHue: 0,
      decorImageSaturate: 100,

      decorSize: 160,
      decorShadow: null,
    }
  },
  computed: {
    decorTypeOptions() {
      return [
        {
          label: 'none',
          value: null,
          icon: 'cross',
        },
        {
          label: 'image',
          value: 'image',
          icon: 'image',
        },
        {
          label: 'vector',
          value: 'svg',
          icon: 'vector-square',
        },
      ].map(option => ({
        ...option,
        label: this.$t(`global.settings.labels.types.${option.label}`),
      }))
    },

    displayImageSettings() {
      return this.decorType === 'image' && this.decorImage
    },

    displaySvgSettings() {
      return this.decorType === 'svg' && this.decorSvg
    },

    output() {
      return {
        _version: Math.random().toString(36).slice(2),
        decorType: this.decorType,
        decorSvg: this.decorSvg,
        decorSvgColor: this.decorSvgColor,
        decorImage: this.decorImage,
        decorImageHue: this.decorImageHue,
        decorImageSaturate: this.decorImageSaturate,
        decorSize: this.decorSize,
        decorShadow: this.decorShadow,
        decorOffset: this.decorOffset,
      }
    },
  },
  created() {
    if (this.value) {
      Object.keys(this.value).forEach(key => {
        this[key] = this.value[key] ?? this[key]
      })
    }
  },
  watch: {
    output(value) {
      this.$emit('input', value)
    },

    decorImage(image, oldImage) {
      if (oldImage && oldImage !== image) {
        this.decorImageHue = 0
        this.decorImageSaturate = 100
      }
    },
  },
}
</script>
