<style scoped lang="scss">
  .roulette-categories-settings {
    .categories-list {
      display: grid;
      grid-auto-rows: 1fr;
      grid-template-columns: 1fr 1fr 1fr;
      grid-row-gap: 20px;
      grid-column-gap: 20px;

      @include media(max, sm) {
        grid-template-columns: 1fr;
      }

      .categories-list-item {
        background: $additional-3;
        padding: 20px;
        border-radius: 5px;
      }

      .new-category {
        padding: 15px;

        .new-category-button {
          height: 100%;
          border-radius: 5px;
          border: 1px dashed rgba(white, .26);
          color: rgba(white, .26);
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          @include padding-y(20px);

          &:hover {
            border-color: rgba(white, .5);
            color: rgba(white, .5);
          }

          .button-icon {
            font-size: 30px;
          }

          .button-text {
            font-size: 18px;
          }

          .button-icon + .button-text {
            margin-top: 15px;
          }
        }
      }
    }
  }
</style>

<template>
  <div class="roulette-categories-settings">
    <template v-if="categorySettingsOpened || currentCategoryId">
      <category-form :categoryId="currentCategoryId" @close="closeCategorySettings"/>
    </template>
    <template v-else>
      <div class="categories-list">
        <div
          v-for="category in categories"
          :key="category.id"
          class="categories-list-item">
          <category-card
            :category-id="category.id"
            :chance="category.chance"
            @edit="openCategorySettings(category.id)"/>
        </div>
        <div class="categories-list-item new-category">
          <div class="new-category-button" @click="openCategorySettings()">
            <div class="button-icon">
              <icon name="plus"/>
            </div>
            <div class="button-text">{{ $t('roulette.category.createCategory') }}</div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>

import { getRarityValues } from '@src/config/roulette/helper'
import CategoryCard from './CategoryCard'
import CategoryForm from './CategoryForm/CategoryForm'

export default {
  name: 'RouletteCategoriesSettings',
  components: {
    CategoryForm,
    CategoryCard,
  },
  data() {
    return {
      currentCategoryId: null,
      categorySettingsOpened: false,
    }
  },
  computed: {
    ...mapGetters('roulette', ['getCategoriesList']),

    categories() {
      const categories = this.getCategoriesList

      const rarityChances = getRarityValues('chance')

      const chancePool = categories
        .filter(category => category.enabled)
        .reduce((acc, category) => acc + rarityChances[category.rarity], 0)

      return categories.map(category => {
        const chance = _.round(rarityChances[category.rarity] / chancePool * 100, 2)

        return {
          ...category,
          chance: category.enabled ? chance : 0,
        }
      })
    },
  },
  methods: {
    ...mapActions('roulette', {
      updateSettings: 'updateSettings',
    }),

    openCategorySettings(id = null) {
      this.currentCategoryId = id
      this.categorySettingsOpened = true
    },

    closeCategorySettings() {
      this.currentCategoryId = null
      this.categorySettingsOpened = false
    },
  },
}
</script>
