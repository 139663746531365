<style scoped lang="scss">
  .category-background-settings {

  }
</style>

<template>
  <div class="category-background-settings">
    <form-group inline :label="$t('global.settings.labels.type')">
      <dropdown-select v-model="backgroundType" :options="backgroundTypeOptions"/>
    </form-group>
    <template v-if="backgroundType">
      <template v-if="backgroundType === 'image'">
        <form-group inline :label="$t('global.settings.labels.background')">
          <file-manager v-model="backgroundImage" widget-element="roulette_card_background" file-type="image"/>
        </form-group>
        <template v-if="displayImageSettings">
          <form-group inline :label="$t('global.settings.labels.hueRotation')">
            <base-range :min="0" :max="360" v-model="backgroundImageHue" input input-addon="°"/>
          </form-group>
          <form-group inline :label="$t('global.settings.labels.saturate')">
            <base-range :min="0" :max="300" v-model="backgroundImageSaturate" input input-addon="%"/>
          </form-group>
        </template>
      </template>
      <template v-if="backgroundType === 'color'">
        <form-group inline :label="$t('global.settings.labels.backgroundColor')">
          <color-gradient-picker v-model="backgroundColor"/>
        </form-group>
      </template>
      <template v-if="displayBackgroundSettings">
        <form-group inline :label="$t('global.settings.labels.borderRadius')">
          <base-range :min="0" :max="100" v-model="backgroundBorderRadius" input input-addon="px"/>
        </form-group>
        <form-group inline :label="$t('global.settings.labels.size')">
          <base-range :min="10" :max="200" v-model="backgroundSize" input input-addon="px"/>
        </form-group>
        <form-group inline :label="$t('global.settings.labels.shadow')">
          <shadow-styler v-model="backgroundShadow" class="left"/>
        </form-group>
      </template>
    </template>
  </div>
</template>

<script>
import ShadowStyler from '@components/BaseComponents/ShadowStyler/ShadowStyler'


import ColorGradientPicker from '@components/BaseComponents/ColorPicker/ColorGradientPicker'
import FileManager from '@components/BaseComponents/FileManager/FileManager'

export default {
  name: 'CategoryBackgroundSettings',
  components: {
    FileManager,
    ColorGradientPicker,


    ShadowStyler,
  },
  props: {
    value: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      backgroundType: null,

      backgroundImage: null,
      backgroundImageHue: 0,
      backgroundImageSaturate: 100,

      backgroundColor: {
        useGradient: true,
        color: '#262626',
        gradient: {
          colors: ['#262626', '#353535'],
          angle: 135,
        },
      },

      backgroundShadow: null,
      backgroundBorderRadius: 20,
      backgroundSize: 160,
    }
  },
  computed: {
    backgroundTypeOptions() {
      return [
        {
          value: null,
          icon: 'cross',
        },
        {
          value: 'image',
          icon: 'image',
        },
        {
          value: 'color',
          icon: 'palette',
        },
      ].map(option => ({
        ...option,
        label: option.value ? this.$t(`global.settings.labels.types.${option.value}`) : null,
      }))
    },

    displayImageSettings() {
      return this.backgroundType === 'image' && this.backgroundImage
    },

    displayBackgroundSettings() {
      return this.backgroundType === 'color' || this.displayImageSettings
    },

    output() {
      return {
        _version: Math.random().toString(36).slice(2),
        backgroundType: this.backgroundType,
        backgroundImage: this.backgroundImage,
        backgroundImageHue: this.backgroundImageHue,
        backgroundImageSaturate: this.backgroundImageSaturate,
        backgroundColor: this.backgroundColor,
        backgroundShadow: this.backgroundShadow,
        backgroundSize: this.backgroundSize,
        backgroundBorderRadius: this.backgroundBorderRadius,
      }
    },
  },
  created() {
    if (this.value) {
      Object.keys(this.value).forEach(key => {
        this[key] = this.value[key] ?? this[key]
      })
    }
  },
  watch: {
    output(value) {
      this.$emit('input', value)
    },

    backgroundImage(image, oldImage) {
      if (oldImage && oldImage !== image) {
        this.backgroundImageHue = 0
        this.backgroundImageSaturate = 100
      }
    },
  },
}
</script>
