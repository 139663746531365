<template>
  <div
    class="status"
    :class="'status_' + type"
  >
    <icon name="check"/>
    <span>{{ $t('accountSettings.vk.connected') }}</span>
  </div>
</template>


<script>
export default {

  name: 'BackBtn',

  props: {
    type: {
      type: String,
      default: null,
    },
  },
  
}
</script>


<style scoped lang="scss">
.status {
  height: 30px;
  background: #262626;
  border-radius: 15px;
  padding: 0 15px;
  display: flex;
  align-items: center;

  svg {
    margin-right: 5px;
  }

  &_connected {
    background: #629068;
  }
}
</style>