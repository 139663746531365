<style scoped lang="scss">
  .offers-carousel {
    display: flex;
    flex-direction: column;
    height: 270px;
    background: #262626;
    border-radius: 8px;
    padding-bottom: 12px;

    .carousel-header {
      display: flex;
      align-items: center;
      padding-top: 15px;
      @include padding-x(20px);

      .carousel-title {
        display: flex;
        align-items: center;

        .carousel-title-text {
          font-weight: 400;
          font-size: 20px;
          line-height: 30px;
        }

        .carousel-title-helper {
          margin-left: 12px;
          color: #464646;
          font-size: 16px;
          cursor: pointer;
          transition: 150ms;

          &:hover {
            color: #6b6b6b;
          }
        }
      }

      .carousel-controls {
        margin-left: auto;
        display: flex;
        align-items: center;
        user-select: none;

        .carousel-control-button {
          color: #F1F1F1;
          width: 32px;
          height: 32px;
          border-radius: 32px;
          background: #303030;
          font-size: 12px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          transition: 150ms;

          &:hover {
            background: #383838;
          }

          &.prev {
            padding-right: 2px;
          }

          &.next {
            padding-left: 2px;
          }
        }

        .carousel-control-button + .carousel-control-button {
          margin-left: 13px;
        }
      }
    }

    .carousel-content {
      margin-top: 16px;
      margin-bottom: 12px;
      flex-grow: 1;
      position: relative;
      overflow: hidden;

      .carousel-slider {
        height: 100%;
        display: flex;
        align-items: stretch;
        transition: transform .5s;
      }

      .carousel-slide {
        width: 70%;
        flex: 0 0 auto;
        padding-left: 20px;
        color: #f1f1f1;
        text-decoration: none;

        @include media(max, sm) {
          width: 100%;
          padding-right: 20px;
        }

        .carousel-slide-content {
          height: 100%;
          display: flex;
          align-items: stretch;
          padding: 14px;
          background: #303030;
          border-radius: 8px;

          .slide-image {
            width: 109px;
            flex: 0 0 auto;

            img {
              width: 100%;
              max-height: 100%;
            }
          }

          .slide-body {
            .offer-name {
              font-size: 16px;
              line-height: 20px;
              letter-spacing: -0.5px;
            }

            .offer-bonus-type, .offer-end-date {
              font-size: 14px;
              line-height: 20px;
            }

            .offer-name {
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;
            }

            .offer-label {
              margin-top: 10px;
              font-size: 12px;
              line-height: 16px;
              color: #838383;
            }
          }

          .slide-image + .slide-body {
            margin-left: 14px;
          }
        }
      }

      .carousel-slide-button {
        width: 30%;
        flex: 0 0 auto;
        @include padding-x(20px);

        @include media(max, sm) {
          display: none;
        }

        .carousel-slide-button-content {
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          padding: 14px;
          background: #303030;
          border-radius: 8px;
          font-weight: 600;
          font-size: 13px;
          line-height: 14px;
          color: var(--theme-color-main);
          text-transform: uppercase;
          text-decoration: none;
        }
      }
    }

    .carousel-pagination {
      display: flex;
      align-items: center;
      justify-content: center;
      user-select: none;

      .carousel-page-button {
        cursor: pointer;
        padding: 6px;

        &:before {
          content: '';
          display: block;
          width: 8px;
          height: 8px;
          border-radius: 100px;
          background: #464646;
          transition: 150ms;
        }

        &:hover {
          &:before {
            background: #6b6b6b;
          }
        }

        &.active {
          &:before {
            background: #F57D07;
          }
        }
      }
    }
  }
</style>

<template>
  <div v-if="showCarusel" class="offers-carousel">
    <div class="carousel-header">
      <div class="carousel-title">
        <div class="carousel-title-text">{{ $t('offers.advertisingCampaigns') }}</div>
        <div class="carousel-title-helper" v-tooltip="helperTooltip">
          <icon name="info-circle"/>
        </div>
      </div>
      <div v-if="slides.length > 1" class="carousel-controls">
        <div class="carousel-control-button prev" @click="moveSlider(-1)">
          <icon name="chevron-left"/>
        </div>
        <div class="carousel-control-button next" @click="moveSlider(1)">
          <icon name="chevron-right"/>
        </div>
      </div>
    </div>
    <div class="carousel-content">
      <div class="carousel-slider" :style="sliderStyles">
        <router-link
          v-for="(slide, slideIndex) in slides"
          :key="`slide_${slideIndex}`"
          ref="slides"
          :to="{
            name: 'dashboard-offers-details',
            params: {id: slide.id}
          }"
          class="carousel-slide"
          :style="slide.styles"
          @click.native="onSlideClick(slideIndex)">
          <div class="carousel-slide-content">
            <div v-if="slide.image" class="slide-image">
              <img :src="slide.image"/>
            </div>
            <div class="slide-body">
              <div class="offer-name">{{ slide.title }}</div>
              <div class="offer-label">{{ $t('offers.carousel.labels.reward') }}</div>
              <div class="offer-bonus-type">{{ slide.bonusTypeText }}  {{ slide.bonusText }}</div>
              <div class="offer-label">{{ $t('offers.carousel.labels.endDate') }}</div>
              <div v-if="slide.endDate" class="offer-end-date">{{ slide.endDate }}</div>
            </div>
          </div>
        </router-link>
        <div class="carousel-slide-button">
          <router-link
            class="carousel-slide-button-content"
            :to="{name: 'dashboard-offers-available'}"
            @click.native="onShowAllButtonClick">
            {{ $t('offers.carousel.viewAll') }}
          </router-link>
        </div>
      </div>
    </div>
    <div v-if="slides.length > 1" class="carousel-pagination">
      <div
        v-for="(slide, slideIndex) in slides"
        :key="`slide_page_${slideIndex}`"
        class="carousel-page-button"
        :class="{active: slideIndex === currentSlideIndex}"
        @click="setCurrentSlideIndex(slideIndex)"></div>
    </div>
  </div>
</template>

<script>

import Reward from '@components/Dashboard/Offers/Reward'

export default {
  name: 'OffersCarousel',

  components: {
    Reward,
  },

  data() {
    return {
      intervalId: null,
      offers: [],
      currentSlideIndex: 0,
      sliderOffset: 0,
    }
  },
  computed: {
    ...mapState('profile', ['profile']),

    showCarusel: (vm) => vm.offers.length,

    bonusTypeText: (vm) => ({
      action: vm.$t('offers.rewards.costPerAction'),
      fix: vm.$t('offers.rewards.fixedAmount'),
      sale: vm.$t('offers.rewards.merchandiseSales'),
      activation: vm.$t('offers.rewards.perPromocode'),
      note: vm.$t('offers.rewards.salesNotifications'),
    }),

    slides: (vm) => vm.offers.map(({ id, title, image, bonus_type, bonus, currency_code, end_at_ts }, index) => {
      return {
        index,
        id,
        title,
        image,
        bonusTypeText: vm.bonusTypeText[ bonus_type ],
        bonusText: `${ bonus } ${ currency_code }`,
        endDate: end_at_ts ? dates.unixToFormat(end_at_ts, 'dd.MM.yyyy') : null,
      }
    }),

    sliderStyles() {
      return {
        transform: `translateX(${-this.sliderOffset}px)`,
      }
    },

    helperTooltip() {
      return this.$tooltip(this.$t('offers.carousel.helper'), 'top')
    },
  },
  methods: {
    async fetchOffersCarousel() {
      const { data } = await api.get('/advcarousel/active')
      this.offers = data?.offers || []
    },

    moveSlider(offset, clearCurrentInterval = true) {
      this.setCurrentSlideIndex(this.currentSlideIndex + offset)
      if(clearCurrentInterval) {
        clearInterval(this.intervalId)
        this.autoPlayCarousel()
      }
    },

    autoPlayCarousel() {
      this.intervalId = setInterval(() => this.moveSlider(1, false), 5000)
    },

    setCurrentSlideIndex(index) {
      if (index >= this.slides.length) {
        this.currentSlideIndex = 0
      } else if (index < 0) {
        this.currentSlideIndex = this.slides.length - 1
      } else {
        this.currentSlideIndex = index
      }

      const { id: offer_id } = this.slides[this.currentSlideIndex]

      this.$tmr.goal({ goal: 'banner_showed' }, {
        user_id: this.profile.id,
        offer_id,
        carousel_index: this.currentSlideIndex,
      })
    },

    onSlideClick(index) {
      const { id: offer_id } = this.slides[index]

      this.$tmr.goal({ goal: 'banner_clicked' }, {
        user_id: this.profile.id,
        offer_id,
        carousel_index: index,
      })
    },

    onShowAllButtonClick() {
      this.$tmr.goal({ goal: 'show_all_clicked' }, {
        user_id: this.profile.id,
      })
    },

    calculateSliderOffset() {
      if (this.$refs.slides) {
        const { width } = this.$refs.slides[0].$el.getBoundingClientRect()

        this.sliderOffset = width * this.currentSlideIndex
      }
    },
  },
  async created() {
    await this.fetchOffersCarousel()
    
    if(this.showCarusel) {
      this.autoPlayCarousel()
    }
  },
  watch: {
    currentSlideIndex: 'calculateSliderOffset',
    $appWidth: 'calculateSliderOffset',
  },
}
</script>
