<style scoped lang="scss">
  .category-visual-settings {
    display: flex;

    .vertical-tabs {
      align-self: flex-start;
    }

    .visual-settings-form {
      margin-left: 20px;
      padding: 15px 15px 15px 20px;
      background-color: $additional-3;
      border-radius: 5px;
      flex: 1 1 auto;
      display: flex;
      flex-direction: column;
    }

    .visual-settings-preview {
      margin-left: 20px;
      align-self: flex-start;
      padding: 15px;
      background-color: $additional-3;
      border-radius: 5px;
      flex: 0 1 auto;

      .preview-content {
        width: 200px;
        height: 200px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        .widget-roulette-background {
          position: absolute;
          width: 1000px;
          height: 200px;
        }
      }

      .preview-title {
        margin-top: 10px;
        text-align: center;
      }

      .preview-controls {
        margin-top: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
</style>

<template>
  <div class="category-visual-settings">
    <vertical-tabs v-model="currentTab" :list="tabs"/>
    <div class="visual-settings-form">
      <template v-if="currentTab === 'background'">
        <category-background-settings v-model="backgroundSettings"/>
      </template>
      <template v-if="currentTab === 'frame'">
        <category-frame-settings v-model="frameSettings"/>
      </template>
      <template v-if="currentTab === 'decor'">
        <category-decor-settings v-model="decorSettings"/>
      </template>
      <template v-if="withText && currentTab === 'text'">
        <category-text-settings v-model="textSettings"/>
      </template>
      <template v-if="currentTab === 'lootbox'">
        <category-lootbox-settings v-model="lootboxSettings"/>
      </template>
      <template v-if="currentTab === 'behavior'">
        <category-behavior-settings v-model="behaviorSettings"/>
      </template>
      <template v-if="currentTab === 'sound'">
        <category-sound-settings v-model="soundSettings"/>
      </template>
      <template v-if="currentTab === 'effects'">
        <category-effect-settings v-model="effectSettings"/>
      </template>
    </div>
    <div class="visual-settings-preview">
      <div class="preview-content">
        <widget-roulette-background :settings="rouletteVisualSettings.backgroundSettings"/>
        <roulette-card
          :settings="output"
          :text="previewText"
          :animation-preview="this.currentTab === 'behavior'"
          :lootbox-preview="this.currentTab === 'lootbox'"
          preview/>
      </div>
      <div class="preview-title">{{ $t('roulette.category.cardPreview') }}</div>
    </div>
  </div>
</template>

<script>

import VerticalTabs from '@components/BaseComponents/Tabs/VerticalTabs'
import RouletteCard from '@components/Widgets/WidgetRoulette/RouletteCard/RouletteCard'
import CategoryFrameSettings from './CategoryFrameSettings'
import CategoryBackgroundSettings from './CategoryBackgroundSettings'
import CategoryTextSettings from './CategoryTextSettings'
import CategoryDecorSettings from './CategoryDecorSettings'
import CategoryLootboxSettings from './CategoryLootboxSettings'
import CategoryBehaviorSettings from './CategoryBehaviorSettings'
import CategorySoundSettings from './CategorySoundSettings'
import CategoryEffectSettings from './CategoryEffectSettings'
import WidgetRouletteBackground from '@components/Widgets/WidgetRoulette/WidgetRouletteBackground'

export default {
  name: 'CategoryVisualSettings',
  components: {
    CategoryLootboxSettings,
    WidgetRouletteBackground,
    CategoryEffectSettings,
    CategorySoundSettings,
    CategoryBehaviorSettings,
    RouletteCard,
    VerticalTabs,
    CategoryDecorSettings,
    CategoryTextSettings,
    CategoryBackgroundSettings,
    CategoryFrameSettings,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    withText: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentTab: 'frame',

      backgroundSettings: null,
      decorSettings: null,
      frameSettings: null,
      textSettings: null,
      lootboxSettings: null,
      behaviorSettings: null,
      soundSettings: null,
      effectSettings: null,
    }
  },
  computed: {
    ...mapState('roulette', { rouletteVisualSettings: 'rouletteVisualSettingsLocal' }),

    tabs() {
      return [
        {
          key: 'frame',
          icon: 'square',
        },
        {
          key: 'background',
          icon: 'shadow',
        },
        {
          key: 'decor',
          icon: 'icons',
        },
        {
          key: 'text',
          icon: 'font',
        },
        {
          key: 'lootbox',
          icon: 'copy',
        },
        {
          key: 'behavior',
          icon: 'spinner',
        },
        {
          key: 'sound',
          icon: 'music',
        },
        {
          key: 'effects',
          icon: 'star',
        },
      ].filter(tab => !(!this.withText && tab.key === 'text'))
        .map(tab => ({
          ...tab,
          title: this.$t(`roulette.category.visualSettings.tabs.${tab.key}`),
        }))
    },

    previewText() {
      return this.withText ? this.$t('roulette.category.cardPreviewText') : null
    },

    output() {
      return {
        backgroundSettings: this.backgroundSettings,
        decorSettings: this.decorSettings,
        frameSettings: this.frameSettings,
        textSettings: this.textSettings,
        lootboxSettings: this.lootboxSettings,
        behaviorSettings: this.behaviorSettings,
        soundSettings: this.soundSettings,
        effectSettings: this.effectSettings,
      }
    },
  },
  created() {
    if (this.value) {
      Object.keys(this.value).forEach(key => {
        this[key] = this.value[key] ?? this[key]
      })
    }
  },
  watch: {
    output(value) {
      this.$emit('input', value)
    },
  },
}
</script>
