<style lang="scss" scoped>
  .dashboard-layout {
    background-color: $black;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    font-size: 14px;
    font-family: $font-family;
    font-weight: normal;
    line-height: 1.5;
    color: $bright;
    position: relative;

    main {
      position: relative;
      flex: 1 0 100%;
      display: flex;
      flex-direction: column;
    }

    ::v-deep {
      svg, svg path {
        transition: none;
        max-width: none;
      }

      a {
        text-decoration: none;
      }

      h1, h2, h3, h4, h5, h6,
      .h1, .h2, .h3, .h4, .h5, .h6 {
        margin-bottom: 20px;
        font-weight: 300;
      }

      h2, .h2 {
        font-size: 24px;

        @include media(max, md) {
          font-size: 20px;
        }
      }

      .helper-text {
        font-size: 14px;
        line-height: 1.43;
        color: $warm-grey;
      }

      .text-link {
        color: $link-color;
        cursor: pointer;
        text-decoration: none;

        &:hover {
          color: $link-hover-color;
          text-decoration: underline;
        }

        &.action-link {
          user-select: none;
          border-bottom: 1px dashed currentColor;

          &:hover {
            text-decoration: none;
          }
        }
      }

      hr {
        border-style: solid;
        margin-block-start: 20px;
        margin-block-end: 20px;
        border-width: 0;
        border-top: 1px solid rgba(white, .05);
        border-bottom: 1px solid rgba(black, .1);

        &.small {
          margin-block-start: 15px;
          margin-block-end: 15px;
        }
      }

      .errors-box {
        margin-top: 20px;

        .info-box + .info-box {
          margin-top: 10px;
        }
      }

      .container {
        min-width: map-get($container, min);
        max-width: map-get($container, max);
        width: 100%;
        //width: map_get($grid-breakpoints, xl) + $dashboard-menu-width;
        @include padding-x($gutter);

        //@include media(max, xl) {
        //  width: map_get($grid-breakpoints, lg);
        //}
        //
        //@include media(max, lg) {
        //  width: map_get($grid-breakpoints, md);
        //}

        @include media(max, lg) {
          width: 100%;
          @include padding-x($gutter-mobile);
        }

        @include media(max, sm) {
          width: 100%;
        }
      }
    }
  }
</style>

<template>
  <div class="dashboard-layout">
    <main>
      <slot/>
    </main>
    <file-manager-modal/>
    <platform-modal v-model="platformsModalOpend" :has-no-platforms="true"/>
  </div>
</template>

<script>
import FileManagerModal from '@components/BaseComponents/FileManager/FileManagerModal'
import PlatformModal from '../pages/Dashboard/AdvOffers/PlatformModal.vue'

import { mapWritableState } from 'pinia'
import { usePlatformsStore } from '@store/platforms'

export default {
  name: 'DashboardLayout',
  components: { FileManagerModal, PlatformModal },
  computed: {
    ...mapWritableState(usePlatformsStore, ['platformsModalOpend'])
  },
}
</script>
