<style lang="scss" scoped>
  .date-time-picker {
    display: flex;
    align-items: center;

    .date-picker ::v-deep {
      .base-input {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        position: relative;
        z-index: 1;

        &:hover {
          z-index: 2;
        }

        &:focus, &.focus {
          z-index: 3;
        }
      }
    }

    .time-picker ::v-deep {
      margin-left: -1px;

      .base-input {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        position: relative;
        z-index: 1;

        &:hover {
          z-index: 2;
        }

        &:focus, &.focus {
          z-index: 3;
        }
      }
    }

    .clear-date-button {
      font-size: 14px;
      line-height: 1;
      margin-left: 15px;
      cursor: pointer;
      color: rgba(white, .7);

      &:hover {
        color: white;
      }
    }
  }
</style>

<template>
  <div class="date-time-picker">
    <date-picker v-model="date" :years-range="yearsRange"/>
    <time-picker v-model="time" class="right"/>
    <div v-show="!!date" class="clear-date-button" @click="clearDateTime">
      <icon name="cross"/>
    </div>
  </div>
</template>

<script>
import DatePicker from './DatePicker'
import TimePicker from './TimePicker'

export default {
  name: 'DateTimePicker',
  components: {
    DatePicker,
    TimePicker,
  },
  props: {
    value: {
      type: Number,
      default: null,
    },
    clocks: {
      type: Boolean,
      default: false,
    },
    yearsRange: {
      type: Array,
      default: () => [1900, 2100],
    },
  },
  data() {
    return {
      date: null,
      time: 0,
    }
  },
  computed: {
    output() {
      if (!this.date) {
        return null
      }

      return Number(this.date) + Number(this.time) * 60
    },
  },
  watch: {
    output(date) {
      this.$emit('input', date)
    },

    value: {
      handler(value) {
        if (!value) {
          this.date = null
          this.time = 0
          return
        }

        const date = dates.timestamp(dates.startOfDay(dates.fromUnixTime(value)))
        const seconds = value - date

        this.date = date
        this.time = (seconds - (seconds % 60)) / 60
      },
      immediate: true,
    },
  },
  methods: {
    clearDateTime() {
      this.date = null
      this.time = 0
    },
  },
}
</script>
