<script setup>
import { computed, onMounted, ref } from 'vue'

import usePromise from '@composables/use-promise'

import { usePlatformsStore } from '@store/platforms'

import BaseButton from '@components/BaseComponents/BaseButton'

const platformsStore = usePlatformsStore()
const { savePlatfrom, fetchPlatform } = platformsStore

const { exec, data, pending } = usePromise(fetchPlatform)
const { exec: addPlatforPromise, parsedErrors, errorCode, pending: addingPlatform } = usePromise(savePlatfrom)

const props = defineProps({
  platformId: {
    type: Number,
    default: null
  },
  hasNoPlatforms: {
    type: Boolean,
    default: false
  },
})

const platform = ref({ title: null, url: null })

const emit = defineEmits(['save:success'])

const urlError = computed(() => parsedErrors.value?.url)
const titleError = computed(() => parsedErrors.value?.title)
const hasErrors = computed(() => (urlError.value || titleError.value || errorCode.value))
const descriptionText = computed(() => props.platformId ? 'offers.platforms.modalEditDesc' : 'offers.platforms.modaldesc')
const acceptText = computed(() => props.platformId ? 'global.common.save' : 'global.common.add')

const addPlatform = async () => {
  await addPlatforPromise(platform.value, props.platformId)

  if(!hasErrors.value) {
    emit('save:success')
  }
}

const getPlatform = () => exec(props.platformId)

onMounted(async () => {
  if(props.platformId) {
    await getPlatform(props.platformId)
    platform.value = data.value
  }
})
</script>

<template>
  <div class="platform-form">
    <transition name="fade">
      <div v-if="pending" class="loader-container">
        <loader size="lg"/>
      </div>
    </transition>

    <div v-if="!pending" class="platform-form-content">
      <info-box class="platform-warn" v-if="hasNoPlatforms">
        {{ $t('offers.platforms.platformRequiredWarning') }}
      </info-box>

      <div class="helper-text">
        {{ $t(descriptionText) }}
      </div>

      <form-group
        inline
        :label="$t('offers.platforms.platformName')"
        :invalidFeedback="titleError"
      >
        <base-input v-model="platform.title" />
      </form-group>

      <form-group
        inline
        :label="$t('offers.platforms.platformLink')"
        :invalidFeedback="urlError"
      >
        <base-input v-model="platform.url" />
      </form-group>

      <div class="form-controls">
        <base-button
          variant="primary-action"
          @click="addPlatform"
          :is-loading="addingPlatform"
        >
          {{ $t(acceptText) }}
        </base-button>
        <base-button
          variant="secondary-action"
          @click="$emit('close:modal')"
        >
          {{ $t('global.common.cancel') }}
        </base-button>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.platform-form {
  width: 550px;
  min-height: 240px;
  position: relative;
  padding-bottom: 30px;

  @include media(max, md) {
    width: 100%;
  }

  .platform-form-content {
    width: 100%;

    & .platform-warn {
      margin-bottom: 20px;
    }
  }

  .helper-text {
    margin-bottom: 10px;
  }

  .form-controls {
    margin-top: 50px;
    display: flex;
    align-items: center;

    .button + .button {
      margin-left: 20px;
    }
  }
}
</style>
