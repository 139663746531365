<template>
  <div
    v-pin="pin"
    class="data-grid__thead"
  >
    <div
      class="data-grid__row data-grid__row_header"
    >
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    pin: {
      type: Boolean,
      default: false,
    }
  },
  directives: {
    pin: {
      bind(el, binding) {
        if (!binding.value) return
        
        document.addEventListener('scroll', () => {
          const
            rect = el.getBoundingClientRect(),
            parentRect = el.parentElement.getBoundingClientRect(),
            child = el.children[0]

          if ( rect.top < 1 && parentRect.height + parentRect.top > 0 ) {
            el.style.height = rect.height + 'px'

            child.style.position = 'fixed'
            child.style.top = 0
            child.style.left = rect.left + 'px'
            child.style.right = rect.right - rect.width + 'px'
          } else {
            el.style.height = ''

            child.style.position = ''
            child.style.top = ''
            child.style.left = ''
            child.style.right = ''
          }
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.data-grid {
  &__thead {
    color: #838383;
    background-color: #1f1f1f;
    display: none;
    cursor: default;
    user-select: none;

    .data-grid__col {
      padding-top: 0px;
      padding-bottom: 0px;
    }

    @media (min-width: 720px) {
      display: table-header-group;
    }
  }

  &__row {
    &_header {
      @media (min-width: 720px) {
        display: table-row;
      }
    }
  }
}
</style>
