<template>
  <div class="data-grid__row">
    <slot />
  </div>
</template>

<script>
export default {
  // ...
}
</script>

<style scoped lang="scss">
.data-grid {
  &__row {
    &:nth-child(2n) {
      .data-grid__col {
        background: #262626;
      }
    }

    @media (min-width: 720px) {
      display: table-row;
      height: 60px;
    }
  }
}
</style>
