<style scoped lang="scss">
  .offers-page {
    .payouts-info {
      .info-box {
        max-width: 800px;
      }

      .payouts-info-pixel {
        position: absolute;
        top: -10000px;
        left: -10000px;
        width: 0;
        height: 0;
      }
    }
  }
</style>

<template>
  <div class="offers-page">
    <div v-if="!$route.meta.hide">
      <Title>{{$t('offers.advertisingCampaigns')}}</Title>

      <NavBar
        :disabled="(offerIsLoading || settingsIsLoading)"
        v-if="offerAdvConfirmed && settingsAdvConfirmed"
      />
    </div>

    <router-view
      v-if="offerAdvConfirmed && settingsAdvConfirmed && detailsAdvConfirmed"
    ></router-view>

    <AdvConfirm v-else/>
  </div>
</template>

<script>
import Title from '@components/BaseComponents/Title'
import NavBar from '@components/Dashboard/Offers/NavBar'
import AdvConfirm from '@components/Dashboard/Offers/AdvConfirm'
import { i18n } from '@src/localization/config'

export default {
  name: 'OffersPage',

  components: {
    Title,
    NavBar,
    AdvConfirm,
  },

  metaInfo() {
    return {
      title: this.$t('dashboard.pages.offers.offers'),
    }
  },
  computed: {
    ...mapState('application', ['isAstrumApp']),

    ...mapGetters('offers/main', {
      'offerIsLoading': 'isLoading',
      'offerAdvConfirmed': 'advConfirmed',
    }),
    ...mapGetters('offers/settings', {
      'settingsIsLoading': 'isLoading',
      'settingsAdvConfirmed': 'advConfirmed',
    }),
    ...mapGetters('offers/details', {
      'detailsIsLoading': 'offerLoading',
      'detailsAdvConfirmed': 'advConfirmed',
    }),
  },
}
</script>
