import Vue from 'vue'
import { mapFields } from '@utils/utils'

export const state = {
  categories: {},

  rouletteSettings: null,
  rouletteVisualSettings: null,
  rouletteVisualSettingsLocal: null,
  goalSettings: null,
  goalVisualSettings: null,

  rouletteWidgetId: null,
  goalWidgetId: null,
}

export const getters = {
  getCategoriesList: state => Object.values(state.categories),
  getCategory: state => id => state.categories[id],
}

export const mutations = {
  SET_CATEGORIES(state, categories) {
    state.categories = _.keyBy(categories, 'id')
  },
  UPDATE_CATEGORIES(state, categories) {
    const mergedCategories = categories.reduce((acc, category) => {
      acc[category.id] = _.merge({}, state.categories[category.id], category)
      return acc
    }, {})
    state.categories = {
      ...state.categories,
      ...mergedCategories,
    }
  },
  DELETE_CATEGORY(state, id) {
    Vue.delete(state.categories, id)
  },

  SET_ROULETTE_SETTINGS(state, settings) {
    state.rouletteSettings = settings
  },
  SET_ROULETTE_VISUAL_SETTINGS(state, settings) {
    state.rouletteVisualSettings = settings
    state.rouletteVisualSettingsLocal = settings
  },
  SET_ROULETTE_VISUAL_SETTINGS_LOCAL(state, settings) {
    state.rouletteVisualSettingsLocal = settings
  },
  SET_GOAL_SETTINGS(state, settings) {
    state.goalSettings = settings
  },
  SET_GOAL_VISUAL_SETTINGS(state, settings) {
    state.goalVisualSettings = settings
  },

  SET_ROULETTE_WIDGET_ID(state, id) {
    state.rouletteWidgetId = id
  },
  SET_GOAL_WIDGET_ID(state, id) {
    state.goalWidgetId = id
  },
}

const goalSettingsFieldsMap = {
  baseGoalAmount: 'base_goal_amount',
  baseRaisedAmount: 'base_raised_amount',
  amountStep: 'base_goal_amount_step',
  discountEnabled: 'discount_enabled',
  discount: 'discount_percent',
  discountDuration: 'discount_duration',
  discountChance: 'discount_chance_percent',
  discountTimeout: 'discount_timeout',
}

const rouletteSettingsFieldsMap = {
  rouletteMode: 'roulette_mode',
}

const categoryFieldsMap = {
  id: 'id',
  name: 'name',
  rarity: 'rarity',
  cards: 'cards',
  useCards: 'use_cards',
  enabled: 'enabled',
  settings: 'json',
  presetId: 'preset_id',
  preset: 'preset',
}

export const actions = {
  async initRouletteConfig({ dispatch }) {
    return Promise.all([
      dispatch('fetchCategories'),
      dispatch('fetchRouletteSettings'),
      dispatch('fetchRouletteVisualSettings'),
      dispatch('fetchGoalSettings'),
      dispatch('fetchGoalVisualSettings'),
    ])
  },

  fetchRouletteSettings({ commit }) {
    return api.get('/roulette/settings')
      .then(({ data }) => {
        commit('SET_ROULETTE_SETTINGS', mapFields(data, rouletteSettingsFieldsMap, true))

        return data
      })
  },
  updateRouletteSettings({ commit, dispatch }, settings) {
    return api
      .put('/roulette/settings', mapFields(settings, rouletteSettingsFieldsMap))
      .then(({ data }) => {
        return commit('SET_ROULETTE_SETTINGS', mapFields(data, rouletteSettingsFieldsMap, true))
      })
  },

  fetchRouletteVisualSettings({ commit }) {
    return api.get('/roulette/roulette-widget')
      .then(({ data }) => {
        const { preset, id } = data

        commit('SET_ROULETTE_VISUAL_SETTINGS', data.json ?? preset.json)
        commit('SET_ROULETTE_WIDGET_ID', id)

        return data.json
      })
  },
  updateRouletteVisualSettings({ commit, dispatch }, { json, presetId }) {
    return api
      .put('/roulette/roulette-widget', {
        json,
        preset_id: presetId,
      })
      .then(({ data }) => {
        const { preset } = data

        return commit('SET_ROULETTE_VISUAL_SETTINGS', data.json ?? preset.json)
      })
  },

  fetchGoalSettings({ commit }) {
    return api.get('/roulette/goal/settings')
      .then(({ data }) => {
        return commit('SET_GOAL_SETTINGS', mapFields(data, goalSettingsFieldsMap, true))
      })
  },
  updateGoalSettings({ commit, dispatch }, settings) {
    return api
      .put('/roulette/goal/settings', mapFields(settings, goalSettingsFieldsMap))
      .then(({ data }) => {
        return commit('SET_GOAL_SETTINGS', mapFields(data, goalSettingsFieldsMap, true))
      })
  },

  fetchGoalVisualSettings({ commit }) {
    return api.get('/roulette/goal-widget')
      .then(({ data }) => {
        const { preset, id } = data

        commit('SET_GOAL_VISUAL_SETTINGS', data.json ?? preset.json)
        commit('SET_GOAL_WIDGET_ID', id)

        return data.json
      })
  },
  updateGoalVisualSettings({ commit, dispatch }, { json, presetId }) {
    return api
      .put('/roulette/goal-widget', {
        json,
        preset_id: presetId,
      })
      .then(({ data }) => {
        const { preset } = data

        return commit('SET_GOAL_VISUAL_SETTINGS', data.json ?? preset.json)
      })
  },

  createCategory({ dispatch }, data) {
    return api
      .post('/roulette/category', mapFields(data, categoryFieldsMap))
      .then(({ data }) => {
        return dispatch('storeCategory', data)
      })
  },
  updateCategory({ dispatch }, { id, data }) {
    return api
      .put(`/roulette/category/${id}`, mapFields(data, categoryFieldsMap))
      .then(({ data }) => {
        return dispatch('storeCategory', data)
      })
  },
  deleteCategory({ commit }, id) {
    return api
      .delete(`/roulette/category/${id}`, { id })
      .then(() => {
        return commit('DELETE_CATEGORY', id)
      })
  },
  fetchCategories({ dispatch }) {
    return api.get('/roulette/category')
      .then(({ data }) => {
        return dispatch('storeCategories', { categories: data })
      })
  },
  storeCategory({ dispatch }, category) {
    return dispatch('storeCategories', { categories: [category] }).then(_.head)
  },
  storeCategories({ state, commit }, { categories, force = false }) {
    const mutation = force ? 'SET_CATEGORIES' : 'UPDATE_CATEGORIES'

    commit(mutation, categories
      .map(category => mapFields(category, categoryFieldsMap, true))
      .map(category => {
        const { preset } = category

        return {
          ...category,
          settings: category.settings ?? preset.json,
          presetId: preset ? preset.id : null,
        }
      }))

    return categories.map(category => state.categories[category.id])
  },

  emitRouletteEvent({}, { event, eventData = null }) {
    api.post('/roulette/roulette-widget/emit-event', {
      event,
      eventData,
    })
  },
}
