import Vue from 'vue'

const cachedFiles = {}

const fileTypesMap = {
  image: ['image', 'video'],
  vector: ['vector'],
  sound: ['sound'],
  video: ['video'],
}

export const state = {
  categories: {},
  files: {},
  widgetElementsFiles: {},

  fileManagerOpened: false,
  fileManagerWidgetElement: null,
  fileManagerFileType: null,
  fileManagerFileId: null,

  userSpaceLimit: 0,
  userSpaceTaken: 0,
}

export const getters = {
  getFile: state => id => state.files[Number(id)],
  getFiles: state => Object.values(state.files)
    .filter(file => fileTypesMap[state.fileManagerFileType].includes(file.type))
    .filter(file => _.map(file.widget_elements, 'code').includes(state.fileManagerWidgetElement)),

  getUserSpaceInfo: state => ({
    limit: state.userSpaceLimit,
    taken: state.userSpaceTaken,
  }),
}

export const mutations = {
  SET_FILE_MANAGER_STATE(state, {
    opened,
    widgetElement,
    fileType,
    fileId,
  }) {
    if (opened) {
      state.fileManagerOpened = true
      state.fileManagerWidgetElement = widgetElement
      state.fileManagerFileType = fileType
      state.fileManagerFileId = fileId
    } else {
      state.fileManagerOpened = false
      state.fileManagerWidgetElement = null
      state.fileManagerFileType = null
      state.fileManagerFileId = null
    }
  },

  UPDATE_FILES(state, files) {
    state.files = {
      ...state.files,
      ..._.keyBy(files, 'id'),
    }
  },

  SET_WIDGET_ELEMENTS_FILES(state, { widgetElementId, filesIds = [] }) {
    state.widgetElementsFiles = {
      ...state.widgetElementsFiles,
      [widgetElementId]: filesIds,
    }
  },

  SET_USER_SPACE_INFO(state, { limit, taken }) {
    state.userSpaceLimit = limit
    state.userSpaceTaken = taken
  },

  DELETE_FILE(state, fileId) {
    Vue.delete(state.files, fileId)
  },
}

let fileManagerCallback

export const actions = {
  openFileManager({ commit, state, dispatch }, {
    widgetElement,
    fileType,
    fileId = null,
  }) {
    commit('SET_FILE_MANAGER_STATE', {
      opened: true,
      widgetElement,
      fileType,
      fileId,
    })

    return new Promise((resolve) => {
      fileManagerCallback = resolve
    })
  },

  chooseFile({ dispatch, commit }, fileId) {
    if (fileManagerCallback) {
      fileManagerCallback(fileId)
      fileManagerCallback = null
    }
    return dispatch('closeFileManager')
  },

  closeFileManager({ commit }) {
    fileManagerCallback = null

    return commit('SET_FILE_MANAGER_STATE', { opened: false })
  },

  fetchFile({ state, commit, dispatch }, fileId) {
    if (state.files[fileId]) {
      return state.files[fileId]
    }

    if (!fileId) {
      return undefined
    }

    if (!cachedFiles[fileId]) {
      cachedFiles[fileId] = api
        .get(`/filemanager2/files/${fileId}`)
        .then(({ data, meta }) => {
          const {
            total_user_files_size: taken,
            user_files_size_quota: limit,
          } = meta

          commit('SET_USER_SPACE_INFO', {
            limit,
            taken,
          })

          return dispatch('storeFile', data)
        })
    }

    return cachedFiles[fileId]
  },
  fetchFiles({ commit, dispatch }, widgetElementCode) {
    return api
      .get('/filemanager2/files', {
        params: {
          widget_element_code: widgetElementCode,
        },
      })
      .then(({ data, meta }) => {
        const {
          total_user_files_size: taken,
          user_files_size_quota: limit,
        } = meta

        commit('SET_USER_SPACE_INFO', {
          limit,
          taken,
        })

        return dispatch('storeFiles', data)
      })
  },
  storeFile({ dispatch }, file) {
    return dispatch('storeFiles', [file]).then(_.head)
  },
  storeFiles({ state, commit }, files) {
    commit('UPDATE_FILES', files.map(file => {
      const ext = _.last(file.name.split('.'))

      return {
        ...file,
        ext,
      }
    }))

    return files.map(file => state.files[file.id])
  },

  async getOrFetchFile({ state, dispatch }, fileId) {
    if (!state.files[Number(fileId)]) {
      await dispatch('fetchFile', fileId)
    }

    return state.files[Number(fileId)]
  },

  uploadFile({ commit, dispatch }, { formData, progressHandler }) {
    return api.uploadFile('/filemanager2/files', formData, progressHandler)
      .then(({ data, meta }) => {
        const {
          total_user_files_size: taken,
          user_files_size_quota: limit,
        } = meta

        commit('SET_USER_SPACE_INFO', {
          limit,
          taken,
        })

        return dispatch('storeFile', data)
      })
  },

  deleteFile({ commit }, fileId) {
    return api.delete(`/filemanager2/files/${fileId}`)
      .then(() => {
        return commit('DELETE_FILE', fileId)
      })
  },
}
