<style scoped lang="scss">
  .base-input {
    width: 100%;
    max-width: 300px;
  }

  button {
    border-radius: 8px !important;
  }

  .settings-box {
    margin-top: 30px;
  }

  .dashboard__title {
    display: block;
  }

  .dashboard__title_2 {
    margin-bottom: 0;
    margin-top: 30px;
  }

  .title-sign {
    font-size: 13px;
    color: #838383;
    margin-top: 5px;
    max-width: 560px;
  }

  .link-sign {
    font-size: 13px;
    color: #838383;
    margin-top: 10px;
    max-width: 560px;
  }

  .inline-form {
    .button {
      margin: 10px 15px 0 0;
    }

    @media (min-width: 720px) {
      display: flex;
      align-items: center;

      .button {
        margin: 0 15px;
      }
    }

    & > .inline-form {
      display: flex;
      align-items: center;

      .button {
        margin: 10px 15px 0 0;

        @media (min-width: 720px) {
          margin: 0 15px;
        }
      }
    }
  }

  .vetification-update {
    color: #D63A3A;
    font-size: 13px;
    max-width: 150px;
  }

  .guide-box {
    background: #262626;
    border-radius: 8px;
    padding: 20px 10px;
    margin-top: 20px;
    max-width: 600px;

    @media (min-width: 720px) {
      display: flex;
    }
  }

  .guide-item {
    padding: 10px;
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;

    @media (min-width: 720px) {
      display: block;
    }
  }

  .guide-image {
    position: relative;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    margin: 0 15px 0 0;
    flex: none;

    @media (min-width: 720px) {
      width: 150px;
      height: 150px;
      margin: 0 auto;
    }
  }

  .guide-item_ru {
    &:nth-child(1) {
      .guide-image { background-image: appAsset('/images/vk/ru-1.png'); }
    }

    &:nth-child(2) {
      .guide-image { background-image: appAsset('/images/vk/ru-2.png'); }
    }

    &:nth-child(3) {
      .guide-image { background-image: appAsset('/images/vk/ru-3.png'); }
    }
  }

  .guide-item_en {
    &:nth-child(1) {
      .guide-image { background-image: appAsset('/images/vk/en-1.png'); }
    }

    &:nth-child(2) {
      .guide-image { background-image: appAsset('/images/vk/en-2.png'); }
    }

    &:nth-child(3) {
      .guide-image { background-image: appAsset('/images/vk/en-3.png'); }
    }
  }

  .guide-index {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background-image: linear-gradient(255deg, var(--theme-color-main), var(--theme-color-additional));
    text-align: center;
    color: #F1F1F1;
    font-weight: bold;
    line-height: 32px;
    font-size: 16px;
    border: 3px solid #262626;
    position: absolute;
    z-index: 2;
    top: 0;
    left: 10px;
  }

  .guide-text {
    color: #F1F1F1;
    font-size: 13px;
    margin-top: 15px;

    @media (min-width: 720px) {
      text-align: center;
    }
  }

  .status {
    margin: 10px 15px 0 0;

    @media (min-width: 720px) {
      margin: 0 0 0 15px;
    }
  }

  .link-btn {
    display: flex;
    align-items: center;
    color: inherit;
    background: transparent;
    border: 0;
    margin: 10px 15px 0 0;

    @media (min-width: 720px) {
      margin: 0 15px;
    }

    span {
      border-bottom: 1px dashed #777;
      line-height: 24px;
      transition: all .15s;
    }

    svg {
      margin-right: 5px;
      fill: url(#theme_svg_gradient);
    }

    &:hover {
      span {
        border-bottom: 1px dashed currentColor;
      }
    }

    &._disabled {
      pointer-events: none;
      opacity: .5;
    }
  }
</style>

<template>
  <div class="vk-link-settings">
    <Title>{{ $t('accountSettings.vk.title') }}</Title>

    <Title type="2">{{ $t('accountSettings.vk.communityTitle') }}</Title>
    <p class="title-sign">{{ $t('accountSettings.vk.communityTitleSign') }}</p>

    <div class="settings-box" v-if="!isLoading">
      <form-group
        v-if="groupUrl && isVerifyed"
        :label="$t('accountSettings.vk.communityLinkLabel')"
        inline
      >
        <div class="inline-form">
          <span>{{ groupID }}</span>

          <div class="inline-form">
            <Status type="connected" />

            <button
              class="link-btn"
              @click="disconnectHandler"
            >
              <icon name="link" />
              <span>{{ $t('accountSettings.vk.disconnectButton') }}</span>
            </button>
          </div>
        </div>
      </form-group>

      <form-group
        v-if="!isVerifyed"
        :label="$t('accountSettings.vk.communityLinkLabel')"
        inline
      >
        <div class="inline-form">
          <base-input
            v-model="groupID"
            :placeholder="$t('accountSettings.vk.communityLinkPlaceholder')"
            :disabled="!!groupUrl && !!verificationURL"
          />

          <button
            v-if="!verificationURL"
            class="link-btn"
            :class="{ '_disabled': (groupID && groupID.length < 1) }"
            @click="connectHandler"
          >
            <icon name="chain" />
            <span>{{ $t('accountSettings.vk.connectButton') }}</span>
          </button>

          <button
            v-if="verificationURL"
            class="link-btn"
            @click="changeGroupID"
          >
            <span>{{ $t('accountSettings.vk.cnangeButton') }}</span>
          </button>
        </div>
      </form-group>

      <form-group
        v-if="groupUrl && verificationURL && !isVerifyed"
        :label="$t('accountSettings.vk.vetificationLinkLabel')"
        inline
        class="vetification-input"
      >
        <div class="inline-form">
          <copy-link :src="verificationURL" />

          <div class="inline-form">
            <btn
              variant="primary-action"
              @click="verifyHandler">{{ $t('accountSettings.vk.vetificationButton') }}</btn>
            <!-- <p class="vetification-update">{{ $t('accountSettings.vk.vetificationUpdate') }}<br /></p> -->
          </div>
        </div>
      </form-group>
      <p class="link-sign">{{ $t('accountSettings.vk.vetificationSign') }}</p>
    </div>

    <div v-if="groupUrl && verificationURL && !isVerifyed">
      <Title type="2">{{ $t('accountSettings.vk.guideTitle') }}</Title>
      <p class="title-sign">{{ $t('accountSettings.vk.guideTitleSign') }}</p>

      <div class="guide-box">
        <div class="guide-item" :class="'guide-item_' + $t('accountSettings.vk.lang')">
          <div class="guide-image">
            <div class="guide-index">1</div>
          </div>

          <div class="guide-text">{{ $t('accountSettings.vk.guideText1') }}</div>
        </div>

        <div class="guide-item" :class="'guide-item_' + $t('accountSettings.vk.lang')">
          <div class="guide-image">
            <div class="guide-index">2</div>
          </div>

          <div class="guide-text">{{ $t('accountSettings.vk.guideText2') }}</div>
        </div>

        <div class="guide-item" :class="'guide-item_' + $t('accountSettings.vk.lang')">
          <div class="guide-image">
            <div class="guide-index">3</div>
          </div>

          <div class="guide-text">{{ $t('accountSettings.vk.guideText3') }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Title from '@components/BaseComponents/Title'
import CopyLink from './components/CopyLink'
import Status from './components/Status'

export default {
  name: 'VkLinkSettings',

  components: {
    Title,
    CopyLink,
    Status,
  },

  data() {
    return {
      groupID: null,
    }
  },

  watch: {
    groupUrl(newVal, oldVal) {
      if (newVal !== null && oldVal === null) {
        this.groupID = newVal
      }
    },
  },

  created() {
    this.fetchStatus()
  },

  methods: {
    ...mapActions('settings/vk', ['connect', 'disconnect', 'verify', 'fetchStatus', 'clearData']),

    changeGroupID() {
      this.clearData()
    },

    connectHandler() {
      this.connect({ groupID: this.groupID })
    },

    disconnectHandler() {
      this.disconnect()
    },

    verifyHandler() {
      this.verify()
    },
  },

  computed: {
    ...mapGetters('settings/vk', [
      'isLoading',
      'groupUrl',
      'verificationURL',
      'isVerifyed',
    ]),
  },
}
</script>
