<style lang="scss" scoped>
  .shadow-settings-popover {
    border-radius: 3px;
    box-shadow: 0 2px 19px rgba(0, 0, 0, .35);
    background-color: $additional-3;

    .text-settings-form {
      margin-top: 10px;
    }
  }
</style>

<template>
  <div class="shadow-settings-popover">
    <shadow-settings-form v-bind="$attrs" v-on="$listeners"/>
  </div>
</template>

<script>
import ShadowSettingsForm from '@components/BaseComponents/ShadowStyler/ShadowSettingsForm'

export default {
  name: 'ShadowSettingsPopover',
  components: {
    ShadowSettingsForm,
  },
}
</script>
