<style lang="scss" scoped>
  .banners-rotation {

  }
</style>

<template>
  <div v-if="displayRotation && currentBanner" class="banners-rotation">
    <banner-wrapper
      @close="closeBanner(currentBanner.code)">
      <component :is="currentBanner.component" banner/>
    </banner-wrapper>
  </div>
</template>

<script>

import cookies from '@services/cookies'

import MemeAlertsBanner from './MemeAlertsBanner'
import BannerWrapper from './BannerWrapper'
import ChaosTricksBanner from './ChaosTricksBanner'
import SteosVoiceBanner from './SteosVoiceBanner/SteosVoiceBanner'

export default {
  name: 'BannersRotation',
  components: { ChaosTricksBanner, BannerWrapper, MemeAlertsBanner },
  data() {
    return {
      displayRotation: true,
    }
  },
  computed: {
    ...mapState('dashboard', ['banners']),

    bannersRotation() {
      return [
        {
          code: 'meme_alerts',
          component: MemeAlertsBanner,
        },
        {
          code: 'chaos_tricks',
          component: ChaosTricksBanner,
        },
        // {
        //   code: 'steos_voice',
        //   component: SteosVoiceBanner,
        // },
      ]
        .filter(banner => {
          return !cookies.getItem(`da.dashboard.hideBanner.${banner.code}`)
            && _.get(this.banners, `${banner.code}.show`)
        })
    },

    currentBanner() {
      return _.head(this.bannersRotation)
    },
  },
  methods: {
    closeBanner(bannerCode) {
      cookies.setItem(`da.dashboard.hideBanner.${bannerCode}`, 1, Infinity, '/')

      this.displayRotation = false

      this.$tmr.goal({ goal: 'banner_rotation_closed' }, {
        banner: bannerCode,
      })
    },
  },
}
</script>
