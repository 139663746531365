<style scoped lang="scss">
  .user-balances {
    .balances-text {
      font-size: 12px;
      line-height: 14px;
      letter-spacing: -0.5px;
      color: #838383;
    }

    .balances-table {
      margin-top: 6px;
      margin-bottom: 10px;

      .balances-table-row {
        display: flex;
        align-items: center;
        font-size: 12px;
        line-height: 20px;
        color: #F1F1F1;

        .balance-currency {
          opacity: 0.68;
        }

        .balance-amount {
          margin-left: auto;
        }
      }

      .balances-table-row + .balances-table-row {
        margin-top: 2px;
        padding-top: 2px;
        border-top: 1px solid #464646;
      }
    }

    .overall-balance {
      margin-top: 8px;
      display: flex;
      align-items: center;
      font-weight: 600;
      font-size: 15px;
      line-height: 20px;
      color: #F1F1F1;

      .overall-balance-amount {
        margin-left: auto;
      }
    }

    .balances-controls {
      font-size: 13px;
      margin-top: 6px;
    }
  }
</style>

<template>
  <div class="user-balances">
    <div
      v-if="balances.length"
      class="balances-text">
      {{ $t('balanceHistory.balances.donationsAmount') }}
    </div>

    <div
      class="balances-table"
      v-for="balance in balances"
    >
      <div
        class="balances-table-row">
        <div class="balance-currency">{{ balance.currency }}</div>
        <div class="balance-amount">{{ balance.amountFormatted }}</div>
      </div>
    </div>

    <div class="balances-text">{{ $t('balanceHistory.balances.overallAmount') }}</div>

    <div class="overall-balance">
      <div class="overall-balance-currency">{{ balance.currency }}</div>
      <div class="overall-balance-amount">{{ balance.amountFormatted }}</div>
    </div>

    <div class="balances-controls">
      <router-link
        :to="balanceLink"
        @click.native="onBalanceLinkClick">
        {{ $t('balanceHistory.balanceHistory') }}
      </router-link>
    </div>
  </div>
</template>

<script>

export default {
  name: 'UserBalances',

  props: {
    balance: {
      type: Object,
      required: true
    },
    balances: {
      type: Array,
      default: () => ([])
    },
    balanceKey: {
      type: String,
      default: ''
    }
  },

  computed: {
    balanceLink: (vm) => vm.balanceKey === 'adv' ? { name: 'adv-balance-history' } : { name: 'dashboard-balance-history' }
  },

  methods: {
    onBalanceLinkClick() {
      this.$ga.event({
        eventCategory: 'header',
        eventAction: `header_${this.balanceKey ? this.balanceKey + '_' : this.balanceKey}balance_button_click`,
      })

      this.$tmr.goal({ goal: `header_${this.balanceKey ? this.balanceKey + '_' : this.balanceKey}balance_button_click` })
    },
  },
}
</script>
