<script>
import frontConfig from '@services/frontConfig'
import Vue from 'vue'
import ChatBotWidget from 'chat-bot-widget'
import 'chat-bot-widget/dist/main.css'
import store from '@state/store'

frontConfig.onReady().then(() => {
  const chatBotConfig = frontConfig.getChatBotConfig()

  Vue.use(ChatBotWidget, {
    store,
    accessToken: window.token_dashboard_streamer,
    widgetToken: _.get(store, 'state.profile.profile.token'),
    env: {
      botApi: chatBotConfig.bot_api_url,
      widgetApi: chatBotConfig.widgets_api_url,
      multichatApi: chatBotConfig.multichat_api_url,
      staticUri: frontConfig.getCDN(),
      botApplication: chatBotConfig.auth_application,
      embedChat: chatBotConfig.chat_embed_url,
      embedPoll: chatBotConfig.poll_embed_url,
      daUrl: frontConfig.getAppUrl(),
      embedKappagen: chatBotConfig.kappagen_embed_url,
      embedTicker: chatBotConfig.ticker_embed_url,
      embedStreamCredits: chatBotConfig.stream_credits_embed_url,
    },
    locale: window.document_language,
  })
})

export default {
  name: 'ChatBotWrapper',
  render() {
    return this.$slots.default
  },
}
</script>
