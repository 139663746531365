<style scoped lang="scss">
  .widget-roulette-title {
    display: flex;
    align-items: center;
    justify-content: center;

    .roulette-title-place {
      height: 0;
      width: 0;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      .roulette-title-text {
        position: absolute;
        white-space: nowrap;
      }
    }
  }
</style>

<template>
  <div v-if="title" class="widget-roulette-title">
    <div class="roulette-title-place" :style="title.placeStyles">
      <div class="roulette-title-text" :style="title.styles">{{ title.text }}</div>
    </div>
  </div>
</template>

<script>

import {
  numbersToUnits,
} from '@utils/utils'

export default {
  name: 'WidgetRouletteTitle',
  props: {
    settings: {
      type: Object,
      default: null,
    },
  },
  computed: {
    title() {
      if (!_.get(this.settings, 'titleEnabled', null)) {
        return null
      }

      const {
        titleText,
        textStyles,
        titleOffsetX = 0,
        titleOffsetY = 0,
      } = this.settings

      const { strokeWidth, strokeColor } = textStyles

      return {
        text: titleText,
        placeStyles: {
          transform: `translate(${titleOffsetX}px, ${titleOffsetY}px)`,
        },
        styles: {
          ...numbersToUnits(textStyles),
          '-webkit-text-stroke': strokeWidth ? `${strokeWidth}px ${strokeColor}` : null,
          textStroke: strokeWidth ? `${strokeWidth}px ${strokeColor}` : null,
          fontFamily: `"${textStyles.fontFamily}"`,
        },
      }
    },
  },
}
</script>
