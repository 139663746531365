<template>
  <div class="partner__container">
    <div class="partner__block">
      <div class="partner__block-item">
        <div class="partner__title">{{$t('offers.landingPage')}}</div>
        <div class="partner__any">
          <dropdown-select
            v-if="landingOptions.length > 1"
            v-bind="$attrs" 
            v-on="$listeners"
            :options="landingOptions"
            style="width: 100%"
          />

          <div v-else>{{ currentOffer.title }}</div>
        </div>
      </div>

      <div class="partner__block-item">
        <div class="partner__title">{{$t('offers.description')}}</div>
        <div class="partner__content">{{ currentOffer.description }}</div>
      </div>
    </div>

    <div class="partner__block">
      <div class="partner__block-item">
        <div class="partner__title">{{$t('offers.affiliateLink')}}</div>
        <div class="partner__content">{{ currentOffer.redirect_link }}</div>

        <copy-link :link="currentOffer.redirect_link" />
      </div>

      <div
        v-if="offerOptions.source.code !== 'my_tracker' && offerOptions.source.code !== 'admitad'"
        class="partner__block-item"
      >
        <div class="partner__title">{{$t('offers.directLink')}} <Tooltip :text="$t('offers.directLinkTooltip')" /></div>
        <div class="partner__content">{{ currentOffer.direct_link }}</div>

        <copy-link :link="currentOffer.direct_link" />
      </div>
      
      <div
        v-if="offerOptions.source.code !== 'admitad'"
        class="partner__block-item"
      >
        <div class="partner__title">{{$t('offers.additionalSplittings')}} <Tooltip :text="$t('offers.additionalSplittingsTooltip')" /></div>
        <div class="partner__any">
          <Button
            variant="secondary-action"
            style="border-radius: 8px;"
            iconLeft="plus"
            @click="showCreateLink"
          >{{$t('offers.createSplit')}}</Button>
        </div>
      </div>
     </div>

    <modal v-model="showPopup" class="create-link-modal">
      <template slot="title">
        <template v-if="separatorNew">{{$t('offers.linkSuccessfullySaved')}}</template>
        <template v-else>{{$t('offers.additionalSplittings')}}</template>
      </template>
      <template slot="body-content" v-if="separatorNew">
        <div>
          <p style="margin-bottom: 20px;">{{$t('offers.allLinksAreStored')}}</p>
       
          <div style="margin-bottom: 20px;">
            <div class="partner__title">{{$t('offers.affiliateLink')}}</div>
            <div class="partner__content">{{ separatorNew.redirect_link }}</div>

            <copy-link :link="separatorNew.redirect_link" />
          </div>

          <div
            v-if="offerOptions.source.code !== 'my_tracker'"
          >
            <div class="partner__title">{{$t('offers.directLink')}}<Tooltip :text="$t('offers.directLinkTooltip')"/></div>
            <div class="partner__content">{{ separatorNew.direct_link }}</div>

            <copy-link :link="separatorNew.direct_link" />
          </div>
          
          <div class=button-group>
            <Button
              variant="primary-action"
              class="btn"
              @click="showCreateLink"
            >{{$t('offers.oku')}}</Button>
          </div>
        </div>
      </template>

      <template slot="body-content" v-else>
        <div>
          <p class="modal-text">{{$t('offers.additionalSplittingsTooltip')}}.</p>

          <FormGroup
            :label="$t('offers.linkName') + '*'"
            inline
          >
            <BaseInput 
              v-model="linkTitle"
            />
          </FormGroup>

          <FormGroup
            :label="$t('offers.valueOfSplit') + '*'"
            :description="$t('offers.valueOfSplitDesc') + '.'"
            inline
          >
            <BaseInput 
              v-model="linkValue"
            />
          </FormGroup>

          <div class=button-group>
            <Button
              variant="primary-action"
              class="btn"
              @click="createSeparator"
              :disabled="!linkTitle || !linkValue"
            >{{$t('offers.create')}}</Button>

            <Button
              variant="secondary-action"
              class="btn"
              style="margin-left: 14px"
              @click="showCreateLink"
            >{{$t('offers.undo')}}</Button>
          </div>
        </div>
      </template>
    </modal>
  </div>
</template>


<script>
import BaseButton from '@components/BaseComponents/BaseButton'
import Modal from '@components/BaseComponents/Modal'
import FormGroup from '@components/BaseComponents/Form/FormGroup'
import BaseInput from '@components/BaseComponents/Form/BaseInput'
import { copyToClipboard } from '@utils/utils'
import Tooltip from './Tooltip'
import CopyLink from './CopyLink'

export default {

  name: 'DetailsPartnerInfo',

  components: {
    Button: BaseButton,
    Modal: Modal,
    FormGroup: FormGroup,
    BaseInput: BaseInput,
    Tooltip,
    CopyLink,
  },

  props: {
    landingOptions: {
      type: Array,
      default: () => [],
    },
    currentOffer: {
      type: Object,
      default: null,
    },
    offerOptions: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      showPopup: false,

      linkTitle: null,
      linkValue: null,
    }
  },

  watch: {
    showPopup (newValue) {
      if (!newValue && this.separatorNew) {
        this.clearSeparatorNew()
      }

      this.linkTitle = null
      this.linkValue = null
    },
  },

   methods: {
    ...mapActions('offers/details', ['separateLink', 'clearSeparatorNew']),

    showCreateLink() {
      this.showPopup = !this.showPopup
    },

    createSeparator() {
      const
        parent = this.currentOffer.id,
        title = this.linkTitle,
        value = this.linkValue
      
      this.separateLink({ parent, title, value })
    },

    clearModal() {
      console.log('clear')
    },
  },

  computed: {
    ...mapGetters('offers/details', [
      'separatorLoading',
      'separatorNew',
    ]),
  },

}
</script>


<style scoped lang="scss">
.partner__block {
  position: relative;
  background-color: #262626;
  border-radius: 5px;
  padding: 10px;
  font-size: 14px;
  cursor: default;
  margin: 20px 0;

  @media (min-width: 720px) {
    max-width: 600px;
  }
}

.partner__block-item {
  padding: 10px;
}

.partner__title {
  margin-bottom: 10px;
}

.partner__content {
  color: #838383;
  overflow-wrap: anywhere;
}

.create-link-modal {
  max-width: 600px;
  margin: auto;
}

.button-group {
  margin: 40px 0 30px 0;
}

.btn {
  border-radius: 8px;
}

.modal-text {
  color: #838383;
  font-size: 12px;
  margin-bottom: 20px;
}
.link {
  margin: 10px 0 0 0;
}
</style>