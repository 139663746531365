import { defineStore } from 'pinia'

export const usePlatformsStore = defineStore('platforms', {
  state: () => ({
    platformsModalOpend: false,
    deletePlatformModalOpend: false,
    platformId: null
  }),
  actions: {
    async fetchPlatforms() {
      const { data } = await api2.get('/ordplatform')
      return data
    },

    async fetchOfferPlatforms(id) {
      const { data } = await api.get(`/advuser/${id}/platforms`)
      return data
    },

    savePlatfrom(platform, id) {
      return id ? api2.put(`/ordplatform/${id}`, platform) : api2.post('/ordplatform', platform)
    },

    async fetchPlatform(id) {
      const { data } = await api2.get(`/ordplatform/${id}`)
      return data
    },

    deletePlatform(id) {
      return api2.delete(`/ordplatform/${id}`)
    },

    connectPlatforms(userOfferId, platformId) {
      return api2.post(`/ordadvuserplatform`, { adv_user_id: userOfferId,  ord_platform: platformId })
    },

    disconnectPlatforms(platformId) {
      return api2.delete(`/ordadvuserplatform/${platformId}`)
    }
  },
})