<style lang="scss" scoped>
  .merchandise-page {
    .payouts-info {
      .info-box {
        max-width: 800px;
      }

      .payouts-info-pixel {
        position: absolute;
        top: -10000px;
        left: -10000px;
        width: 0;
        height: 0;
      }
    }
  }
</style>

<template>
  <div class="merchandise-page">
    <transition name="fade-up">
      <div v-show="!loadingState.page" ref="legacy"/>
    </transition>
  </div>
</template>

<script>
import { loadLegacyPage } from '@src/services/legacyLoader'
import { i18n } from '@src/localization/config'

export default {
  name: 'MerchandisePage',
  data() {
    return {
      loadingState: {
        page: false,
      },
    }
  },
  metaInfo() {
    return {
      title: this.$t('dashboard.pages.merchandise'),
    }
  },
  mounted() {
    this.loadingState.page = true

    loadLegacyPage('/dashboard/old-page-merchandise', this.$refs.legacy)
      .finally(() => {
        this.loadingState.page = false
      })
  },
  computed: {
    ...mapState('application', ['isAstrumApp']),
  },
}
</script>
