import { render, staticRenderFns } from "./RouletteBackgroundSettings.vue?vue&type=template&id=8dba0a0c&scoped=true"
import script from "./RouletteBackgroundSettings.vue?vue&type=script&lang=js"
export * from "./RouletteBackgroundSettings.vue?vue&type=script&lang=js"
import style0 from "./RouletteBackgroundSettings.vue?vue&type=style&index=0&id=8dba0a0c&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8dba0a0c",
  null
  
)

export default component.exports