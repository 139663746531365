<style scoped lang="scss">
  .category-form {
    padding: 15px 20px;
    background-color: rgba($additional-3, .5);
    border-radius: 5px;

    .category-form-header {
      display: flex;
      align-items: center;

      .header-title {
        font-size: 20px;
      }

      .settings-close-button {
        margin-left: auto;
        width: 30px;
        height: 30px;
        font-size: 30px;
        line-height: 1;

        color: rgba(white, .3);
        cursor: pointer;

        &:hover {
          color: rgba(white, .6);
        }

        &:active {
          color: rgba(white, .9);
        }
      }
    }

    .category-form-body {
      margin-top: 10px;

      .settings-content {
        margin-top: 20px;
      }
    }

    .category-form-controls {
      margin-top: 20px;
      display: flex;
      align-items: center;

      .button + .button {
        margin-left: 20px;
      }

      .button + .category-remove-button {
        margin-left: auto;
      }
    }

    .debug-tab {
      .debug-box {
        width: 100%;
        padding: 10px 15px;
        border-radius: 5px;
        border: 1px solid rgba(white, .2);
      }
    }
  }
</style>

<template>
  <div class="category-form">

    <div class="category-form-header">
      <div class="header-title">
        <template v-if="category">{{ $t('roulette.category.editCategory') }}
        </template>
        <template v-else>{{ $t('roulette.category.createCategory') }}</template>
      </div>
      <div class="settings-close-button" @click="close">
        <icon name="cross"/>
      </div>
    </div>

    <div class="category-form-body">
      <tabs v-model="currentTab" :list="tabs"/>
      <div class="settings-content">
        <div v-if="currentTab === 'category'" class="category-data-tab">
          <category-settings
            :errors="errors"
            :category-id="categoryId"
            v-model="categoryData"
            @visual-settings-import="importVisualSettings"/>
        </div>

        <div v-if="currentTab === 'visual'" class="visual-settings-tab">
          <category-visual-settings
            v-model="categorySettings"
            :with-text="categoryData.useCards"/>
        </div>

        <div v-if="currentTab === 'presets'" class="presets-tab">
          <category-presets @select="setPresetSettings"/>
        </div>

        <div v-if="currentTab === 'debug'" class="debug-tab">
          <pre class="debug-box" ref="debug-box" @click="selectCopyDebug">{{ debugOutput }}</pre>
        </div>
      </div>
    </div>
    <template v-if="controlsVisible">
      <hr>
      <div class="category-form-controls">
        <btn
          variant="primary-action"
          icon-left="check"
          :disabled="actionsDisabled"
          :is-loading="loadingState.save"
          @click="save">{{ $t('global.common.save') }}
        </btn>
        <btn
          variant="secondary-action"
          :disabled="actionsDisabled"
          @click="close">{{ $t('global.common.cancel') }}
        </btn>
        <btn
          v-if="category"
          variant="secondary-action"
          class="category-remove-button"
          icon-left="trash"
          :disabled="actionsDisabled"
          :is-loading="loadingState.remove"
          @click="remove">{{ $t('roulette.category.deleteCategory') }}
        </btn>
      </div>
    </template>
  </div>
</template>

<script>

import CategorySettings from './CategorySettings'
import CategoryVisualSettings from './CategoryVisualSettings/CategoryVisualSettings'
import CategoryPresets from './CategoryPresets'

import { copyToClipboard } from '@utils/utils'

export default {
  name: 'CategoryForm',
  components: {
    CategorySettings,
    CategoryPresets,
    CategoryVisualSettings,
  },
  props: {
    value: {
      type: Boolean,
    },
    categoryId: {
      type: [Number, String],
      default: null,
    },
  },
  data() {
    return {
      loadingState: {
        save: false,
        remove: false,
      },

      currentTab: 'category',

      categoryData: {},
      categorySettings: {},
      categoryPresetId: null,

      categorySelectModalOpened: false,

      errors: [],
    }
  },
  computed: {
    ...mapGetters('roulette', ['getCategory', 'getCategoriesList']),
    ...mapGetters('widgets/presets', ['getDefaultPresetByType']),

    controlsVisible() {
      return ['category', 'visual'].includes(this.currentTab)
    },

    actionsDisabled() {
      return this.loadingState.save || this.loadingState.remove
    },

    tabs() {
      const tabs = [
        {
          key: 'category',
          title: 'category',
          icon: 'pencil',
        },
        {
          key: 'visual',
          title: 'cardDesign',
          icon: 'eye',
        },
        {
          key: 'presets',
          title: 'presets',
          icon: 'list',
        },
      ].map(tab => ({
        ...tab,
        title: this.$t(`roulette.category.tabs.${tab.title}`),
      }))

      if (process.env.NODE_ENV !== 'production') {
        tabs.push({
          key: 'debug',
          title: this.$t('global.common.debug'),
        })
      }

      return tabs
    },

    debugOutput() {
      return {
        ...this.categoryData,
        settings: this.categorySettings,
      }
    },

    category() {
      return this.getCategory(this.categoryId)
    },
  },
  methods: {
    ...mapActions('roulette', ['createCategory', 'updateCategory', 'deleteCategory']),

    selectCopyDebug() {
      window.getSelection().selectAllChildren(this.$refs['debug-box'])
      copyToClipboard(JSON.stringify(this.debugOutput))
    },

    importVisualSettings(targetCategoryId) {
      const targetCategory = this.getCategory(targetCategoryId)

      this.categorySettings = _.cloneDeep(targetCategory.settings) ?? {}

      this.currentTab = 'visual'
    },

    setPresetSettings(data) {
      const { settings, useCards, presetId } = data

      this.categorySettings = _.cloneDeep(settings)
      this.$set(this.categoryData, 'useCards', useCards)

      this.categoryPresetId = presetId

      this.currentTab = 'visual'
    },

    close() {
      if (!this.actionsDisabled) {
        this.$emit('close')
      }
    },

    remove() {
      this.loadingState.remove = true

      this.deleteCategory(this.categoryId)
        .then(() => {
          this.$emit('close')
        })
        .finally(() => {
          this.loadingState.remove = false
        })
    },

    save() {
      this.loadingState.save = true

      const data = {
        ...this.categoryData,
        settings: this.categorySettings,
        presetId: this.categoryPresetId,
      }

      let promise

      if (this.category) {
        promise = this.updateCategory({
          id: this.categoryId,
          data,
        })
      } else {
        promise = this.createCategory(data)
      }

      promise
        .then(() => {
          this.$emit('close')
        })
        .catch(({ errors }) => {
          this.errors = errors
          this.currentTab = 'category'
        })
        .finally(() => {
          this.loadingState.save = false
        })
    },

    reset() {
      const { json, id } = this.getDefaultPresetByType('roulette-category')

      if (this.category) {
        const {
          name,
          useCards,
          cards,
          enabled,
          rarity,
          settings,
          presetId,
        } = this.category

        this.categoryData = {
          name,
          useCards,
          cards,
          enabled,
          rarity,
        }

        this.categorySettings = _.cloneDeep(settings)
        this.categoryPresetId = presetId ?? id
      } else {
        this.categoryData = {}
        this.categoryPresetId = id

        this.categorySettings = json
      }
    },
  },
  watch: {
    category: {
      handler: 'reset',
      immediate: true,
    },
  },
}
</script>
