<style scoped lang="scss">
  .goal-design-settings {
    .settings-form {
      margin-top: 20px;
      width: 500px;

      @include media(max, md) {
        width: 100%;
      }
    }

    .settings-controls {
      margin-top: 40px;
      display: flex;
      align-items: center;

      .button + .button {
        margin-left: 20px;
      }
    }
  }
</style>

<template>
  <div class="goal-design-settings">
    <tabs v-model="currentTab" :list="tabs"/>
    <div class="settings-form">
      <elements-settings-form v-show="currentTab === 'elements'"/>
      <indicator-settings-form v-show="currentTab === 'indicator'"/>
      <fonts-settings-form v-show="currentTab === 'fonts'"/>
    </div>
    <div class="settings-controls">
      <btn
        :is-loading="loadingState.save"
        variant="primary-action"
        icon-left="check"
        @click="save">{{$t('goals.designForm.buttonSave')}}
      </btn>
    </div>
  </div>
</template>

<script>
import Tabs from '@components/BaseComponents/Tabs/Tabs'
import IndicatorSettingsForm from './IndicatorSettingsForm'
import ElementsSettingsForm from '@components/Dashboard/GoalSettings/DesignSettings/ElementsSettingsForm'
import FontsSettingsForm from '@components/Dashboard/GoalSettings/DesignSettings/FontsSettingsForm'

export default {
  name: 'GoalDesignSettings',
  components: {
    FontsSettingsForm,
    ElementsSettingsForm,
    IndicatorSettingsForm,
    Tabs,
  },
  data() {
    return {
      currentTab: 'elements',
      tabsData: [
        {
          key: 'elements',
          icon: 'gear',
          title: 'Elements settings',
        },
        {
          key: 'indicator',
          icon: 'progress',
          title: 'Progress bar design',
        },
        {
          key: 'fonts',
          icon: 'font',
          title: 'Font settings',
        },
      ],

      loadingState: {
        save: false,
      },
    }
  },
  methods: {
    ...mapActions('goal/presets', ['updateUserSettings']),

    save() {
      this.loadingState.save = true

      this.updateUserSettings()
        .finally(() => {
          this.loadingState.save = false
        })
    },
  },
  computed: {
    tabs() {
      return this.tabsData.map(tab => ({
        ...tab,
        title: this.$t(`goals.designForm.tabs.${tab.key}`),
      }))
    },
  },
}
</script>
