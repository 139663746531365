<style scoped lang="scss">
  .widget-roulette-goal {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    @include padding-x(50px);

    .goal-body {
      padding: 0 10px 10px 10px;
      width: 100%;
      position: relative;

      .goal-background {
        @include position-all(absolute, 0);
        z-index: 1;
      }

      .goal-frame {
        @include position-all(absolute, 0);
        z-index: 7;
      }

      .goal-content {
        position: relative;
        z-index: 2;
      }

      .goal-title {
        width: 100%;
        text-align: center;
        word-break: break-word;
      }

      .goal-progress {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        width: 100%;
        @include padding-x(10px);

        .goal-progress-frame {
          @include position-all(absolute, 0);
          z-index: 6;
        }

        .goal-progress-bar {
          overflow: hidden;
          @include position-all(absolute, 0);
          z-index: 5;
          border-style: solid;
          border-width: 0;
          background-clip: padding-box;

          .goal-progress-bar-filler {
            transition: right 2s;
            @include position-all(absolute, 0);
          }
        }

        .goal-progress-text {
          width: 100%;
          z-index: 6;
        }
      }
    }
  }

  .fade-rotate {
    &-enter-active, &-leave-active {
      transition: opacity .5s;
    }

    &-enter, &-leave-to {
      opacity: 0;
    }

    &-leave-active {
      position: absolute;
    }
  }
</style>

<template>
  <div class="widget-roulette-goal">
    <div v-if="goalSettings" class="goal-body" ref="goal-body">
      <div v-if="background" class="goal-background" :style="background.styles" :class="background.classes"/>
      <div v-if="frame" class="goal-frame">
        <svg-gradient
          v-if="frame.gradient"
          :name="frame.gradient.name"
          :colors="frame.gradient.colors"
          :angle="frame.gradient.angle"
          :hard="frame.gradient.hard"/>
        <svg class="goal-frame-svg" :style="frame.svgStyles">
          <rect
            :key="renderKey"
            :x="frame.rectStyles.x"
            :y="frame.rectStyles.y"
            :rx="frame.rectStyles.rx"
            :ry="frame.rectStyles.ry"
            :stroke="frame.rectStyles.stroke"
            :stroke-width="frame.rectStyles.strokeWidth"
            :width="frame.rectStyles.width"
            :height="frame.rectStyles.height"/>
        </svg>
      </div>
      <div class="goal-content">

        <div class="goal-title" :style="goalTitle.styles">
          <div v-if="rollingState">
            {{ rollingTitle }}
          </div>
          <div v-else-if="discountVisible">
            {{ discountText }}
          </div>
          <div v-else>{{ goalDefaultTitle }}</div>
        </div>

        <div v-if="indicatorWrapper" ref="goal-progress" class="goal-progress" :style="indicatorWrapper.styles">
          <div v-if="indicatorFrame" class="goal-progress-frame" ref="goal-progress-frame">
            <svg-gradient
              v-if="indicatorFrame.gradient"
              :name="indicatorFrame.gradient.name"
              :colors="indicatorFrame.gradient.colors"
              :angle="indicatorFrame.gradient.angle"
              :hard="indicatorFrame.gradient.hard"/>
            <svg
              class="goal-progress-frame-svg"
              :style="indicatorFrame.svgStyles">
              <rect
                :key="renderKey"
                :x="indicatorFrame.rectStyles.x"
                :y="indicatorFrame.rectStyles.y"
                :rx="indicatorFrame.rectStyles.rx"
                :ry="indicatorFrame.rectStyles.ry"
                :stroke="indicatorFrame.rectStyles.stroke"
                :stroke-width="indicatorFrame.rectStyles.strokeWidth"
                :width="indicatorFrame.rectStyles.width"
                :height="indicatorFrame.rectStyles.height"/>
            </svg>
          </div>
          <div v-if="indicatorBackground" class="goal-progress-bar" :style="indicatorBackground.styles">
            <div class="goal-progress-bar-filler" :style="indicatorFiller.styles"/>
          </div>

          <div class="goal-progress-text" :style="progressText.styles">
            <div v-if="rollingState">
              {{ rollingText }}
            </div>
            <div v-else-if="discountVisible">
              <time-left :to="discountEndsAt"/>
            </div>
            <div v-else>
              {{ raisedAmount }} / {{ goalAmount }} {{ currency }}
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>

import TimeLeft from './TimeLeft'
import SvgGradient from '@components/BaseComponents/SvgGradient'
import {
  createCssFilters,
  createGradientString,
  numbersToUnits,
  parseGradientColor,
} from '@utils/utils'

const getInRange = (value, range) => {
  return value < range[0] ? range[0] : (value > range[1] ? range[1] : value)
}

export default {
  name: 'WidgetRouletteGoal',
  components: {
    SvgGradient,
    TimeLeft,
  },
  props: {
    goalData: {
      type: Object,
      required: true,
    },
    goalSettings: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      discountRotationInterval: null,
      discountVisible: false,

      discountActive: false,
      discount: 50,
      discountEndsAt: null,
      raisedAmount: 0,
      goalAmount: 500,

      rollingTitle: null,
      rollingText: null,
      rollingState: null,

      renderKey: 1,
    }
  },
  computed: {
    ...mapGetters('profile', ['getUserCurrency']),

    currency() {
      return this.getUserCurrency
    },

    goalDefaultTitle() {
      const {
        goalTitle,
      } = this.goalSettings.textSettings

      return goalTitle && goalTitle.length ? goalTitle : this.$t('roulette.goalWidget.untilRoulette')
    },

    discountText() {
      return this.$t('roulette.goalWidget.discountText').replace('{discount}', this.discount)
    },

    progressPercentage() {
      if (this.rollingState) {
        return 100
      }

      let percentage = this.raisedAmount / this.goalAmount * 100

      return percentage < 1 ? (percentage > 0 ? 1 : 0) : Math.round(percentage)
    },

    backgroundBorderRadius() {
      const _renderKey = this.renderKey

      if (!_.get(this.goalSettings, 'backgroundSettings', null)) {
        return null
      }

      const {
        borderRadius,
      } = this.goalSettings.backgroundSettings

      return this.calculateBorderRadius(this.$refs['goal-body'], borderRadius)
    },

    background() {
      if (!_.get(this.goalSettings, 'backgroundSettings', null)) {
        return null
      }

      const {
        backgroundColor = {},
        backgroundShadow,
      } = this.goalSettings.backgroundSettings

      const {
        color = null,
        gradient = {},
        useGradient = false,
      } = backgroundColor
      const {
        colors = [],
        angle = 0,
        hard = false,
      } = gradient

      const styles = {
        borderRadius: `${this.backgroundBorderRadius}px`,
        filter: createCssFilters({
          shadow: backgroundShadow,
        }),
      }

      if (useGradient) {
        styles.backgroundImage = `linear-gradient(${angle}deg, ${createGradientString(colors, hard)})`
      } else {
        styles.backgroundColor = color
      }

      return {
        styles,
        classes: {},
      }
    },

    frame() {
      if (!_.get(this.goalSettings, 'backgroundSettings', null)) {
        return null
      }

      const {
        borderWidth,
        borderColor = {},
        borderShadow,
      } = this.goalSettings.backgroundSettings

      const {
        color = null,
        gradient = {},
        useGradient = false,
      } = borderColor
      const {
        colors = [],
        angle = 0,
        hard = false,
      } = gradient

      const gradientName = `_goal_frame_${this.$componentId}`
      const gradientLink = `url(#${gradientName})`

      const frame = {
        svgStyles: {
          marginTop: `-${borderWidth / 2}px`,
          marginLeft: `-${borderWidth / 2}px`,
          width: `calc(100% + ${borderWidth}px)`,
          height: `calc(100% + ${borderWidth}px)`,
          fill: 'transparent',
          position: 'absolute',
          filter: createCssFilters({
            shadow: borderShadow,
          }),
        },
        rectStyles: {
          width: `calc(100% - ${borderWidth}px)`,
          height: `calc(100% - ${borderWidth}px)`,
          x: borderWidth / 2,
          y: borderWidth / 2,
          ry: this.backgroundBorderRadius,
          rx: this.backgroundBorderRadius,
          stroke: useGradient ? gradientLink : color,
          strokeWidth: borderWidth,
          fill: 'transparent',
          position: 'absolute',
          filter: createCssFilters({
            shadow: borderShadow,
          }),
        },
      }

      if (useGradient) {
        frame.gradient = {
          name: gradientName,
          colors,
          angle,
          hard,
        }
      }

      return frame
    },

    indicatorBorderRadius() {
      const _renderKey = this.renderKey

      if (!_.get(this.goalSettings, 'indicatorSettings', null)) {
        return null
      }

      const {
        indicatorBorderRadius = 0,
      } = this.goalSettings.indicatorSettings

      return this.calculateBorderRadius(this.$refs['goal-progress'], indicatorBorderRadius)
    },

    indicatorWrapper() {
      if (!_.get(this.goalSettings, 'indicatorSettings', null)) {
        return null
      }

      const {
        indicatorShadow,
      } = this.goalSettings.indicatorSettings

      return {
        styles: {
          filter: createCssFilters({
            shadow: indicatorShadow,
          }),
        },
      }
    },

    indicatorBackground() {
      if (!_.get(this.goalSettings, 'indicatorSettings', null)) {
        return null
      }

      const {
        indicatorBackgroundColor = {},
      } = this.goalSettings.indicatorSettings

      const {
        color = null,
        gradient = {},
        useGradient = false,
      } = indicatorBackgroundColor
      const {
        colors = [],
        angle = 0,
        hard = false,
      } = gradient

      return {
        styles: {
          borderRadius: `${this.indicatorBorderRadius}px`,
          backgroundImage: useGradient
            ? `linear-gradient(${angle}deg, ${createGradientString(colors, hard)})`
            : null,
          backgroundColor: useGradient ? null : color,
        },
      }
    },

    indicatorFiller() {
      if (!_.get(this.goalSettings, 'indicatorSettings', null)) {
        return null
      }

      const {
        indicatorFillerColor = {},
      } = this.goalSettings.indicatorSettings

      const {
        color = null,
        gradient = {},
        useGradient = false,
      } = indicatorFillerColor
      const {
        colors = [],
        angle = 0,
        hard = false,
      } = gradient

      return {
        styles: {
          backgroundImage: useGradient
            ? `linear-gradient(${angle}deg, ${createGradientString(colors, hard)})`
            : null,
          backgroundColor: useGradient ? null : color,
          right: `${100 - this.progressPercentage}%`,
        },
      }
    },

    indicatorFrame() {
      if (!_.get(this.goalSettings, 'indicatorSettings', null)) {
        return null
      }

      const {
        indicatorBorderColor = {},
        indicatorBorderWidth = 0,
      } = this.goalSettings.indicatorSettings

      const {
        color = null,
        gradient = {},
        useGradient = false,
      } = indicatorBorderColor
      const {
        colors = [],
        angle = 0,
        hard = false,
      } = gradient

      const gradientName = `_goal_indicator_frame_${this.$componentId}`
      const gradientLink = `url(#${gradientName})`

      const frame = {
        svgStyles: {
          marginTop: `-${indicatorBorderWidth / 2}px`,
          marginLeft: `-${indicatorBorderWidth / 2}px`,
          width: `calc(100% + ${indicatorBorderWidth}px)`,
          height: `calc(100% + ${indicatorBorderWidth}px)`,
          fill: 'transparent',
          position: 'absolute',
        },
        rectStyles: {
          width: `calc(100% - ${indicatorBorderWidth}px)`,
          height: `calc(100% - ${indicatorBorderWidth}px)`,
          x: indicatorBorderWidth / 2,
          y: indicatorBorderWidth / 2,
          ry: this.indicatorBorderRadius,
          rx: this.indicatorBorderRadius,
          stroke: useGradient ? gradientLink : color,
          strokeWidth: indicatorBorderWidth,
          fill: 'transparent',
          position: 'absolute',
        },
      }

      if (useGradient) {
        frame.gradient = {
          name: gradientName,
          colors,
          angle,
          hard,
        }
      }

      return frame
    },

    goalTitle() {
      if (!_.get(this.goalSettings, 'textSettings', null)) {
        return null
      }

      const {
        titleTextStyles,
      } = this.goalSettings.textSettings

      const {
        strokeWidth,
        strokeColor,
      } = titleTextStyles

      return {
        styles: {
          ...numbersToUnits(titleTextStyles, 'px'),
          '-webkit-text-stroke': strokeWidth ? `${strokeWidth}px ${strokeColor}` : null,
          textStroke: strokeWidth ? `${strokeWidth}px ${strokeColor}` : null,
          fontFamily: `"${titleTextStyles.fontFamily}"`,
        },
      }
    },

    progressText() {
      if (!_.get(this.goalSettings, 'textSettings', null)) {
        return null
      }

      const {
        contentTextStyles,
      } = this.goalSettings.textSettings

      const {
        strokeWidth,
        strokeColor,
      } = contentTextStyles

      return {
        styles: {
          ...numbersToUnits(contentTextStyles, 'px'),
          '-webkit-text-stroke': strokeWidth ? `${strokeWidth}px ${strokeColor}` : null,
          textStroke: strokeWidth ? `${strokeWidth}px ${strokeColor}` : null,
          fontFamily: `"${contentTextStyles.fontFamily}"`,
        },
      }
    },
  },
  methods: {
    calculateBorderRadius(el, borderRadius) {
      let finalBorderRadius = borderRadius

      if (el && el.getBoundingClientRect) {
        const {
          width: elWidth,
          height: elHeight,
        } = el.getBoundingClientRect()
        finalBorderRadius = _.min([elWidth / 2, elHeight / 2]) * (borderRadius / 100)
      }

      return Math.floor(finalBorderRadius)
    },

    generateRenderKey() {
      this.$nextTick(() => {
        this.renderKey = this.$timestamp + _.random(1, 100000)
      })
    },
  },
  created() {
    this.generateRenderKey()
  },
  mounted() {
    this.generateRenderKey()
  },
  watch: {
    $appWidth: 'generateRenderKey',
    $appHeight: 'generateRenderKey',
    goalData: {
      handler(value) {
        if (value) {
          Object.keys(value).forEach(key => {
            this[key] = value[key]
          })
        }
        this.generateRenderKey()
      },
      immediate: true,
    },
    goalSettings: {
      handler(value) {
        this.generateRenderKey()
      },
      immediate: true,
    },
    discountVisible: 'generateRenderKey',
    discountActive: {
      handler(value) {
        this.discountVisible = false
        clearInterval(this.discountRotationInterval)

        if (value) {
          this.discountRotationInterval = setInterval(() => {
            this.discountVisible = !this.discountVisible
          }, 5000)
        }
      },
      immediate: true,
    },
  },
}
</script>
