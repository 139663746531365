<template>
  <nav
    class="tabs"
    :class="{ 'tabs_disabled': disabled }"
  >
    <div class="tabs__wrapper">
      <router-link
        v-for="navItem in navItems"
        :key="navItem.path"
        class="tabs__item"
        :to="navItem.to"
      >
        <icon :name="navItem.icon"/>
        <span>{{ $t(navItem.title) }}</span>
      </router-link>
    </div>
  </nav>
</template>

<script>
export default {

  name: 'NavBar',

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapState('dashboard', ['newDashboard']),

    navItems() {
      const makeRoute = route => this.newDashboard ? {
        name: `dashboard-chaostricks-${route}`,
      } : {
        path: `/dashboard/chaostricks/${route}`,
      }

      return [
        {
          to: makeRoute('settings'),
          title: 'dashboard.pages.bft.settings',
          icon: 'gear',
        },
        {
          to: makeRoute('statistics'),
          title: 'dashboard.pages.bft.statistics',
          icon: 'statistics',
        },
        {
          to: makeRoute('history'),
          title: 'dashboard.pages.bft.history',
          icon: 'statistics',
        },
        {
          to: makeRoute('widget'),
          title: 'dashboard.pages.bft.widget',
          icon: 'widgets',
        },
      ]
    },
  },

}

</script>

<style scoped lang="scss">
.tabs {
  margin-bottom: 20px;
  overflow: hidden;

  @media (min-width: 720px) {
    border-bottom: 1px solid #353535;
    overflow: initial;
  }

  &_disabled {
    opacity: .5;
    pointer-events: none;
  }

  &__wrapper {
    overflow-x: auto;
    overflow-y: hidden;
    margin-bottom: -20px;
    padding-bottom: 20px;
    display: flex;
    justify-content: flex-start;
  }

  &__item {
    display: block;
    text-transform: uppercase;
    text-decoration: none;;
    font-size: 14px;
    font-weight: 600;
    line-height: 1;
    padding: 15px 0;
    position: relative;
    margin: 0 20px;
    color: #f1f1f1;
    user-select: none;
    white-space: nowrap;
    cursor: pointer;
    transition: color .2s;

    &:after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      width: 100%;
      bottom: -1px;
      height: 2px;
      background-image: linear-gradient(255deg, var(--theme-color-main), var(--theme-color-additional));
      transform-origin: 100% 0;
      transform: scaleY(0);
      transition: transform .2s;
    }

    &:hover {
      color: var(--theme-color-main);
    }

    &.router-link-active {
      color: #f1f1f1;
      cursor: default;
      pointer-events: none;

      &:after {
        transform: scaleY(1);
      }
    }

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    & > svg {
      margin-right: 10px;
      vertical-align: middle;
      fill: url(#theme_svg_gradient);
    }

    & > span {
      vertical-align: middle;
    }
  }
}
</style>
