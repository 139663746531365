<style scoped lang="scss">
  .widget-roulette-pin {
    display: flex;
    align-items: center;
    justify-content: center;

    .roulette-pin-image {
      max-width: 100%;
      max-height: 100%;
    }

    .roulette-pin-video {
      max-width: 100%;
      max-height: 100%;
    }

    .roulette-pin-svg {
      width: 100%;
      height: 100%;
    }
  }
</style>

<template>
  <div v-if="pin" class="widget-roulette-pin">
    <template v-if="pin.type === 'svg' && pin.svg">
      <svg-gradient
        v-if="pin.gradient"
        :name="pin.gradient.name"
        :colors="pin.gradient.colors"
        :angle="pin.gradient.angle"
        :hard="pin.gradient.hard"/>
      <svg-image-external
        :key="pin.svg.src"
        class="roulette-pin-svg"
        :src="pin.svg.src"
        :style="pin.svg.styles"/>
    </template>
    <template v-if="pin.type === 'image' && pin.image">
      <img
        v-if="pin.image.type === 'image'"
        class="roulette-pin-image"
        :src="pin.image.src"
        :style="pin.image.styles"/>
      <video
        v-if="pin.image.type === 'video'"
        class="roulette-pin-video"
        autoplay
        loop
        :src="pin.image.src"
        :style="pin.image.styles"/>
    </template>
  </div>
</template>

<script>

import SvgGradient from '@components/BaseComponents/SvgGradient'
import { createCssFilters } from '@utils/utils'
import SvgImageExternal from '@components/BaseComponents/SvgImageExternal'

export default {
  name: 'WidgetRoulettePin',
  components: {
    SvgImageExternal,
    SvgGradient,
  },
  props: {
    settings: {
      type: Object,
      default: null,
    },
  },
  computed: {
    ...mapGetters('fileManager', ['getFile']),

    pinImage() {
      const {
        pinImage,
      } = this.settings

      this.fetchFile(pinImage)

      return this.getFile(pinImage)
    },

    pinSvg() {
      const {
        pinSvg,
      } = this.settings

      this.fetchFile(pinSvg)

      return this.getFile(pinSvg)
    },

    pin() {
      if (!_.get(this.settings, 'pinType', null)) {
        return null
      }

      const {
        pinType,
        pinImageHue,
        pinImageSaturate,
        pinSvgColor = {},
        pinSvgStrokeWidth,
        pinSvgStrokeColor,
        pinShadow,
      } = this.settings

      const { color = null, gradient = {}, useGradient = false } = pinSvgColor
      const { colors = [], angle = 0, hard = false } = gradient

      const gradientName = `_roulette_pin_${this.$componentId}`
      const gradientLink = `url(#${gradientName})`

      const pin = {
        type: pinType,
      }

      if (pinType === 'image' && this.pinImage) {
        pin.image = {
          type: this.pinImage.type,
          src: `${this.pinImage.url}`,
          styles: {
            filter: createCssFilters({
              shadow: pinShadow,
              hue: pinImageHue,
              saturate: pinImageSaturate,
            }),
          },
        }
      }

      if (useGradient) {
        pin.gradient = {
          name: gradientName,
          link: gradientLink,
          colors,
          angle,
          hard,
        }
      }

      if (pinType === 'svg' && this.pinSvg) {
        pin.svg = {
          src: this.pinSvg.url,
          styles: {
            fill: useGradient ? gradientLink : color,
            strokeWidth: `${pinSvgStrokeWidth}px`,
            stroke: pinSvgStrokeColor,
            filter: createCssFilters({
              shadow: pinShadow,
            }),
          },
        }
      }

      return pin
    },
  },
}
</script>
