<style scoped lang="scss">
  .steos-voice-banner {
    position: relative;
    overflow: hidden;
    border-radius: 8px;
    background-color: #724FE3;
    user-select: none;

    container-type: inline-size;
    container-name: steos-banner;

    .banner-wrapper {
      height: 100px;

      @container steos-banner (max-width: 576px) {
        height: auto;
      }
    }

    .banner-bg {
      @include position-all(absolute, 0);
      display: flex;
      align-items: center;
      justify-content: center;

      .banner-bg-img {
        position: absolute;
        height: 100%;

        &:nth-child(1) {
          right: 0;
        }

        &:nth-child(2) {
          left: 0;
        }

        &:nth-child(3) {
          right: 0;
        }

        &:nth-child(4) {
        }
      }

      &:after {
        content: '';
        @include position-all(absolute, 0);
        background-image: appAsset('/images/banners/steos-voice/bg/noise.png');
      }
    }

    .banner-content {
      min-height: 100%;
      position: relative;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      @container steos-banner (max-width: 576px) {
        flex-direction: column;
        gap: 20px;
        padding-bottom: 20px;
      }

      .banner-hero {
        padding: 14px 0 0 16px;
        display: flex;
        flex-direction: column;
        gap: 11px;
        align-items: flex-start;

        .hero-text {
          font-size: 21px;
          font-weight: 700;
          line-height: 25px;
          font-family: Manrope;
          padding-right: 20px;
        }

        .hero-button {
          height: 34px;
          border-radius: 8px;
          background: #FFF;
          color: #7B4FE0;
          font-size: 13px;
          font-weight: 700;
          line-height: 20px;
          text-transform: uppercase;
          @include padding-x(12px);
          display: flex;
          align-items: center;
          cursor: pointer;
        }
      }

      .steos-voice-banner-player {
        align-self: center;
        position: absolute;
        right: 24px;
        width: 354px;

        @container steos-banner (max-width: 576px) {
          position: relative;
          align-self: center;
          right: auto;
        }

        @container steos-banner (min-width: 625px) and (max-width: 720px) {
          width: 256px;
        }

        @container steos-banner (min-width: 576.9px) and (max-width: 624px) {
          width: 160px;
        }

        @container steos-banner (max-width: 576.9px) {
          width: 304px;
        }
      }
    }

    &:not(.banner) {
      .banner-content {
        align-items: center;

        .demo-text {
          padding-left: 16px;
          max-width: 230px;
          font-size: 20px;
          font-weight: 700;
          font-family: Manrope;

          @container steos-banner (max-width: 576px) {
            padding-right: 16px;
            padding-top: 14px;
            max-width: 100%;
            font-size: 16px;
          }
        }

        .steos-voice-banner-player {
          @container steos-banner (min-width: 576.9px) and (max-width: 624px) {
            width: 256px;
          }
        }
      }
    }

    .hidden-player {
      display: none;
    }
  }
</style>

<template>
  <div class="steos-voice-banner" :class="{banner}">
    <div class="banner-wrapper">
      <div class="banner-bg">
        <img class="banner-bg-img" :src="$asAppAsset('/images/banners/steos-voice/bg/Vector 1.svg')"/>
        <img class="banner-bg-img" :src="$asAppAsset('/images/banners/steos-voice/bg/Vector 2.svg')"/>
        <img class="banner-bg-img" :src="$asAppAsset('/images/banners/steos-voice/bg/Vector 3.svg')"/>
        <img class="banner-bg-img" :src="$asAppAsset('/images/banners/steos-voice/bg/Vector 4.svg')"/>
      </div>
      <div class="banner-content">
        <div v-if="banner" class="banner-hero">
          <div class="hero-text">{{ $t('dashboard.banners.steosVoice.title') }}</div>
          <div class="hero-button" @click="showSteosVoiceModal">
            {{ $t('dashboard.banners.steosVoice.startUsing') }}
          </div>
        </div>
        <div v-else class="demo-text">{{ $t('dashboard.banners.steosVoice.titleModal') }}</div>
        <steos-voice-banner-player v-if="steosVoices.length" :voices="steosVoices"/>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted } from 'vue'
import { useSteosVoicesStore } from '@store/steosVoices'
import { storeToRefs } from 'pinia'
import { tmr } from '@src/plugins/tmr'
import { ga } from '@src/plugins/ga'

import SteosVoiceBannerPlayer from './SteosVoiceBannerPlayer.vue'

const steosVoicesStore = useSteosVoicesStore()

const { showSteosVoiceModal } = steosVoicesStore
const { steosVoices } = storeToRefs(steosVoicesStore)

const props = defineProps({
  banner: {
    type: Boolean,
    default: false,
  },
})

onMounted(() => {
  tmr.goal({ goal: 'BannerShow' }, {
    banner: 'steos_voice',
  })

  ga.event({
    eventCategory: 'Banner_steos_voice',
    eventAction: 'BannerShow',
  })
})

</script>
