<template>
  <modal v-model="modalOpened">
    <template slot="title">
      <template>{{$t('offers.advertisersAccount.users')}}</template>
    </template>
    <template slot="body-content">
        <loader
          size="md"
          v-if="isLoading"
        />

      <div v-else class="user-list">
        <div class="user-balance">
          <div class="user-balance__title">{{$t('offers.advertisersAccount.currentBalance')}}:</div>
          <div
             class="user-balance__item"
            v-for="balance in profile.balances"
            :key="balance.currency"
          >
            <span>{{ balance.adv_balance + ' ' + balance.currency }}</span>
          </div>
        </div>

        <data-grid>
          <data-grid-header>
            <data-grid-col>{{$t('offers.advertisersAccount.username')}}</data-grid-col>
            <data-grid-col>{{$t('offers.advertisersAccount.numberOfViewersOnline')}}</data-grid-col>
            <data-grid-col>{{$t('offers.advertisersAccount.costOfSendingMessage')}}</data-grid-col>
            <data-grid-col></data-grid-col>
          </data-grid-header>

          <data-grid-row
            v-for="user in users"
            :key="user.id"
          >
            <data-grid-col>
              <span v-if="user.user.stream" class="stream_on" />
              <span v-else class="stream_off" />
              <span>{{ user.user.name }}</span>
            </data-grid-col>
            <data-grid-col>{{ user.user.stream ? user.user.stream.platforms_combined.viewers : '–' }}</data-grid-col>
            <data-grid-col>{{ user.options.min_donation_amount + ' ' + user.options.min_donation_currency }}</data-grid-col>
            <data-grid-col>
              <base-button 
                variant="primary-action"
                class="send-button"
                @click="() => showMessageModal(user.id)"
                :disabled="!user.user.stream || user.user.stream.platforms_combined.viewers < 10"
              >{{$t('offers.send')}}</base-button>
            </data-grid-col>
          </data-grid-row>
        </data-grid>
      </div>
    </template>
  </modal>
</template>

<script>
import Modal from '@components/BaseComponents/Modal'
import DG from '@components/Dashboard/DataGrid'
import BaseButton from '@components/BaseComponents/BaseButton'

export default {
  components: {
    Modal,
    BaseButton,
    DataGrid: DG.DataGrid,
    DataGridHeader: DG.DataGridHeader,
    DataGridRow: DG.DataGridRow,
    DataGridCol: DG.DataGridCol,
  },

  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    offer: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {}
  },

  created() {
    this.fetchUsers(this.offer.id)
  },

  computed: {
    ...mapGetters('profile', {
      profile: 'getCurrentProfile',
    }),

    ...mapGetters('sendOffers/users', [
      'isLoading',
      'users',
    ]),

    modalOpened: {
      get() { return this.isShow },
      set(value) { return !value && this.undoClickHandler() },
    },
  },

  methods: {
    ...mapActions('sendOffers/users', ['fetchUsers']),

    undoClickHandler() {
      this.$emit('toggleModal') ;
    },

    showMessageModal(id) {
      this.$emit('showMessageModal', id) ;
    },
  },
}
</script>

<style scoped lang="scss">
.user-list {
  padding-bottom: 30px;
}

.stream_on,
.stream_off {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 5px;
}

.stream_on {
  background: #59A840;
}

.stream_off {
  background: #D63A3A;
}

.send-button {
  border-radius: 8px;
}

.user-balance {
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  &__item {
    background: rgba(255, 255, 255, 0.05);
    padding: 5px 10px;
    font-weight: bold;
    margin: 0 5px;
    border-radius: 8px;
  }
}
</style>