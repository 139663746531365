<style lang="scss" scoped>
  .colored-select {
  }
</style>

<template>
  <dropdown-select v-bind="$attrs" v-on="$listeners" :options="coloredOptions" class="colored-select"/>
</template>

<script>

export default {
  name: 'ColoredSelect',
  props: {
    options: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    coloredOptions() {
      return this.options.map(option => ({
        ...option,
        styles: {
          ...option.styles,
          color: option.color,
        },
      }))
    },
  },
}
</script>
