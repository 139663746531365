<style lang="scss" scoped>
  .font-select {
    text-rendering: geometricPrecision;
  }
</style>

<template>
  <dropdown-select v-bind="$attrs" v-on="$listeners" :options="fontsOptions" class="font-select"/>
</template>

<script>

export default {
  name: 'FontSelect',
  props: {
    fontStyle: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    ...mapGetters('application', ['getFonts']),

    fontsOptions() {
      return this.getFonts.map(font => ({
        label: font.family,
        value: font.family,
        styles: {
          ...this.fontStyle,
          fontFamily: `"${font.family}"`,
        },
      }))
    },
  },
}
</script>
