<style lang="scss" scoped>
  .color-palette {
    width: 220px;
    padding: 20px;
    background-color: $additional-2;
    border-radius: 3px;
    user-select: none;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);

    $toggle-size: 16px;

    .palette-sv {
      border-radius: 2px;
      height: 120px;
      background-image: linear-gradient(to top, #000, rgba(0, 0, 0, 0)), linear-gradient(to right, #fff, rgba(255, 255, 255, 0));
      position: relative;
      cursor: pointer;

      .saturation-value-toggle {
        @include position(absolute, 0, auto, auto, 0);
        height: 0;
        width: 0;

        &:after {
          content: '';
          @include position(absolute, -1px);
          height: $toggle-size;
          width: $toggle-size;
          margin-left: -$toggle-size/2;
          margin-top: -$toggle-size/2;
          border: 1px solid rgba(white, .4);
          border-radius: 50%;
          background: currentColor;
          box-shadow: 0 0 10px rgba(black, .3);
        }
      }
    }

    .palette-h {
      border-radius: 2px;
      margin-top: 15px;
      height: $toggle-size - 4px;
      position: relative;
      background-image: linear-gradient(90deg, red, yellow, lime, cyan, blue, magenta, red);
      cursor: pointer;

      .hue-toggle {
        @include position(absolute, 0, auto, auto, 0);
        height: 100%;
        width: 0;

        &:after {
          content: '';
          @include position(absolute, -2px);
          height: $toggle-size;
          width: $toggle-size;
          margin-left: -$toggle-size/2;
          border: 2px solid rgba(white, 1);
          border-radius: 50%;
          background: currentColor;
        }
      }
    }

    .palette-alpha {
      border-radius: 2px;
      margin-top: 15px;
      height: $toggle-size - 4px;
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAIAAADZF8uwAAAAGUlEQVQYV2M4gwH+YwCGIasIUwhT25BVBADtzYNYrHvv4gAAAABJRU5ErkJggg==);

      .alpha-range {
        height: 100%;
        position: relative;
        cursor: pointer;

        .alpha-toggle {
          @include position(absolute, 0, auto, auto, 0);
          height: 100%;
          width: 0;

          &:after {
            content: '';
            @include position(absolute, -2px);
            height: $toggle-size;
            width: $toggle-size;
            margin-left: -$toggle-size/2;
            border: 2px solid rgba(white, 1);
            border-radius: 50%;
          }
        }
      }
    }
  }
</style>

<template>
  <div
    class="color-palette"
    @mousedown.self="paletteDrag">
    <div
      ref="sv"
      class="palette-sv"
      :style="saturationValueStyles"
      @mousedown="onSVDrag">
      <div
        class="saturation-value-toggle"
        :style="saturationValueToggleStyles"></div>
    </div>
    <div
      ref="hue"
      class="palette-h"
      @mousedown="onHueDrag">
      <div
        class="hue-toggle"
        :style="hueToggleStyles"></div>
    </div>
    <div class="palette-alpha">
      <div
        ref="alpha"
        class="alpha-range"
        :style="alphaStyles"
        @mousedown="onAlphaDrag">
        <div
          class="alpha-toggle"
          :style="alphaToggleStyles"></div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  HSVtoRGB,
  RGBtoHSV,
  RGBToHEX,
  HEXToRGB,
  parseRGBA,
  isRGBString,
  isHEXString,
} from '@utils/colors'

const getInRange = (value, range) => {
  return value < range[0] ? range[0] : (value > range[1] ? range[1] : value)
}

export default {
  name: 'Calendar',
  props: {
    value: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      hue: null,
      saturation: null,
      lightness: null,

      alpha: null,
    }
  },
  watch: {
    value(color) {
      if (color !== this.outputColor) {
        this.setColor(color)
      }
    },
    outputColor(color) {
      this.$emit('input', color)
    },
  },
  mounted() {
    this.setColor('#ffffff')
    this.setColor(this.value)
  },
  computed: {
    rgbColor() {
      const {
        r, g, b,
      } = HSVtoRGB(this.hue, this.saturation, this.lightness)

      return [r, g, b]
    },

    hueToggleStyles() {
      const {
        r, g, b,
      } = HSVtoRGB(this.hue, 1, 1)

      const { width } = this.getRefBounds('hue')

      return {
        transform: `translateX(${this.hue * width}px)`,
        color: `rgb(${r}, ${g}, ${b})`,
      }
    },

    saturationValueStyles() {
      return {
        backgroundColor: this.hueToggleStyles.color,
      }
    },

    saturationValueToggleStyles() {
      const { width, height } = this.getRefBounds('sv')

      return {
        color: `rgb(${this.rgbColor.join(', ')})`,
        transform: `translate(${this.saturation * width}px, ${(1 - this.lightness) * height}px)`,
      }
    },

    alphaStyles() {
      const gradient = [
        `rgba(${this.rgbColor.join(', ')},1)`,
        'rgba(0,0,0,0)',
      ].join(', ')

      return {
        backgroundImage: `linear-gradient(to left, ${gradient})`,
      }
    },

    alphaToggleStyles() {
      const { width } = this.getRefBounds('alpha')

      return {
        transform: `translateX(${this.alpha * width}px)`,
      }
    },

    outputColor() {
      const color = [
        ...this.rgbColor,
      ]

      if (this.alpha !== null && this.alpha < 1) {
        color.push(this.alpha)

        return `rgba(${color.join(',')})`
      }

      return RGBToHEX(...color)
    },
  },
  methods: {
    setColor(color) {
      let rgba

      if (isRGBString(color)) {
        rgba = parseRGBA(color)
      } else if (isHEXString(color)) {
        rgba = HEXToRGB(color)
      } else {
        return this.$emit('input', this.outputColor)
      }

      const { r, g, b, a = 1 } = rgba
      const { h, s, v } = RGBtoHSV(...[r, g, b])

      this.hue = h
      this.saturation = s
      this.lightness = v
      this.alpha = a
    },

    getRefBounds(ref) {
      return !this.$refs[ref] ? {} : this.$refs[ref].getBoundingClientRect()
    },

    onDrag(callback) {
      document.onmouseup = () => {
        document.onmouseup = null
        document.onmousemove = null

        this.$emit('drag-stop')
      }

      if (callback) {
        document.onmousemove = callback
      }

      this.$emit('drag-start')
    },

    onHueDrag(event) {
      const callback = ({ x }) => {
        const { left, width } = this.$refs.hue.getBoundingClientRect()

        this.hue = getInRange((x - left) / width * 100 / 100, [0, 1])
      }
      callback(event)
      this.onDrag(callback)
    },

    onSVDrag(event) {
      const callback = ({ x, y }) => {
        const { left, top, width, height } = this.getRefBounds('sv')

        this.saturation = getInRange(Math.round((x - left) / width * 100) / 100, [0, 1])
        this.lightness = getInRange(1 - Math.round((y - top) / height * 100) / 100, [0, 1])
      }
      callback(event)
      this.onDrag(callback)
    },

    onAlphaDrag(event) {
      const callback = ({ x }) => {
        const { left, width } = this.getRefBounds('alpha')

        this.alpha = getInRange(Math.round((x - left) / width * 100) / 100, [0, 1])
      }
      callback(event)
      this.onDrag(callback)
    },

    paletteDrag() {
      this.onDrag()
    },
  },
}
</script>
