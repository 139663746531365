<script setup>
import { computed, ref } from 'vue'

import PlatformForm from './PlatformForm.vue'
import SuccessSavingPlatform from './SuccessSavingPlatform.vue'

const props = defineProps({
  value: {
    type: Boolean,
    default: false
  },
  hasNoPlatforms: {
    type: Boolean,
    default: false
  },
  platformId: {
    type: Number,
    default: null
  }
})

const emit = defineEmits(['input', 'save:success'])

const successSaving = ref(false)

const modalTitle = computed(() => props.platformId ? 'offers.platforms.editing' : 'offers.platforms.adding')

const onCloseModal = () => emit('input', false)
const onSaveSuccess = () => {
  successSaving.value = true
  emit('save:success')
}
</script>

<template>
  <modal :value="value" @input="onCloseModal">
    <template slot="title">
      {{ $t(modalTitle) }}
    </template>
    <template slot="body-content">
      <SuccessSavingPlatform v-if="successSaving" :platform-id="platformId" @close:modal="onCloseModal"/>
      <PlatformForm v-else :platform-id="platformId" @save:success="onSaveSuccess" @close:modal="onCloseModal" :has-no-platforms="hasNoPlatforms"/>
    </template>
  </modal>
</template>
