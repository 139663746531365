<template>
  <div class="tooltip"><div class="tooltip-text">{{ text }}</div>?</div>
</template>


<script>
export default {

  name: 'Tooltip',

  props: {
    text: {
      type: String,
      default: null,
    },
  },
  
}
</script>


<style scoped lang="scss">
.tooltip {
  display: inline-block;
  cursor: pointer;
  position: relative;
  background: #F1F1F1;
  color: #262626;
  line-height: 16px;
  width: 16px;
  text-align: center;
  border-radius: 50%;
  margin-left: 5px;
  opacity: .8;

  &-text {
    background: #191919;
    color: #F1F1F1;
    border-radius: 8px;
    padding: 5px 10px;
    pointer-events: none;
    position: absolute;
    font-size: 12px;
    line-height: 16px;
    bottom: 28px;
    left: 8px;
    transform: translateX(-50%);
    min-width: 260px;
    text-align: left;
    display: none;

    &:after {
      display: block;
      content: '';
      width: 0;
      height: 0;
      position: absolute;
      border-top: 8px solid #191919;
      border-bottom: 8px solid transparent;
      border-right: 8px solid transparent;
      border-left: 8px solid transparent;
      bottom: -16px;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &:hover {
    opacity: 1;

    .tooltip-text {
      display: block;
    }
  }
}
</style>