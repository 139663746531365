<template>
  <div class="data-grid">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'DataGrid'
}

</script>

<style scoped lang="scss">
.data-grid {
  width: 100%;
  font-size: 14px;
  line-height: 20px;
  color: #f1f1f1;

  @media (min-width: 720px) {
    display: table;
  }
}
</style>
