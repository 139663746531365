<style scoped lang="scss">
  .header-notifications-section {
    height: 40px;

    .nn-buttons {
      height: 100%;
      display: flex;
      align-items: center;
    }

    .nn-button {
      height: 100%;
      width: 40px;
      background-color: $additional-2;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      position: relative;

      .icon {
        font-size: 20px;
        color: white;
      }

      &.news {
        border-radius: 8px 0 0 8px;
      }

      &.notifications {
        margin-left: 1px;
        border-radius: 0 8px 8px 0;
      }

      &.unseen:before {
        content: '';
        display: block;
        position: absolute;
        top: 6px;
        right: 6px;
        width: 14px;
        height: 14px;
        border-radius: 8px;
        background-color: var(--theme-color-additional);
        border: 3px solid $additional-2;
      }

      &:hover, &.active {
        background-color: $additional-1;

        &:before {
          border-color: $additional-1;
        }
      }
    }

    .nn-popover {
      position: fixed;
      z-index: 20;
      top: $dashboard-header-height;
      right: 50%;
      bottom: 0;
      transform: translateX(map-get($container, max) / 2 - $gutter);
      background-color: $additional-3;
      padding: 16px 16px 0 16px;
      width: 450px;
      filter: drop-shadow(0 6px 10px rgba(0, 0, 0, 0.1));
      border-radius: 0;
      margin-top: 1px;

      @include media(max, xl) {
        right: 0;
        transform: none;
      }

      @include media(max, xs) {
        width: 100%;
        left: 0;
      }
    }
  }
</style>

<template>
  <div class="header-notifications-section" v-click-outside="hideFeed">
    <div class="nn-buttons">
      <div
        class="nn-button news"
        :class="{unseen: hasUnseenNews, active: currentFeed === 'news'}"
        @click="setCurrentFeed('news')">
        <icon name="news"/>
      </div>
      <div
        class="nn-button notifications"
        :class="{unseen: hasUnseenNotifications, active: currentFeed === 'notifications'}"
        @click="setCurrentFeed('notifications')">
        <icon name="notification"/>
      </div>
    </div>
    <transition name="fade-up">
      <div v-if="currentFeed" class="nn-popover" :class="{[currentFeed]: true}">
        <telegram-news-feed v-if="currentFeed === 'news'"/>
        <notifications-feed v-if="currentFeed === 'notifications'"/>
      </div>
    </transition>
  </div>
</template>

<script>

import TelegramNewsFeed from './TelegramNewsFeed'
import NotificationsFeed from './NotificationsFeed'

export default {
  name: 'HeaderNotificationsSection',
  components: {
    NotificationsFeed,
    TelegramNewsFeed,
  },
  data() {
    return {
      currentFeed: null,
    }
  },
  computed: {
    ...mapState('dashboard', ['dashboardInfo']),

    hasUnseenNews() {
      return this.dashboardInfo.unseenNewsPostsCount > 0
    },

    hasUnseenNotifications() {
      return this.dashboardInfo.unseenNotificationsCount > 0
    },
  },
  methods: {
    setCurrentFeed(feed = null) {
      if (this.currentFeed === feed) {
        this.currentFeed = null
      } else {
        this.currentFeed = feed

        if (feed) {
          this.$ga.event({
            eventCategory: 'header',
            eventAction: `header_${feed}_button_click`,
          })

          this.$tmr.goal({ goal: `header_${feed}_button_click` })
        }
      }
    },

    hideFeed() {
      this.setCurrentFeed(null)
    },
  },
}
</script>
