<style lang="scss" scoped>
  .widget-link {
    display: flex;
    align-items: center;

    .link-container {
      flex-grow: 1;
      margin-right: 12px;
      position: relative;
      min-width: 0;

      .link-text {
        white-space: nowrap;
        position: relative;
        max-width: 100%;
        overflow: hidden;

        &:after {
          content: '';
          @include position(absolute, 0, 0, 0, auto);
          width: 30px;
          background: linear-gradient(to right, rgba($additional-3, 0), rgba($additional-3, 1));
        }
      }

      .warning {
        z-index: 1;
        margin-top: 7px;
        position: absolute;
        display: flex;
        align-items: center;
        padding: 5px 10px;
        background-color: $additional-2;
        border-radius: 5px;
        font-size: 13px;
        line-height: 1.38;
        color: #ffffff;
        white-space: nowrap;

        .icon {
          flex-shrink: 0;
          margin-right: 5px;
        }
      }
    }

    .button ::v-deep .icon {
      font-size: 22px;
    }

    .button {
      flex-shrink: 0;
    }

    .tooltip {
      background: #333;
      color: white;
      font-weight: bold;
      padding: 4px 8px;
      font-size: 13px;
      border-radius: 4px;

      &-arrow,
      &-arrow::before {
        position: absolute;
        width: 8px;
        height: 8px;
        z-index: -1;
      }

      &-arrow::before {
        content: '';
        transform: rotate(45deg);
        background: #333;
      }

      &[data-popper-placement^='top'] > &-arrow {
        bottom: -4px;
      }

      &[data-popper-placement^='bottom'] > &-arrow {
        top: -4px;
      }

      &[data-popper-placement^='left'] > &-arrow {
        right: -4px;
      }

      &[data-popper-placement^='right'] > &-arrow {
        left: -4px;
      }
    }
  }
</style>

<template>
  <div ref="container" class="widget-link" v-tooltip="tooltipOptions">
    <template v-if="linkVisible">
      <btn icon-left="eye-crossed" variant="link" @click="toggleLinkVisible"/>
      <div class="link-container">
        <div class="link-text">{{link}}</div>
        <div class="warning"><icon name="warning-red"/>{{$t('global.widgetLink.warning')}}</div>
      </div>
      <btn slot="reference" icon-left="copy" variant="link" @click="copyLink"/>
    </template>
    <template v-else>
      <btn icon-left="eye" variant="link" @click="toggleLinkVisible">{{$t('global.widgetLink.showLink')}}</btn>
    </template>
  </div>
</template>

<script>
import { copyToClipboard } from '@utils/utils'

export default {
  props: {
    link: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      linkVisible: false,

      tooltipVisible: false,
      tooltipTimeout: null,
    }
  },
  computed: {
    tooltipOptions() {
      return {
        placement: 'top',
        trigger: 'manual',
        show: this.tooltipVisible,
        content: this.$t('global.widgetLink.linkCopied'),
      }
    },
  },
  methods: {
    toggleLinkVisible() {
      this.linkVisible = !this.linkVisible
    },

    copyLink() {
      clearTimeout(this.tooltipTimeout)
      copyToClipboard(this.link)
      this.tooltipVisible = true
      setTimeout(() => {
        this.tooltipVisible = false
      }, 3000)
    },
  },
}
</script>
