<style scoped lang="scss">
  .category-settings {

  }
</style>

<template>
  <div class="category-settings">
    <grid columns="2" gap="40">
      <div>
        <form-group inline :label="$t('roulette.category.settings.categoryName')">
          <base-input v-model="name" :placeholder="$t('roulette.category.settings.categoryName')"/>
        </form-group>
        <form-group inline :label="$t('roulette.category.settings.useCards')">
          <base-switch v-model="useCards"/>
        </form-group>
        <form-group
          v-if="useCards"
          inline
          :label="$t('roulette.category.settings.cards')"
          :description="$t('roulette.category.settings.cardsHelper')">
          <base-textarea
            v-model="cards"
            :placeholder="$t('roulette.category.settings.cardsHelper')"/>
        </form-group>
        <form-group inline :label="$t('roulette.category.settings.categoryRarity')">
          <colored-select v-model="rarity" :options="rarityOptions"/>
        </form-group>
        <form-group inline :label="$t('roulette.category.settings.designManagement')">
          <btn icon-left="copy" @click="openCategorySelectModal">
            {{ $t('roulette.category.settings.importDesign') }}
          </btn>
        </form-group>
        <div class="errors-box" v-if="errors.length">
          <info-box
            v-for="(error, errorIndex) in errors"
            :key="errorIndex"
            variant="error">{{ error.message }}
          </info-box>
        </div>
      </div>
      <div>
        <form-group>
          <info-box class="small" :show-icon="false">
            {{ $t('roulette.category.settings.rarityTextPrompt') }}
          </info-box>
        </form-group>
        <form-group>
          <info-box class="small" :show-icon="false">
            {{ $t('roulette.category.settings.cardsTextPrompt') }}
          </info-box>
        </form-group>
        <form-group>
          <info-box class="small" :show-icon="false">
            {{ $t('roulette.category.settings.emptyCardsPrompt') }}
          </info-box>
        </form-group>
      </div>
    </grid>
    <category-select-modal
      :exclude-categories="[categoryId]"
      v-model="categorySelectModalOpened"
      @select="importSettings"/>
  </div>
</template>

<script>

import { cardRarity } from '@src/config/roulette/helper'
import ColoredSelect from '@components/BaseComponents/Select/ColoredSelect'
import CategorySelectModal from './CategorySelectModal'

export default {
  name: 'CategorySettings',
  components: {
    CategorySelectModal,
    ColoredSelect,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    categoryId: {
      type: [String, Number],
      default: null,
    },
    errors: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      rarity: 'common',
      name: null,
      useCards: true,
      cards: null,
      enabled: true,

      maxWinsPerSession: 0,

      categorySelectModalOpened: false,
    }
  },
  computed: {
    designCopyAvailable() {
      return this.getCategoriesList.filter(category => category.id !== this.categoryId).length > 0
    },

    defaultValues() {
      return {
        name: this.$t('roulette.category.settings.defaultValues.name'),
        cards: this.$t('roulette.category.settings.defaultValues.cards'),
      }
    },

    rarityOptions() {
      return cardRarity.map(rarity => ({
        ...rarity,
        label: this.$t(`roulette.category.rarities.${rarity.value}`),
      }))
    },

    output() {
      return {
        rarity: this.rarity,
        name: this.name,
        useCards: this.useCards,
        cards: this.cards,
        enabled: this.enabled,
      }
    },
  },
  methods: {
    importSettings(categoryId) {
      this.$emit('visual-settings-import', categoryId)
    },

    openCategorySelectModal() {
      this.categorySelectModalOpened = true
    },
  },
  created() {
    const storedValue = _.cloneDeep(this.value)

    if (storedValue) {
      Object.keys(storedValue).forEach(key => {
        this[key] = storedValue[key] ?? this[key]
      })
    }

    this.name = this.name ?? this.defaultValues.name
    this.cards = this.cards ?? this.defaultValues.cards
  },
  watch: {
    output(value) {
      this.$emit('input', value)
    },
  },
}
</script>
