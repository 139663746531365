<style scoped lang="scss">
  .goal-text-settings {
  }
</style>

<template>
  <div class="goal-text-settings">
    <form-group inline :label="$t('roulette.goal.visualSettings.text.titleText')">
      <base-input v-model="goalTitle" :placeholder="$t('roulette.goalWidget.untilRoulette')"/>
    </form-group>
    <form-group inline :label="$t('roulette.goal.visualSettings.text.title')">
      <text-styler v-model="titleTextStyles" class="bottom"/>
    </form-group>
    <form-group inline :label="$t('roulette.goal.visualSettings.text.goalProgress')">
      <text-styler v-model="contentTextStyles" class="bottom"/>
    </form-group>
  </div>
</template>

<script>

import TextStyler from '@components/BaseComponents/TextStyler/TextStyler'

export default {
  name: 'GoalTextSettings',
  components: {
    TextStyler,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      goalTitle: null,
      titleTextStyles: {},
      contentTextStyles: {},
    }
  },
  computed: {
    output() {
      return {
        goalTitle: this.goalTitle,
        titleTextStyles: this.titleTextStyles,
        contentTextStyles: this.contentTextStyles,
      }
    },
  },
  created() {
    if (this.value) {
      Object.keys(this.value).forEach(key => {
        this[key] = this.value[key] ?? this[key]
      })
    }
  },
  watch: {
    output(value) {
      this.$emit('input', value)
    },
  },
}
</script>
