<style scoped lang="scss">
  .filler-style-form {
  }
</style>

<template>
  <div class="filler-style-form">
    <template v-if="useGradient">
      <form-group class="with-switcher" inline :label="$t('goals.designForm.indicatorSettings.fillerBackgroundGradient')">
        <gradient-picker v-model="gradient"/>
        <div slot="helper" class="text-link" @click="toggleGradient">{{$t('global.color.usePlainColor')}}</div>
      </form-group>
    </template>
    <template v-else>
      <form-group class="with-switcher" inline :label="$t('goals.designForm.indicatorSettings.fillerBackgroundColor')">
        <color-picker v-model="backgroundColor"/>
        <div slot="helper" class="text-link" @click="toggleGradient">{{$t('global.color.useColorGradient')}}</div>
      </form-group>
    </template>
  </div>
</template>

<script>

export default {
  name: 'FillerStyleForm',
  props: {
    value: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      useGradient: false,

      backgroundColor: null,
      gradient: null,
    }
  },
  watch: {
    value: {
      handler(value, oldValue) {
        if (JSON.stringify(value) !== JSON.stringify(oldValue)) {
          if (value) {
            const { backgroundColor, gradient, useGradient = null } = value

            const gradientColors = _.get(gradient, 'colors', [backgroundColor, backgroundColor])
            this.backgroundColor = backgroundColor || gradientColors[0]
            this.gradient = {
              colors: gradientColors,
              direction: _.get(gradient, 'direction', 'top'),
              angle: _.get(gradient, 'angle', 0),
            }

            this.useGradient = useGradient === null ? !!gradient : useGradient
          }
        }
      },
      immediate: true,
    },
    output: {
      handler(value) {
        this.$emit('input', value)
      },
    },
  },
  computed: {
    output() {
      return {
        backgroundColor: this.backgroundColor,
        gradient: this.gradient,
        useGradient: this.useGradient,
      }
    },
  },
  methods: {
    toggleGradient() {
      this.useGradient = !this.useGradient
    },
  },
}
</script>
