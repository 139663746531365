/* eslint-disable */
import {
  format,
  utcToZonedTime,
  zonedTimeToUtc,
} from 'date-fns-tz'
import { enUS, ru, fr, de, sv, it, pt, pl, es } from 'date-fns/locale'
import fromUnixTime from 'date-fns/fromUnixTime'
import setMonth from 'date-fns/setMonth'
import getDay from 'date-fns/getDay'
import getDaysInMonth from 'date-fns/getDaysInMonth'
import startOfDay from 'date-fns/startOfDay'
import isAfter from 'date-fns/isAfter'
import isBefore from 'date-fns/isBefore'

import differenceInSeconds from 'date-fns/differenceInSeconds'
import differenceInMinutes from 'date-fns/differenceInMinutes'
import differenceInHours from 'date-fns/differenceInHours'
import differenceInDays from 'date-fns/differenceInDays'
import differenceInWeeks from 'date-fns/differenceInWeeks'
import differenceInMonths from 'date-fns/differenceInMonths'
import differenceInYears from 'date-fns/differenceInYears'

const DEFAULT_TIME_ZONE = 'Europe/Moscow'
const DEFAULT_TIME_FORMAT = 'dd.MM.yyyy HH:mm'

import { i18n } from '@src/localization/config'

const locales = {
  'en_US': enUS,
  'ru_RU': ru,
  'fr_FR': fr,
  'de_DE': de,
  'sv_SE': sv,
  'it_IT': it,
  'pt_BR': pt,
  'pl_PL': pl,
  'es_ES': es,
}

const parseDate = (_date) => ({
  day: _date.getDate(),
  month: _date.getMonth(),
  year: _date.getFullYear(),

  hour: _date.getHours(),
  minute: _date.getMinutes(),
  second: _date.getSeconds(),
})

const parseTs = (_ts) => parseDate(fromUnixTime(_ts))

export default {
  // Const
  DEFAULT_TIME_ZONE,
  DEFAULT_TIME_FORMAT,

  // Functions
  format: (_date, _format, _options = {}) => format(_date, _format, { ..._options, locale: locales[i18n.locale] }),
  setMonth,
  isAfter,
  isBefore,
  utcToZonedTime,
  zonedTimeToUtc,
  startOfDay,
  fromUnixTime,
  getDaysInMonth,
  getWeekDay: (_date) => getDay(_date),
  timestamp: (_date = null) => +(_date ? new Date(_date) : Date.now()) / 1000,
  parseTs,
  getTimeLeft: (tsStart, tsEnd) => {
    const diff = {
      sec: tsEnd - tsStart,
    }
    const seconds = diff.sec % 60

    diff.min = (diff.sec - seconds) / 60
    const minutes = diff.min % 60

    diff.hours = (diff.min - minutes) / 60
    const hours = diff.hours % 24

    const days = (diff.hours - hours) / 24

    return {
      seconds,
      minutes,
      hours,
      days,
    }
  },
  getDifference: (tsStart, tsEnd) => {
    const end = fromUnixTime(tsStart)
    const start = fromUnixTime(tsEnd)

    return {
      second: differenceInSeconds(start, end),
      minute: differenceInMinutes(start, end),
      hour: differenceInHours(start, end),
      day: differenceInDays(start, end),
      week: differenceInWeeks(start, end),
      month: differenceInMonths(start, end),
      year: differenceInYears(start, end),
    }
  },
  // Formatting
  unixToFormat: (_ts, _format = DEFAULT_TIME_FORMAT) => format(fromUnixTime(_ts), _format),
  unixToFormatTZ: (_ts, _format = DEFAULT_TIME_FORMAT, _tz = DEFAULT_TIME_ZONE) => format(utcToZonedTime(fromUnixTime(_ts), _tz), _format),
}
