import axios from 'axios'
import $ from 'jquery'

window.activityHeight = () => {
  if ($('.s-last-events-block').length > 0) {
    $('.s-last-events-block').removeAttr('style')
  }
}

export const loadLegacyPage = (url, el = null, preInitCb) => {
  return axios.post(url, {
    ajax: true,
  }, {
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
    },
  })
    .then(({ data }) => {
      const html = data.html ?? data

      if (el) {
        $(el).html(html)
      } else {
        $('#page-html').html(html)
      }

      if (preInitCb) {
        preInitCb()
      }

      window.initPage()
      window.binarySelectInit()
      window.clipboardCopyInit()
      window.initMiscPageItems()
      window.tooltipInit()
    })
}
