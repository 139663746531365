<style scoped lang="scss">
  .roulette-visual-settings {
    display: flex;

    @include media(max, sm) {
      flex-direction: column;
    }

    .vertical-tabs {
      align-self: flex-start;
    }

    .settings-content {
      padding: 15px 20px;
      margin-left: 20px;
      background-color: $additional-3;
      border-radius: 5px;
      flex: 1 1 auto;
      display: flex;
      flex-direction: column;

      @include media(max, sm) {
        margin-left: 0;
        margin-top: 20px;
      }

      .settings-tabs {
        margin-bottom: 20px;
      }

      .settings-controls {
        margin-top: auto;
        border-top: 1px solid rgba(white, .05);
        padding-top: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
</style>

<template>
  <div class="roulette-visual-settings">
    <vertical-tabs v-model="currentTab" :list="tabs"/>
    <div class="settings-content">
      <div class="settings-tabs" :key="settingsStamp">
        <roulette-background-settings v-show="currentTab === 'background'" v-model="backgroundSettings"/>
        <roulette-frame-settings v-show="currentTab === 'frame'" v-model="frameSettings"/>
        <roulette-decor-settings v-show="currentTab === 'decor'" v-model="decorSettings"/>
        <roulette-pin-settings v-show="currentTab === 'pin'" v-model="pinSettings"/>
        <roulette-title-settings v-show="currentTab === 'title'" v-model="titleSettings"/>
        <roulette-behavior-settings v-show="currentTab === 'behavior'" v-model="behaviorSettings"/>
        <roulette-sound-settings v-show="currentTab === 'sounds'" v-model="soundSettings"/>
      </div>
      <div class="settings-controls">
        <btn variant="primary-action" :is-loading="loadingState.save" @click="save">
          {{ $t('global.common.save') }}
        </btn>
        <!--        <btn variant="secondary-action" @click="reset">Отменить изменения</btn>-->
      </div>
    </div>
  </div>
</template>

<script>
import VerticalTabs from '@components/BaseComponents/Tabs/VerticalTabs'
import RouletteBackgroundSettings from './RouletteBackgroundSettings'
import RouletteFrameSettings from './RouletteFrameSettings'
import RoulettePinSettings from './RoulettePinSettings'
import RouletteBehaviorSettings from './RouletteBehaviorSettings'
import RouletteTitleSettings from './RouletteTitleSettings'
import RouletteDecorSettings from './RouletteDecorSettings'
import RouletteSoundSettings from './RouletteSoundSettings'
import { copyToClipboard } from '@utils/utils'

export default {
  name: 'RouletteVisualSettings',
  components: {
    RouletteSoundSettings,
    VerticalTabs,
    RouletteDecorSettings,
    RouletteTitleSettings,
    RouletteBehaviorSettings,
    RoulettePinSettings,
    RouletteFrameSettings,
    RouletteBackgroundSettings,
  },
  data() {
    return {
      loadingState: {
        save: false,
      },

      currentTab: 'background',

      backgroundSettings: {},
      frameSettings: {},
      decorSettings: {},
      pinSettings: {},
      titleSettings: {},
      behaviorSettings: {},
      soundSettings: {},

      settingsStamp: 1,
    }
  },
  computed: {
    ...mapState('roulette', ['rouletteVisualSettings']),
    ...mapGetters('widgets/presets', ['getDefaultPresetByType']),

    tabs() {
      return [
        {
          key: 'background',
          title: 'background',
          icon: 'shadow',
        },
        {
          key: 'frame',
          title: 'frame',
          icon: 'square',
        },
        {
          key: 'decor',
          title: 'decor',
          icon: 'icons',
        },
        {
          key: 'pin',
          title: 'pin',
          icon: 'pin',
        },
        {
          key: 'title',
          title: 'title',
          icon: 'font',
        },
        {
          key: 'behavior',
          title: 'behavior',
          icon: 'spinner',
        },
        {
          key: 'sounds',
          title: 'sounds',
          icon: 'music',
        },
      ].map(tab => ({
        ...tab,
        title: this.$t(`roulette.visualSettings.tabs.${tab.title}`),
      }))
    },

    output() {
      return {
        backgroundSettings: this.backgroundSettings,
        frameSettings: this.frameSettings,
        decorSettings: this.decorSettings,
        pinSettings: this.pinSettings,
        titleSettings: this.titleSettings,
        behaviorSettings: this.behaviorSettings,
        soundSettings: this.soundSettings,
      }
    },
  },
  methods: {
    ...mapActions('roulette', {
      updateVisualSettings: 'updateRouletteVisualSettings',
    }),

    save() {
      this.loadingState.save = true

      const { id } = this.getDefaultPresetByType('roulette')

      this.updateVisualSettings({
        json: this.output,
        presetId: id,
      }).finally(() => {
        this.loadingState.save = false
      })
    },

    reset() {
      const storeSettings = _.cloneDeep(this.rouletteVisualSettings)

      if (storeSettings) {
        Object.keys(storeSettings).forEach(key => {
          this[key] = storeSettings[key] ? storeSettings[key] : this[key]
        })
      }

      this.settingsStamp = this.$timestamp
    },
  },
  watch: {
    output(output) {
      this.$emit('update', output)
    },
    rouletteVisualSettings: {
      handler: 'reset',
      immediate: true,
    },
  },
}
</script>
