<style lang="scss" scoped></style>

<template>
  <div class="time-diff">
    <slot name="before"/>
    {{ time }}
    <slot name="after"/>
  </div>
</template>

<script>

export default {
  name: 'TimeDiff',
  props: {
    from: {
      type: [String, Number],
      default: null,
    },
    to: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      dateFrom: null,
      dateTo: null,
    }
  },
  computed: {
    time() {
      const from = this.dateFrom ?? this.$timestamp
      const to = this.dateTo ?? this.$timestamp

      const diff = dates.getDifference(from, to)

      return ['second', 'minute', 'hour', 'day', 'week', 'month', 'year']
        .reverse()
        .reduce((output, variant) => {
          return !output && diff[variant]
            ? `${diff[variant]} ${this.$tc(`global.dates.${variant}`, diff[variant])}`
            : output
        }, null)
    },
  },
  methods: {
    calculateDifference(from, to) {
      this.calculatedOnce = true
      this.difference =  dates.getDifference(from, to)
    },
  },
  watch: {
    to: {
      handler(value) {
        this.dateTo = value ? Number(value) : null
      },
      immediate: true,
    },
    from: {
      handler(value) {
        this.dateFrom = value ? Number(value) : null
      },
      immediate: true,
    },
  },
}
</script>
