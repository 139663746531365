<style lang="scss">
  .notifications-list {
    position: relative;
  }

  .notification {
    position: relative;
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 15px 30px 15px 20px;
    border-radius: 8px;
    box-shadow: 0 4px 24px 0 rgba(black, .05);
    flex-shrink: 0;
    margin-bottom: 12px;

    &.success {
      background-color: #59A840;
    }

    .notification-icon {
      font-size: 20px;
      flex-shrink: 0;
    }

    .notification-text {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
    }

    .notification-close-button {
      position: absolute;
      top: 15px;
      right: 15px;
      color: rgba(white, .5);
      font-size: 12px;
      line-height: 12px;
      width: 18px;
      height: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }

  /* 1. объявление transition */
  .list-move, /* применять переход к движущимся элементам */
  .list-enter-active,
  .list-leave-active {
    transition: all .5s ease;
  }

  .list-enter-from,
  .list-leave-to {
    opacity: 0;
    transform: translateX(30px);
  }

  /* убедитесь, что удаляющиеся элементы выведены из потока, чтобы
  анимации перемещения могли быть рассчитаны правильно. */
  .list-leave-active {
    position: absolute;
  }
</style>

<template>
  <div class="dashboard-push-notifications">
    <TransitionGroup tag="div" name="list" class="notifications-list">
      <div
        v-for="(notification, index) in notificationsComputed"
        :key="notification.id"
        class="notification"
        :class="notification.type">
        <icon name="check-round" class="notification-icon"/>
        <div class="notification-text">{{ notification.text }}</div>
        <div class="notification-close-button" @click="removeNotification(notification.id)">
          <icon name="cross"/>
        </div>
      </div>
    </TransitionGroup>
  </div>
</template>

<script setup>
import { ref, computed, onBeforeUnmount } from 'vue'

const notifications = ref([])

const timeouts = []

window.daDashboardPushNotification = ({ type, text }) => {
  const id = window.crypto.randomUUID()

  notifications.value.unshift({ id, type, text })

  timeouts.push(setTimeout(() => removeNotification(id), 5000))
}

onBeforeUnmount(() => {
  timeouts.forEach(clearTimeout)
})

const notificationsComputed = computed(() => {
  return notifications.value.map(notification => {
    return {
      ...notification,
      icon: {
        success: 'check-round',
      }[notification.type],
    }
  })
})

const removeNotification = id => {
  const index = notifications.value.findIndex(n => n.id === id)
  notifications.value.splice(index, 1)
}
</script>