<style lang="scss" scoped>
  .widget-alerts-page {

  }
</style>

<template>
  <div class="widget-alerts-page">
    <div ref="legacy"/>
  </div>
</template>

<script>
import { loadLegacyPage } from '@src/services/legacyLoader'

export default {
  name: 'WidgetAlertsPage',
  metaInfo() {
    const title = [
      this.$t('dashboard.pages.widgets.alerts'),
      this.$t('dashboard.pages.widgets.widgets'),
    ]

    return {
      title: title.join(' - '),
    }
  },
  computed: {
    ...mapState('application', ['isAstrumApp']),
  },
  mounted() {
    this.$nextTick(() => {
      loadLegacyPage('/dashboard/old-page-alert-widget', this.$refs.legacy)
    })
  },
}
</script>
