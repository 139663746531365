<template>
  <div>
    <BackBtn
      v-if="!offerLoading"
      :disabled="offerLoading"
      :route="backButtonRoute"
    />

    <loader
      size="md"
      v-if="offerLoading"
      style="margin: 10vh auto;"
    />

    <div v-else>
      <div>
        <Title>
          <span>{{ offerTitle }}</span>
        </Title>

        <Status
          v-if="hasOffer"
          class="title-status"
          :type="offerStatus"
        />
      </div>

      <Tabs
        v-model="currentTab"
        :list="tabList"
      />

      <div v-if="currentTab === 'desc'">
        <offersDesc v-if="showOfferDescription" />

        <div v-else >
          <span class="ban-message">{{$t('offers.offerIsNoAvailable')}}.</span>
        </div>
      </div>

      <div v-if="currentTab === 'stats'">
        <offersStats />
      </div>

      <div v-if="currentTab === 'links'">
        <offersLinks />
      </div>

    </div>
  </div>
</template>

<script>
import Title from '@components/BaseComponents/Title'
import Tabs from '@components/BaseComponents/Tabs/Tabs'
import offersStats from './OffersDetailsStatsPage'
import offersDesc from './OffersDetailsDescPage'
import offersLinks from './OffersDetailsLinksPage'
import BackBtn from '@components/Dashboard/Offers/BackBtn'
import Status from '@components/Dashboard/Offers/Status'

import {
  OFFER_WORK_STATUS,
  OFFER_BAN_STATUS,
  OFFER_NOT_APPROVED_STATUS,
  OFFER_ON_APPROVAL_STATUS,
  OFFER_ACTIVATION_TYPE,
  OFFER_ACTION_TYPE,
  OFFER_PROCESSING_STATUS
} from '@constants/offer'

import { ADMITAT_STATISTIC } from '@constants/statistic'


export default {
  name: 'OffersDetailsPage',

  props: {
    id: {
      type: [String, Number],
      required: true,
    },
  },

  components: {
    Title,
    Status,
    Tabs,
    offersLinks,
    offersStats,
    offersDesc,
    BackBtn,
  },

  data() {
    return {
      currentTab: 'desc',
      tabs: {
        desc: {
          key: 'desc',
          title: 'offers.description',
          icon: 'livefeed',
        },
        stats: {
          key: 'stats',
          title: 'offers.statistics.statistics',
          icon: 'statistics',
        },
        links: {
          key: 'links',
          title: 'offers.savedLinks',
          icon: 'chain',
        },
      },
    }
  },

  created() {
    this.fetchOffer(this.id)
  },

  destroyed() {
    this.clearOffer()
  },

  methods: {
    ...mapActions('offers/details', ['fetchOffer', 'clearOffer']),
    ...mapActions('profile/getTimezone', ['getTimezone']),
  },

  computed: {
    ...mapState('dashboard', ['newDashboard']),

    ...mapGetters('offers/details', [
      'offerLoading',
      'offer',
      'userOfferId'
    ]),

    backButtonRoute() {
      return this.hasOffer
        ? (this.offerStatus === OFFER_WORK_STATUS || this.offerStatus === OFFER_BAN_STATUS
          ? { name: 'dashboard-offers-active' }
          : { name: 'dashboard-offers-finished' } )
        : { name: 'dashboard-offers' }
    },

    hasOffer: (vm) => vm.offer.offer_user && vm.offer.offer_user[0],
    offerTitle: (vm) => vm.offer?.title || '',
    offerStatus: (vm) => vm.offer.offer_user?.[0]?.status,
    notBannedOffer: (vm) => vm.hasOffer && vm.offerStatus !== OFFER_BAN_STATUS,
    showOfferDescription: (vm) => !vm.offer.offer_user || vm.notBannedOffer,
    approvedOffer: (vm) => vm.offerStatus !== OFFER_NOT_APPROVED_STATUS && vm.offerStatus !== OFFER_ON_APPROVAL_STATUS,
    approvedActivationOffer: (vm) => vm.hasOffer && vm.offer.bonus_type === OFFER_ACTIVATION_TYPE && vm.approvedOffer,
    actionOfferProcessing: (vm) => vm.hasOffer && vm.offer.bonus_type === OFFER_ACTION_TYPE && vm.offerStatus === OFFER_PROCESSING_STATUS,
    approvedActionOffer: (vm) => vm.hasOffer && vm.offer.bonus_type === OFFER_ACTION_TYPE && (vm.approvedOffer && vm.offerStatus !== OFFER_PROCESSING_STATUS),
    notAdmitadStatistic: (vm) => vm.offer?.options?.source?.code !== ADMITAT_STATISTIC,

    tabList() {
      let tabs = []

      if(this.approvedActivationOffer) {
        tabs = [ this.tabs.desc, this.tabs.stats ]
      }

      if(this.actionOfferProcessing) {
        tabs = [ this.tabs.desc ]
      }

      if(this.approvedActionOffer) {
        tabs = [ this.tabs.desc, this.tabs.stats, ...(this.notBannedOffer && this.notAdmitadStatistic ? [this.tabs.links] : []) ]
      }

      return tabs.map(el => ({ ...el, title: this.$t(el?.title) }))
    }
  },
}
</script>

<style scoped lang="scss">
.title-status {
  display: inline-block;
  vertical-align: top;
  margin-left: 10px;
  transform: translateY(3px);
}

.ban-message {
  color: #D63A3A;
  margin: 20px 0;
  display: block;
}
</style>
