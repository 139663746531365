<style lang="scss" scoped>
  .widget-stickers-page {

  }
</style>

<template>
  <div class="widget-stickers-page">
    <div ref="legacy"/>
  </div>
</template>

<script>
import { loadLegacyPage } from '@src/services/legacyLoader'

export default {
  name: 'WidgetStickersPage',
  metaInfo() {
    const title = [
      this.$t('dashboard.pages.widgets.stickers'),
      this.$t('dashboard.pages.widgets.widgets'),
    ]

    return {
      title: title.join(' - '),
    }
  },
  mounted() {
    loadLegacyPage('/dashboard/old-page-sticker', this.$refs.legacy)
  },
}
</script>
