<style scoped lang="scss">
  .stream-stats-preview {
    width: 100%;
    height: 100%;
    position: relative;
    background-color: $additional-3;
    border-radius: 4px;
    overflow: hidden;
    padding: 40px;

    .preview-background {
      @include position-all(absolute, 0);
      z-index: 1;
      background-image: appAsset('/images/goal-preview-bg.jpg');
      background-size: cover;
      background-position: center center;
    }

    .preview-widget-wrapper {
      width: 100%;
      height: 100%;
      position: relative;
      z-index: 2;
      overflow: hidden;
    }

    .preview-controls {
      @include position(absolute, auto, 0, 14px, 0);
      z-index: 2;
      display: flex;
      justify-content: center;
      text-transform: uppercase;

      .background-toggle {
        border-radius: 16px;
        background-color: $additional-3;
        padding: 6px 6px 6px 10px;
        display: flex;
        align-items: center;
        font-size: 13px;
        font-weight: 600;

        .base-switch {
          margin-left: 10px;
        }
      }
    }
  }
</style>

<template>
  <div class="stream-stats-preview">
    <transition name="fade">
      <div v-if="backgroundVisible" class="preview-background"/>
    </transition>
    <div class="preview-widget-wrapper">
      <widget-stream-stats
        :widget-settings="widgetSettings"
        :widget-design="widgetDesign"
        :widget-data="widgetData"/>
    </div>
    <div class="preview-controls">
      <div class="background-toggle">
        {{ $t('goals.goalPreview.gamingBackground') }}
        <base-switch v-model="backgroundVisible" small/>
      </div>
    </div>
  </div>
</template>

<script>

import WidgetStreamStats from '@components/Widgets/WidgetStreamStats/WidgetStreamStats'
import fakeData from '@src/config/streamStats/fakeData'

export default {
  name: 'StreamStatsPreview',
  components: {
    WidgetStreamStats,
  },
  props: {
    widgetSettings: {
      type: Object,
      required: true,
    },
    widgetDesign: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      backgroundVisible: true,
    }
  },
  computed: {
    widgetData() {
      const { dataType, subscribersTypes, subscribersNumberSource, viewersNumberSource } = this.widgetSettings

      let widgetData

      switch (dataType) {
        case 'subscriptionsNumber':
          widgetData = Object.keys(subscribersTypes)
            .filter(key => subscribersTypes[key])
            .reduce((acc, key) => acc + fakeData.subscriptionsNumber[key], 0)

          break
        case 'subscribersNumber':
          widgetData = fakeData.subscribersNumber[subscribersNumberSource]
          break
        case 'viewersNumber':
          widgetData = fakeData.viewersNumber[viewersNumberSource]
          break
        default:
          widgetData = fakeData[dataType]
      }

      return widgetData
    },
  },
}
</script>
