<template>
  <span class="time-left">
    <text-wrapper :text="time" :animation="animation"/>
  </span>
</template>

<script>
import { declOfNum } from '@utils/utils'
import TextWrapper from '@components/Widgets/WidgetGoal/TextWrapper'

export default {
  name: 'TimeLeft',
  components: { TextWrapper },
  props: {
    to: {
      type: [String, Number],
      default: null,
    },
    animation: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      dateTo: null,
    }
  },
  computed: {
    time() {
      if (!this.dateTo) {
        return ''
      }

      const diff = dates.getTimeLeft(+this.$timestamp.toFixed(0), this.dateTo)

      const timeIsOut = diff.days + diff.hours + diff.minutes + diff.seconds <= 0

      if (timeIsOut) {
        return ''
      }

      const getVariant = () => {
        if (diff.days >= 1) {
          return 'days'
        } else if (diff.hours >= 1) {
          return 'hours'
        } else if (diff.minutes >= 1) {
          return 'minutes'
        }

        return 'seconds'
      }

      const variant = getVariant()
      const text = this.$tc(`goals.goalTimer.${variant}_plural`, diff[variant], { [variant]: diff[variant] })

      return text || ''
    },
  },
  watch: {
    to: {
      handler() {
        this.dateTo = /[^\d.-]/g.test(this.to) ? 0 : this.to
      },
      immediate: true,
    },
  },
}
</script>
