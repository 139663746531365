<template>
  <div class="card__wrapper">
    <div class="card__frame">
      <div class="card__content">
        <div class="card__icon">
          <icon name="plus"/>
        </div>

        <div class="card__text">
          <span>{{$t('offers.advertisersAccount.createPreset')}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},

  data() {
    return {}
  },
}
</script>

<style scoped lang="scss">
.card {
  &__wrapper {
    background: #262626;
    border-radius: 8px;
    padding: 20px;
    height: 100%;
    cursor: pointer;
    user-select: none;

    &:hover {
      .card__frame {
        opacity: 1;
      }
    }
  }

  &__frame {
    border: 1px dashed #838383;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #838383;
    opacity: .5;
  }

  // &__content {}

  &__icon {
    font-size: 30px;
    margin-bottom: 20px;
    text-align: center;
  }

  &__text {
    text-align: center;
    font-size: 16px;
  }
}
</style>