<style lang="scss" scoped>
  .telegram-news-feed {
    height: 100%;
    display: flex;
    flex-direction: column;

    .news-feed-title {
      display: flex;
      align-items: center;
      height: 22px;

      .feed-title {
        font-weight: 400;
        font-size: 16px;
        color: $bright;
        white-space: nowrap;

        .news-count {
          font-size: 13px;
          padding: 1px 7px;
          background: $additional-2;
          border-radius: 100px;
        }
      }

      .telegram-link {
        margin-left: auto;
        color: rgba($bright, .5);
        font-size: 13px;
        display: flex;
        align-items: center;

        .icon {
          margin-left: 10px;
          font-size: 22px;
        }

        &:hover {
          color: var(--theme-color-additional);
        }
      }
    }

    .news-feed-list-wrapper {
      margin-top: 20px;
      position: relative;
      min-height: 0;
      height: 100%;

      .news-feed-loader {
        @include position-all(absolute, 0);
        background-color: rgba($additional-3, .5);
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .news-feed-list {
        height: 100%;
        overflow-y: scroll;
        margin-right: -15px;
        padding-right: 8px;
        padding-bottom: 16px;

        .news-post + .news-post {
          margin-top: 12px;
        }

        .news-post {
          background-color: $additional-2;
          border-radius: 8px;

          .post-media {
            img {
              border-radius: 8px 8px 0 0;
            }
          }

          .post-content {
            @include padding-x(16px);
            margin-top: 14px;
            font-size: 14px;
            line-height: 1.43;
            color: $bright;
          }

          .post-date {
            font-size: 13px;
            color: $gray;
            padding: 14px 16px;
          }
        }
      }
    }
  }
</style>

<template>
  <div class="telegram-news-feed">
    <div class="news-feed-title">
      <div class="feed-title">{{ $t('dashboard.header.news') }}
        <span
          v-if="unseenNewsCount"
          class="news-count">{{ unseenNewsCount }}</span>
      </div>
      <a href="https://t.me/donation_alerts" target="_blank" class="telegram-link" @click="onChannelLinkClick">
        {{ $t('dashboard.header.telegramChannel') }}
        <icon name="social-telegram-colored"/>
      </a>
    </div>
    <div class="news-feed-list-wrapper">
      <transition name="fade">
        <div v-if="loadingState.news" class="news-feed-loader">
          <loader size="lg"/>
        </div>
      </transition>
      <div
        v-if="news.length"
        ref="news-feed-list"
        class="news-feed-list"
        @scroll="onNewsListScroll">
        <div
          v-for="(post, postIndex) in news"
          :key="postIndex"
          class="news-post">
          <div v-if="post.mediaUrl" class="post-media">
            <img :src="post.mediaUrl"/>
          </div>
          <div class="post-content" v-html="post.htmlContent"/>
          <div class="post-date">
            <time-diff :from="post.postDate">
              <template slot="after">назад</template>
            </time-diff>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'TelegramNewsFeed',
  data() {
    return {
      loadingState: {
        news: true,
      },

      news: [],
      page: 1,
      perPage: 10,
      total: 0,
      pagesCount: 0,

      newsPopoverOpened: false,
    }
  },
  computed: {
    ...mapState('dashboard', ['dashboardInfo']),

    unseenNewsCount() {
      return this.dashboardInfo.unseenNewsPostsCount
    },
  },
  methods: {
    ...mapActions('dashboard', [
      'markTelegramChannelNewsAsRead',
      'fetchTelegramChannelNews',
      'fetchDashboardInfo',
    ]),

    onChannelLinkClick() {
      this.$ga.event({
        eventCategory: 'header',
        eventAction: 'header_news_channel_click',
      })

      this.$tmr.goal({ goal: 'header_news_channel_click' })
    },

    onNewsListScroll(event) {
      const { scrollHeight, scrollTop, offsetHeight } = event.target

      if (scrollTop >= scrollHeight - offsetHeight) {
        this.nextPage()
      }
    },

    nextPage() {
      if (!this.loadingState.news) {
        if (this.total > this.page * this.perPage) {
          this.page = this.page + 1
          this.fetchNews()
            .then(() => {
              this.$nextTick(() => {
                const newsFeedListEl = this.$refs['news-feed-list']

                newsFeedListEl.scrollTo({
                  top: newsFeedListEl.scrollTop + 100,
                  behavior: 'smooth',
                })
              })
            })
        }
      }
    },

    fetchNews() {
      this.loadingState.news = true

      return this.fetchTelegramChannelNews({
        page: this.page,
        perPage: this.perPage,
      }).then(({
          pagination,
          news,
        }) => {
          const processedNews = news.map(post => {
            const htmlContent = post.text
              .replace(/\\n\\n/gm, '<br><br>')
              .replace(
                /(https:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9]{1,6}\b([-a-zA-Z0-9@:%_+.~#?&/=]*))/gm,
                '<a href="$1" target="_blank">$1</a>')

            return {
              ...post,
              htmlContent,
            }
          })

          this.news = [
            ...this.news,
            ...processedNews,
          ]
          this.total = pagination.total
          this.pagesCount = pagination.pagesCount

          this.markTelegramChannelNewsAsRead()

          return true
        },
      ).finally(() => {
        this.loadingState.news = false
      })
    },
  },
  created() {
    this.fetchNews()
  },
}
</script>
