<style lang="scss">
  .widget-donation-goal-page {
    flex: 1 0 auto;
    min-height: 100%;
  }
</style>

<template>
  <div class="widget-donation-goal-page">
    <goal-config/>
  </div>
</template>

<script>

import GoalConfig from '@components/Dashboard/GoalSettings/GoalConfig'

export default {
  name: 'WidgetDonationGoalPage',
  components: { GoalConfig },
  metaInfo() {
    const title = [
      this.$t('dashboard.pages.widgets.donationGoal'),
      this.$t('dashboard.pages.widgets.widgets'),
    ]

    return {
      title: title.join(' - '),
    }
  },
}
</script>
