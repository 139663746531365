<template>
  <div class="game-label" :class="{ fill: fill }">
    <span>{{ text }}</span>
    <span>{{ amount }}</span>
  </div>
</template>

<script>
export default {

  name: 'GameLabel',

  props: {
    fill: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: '',
    },
    amount: {
      type: String,
      default: '',
    },
  },

  data() {
    return {}
  },

  watch: {},

  created() {},

  computed: {},

  methods: {},

}

</script>

<style scoped lang="scss">
.game-label {
  width: 358px;
  border: 1px solid #464646;
  border-radius: 8px;
  line-height: 20px;
  padding: 11px 16px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;

  &.fill {
    background: #303030;
  }
}
</style>
