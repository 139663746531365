const lorem = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
const names = ['Semen', 'Налоговая', 'Ray Tracing', 'Rick the Roller', 'ADVOKAT', 'Буйный', 'KopoJLb ICQ', 'Хороший Марк', '통역사']

const donations = Array.from({ length: 100 }, (v, k) => {
  const isAnonymous = _.random(1, 20) <= 2
  const noMessage = _.random(1, 10) <= 2

  return {
    username: isAnonymous ? null : _.sample(names),
    amount: _.random(50, 666),
    currency: 'RUB',
    message: noMessage ? null : _.shuffle(lorem.split(' ')).slice(0, _.random(0, 30)).join(' '),
  }
})

export default {
  overallDonationsAmount: {
    amount: donations.reduce((acc, d) => acc + d.amount, 0),
    currency: 'RUB',
  },

  subscriptionsNumber: {
    twitchFollower: 11,
    twitchSubscriber: 22,
    twitchPrimeSubscriber: 33,
    twitchGiftSubscriber: 44,
    twitchGiftRenewalSubscriber: 55,
    youtubeSubscriber: 66,
    youtubeSponsor: 77,
    vkSubscriber: 88,
    boostySubscriber: 99,
  },

  subscribersNumber: {
    twitchFollower: 10,
    twitchSubscriber: 22,
    vkLiveFollower: 1337,
    vkLiveSubscriber: 69,
  },

  viewersNumber: {
    vkLiveViewer: 256,
  },

  currentMediaName: {
    media: 'Rick Astley - Never Gonna Give You Up (Official Music Video)',
    username: 'D0N4TELL0',
  },

  productsSoldNumber: '1337',

  lastSubscribers: Array.from({ length: 100 }, (v, k) => {
    const noMessage = _.random(1, 10) <= 8

    return {
      username: _.sample(names),
      message: noMessage ? null : _.shuffle(lorem.split(' ')).slice(0, _.random(0, 30)).join(' '),
    }
  }),

  topDonations: _.sortBy(donations, (d) => -d.amount),

  topDonators: _.sortBy(
    Object.values(
      donations.reduce((acc, donation) => {
        if (!acc[donation.username]) {
          acc[donation.username] = {
            username: donation.username,
            amount: donation.amount,
            currency: donation.currency,
          }
        } else {
          acc[donation.username].amount += donation.amount
        }
        return acc
      }, {}),
    ), (d) => -d.amount,
  ),

  lastDonations: donations,
}
