<style scoped lang="scss">
  .stream-stats-design-form {

  }
</style>

<template>
  <div class="stream-stats-design-form">
    <form-group :label="$t('global.common.verticalAlign.label')" inline>
      <dropdown-select v-model="verticalAlign" :options="verticalAlignOptions"/>
    </form-group>
    <form-group inline :label="$t('streamStats.labels.mainRowStyle')">
      <text-styler v-model="mainRowTextStyles" class="bottom"/>
    </form-group>
    <form-group v-if="allowAdditionalTemplate" inline :label="$t('streamStats.labels.additionalRowStyle')">
      <text-styler v-model="additionalRowTextStyles" class="bottom"/>
    </form-group>
  </div>
</template>

<script>

import TextStyler from '@components/BaseComponents/TextStyler/TextStyler'
import dataTypeSettings from '@src/config/streamStats/dataTypeSettings'
import { defaultWidgetDesign } from '@src/config/streamStats/defaultValues'

const defaultDesign = defaultWidgetDesign()

export default {
  name: 'StreamStatsDesignForm',
  components: {
    TextStyler,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    settings: {
      type: Object,
    },
  },
  data() {
    return {
      verticalAlign: defaultDesign.verticalAlign,
      mainRowTextStyles: defaultDesign.mainRowTextStyles,
      additionalRowTextStyles: defaultDesign.additionalRowTextStyles,
    }
  },
  computed: {
    output() {
      return {
        verticalAlign: this.verticalAlign,
        mainRowTextStyles: this.mainRowTextStyles,
        additionalRowTextStyles: this.additionalRowTextStyles,
      }
    },

    dataTypeSettings() {
      return dataTypeSettings[this.settings.dataType]
    },

    allowAdditionalTemplate() {
      return _.get(this.dataTypeSettings, 'allowAdditionalTemplate', false)
        && this.settings.displayMode === 'slider'
    },

    verticalAlignOptions() {
      return [
        'top',
        'middle',
        'bottom',
      ].map(option => ({
        label: this.$t(`global.common.verticalAlign.${option}`),
        value: option,
      }))
    },
  },
  watch: {
    output: {
      handler(value) {
        this.$emit('input', value)
      },
      // immediate: true,
    },
    value: {
      handler(value) {
        if (value) {
          Object.keys(value).forEach(key => {
            if (this.hasOwnProperty(key)) {
              this[key] = value[key]
            }
          })
        }
      },
      immediate: true,
    },
  },
}
</script>
