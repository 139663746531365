<style lang="scss" scoped>
  .button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    white-space: nowrap;
    cursor: pointer;
    letter-spacing: -0.5px;
    font-size: $control-font-size;
    line-height: $control-line-height;
    @include padding-x($control-padding-x);
    @include padding-y($control-padding-y);
    border: none;
    box-shadow: none;
    min-width: 0;
    border-radius: $control-border-radius;

    .button-text {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .icon {
      flex-shrink: 0;

      &-left.mg {
        margin-right: 12px;
      }

      &-right.mg {
        margin-left: 12px;
      }
    }

    &, &:hover, &:active, &:focus {
      text-decoration: none;
      outline: 0 none;
    }

    &-primary-action {
      @include padding-x(20px);
      @include padding-y($control-padding-y + $control-border-width);
      background-image: linear-gradient(255deg, var(--theme-color-main), var(--theme-color-additional));
      color: white;
      font-weight: bold;
      text-transform: uppercase;

      &:hover {
        background-image: none;
        background-color: white;
        color: var(--theme-color-main);
      }

      &:disabled {
        opacity: .5;
      }
    }

    &-secondary-action {
      @include padding-x(20px);
      @include padding-y($control-padding-y + $control-border-width);
      background-color: $additional-2;
      color: white;
      font-weight: bold;
      text-transform: uppercase;

      &:hover {
        background-color: $additional-1;
      }

      &:disabled {
        color: $black;
      }
    }

    &-default {
      border: $control-border-width solid transparent;
      background-color: $additional-2;
      color: white;
      font-weight: 500;

      .icon {
        fill: url(#theme_svg_gradient);
      }

      &.control {
        color: rgba($bright, .7);

        .icon {
          fill: currentColor;
        }
      }

      &:hover:not(.opened) {
        background-color: $additional-1;
      }

      &.opened {
        border-color: var(--theme-color-additional);
      }

      &:disabled {
        border-color: transparent;
        color: $black;

        .icon {
          fill: currentColor;
        }
      }
    }

    &-link {
      color: $bright;
      background-color: transparent;
      transition: none;
      padding: 0;

      &.primary {
        text-transform: uppercase;
        color: var(--theme-color-additional);
        font-weight: 500;

        .icon {
          fill: url(#theme_svg_gradient);
        }
      }

      &:hover,
      &:focus,
      &:active {
        text-decoration: none;
        border-color: transparent;
        box-shadow: none;
      }

      .icon {
        color: rgba(white, 0.6);
      }

      &:hover {
        color: $bright;

        .icon {
          fill: url(#theme_svg_gradient);
        }
      }

      &:disabled {
        color: rgba(white, 0.4);
        background: none;

        .icon {
          color: rgba(white, 0.4);
        }

        &:hover {
          color: rgba(white, 0.4);

          .icon {
            color: rgba(white, 0.4);
            fill: currentColor;
          }
        }
      }
    }

    &:disabled {
      &, &:hover, &:focus {
        cursor: not-allowed;
      }
    }

    &.button-lg {
      font-size: 18px;
    }

    &.button-sm {
      @include padding-y(4px);
      @include padding-x(10px);
      font-size: 12px;
    }

    .loader {
      position: absolute;
      font-size: 20px;
    }

    &-loading {
      &, &:disabled, .icon {
        color: transparent !important;
      }
    }

    &.supreme {
      text-transform: uppercase;
      font-weight: 600;
    }
  }
</style>

<template>
  <component
    :is="component"
    :to="to"
    :disabled="buttonDisabled"
    class="button"
    :class="classes"
    :type="buttonType"
    v-on="listeners">
    <transition name="fade">
      <loader v-if="isLoading"/>
    </transition>
    <icon
      v-if="iconLeft"
      class="icon-left"
      :class="iconClasses"
      :name="iconLeft"/>
    <div v-if="$slots.default" class="button-text">
      <slot/>
    </div>
    <icon
      v-if="iconRight"
      class="icon-right"
      :class="iconClasses"
      :name="iconRight"/>
  </component>
</template>

<script>
export default {
  props: {
    variant: {
      type: String,
      default: 'default',
    },
    size: {
      type: String,
      default: '',
    },
    iconLeft: {
      type: String,
      default: '',
    },
    iconRight: {
      type: String,
      default: '',
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    to: {
      type: [Object, String],
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'button',
    },
  },
  computed: {
    component() {
      return this.$attrs.href ? 'a' : (this.to ? 'router-link' : 'button')
    },

    buttonType() {
      return this.to || this.$attrs.href ? null : this.type
    },

    buttonDisabled() {
      return this.disabled || this.isLoading
    },

    classes() {
      return {
        disabled: this.buttonDisabled,
        [`button-${this.variant}`]: !!this.variant,
        [`button-${this.size}`]: !!this.size,
        'button-block': this.block,
        'button-loading': this.isLoading,
      }
    },

    iconClasses() {
      return {
        mg: !!this.$slots.default,
      }
    },

    listeners() {
      return this.disabled || this.isLoading ? undefined : this.$listeners
    },
  },
}
</script>
