<template>
  <div>
    <loader
      size="md"
      v-if="isStatisticsLoading"
    />
    <div v-else>
      <stub v-if="statistics === null">
        <template v-slot:title>
          <Title type="2">{{ $t('bft.settings.stubTitle') }}</Title>
        </template>

        <template v-slot:content>
          <p>{{ $t('bft.settings.stubContent') }}</p>

          <div class="button-container">
            <bft-download-link/>
          </div>
        </template>
      </stub>

      <div class="stub" v-if="statistics !== null && statistics.length === 0">
        <p>{{ $t('bft.statistics.stubContent') }}</p>
      </div>

      <div v-if="statistics !== null && statistics.length > 0">
        <table-container>
          <table-header>
            <table-cell>{{ $t('bft.statistics.gameLabel') }}</table-cell>
            <table-cell>{{ $t('bft.statistics.categoryLabel') }}</table-cell>
            <table-cell>{{ $t('bft.statistics.actionLabel') }}</table-cell>
            <table-cell>
              <span
                class="link"
                @click="toggleSort"
              >{{ $t('bft.statistics.countLabel') + (sort === 'desc' ? ' ↓' : ' ↑') }}</span>
            </table-cell>
            <table-cell>{{ $t('bft.statistics.sumLabel') }}</table-cell>
          </table-header>

          <table-row
            v-for="item, i in statistics"
            :key="i"
          >
            <table-cell>{{ item.game.title }}</table-cell>
            <table-cell>{{ item.category.title }}</table-cell>
            <table-cell>{{ item.action.title }}</table-cell>
            <table-cell>{{ item.total_count }}</table-cell>
            <table-cell>{{ costTransform(item.total_profit.amount, item.total_profit.currency) }}</table-cell>
          </table-row>
        </table-container>

        <div class="pagination" v-if="statistics.length > 0">
          <pagination
            v-if="pagination.last_page && pagination.last_page > 1"
            v-model="page"
            :current-page="pagination.current_page"
            :pages-count="pagination.last_page"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import currency from 'currency.js'

import Title from '@components/BaseComponents/Title'
import Pagination from '@components/BaseComponents/Pagination'
import BaseButton from '@components/BaseComponents/BaseButton'

import StatisticsTable from '@components/Dashboard/Bft/StatisticsTable'
import Stub from '@components/Dashboard/Bft/Stub'
import BftDownloadLink from '@pages/Dashboard/Bft/BftDownloadLink'


export default {
  name: 'ChaosTricksStatisticsPage',

  components: {
    BftDownloadLink,
    Title,
    Pagination,
    Stub,
    ...StatisticsTable,
    BaseButton,
  },


  data() {
    return {
      page: 1,
    }
  },

  metaInfo() {
    return {
      title: [
        this.$t('dashboard.pages.bft.statistics'),
        this.$t('dashboard.pages.bft.bft'),
      ].join(' - '),
    }
  },

  watch: {
    page: {
      handler(newVal, oldVal) {
        if (oldVal && oldVal !== newVal) {
          this.$router.push({ query: { page: newVal} })
          this.getStatistics({ page: newVal })
        }
      },
      immediate: true,
    },
  },

  created() {
    const currentPage = +this.$route.query.page || 1

    this.getStatistics({ page: currentPage })
    this.page = currentPage

    this.getStats()
  },

  computed: {
    ...mapGetters('bft/statistics', [
      'isStatisticsLoading',
      'statistics',
      'pagination',
      'sort',
    ]),
  },

  methods: {
    ...mapActions('bft/statistics', ['getStatistics', 'toggleSort']),

    ...mapActions('bft/settings', ['getStats']),

    costTransform(cost, amount) {
      const _cost = currency(Math.abs(cost), {
        separator: ' ',
        decimal: ','
      }).format()

      const _amount = amount
        .replace('USD', '$')
        .replace('RUB', '₽')

      return `${ _cost } ${ _amount }`
    },
  },
}
</script>

<style scoped lang="scss">
.stub {
  margin-top: 80px;
  color: #838383;
  font-size: 14px;
  text-align: center;
}

.link {
  color: var(--theme-color-main);
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.button-container {
  margin-top: 20px;
}
</style>
