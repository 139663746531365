<style scoped lang="scss">
  .widget-roulette-win-effect {
    display: flex;
    align-items: center;
    justify-content: center;

    .roulette-win-effect-place {
      height: 0;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      .roulette-win-effect-video {
        position: absolute;
        max-width: 1000px;
        max-height: 300px;
      }
    }
  }
</style>

<template>
  <div v-if="winEffect" class="widget-roulette-win-effect">
    <div class="roulette-win-effect-place" :style="winEffect.placeStyles">
      <video
        ref="win-effect"
        class="roulette-win-effect-video"
        muted
        :key="winEffect.src"
        :src="winEffect.src"
        :style="winEffect.styles"/>
    </div>
  </div>
</template>

<script>

import { createCssFilters } from '@utils/utils'

export default {
  name: 'WidgetRouletteWinEffect',
  components: {},
  props: {
    settings: {
      type: Object,
      default: null,
    },
    offset: {
      type: [String, Number],
      default: 0,
    }
  },
  data() {
    return {
      visible: false,
    }
  },
  computed: {
    ...mapGetters('fileManager', ['getFile']),

    effectImage() {
      const {
        winEffect,
      } = this.settings

      this.fetchFile(winEffect)

      return this.getFile(winEffect)
    },

    winEffect() {
      if (!this.settings || !this.effectImage) {
        return null
      }

      const {
        winEffectHue,
        winEffectSaturate,
      } = this.settings

      return {
        src: this.effectImage.url,
        styles: {
          opacity: this.visible ? 1 : 0,
          filter: createCssFilters({
            hue: winEffectHue,
            saturate: winEffectSaturate,
          }),
        },
        placeStyles: {
          transform: `translateX(${this.offset}px)`,
        },
      }
    },
  },
  methods: {
    playWinEffect() {
      const winEffectElement = this.$refs['win-effect']

      if (winEffectElement) {
        this.visible = true
        winEffectElement.onended = () => {
          this.visible = false
        }
        winEffectElement.play()
      }
    },
  },
  created() {
    this.$emit('play-method-update', this.playWinEffect)
  },
}
</script>
