<template>
  <div>
    <info-box class="global-notification" v-if="showTinNotification">
      <template>
        <tag-replacer class="tag-replacer" :text="$t('dashboard.notifications.tin')">
          <template #settingslink="{ content }">
            <router-link to="/dashboard/offers-v2/settings">{{ content }}</router-link>
          </template>
        </tag-replacer>
      </template>
    </info-box>
  </div>
</template>

<script>
export default {
  name: 'GlobalNotifications',

  computed: {
    ...mapState('application', ['isAstrumApp']),
    ...mapGetters('profile', ['getUserIsStreamer']),
    ...mapGetters('offers/settings', ['settings']),

    tin: (vm) => vm.settings?.tin,
    showTinNotification: (vm) => vm.isAstrumApp && !vm.tin,
  },

  methods: {
    ...mapActions('offers/settings', ['fetchSettings']),
  },

  created() {
    if (this.getUserIsStreamer) {
      this.fetchSettings()
    }
  },
}
</script>

<style lang="scss" scoped>
.global-notification {
  margin-bottom: 20px;
}
</style>
