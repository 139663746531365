export const cardRarity = [
  {
    value: 'common',
    color: '#ffffff',
    chance: 500,
  },
  {
    value: 'uncommon',
    color: '#37ff00',
    chance: 250,
  },
  {
    value: 'rare',
    color: '#00c8ff',
    chance: 160,
  },
  {
    value: 'epic',
    color: '#ff00fb',
    chance: 70,
  },
  {
    value: 'legendary',
    color: '#f57507',
    chance: 15,
  },
  {
    value: 'artifact',
    color: '#e5cc80',
    chance: 5,
  },
]

export const getRarityValues = field => {
  return cardRarity.reduce((acc, rarity) => {
    acc[rarity.value] = rarity[field]
    return acc
  }, {})
}

export const rouletteBoxAnimations = {
  'slide-from-left': {
    translateX: -1600,
  },
  'slide-from-right': {
    translateX: 1600,
  },
  'slide-from-top': {
    translateY: -500,
  },
  'slide-from-bottom': {
    translateY: 500,
  },
  'slide-to-left': {
    translateX: -1600,
  },
  'slide-to-right': {
    translateX: 1600,
  },
  'slide-to-top': {
    translateY: -500,
  },
  'slide-to-bottom': {
    translateY: 500,
  },
}

export const generateSlots = (categories) => {
  const rarityChances = getRarityValues('chance')

  return _.sortBy(categories.reduce((slots, category) => {
    if (category.useCards) {
      const cards = category.cards.split(',')

      cards.forEach((card, index) => {
        slots.push({
          id: `${category.id}_${index}`,
          settings: category.settings,
          text: card,
          winText: card,
          chance: rarityChances[category.rarity] / cards.length,
        })
      })
    } else {
      slots.push({
        id: `${category.id}`,
        settings: category.settings,
        text: null,
        winText: category.name,
        chance: rarityChances[category.rarity],
      })
    }

    return slots
  }, []), 'chance')
}

export const generateSlotsPool = (slots) => {
  return slots.reduce((result, slot) => {
    for (let i = 1; i < slot.chance * 100; i++) {
      result.push(slot.id)
    }

    return result
  }, [])
}

export const pickWinner = slotsPool => _.sample(slotsPool)

export const generateSlotsLine = (slotsPool, lineLength, winnerPosition, winnerSlotId) => {
  const slotsLine = _.shuffle(_.shuffle(slotsPool)).slice(0, lineLength)

  if (slotsLine.length) {
    slotsLine[winnerPosition - 1] = winnerSlotId
  }

  return slotsLine
}

export const generateRoulette = ({ categories, lineLength, winnerPosition }) => {
  const slots = generateSlots(categories)
  const slotsPool = generateSlotsPool(slots)
  const winnerSlotId = pickWinner(slotsPool)
  const slotsLine = generateSlotsLine(slotsPool, lineLength, winnerPosition, winnerSlotId)

  return {
    slots,
    winnerSlotId,
    slotsLine,
  }
}
