<style scoped lang="scss">
  .roulette-card-frame {
    display: flex;
    align-items: center;
    justify-content: center;

    .card-frame-border {
      fill: transparent;
    }
  }
</style>

<template>
  <div v-if="frame" class="roulette-card-frame">
    <svg-gradient
      v-if="frame.gradient"
      :name="frame.gradient.name"
      :colors="frame.gradient.colors"
      :angle="frame.gradient.angle"
      :hard="frame.gradient.hard"/>
    <svg v-if="frame.type === 'border'" class="card-frame-border" :style="frame.border.svgStyles">
      <rect
        :x="frame.border.rectStyles.x"
        :y="frame.border.rectStyles.y"
        :rx="frame.border.rectStyles.rx"
        :ry="frame.border.rectStyles.ry"
        :stroke="frame.border.rectStyles.stroke"
        :stroke-width="frame.border.rectStyles.strokeWidth"
        :width="frame.border.rectStyles.width"
        :height="frame.border.rectStyles.height"/>
    </svg>
    <svg-image-external
      v-if="frame.type === 'svg' && frame.svg"
      :src="frame.svg.src"
      :style="frame.svg.styles"
      class="card-frame-svg"/>
    <template v-if="frame.type === 'image' && frame.image">
      <video
        class="card-frame-video"
        autoplay
        loop
        v-if="frame.image.type === 'webm'"
        :src="frame.image.src"
        :style="frame.image.styles"/>
      <img
        v-else
        class="card-frame-image"
        :src="frame.image.src"
        :style="frame.image.styles"/>
    </template>
  </div>
</template>

<script>
import {
  numbersToUnits,
  createCssFilters,
  createGradientString,
} from '@utils/utils'
import SvgGradient from '@components/BaseComponents/SvgGradient'
import SvgImageExternal from '@components/BaseComponents/SvgImageExternal'

export default {
  name: 'RouletteCardFrame',
  components: {
    SvgImageExternal,
    SvgGradient,
  },
  props: {
    settings: {
      type: Object,
      default: null,
    },
  },
  computed: {
    ...mapGetters('fileManager', ['getFile']),

    frameImage() {
      const {
        frameImage,
      } = this.settings

      this.fetchFile(frameImage)

      return this.getFile(frameImage)
    },

    frameSvg() {
      const {
        frameSvg,
      } = this.settings

      this.fetchFile(frameSvg)

      return this.getFile(frameSvg)
    },

    frame() {
      if (!_.get(this.settings, 'frameType', null)) {
        return null
      }

      const {
        borderWidth,
        borderRadius,
        frameColor = {},
        frameShadow,
        frameSize,
        frameType,
        frameImageHue,
        frameImageSaturate,
      } = this.settings

      const { color = null, gradient = {}, useGradient = false } = frameColor
      const { colors = [], angle = 0, hard = false } = gradient

      const gradientName = `_card_frame_${this.$componentId}`
      const gradientLink = `url(#${gradientName})`

      const frame = {
        type: frameType,
      }

      if (useGradient) {
        frame.gradient = {
          name: gradientName,
          colors,
          angle,
          hard,
        }
      }

      if (frameType === 'border') {
        frame.border = {
          svgStyles: {
            width: `${frameSize}px`,
            height: `${frameSize}px`,
            filter: createCssFilters({
              shadow: frameShadow,
            }),
          },
          rectStyles: {
            width: `${frameSize - (borderWidth)}px`,
            height: `${frameSize - (borderWidth)}px`,
            x: borderWidth / 2,
            y: borderWidth / 2,
            ry: borderRadius,
            rx: borderRadius,
            stroke: useGradient ? gradientLink : color,
            strokeWidth: borderWidth,
          },
        }
      }

      if (frameType === 'svg' && this.frameSvg) {
        frame.svg = {
          src: this.frameSvg.url,
          styles: {
            width: `${frameSize}px`,
            height: `${frameSize}px`,
            filter: createCssFilters({
              shadow: frameShadow,
            }),
            fill: useGradient ? gradientLink : color,
          },
        }
      }

      if (frameType === 'image' && this.frameImage) {
        frame.image = {
          type: this.frameImage.ext,
          src: this.frameImage.url,
          styles: {
            filter: createCssFilters({
              shadow: frameShadow,
              hue: frameImageHue,
              saturate: frameImageSaturate,
            }),
            maxWidth: `${frameSize}px`,
            maxHeight: `${frameSize}px`,
          },
        }
      }

      return frame
    },
  },
}
</script>
