<style scoped lang="scss">
  .chaos-tricks-banner {
    height: 100px;
    position: relative;
    background-image: appAsset('/images/banners/chaos-tricks/bg.svg');
    background-size: cover;
    background-position: top right;
    overflow: hidden;
    border-radius: 8px;
    display: flex;
    align-items: center;
    padding-right: 30px;
    padding-left: 20px;

    @include media(max, sm) {
      flex-direction: column;
      height: auto;
      justify-content: center;
      align-items: flex-start;
      @include padding-y(20px);
      background-image: appAsset('/images/banners/chaos-tricks/bg-mobile.svg');
    }

    .pixel {
      position: fixed;
      width: 0;
      height: 0;
      top: -10000px;
    }

    .logo {
      flex-shrink: 0;
      width: 64px;
      height: 64px;
      background-image: appAsset('/images/banners/chaos-tricks/logo@1x.png');
      background-size: contain;

      @include mediaDPI() {
        background-image: appAsset('/images/banners/chaos-tricks/logo@2x.png');
      }

      @include media(max, sm) {
        position: absolute;
        right: 20px;
        top: 20px;
      }
    }

    .content {
      margin-left: 15px;

      @include media(max, sm) {
        margin-top: 10px;
        margin-left: 0;
        margin-right: 100px;
      }

      .text {
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #FFFFFF;
      }

      .title {
        margin-top: 10px;
      }
    }

    .control {
      margin-left: auto;
      background: #FFFFFF;
      border-radius: 8px;
      padding: 12px 44px;
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      text-transform: uppercase;
      color: #000000;

      @include media(max, sm) {
        margin-left: 0;
        margin-top: 20px;
      }
    }
  }
</style>

<template>
  <div class="chaos-tricks-banner">
    <img class="pixel" src="https://trk.mail.ru/i/ctlnu5"/>
    <div class="logo"/>
    <div class="content">
      <div class="text">{{ $t('dashboard.banners.chaosTricks.text') }}</div>
      <div class="title"><img :src="$asAppAsset('/images/banners/chaos-tricks/title.svg')"/></div>
    </div>
    <a href="https://trk.mail.ru/c/ctlnu5" target="_blank" class="control">
      {{ $t('dashboard.banners.chaosTricks.button') }}
    </a>
  </div>
</template>

<script>

export default {
  name: 'ChaosTricksBanner',
}
</script>
