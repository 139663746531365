<style scoped lang="scss">
  .roulette-background-settings {
    
    .settings-area {
      @include media(max, md) {
        grid-template-columns: auto !important;
      }
    }

  }
</style>

<template>
  <div class="roulette-background-settings">
    <grid columns="2" gap="40" class="settings-area">
      <div>
        <form-group inline :label="$t('global.settings.labels.type')">
          <dropdown-select v-model="backgroundType" :options="backgroundTypeOptions"/>
        </form-group>
        <form-group inline :label="$t('global.settings.labels.borderRadius')">
          <base-range :min="0" :max="100" v-model="backgroundBorderRadius" input input-addon="px"/>
        </form-group>
        <template v-if="backgroundType === 'image'">
          <form-group inline :label="$t('global.settings.labels.background')">
            <file-manager v-model="backgroundImage" widget-element="roulette_background" file-type="image"/>
          </form-group>
          <template v-if="backgroundImage">
            <form-group inline :label="$t('global.settings.labels.hueRotation')">
              <base-range :min="0" :max="360" v-model="backgroundImageHue" input input-addon="°"/>
            </form-group>
            <form-group inline :label="$t('global.settings.labels.saturate')">
              <base-range :min="0" :max="300" v-model="backgroundImageSaturate" input input-addon="%"/>
            </form-group>
          </template>
        </template>

        <template v-if="backgroundType === 'color'">
          <form-group inline :label="$t('global.settings.labels.backgroundColor')">
            <color-gradient-picker v-model="backgroundColor"/>
          </form-group>
        </template>

        <template v-if="backgroundType === 'image'">
          <form-group
            v-tooltip="tooltips.backgroundImageParallax"
            inline
            :label="$t('roulette.visualSettings.background.parallaxEffect')">
            <base-switch v-model="backgroundImageParallax"/>
          </form-group>
        </template>
      </div>
      <div>
        <form-group v-if="backgroundType" inline :label="$t('global.settings.labels.shadow')">
          <shadow-styler v-model="backgroundShadow" class="left"/>
        </form-group>
      </div>
    </grid>
  </div>
</template>

<script>
import ShadowStyler from '@components/BaseComponents/ShadowStyler/ShadowStyler'
import ColorGradientPicker from '@components/BaseComponents/ColorPicker/ColorGradientPicker'
import FileManager from '@components/BaseComponents/FileManager/FileManager'

export default {
  name: 'RouletteBackgroundSettings',
  components: {
    FileManager,
    ColorGradientPicker,
    ShadowStyler,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      backgroundType: null,
      backgroundImageParallax: true,

      backgroundImage: null,
      backgroundImageHue: 0,
      backgroundImageSaturate: 100,

      backgroundColor: null,

      backgroundShadow: null,
      backgroundBorderRadius: 20,
    }
  },
  computed: {
    backgroundTypeOptions(){
      return [
        {
          value: null,
          icon: 'cross',
        },
        {
          value: 'image',
          icon: 'image',
        },
        {
          value: 'color',
          icon: 'palette',
        },
      ].map(option => ({
        ...option,
        label: option.value ? this.$t(`global.settings.labels.types.${option.value}`) : null,
      }))
    },

    tooltips() {
      return {
        backgroundImageParallax: this.$tooltip(
          'Во время прокрутки рулетки фон будет прокручиваться вместе с карточками'),
      }
    },

    output() {
      return {
        _version: Math.random().toString(36).slice(2),
        backgroundType: this.backgroundType,
        backgroundImageParallax: this.backgroundImageParallax,
        backgroundImage: this.backgroundImage,
        backgroundImageHue: this.backgroundImageHue,
        backgroundImageSaturate: this.backgroundImageSaturate,
        backgroundColor: this.backgroundColor,
        backgroundShadow: this.backgroundShadow,
        backgroundBorderRadius: this.backgroundBorderRadius,
      }
    },
  },
  created() {
    const storedValue = _.cloneDeep(this.value)

    if (storedValue) {
      Object.keys(storedValue).forEach(key => {
        this[key] = storedValue[key] ?? this[key]
      })
    }
  },
  watch: {
    output(value) {
      this.$emit('input', value)
    },
    backgroundImage(image, oldImage) {
      if (oldImage && oldImage !== image) {
        this.backgroundImageHue = 0
        this.backgroundImageSaturate = 100
      }
    },
  },
}
</script>
