<style scoped lang="scss">
  .widget-roulette-frame {
    .roulette-frame-svg {
      @include position-all(absolute, 100px);
      fill: transparent;
    }

    .roulette-frame-image {
      @include position-all(absolute, 0);
      fill: transparent;
    }
  }
</style>

<template>
  <div v-if="frame" class="widget-roulette-frame">
    <svg-gradient
      v-if="frame.gradient"
      :name="frame.gradient.name"
      :colors="frame.gradient.colors"
      :angle="frame.gradient.angle"
      :hard="frame.gradient.hard"/>
    <svg v-if="frame.type === 'border'" class="roulette-frame-svg" :style="frame.border.svgStyles">
      <rect
        :x="frame.border.rectStyles.x"
        :y="frame.border.rectStyles.y"
        :rx="frame.border.rectStyles.rx"
        :ry="frame.border.rectStyles.ry"
        :stroke="frame.border.rectStyles.stroke"
        :stroke-width="frame.border.rectStyles.strokeWidth"
        :width="frame.border.rectStyles.width"
        :height="frame.border.rectStyles.height"/>
    </svg>
    <img
      v-if="frame.type === 'image' && frame.image"
      class="roulette-frame-image"
      :src="frame.image.src"
      :style="frame.image.styles"/>
  </div>
</template>

<script>

import SvgGradient from '@components/BaseComponents/SvgGradient'
import { createCssFilters } from '@utils/utils'

export default {
  name: 'WidgetRouletteFrame',
  components: {
    SvgGradient,
  },
  props: {
    settings: {
      type: Object,
      default: null,
    },
  },
  computed: {
    ...mapGetters('fileManager', ['getFile']),

    frameImage() {
      const {
        frameImage,
      } = this.settings

      this.fetchFile(frameImage)

      return this.getFile(frameImage)
    },

    frame() {
      if (!_.get(this.settings, 'frameType', null)) {
        return null
      }

      const {
        borderWidth,
        borderRadius,
        frameColor = {},
        frameShadow,
        frameType,
        frameImageHue,
        frameImageSaturate,
      } = this.settings

      const { color = null, gradient = {}, useGradient = false } = frameColor
      const { colors = [], angle = 0, hard = false } = gradient

      const gradientName = `_roulette_frame_${this.$componentId}`
      const gradientLink = `url(#${gradientName})`

      const frame = {
        type: frameType,
      }

      if (useGradient) {
        frame.gradient = {
          name: gradientName,
          colors,
          angle,
          hard,
        }
      }

      if (frameType === 'border') {
        frame.border = {
          svgStyles: {
            marginTop: `-${borderWidth / 2}px`,
            marginLeft: `-${borderWidth / 2}px`,
            width: `${1000 + borderWidth}px`,
            height: `${200 + borderWidth}px`,
            filter: createCssFilters({
              shadow: frameShadow,
            }),
          },
          rectStyles: {
            width: `calc(100% - ${borderWidth}px)`,
            height: `calc(100% - ${borderWidth}px)`,
            x: borderWidth / 2,
            y: borderWidth / 2,
            ry: borderRadius,
            rx: borderRadius,
            stroke: useGradient ? gradientLink : color,
            strokeWidth: borderWidth,
            filter: createCssFilters({
              shadow: frameShadow,
            }),
          },
        }
      }

      if (frameType === 'image' && this.frameImage) {
        frame.image = {
          src: `${this.frameImage.url}`,
          styles: {
            filter: createCssFilters({
              shadow: frameShadow,
              hue: frameImageHue,
              saturate: frameImageSaturate,
            }),
          },
        }
      }

      return frame
    },
  },
}
</script>
