<style scoped lang="scss">
  .roulette-config {
    .widget-roulette-preview {
      position: relative;
      height: 400px;
      overflow: hidden;

      .widget-roulette {
        position: absolute;
      }
    }

    .config-content {
      position: relative;

      .roulette-behavior-controls {
        position: absolute;
        top: 0;
        right: 0;
      }

      .config-content {
        margin-top: 20px;
      }

      .tabs {
        @include media(max, md) {
          padding-top: 40px;
        }
      }

      .instruction {
        width: 100%;
        position: relative;

        &:after {
          display: block;
          content: '';
          padding-top: 56.25%;
        }
      }

      .iframe-instruction {
        display: block;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        position: absolute;
      }
    }

    .debug-box {
      width: 100%;
      padding: 10px 15px;
      border-radius: 5px;
      border: 1px solid rgba(white, .2);
    }
  }
</style>

<template>
  <div class="roulette-config" ref="config">
    <div class="widget-roulette-preview" :style="previewStyles.wrapper">
      <widget-roulette
        :style="previewStyles.widget"
        :settings="rouletteVisualSettings"
        :categories="getCategoriesList"/>
    </div>
    <div class="config-content">
      <roulette-behavior-controls/>
      <tabs v-model="currentTab" :list="tabs" class="tabs"/>
      <div class="config-content">
        <div v-if="currentTab === 'categories'" class="categories-config">
          <roulette-categories-settings/>
        </div>
        <div v-show="currentTab === 'design'" class="activation-config">
          <roulette-visual-settings @update="setRouletteVisualSettings"/>
        </div>
        <div v-show="currentTab === 'widget'" class="widget-config">
          <roulette-settings/>
        </div>
        <div v-show="currentTab === 'instruction'" class="instruction">
          <iframe
            class="iframe-instruction"
            src="https://www.youtube.com/embed/vaH3zp1cl0k"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen/>
        </div>
        <div v-if="currentTab === 'debug'" class="activation-config">
            <pre class="debug-box" ref="debug-box" @click="selectCopyDebug">{{
                rouletteVisualSettings
              }}</pre>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import WidgetRoulette from '@components/Widgets/WidgetRoulette/WidgetRoulette'
import RouletteVisualSettings from './RouletteVisualSettings/RouletteVisualSettings'
import RouletteCategoriesSettings from './RouletteCategoriesSettings/RouletteCategoriesSettings'
import RouletteBehaviorControls from './RouletteBehaviorControls'
import RouletteSettings from './RouletteSettings/RouletteSettings'
import { copyToClipboard } from '@utils/utils'

export default {
  name: 'RouletteConfig',
  components: {
    RouletteSettings,
    RouletteBehaviorControls,
    RouletteCategoriesSettings,
    WidgetRoulette,
    RouletteVisualSettings,
  },
  data() {
    return {
      currentTab: 'categories',

      rouletteVisualSettings: null,

      previewScaleK: 1,
    }
  },
  computed: {
    ...mapGetters('roulette', ['getCategoriesList']),

    tabs() {
      const tabs = [
        {
          key: 'categories',
          title: 'rouletteCategories',
        },
        {
          key: 'design',
          title: 'rouletteDesign',
        },
        // {
        //   key: 'presets',
        //   title: 'designPresets',
        // },
        {
          key: 'widget',
          title: 'widgetSettings',
        },
        {
          key: 'instruction',
          title: 'instruction',
        },
      ].map(tab => ({
        ...tab,
        title: this.$t(`roulette.tabs.${tab.title}`),
      }))

      if (process.env.NODE_ENV !== 'production') {
        tabs.push({
          key: 'debug',
          title: this.$t('global.common.debug'),
        })
      }

      return tabs
    },

    previewStyles() {
      return {
        wrapper: {
          height: `${400 * this.previewScaleK}px`,
        },
        widget: {
          transform: `scale(${this.previewScaleK})`,
        },
      }
    },
  },
  mounted() {
    setTimeout(this.appear, 500)

    this.calcPreviewScale()
  },
  methods: {
    ...mapMutations('roulette', {
      setLocalVisualSettings: 'SET_ROULETTE_VISUAL_SETTINGS_LOCAL',
    }),
    ...mapActions('widgets/presets', ['fetchPresets']),

    calcPreviewScale() {
      const $configEl = this.$refs.config

      if ($configEl) {
        const { width } = $configEl.getBoundingClientRect()

        if (width < 1400) {
          this.previewScaleK = width / 1400
        }
      }
    },

    selectCopyDebug() {
      window.getSelection().selectAllChildren(this.$refs['debug-box'])
      copyToClipboard(JSON.stringify(this.debugOutput))
    },

    appear() {
      this.$root.$emit('roulette.widget.appear')
    },

    setRouletteVisualSettings(settings) {
      this.rouletteVisualSettings = settings
      this.storeLocalVisualSettings()
    },

    storeLocalVisualSettings: _.debounce(function () {
      this.setLocalVisualSettings(_.cloneDeep(this.rouletteVisualSettings))
    }, 500),
  },
  created() {
    this.fetchPresets('roulette')
    this.fetchPresets('roulette-category')
    this.fetchPresets('roulette-goal')
  },
  watch: {
    $appWidth: 'calcPreviewScale',
  },
}
</script>
