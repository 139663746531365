<style scoped lang="scss">
  .widget-config {
    display: flex;

    @include media(max, sm) {
      flex-direction: column;
    }

    .vertical-tabs {
      align-self: flex-start;
    }

    .config-content {
      padding: 15px 20px;
      margin-left: 20px;
      background-color: $additional-3;
      border-radius: 5px;
      flex: 1 1 auto;
      display: flex;
      flex-direction: column;

      @include media(max, sm) {
        margin-top: 20px;
        margin-left: 0;
      }

      .config-tabs {
        margin-bottom: 20px;
        max-width: 540px;

        @include media(max, sm) {
          max-width: none;
        }
      }

      .config-controls {
        margin-top: auto;
        border-top: 1px solid rgba(white, .05);
        padding-top: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
</style>

<template>
  <div class="widget-config">
    <tabs v-if="$appIsSM" v-model="currentTab" :list="tabs"/>
    <vertical-tabs v-else v-model="currentTab" :list="tabs"/>

    <div class="config-content">
      <div class="config-tabs">
        <layout-settings
          v-model="layoutSettings"
          v-show="currentTab === 'layout'"/>
        <background-settings
          v-model="backgroundSettings"
          v-show="currentTab === 'background'"/>
        <title-settings
          v-model="titleSettings"
          :group-actions="layoutSettings.groupActions"
          v-show="currentTab === 'title'"/>
        <actions-settings
          v-model="actionsSettings"
          v-show="currentTab === 'actions'"/>
        <separator-settings
          v-model="separatorSettings"
          v-show="currentTab === 'separator'"/>
        <slider-settings
          v-model="sliderSettings"
          v-show="currentTab === 'slider'"/>
        <div v-if="currentTab === 'debug'">
          <pre>
            {{ output }}
          </pre>
        </div>
      </div>
      <div class="config-controls">
        <btn variant="primary-action" @click="save" :is-loading="loadingState.save">
          {{ $t('global.common.save') }}
        </btn>
      </div>
    </div>
  </div>
</template>

<script>

import BackgroundSettings from './BackgroundSettings'
import TitleSettings from './TitleSettings'
import ActionsSettings from './ActionsSettings'
import SeparatorSettings from './SeparatorSettings'
import SliderSettings from './SliderSettings'
import LayoutSettings from './LayoutSettings'

import { defaultDesignSettings } from '@src/config/bft/defaultValues'

const designSettings = defaultDesignSettings()

export default {
  name: 'WidgetConfig',
  components: {
    LayoutSettings,
    SliderSettings,
    SeparatorSettings,
    ActionsSettings,
    TitleSettings,
    BackgroundSettings,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loadingState: {
        save: false,
      },

      currentTab: 'layout',

      backgroundSettings: designSettings.backgroundSettings,
      titleSettings: designSettings.titleSettings,
      actionsSettings: designSettings.actionsSettings,
      separatorSettings: designSettings.separatorSettings,
      sliderSettings: designSettings.sliderSettings,
      layoutSettings: designSettings.layoutSettings,
    }
  },
  computed: {
    tabs() {
      const tabs = [
        {
          key: 'layout',
          title: 'layout',
        },
        {
          key: 'background',
          title: 'background',
        },
        {
          key: 'title',
          title: 'title',
        },
        {
          key: 'actions',
          title: 'actions',
        },
        {
          key: 'separator',
          title: 'separator',
        },
        {
          key: 'slider',
          title: 'slider',
        },
      ].map(tab => ({
        ...tab,
        title: this.$t(`bft.widget.tabs.${tab.title}`),
      }))

      if (process.env.NODE_ENV !== 'production') {
        tabs.push({
          key: 'debug',
          title: this.$t('global.common.debug'),
        })
      }

      return tabs
    },

    widgetDesign() {
      return {
        backgroundSettings: this.backgroundSettings,
        titleSettings: this.titleSettings,
        actionsSettings: this.actionsSettings,
        separatorSettings: this.separatorSettings,
      }
    },

    widgetLayout() {
      return {
        sliderSettings: this.sliderSettings,
        layoutSettings: this.layoutSettings,
      }
    },

    output() {
      return {
        ...this.widgetDesign,
        ...this.widgetLayout,
      }
    },
  },
  methods: {
    ...mapActions('bft/widget', ['updateWidgetSettings']),

    save() {
      this.loadingState.save = true

      this.updateWidgetSettings({
        design: this.widgetDesign,
        layout: this.widgetLayout,
      })
        .finally(() => {
          this.loadingState.save = false
        })
    },
  },
  created() {
    const storedValue = _.cloneDeep(this.value)

    if (storedValue) {
      Object.keys(storedValue).forEach(key => {
        this[key] = storedValue[key] ?? this[key]
      })
    }
  },
  watch: {
    output(value) {
      this.$emit('input', value)
    },

    value: {
      handler(value) {
        if (value) {
          Object.keys(value).forEach(key => {
            this[key] = value[key] ?? this[key]
          })
        }
      },
      immediate: true,
    },
  },
}
</script>
