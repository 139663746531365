<style scoped lang="scss">
  .category-frame-settings {

  }
</style>

<template>
  <div class="category-frame-settings">
    <form-group inline :label="$t('global.settings.labels.type')">
      <dropdown-select v-model="frameType" :options="frameTypeOptions"/>
    </form-group>
    <template v-if="frameType">
      <template v-if="frameType === 'svg' || frameType === 'border'">
        <template v-if="frameType === 'border'">
          <form-group inline :label="$t('global.settings.labels.borderWidth')">
            <base-range :min="0" :max="50" v-model="borderWidth" input input-addon="px"/>
          </form-group>
          <form-group inline :label="$t('global.settings.labels.borderRadius')">
            <base-range :min="0" :max="100" v-model="borderRadius" input input-addon="px"/>
          </form-group>
        </template>
        <form-group v-if="frameType === 'svg'" inline :label="$t('roulette.category.visualSettings.frame.frame')">
          <file-manager v-model="frameSvg" widget-element="roulette_card_frame" file-type="vector"/>
        </form-group>
        <form-group inline :label="$t('global.settings.labels.color')">
          <color-gradient-picker v-model="frameColor"/>
        </form-group>
      </template>
      <template v-if="frameType === 'image'">
        <form-group inline :label="$t('roulette.category.visualSettings.frame.frame')">
          <file-manager v-model="frameImage" widget-element="roulette_card_frame" file-type="image"/>
        </form-group>
        <form-group inline :label="$t('global.settings.labels.hueRotation')">
          <base-range :min="0" :max="360" v-model="frameImageHue" input input-addon="°"/>
        </form-group>
        <form-group inline :label="$t('global.settings.labels.saturate')">
          <base-range :min="0" :max="300" v-model="frameImageSaturate" input input-addon="%"/>
        </form-group>
      </template>
      <form-group inline :label="$t('global.settings.labels.size')">
        <base-range :min="10" :max="200" v-model="frameSize" input input-addon="px"/>
      </form-group>
      <form-group inline :label="$t('global.settings.labels.shadow')">
        <shadow-styler v-model="frameShadow" class="left"/>
      </form-group>
    </template>
  </div>
</template>

<script>
import ShadowStyler from '@components/BaseComponents/ShadowStyler/ShadowStyler'

import ColorGradientPicker from '@components/BaseComponents/ColorPicker/ColorGradientPicker'

import FileManager from '@components/BaseComponents/FileManager/FileManager'

export default {
  name: 'CategoryFrameSettings',
  components: {
    FileManager,

    ColorGradientPicker,

    ShadowStyler,
  },
  props: {
    value: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      frameType: null,

      frameSvg: null,

      frameImage: null,
      frameImageHue: 0,
      frameImageSaturate: 100,

      borderWidth: 6,
      borderRadius: 10,
      frameColor: {
        useGradient: true,
        color: '#f59c07',
        gradient: {
          colors: ['#f59c07', '#f57507'],
          angle: 135,
        },
      },
      frameSize: 160,
      frameShadow: null,
    }
  },
  computed: {
    frameTypeOptions() {
      return [
        {
          label: 'none',
          value: null,
          icon: 'cross',
        },
        {
          label: 'border',
          value: 'border',
          icon: 'circle',
        },
        {
          label: 'image',
          value: 'image',
          icon: 'image',
        },
        {
          label: 'vector',
          value: 'svg',
          icon: 'vector-square',
        },
      ].map(option => ({
        ...option,
        label: this.$t(`global.settings.labels.types.${option.label}`),
      }))
    },

    output() {
      return {
        _version: Math.random().toString(36).slice(2),
        borderWidth: this.borderWidth,
        borderRadius: this.borderRadius,
        frameColor: this.frameColor,
        frameShadow: this.frameShadow,
        frameSize: this.frameSize,
        frameType: this.frameType,
        frameSvg: this.frameSvg,
        frameImage: this.frameImage,
        frameImageHue: this.frameImageHue,
        frameImageSaturate: this.frameImageSaturate,
      }
    },

    frameSvgOptions() {
      return this.frameSvgOptionsData.map(frame => ({
        label: frame,
        value: `frames/${frame}`,
        icon: `frames/${frame}`,
      }))
    },
  },
  created() {
    if (this.value) {
      Object.keys(this.value).forEach(key => {
        this[key] = this.value[key] ?? this[key]
      })
    }
  },
  watch: {
    output(value) {
      this.$emit('input', value)
    },

    frameImage(image, oldImage) {
      if (oldImage && oldImage !== image) {
        this.frameImageHue = 0
        this.frameImageSaturate = 100
      }
    },
  },
}
</script>
