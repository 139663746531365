<style scoped lang="scss">
  .referral-program-page {
    .referral-program-wrapper {
      max-width: 612px;
    }

    .referral-program-info {
      display: flex;
      background-color: #262626;
      border-radius: 8px;
      padding: 10px 15px 20px 20px;

      .program-info-content {
        margin-right: 36px;

        .content-title {
          font-size: 20px;
          font-weight: 400;
          line-height: 30px;
          letter-spacing: -0.5px;
        }

        .content-text {
          margin-top: 8px;
          font-size: 12px;
          font-weight: 400;
          line-height: 16px;
          color: #838383;
        }

        .partner-link {
          margin-top: 20px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          .link-title {
            font-weight: 500;
            line-height: 20px;
          }

          .link-content {
            margin-top: 10px;
            color: #838383;
            word-break: break-word;
          }

          .link-copy {
            margin-top: 10px;
            display: flex;
            align-items: center;
            color: #F57D07;
            cursor: pointer;

            &:hover {
              color: #f59c07;
            }

            span {
              margin-left: 8px;
            }
          }
        }
      }

      .program-info-image {
        flex: 0 0 137px;
      }
    }

    .referral-block {
      margin-top: 30px;
      background-color: #262626;
      border-radius: 8px;
      padding: 15px 20px;

      .block-header {
        display: flex;
        align-items: center;

        .header-title {
          font-size: 20px;
          line-height: 30px;
          letter-spacing: -0.5px;
        }

        .header-period {
          margin-left: auto;
          font-weight: 500;
          line-height: 20px;
          text-transform: uppercase;

          span {
            color: #838383;
          }
        }
      }

      .block-body {
        margin-top: 20px;

        .chart {
          height: 260px;
        }
      }
    }

    .program-stats {
      display: flex;
      align-items: flex-start;
      padding-bottom: 5px;

      .stats-block {
        .stats-amount {
          font-size: 20px;
          line-height: 30px;
          letter-spacing: -0.5px;
        }

        .stats-label {
          color: #838383;
          font-size: 14px;
          line-height: 20px;
        }

        &.income {
          width: 170px;
        }
      }
    }
  }
</style>

<template>
  <div class="referral-program-page">
    <title>{{ this.$t('dashboard.pages.referralProgram') }}</title>

    <div v-if="!loadingState.data" class="referral-program-wrapper">
      <div class="referral-program-info">
        <div class="program-info-content">
          <div class="content-title">
            MemeAlerts
          </div>
          <div class="content-text">
            {{ this.$t('referralProgram.description', { name: 'MemeAlerts', amount: 5 }) }}
          </div>
          <div v-if="referralLink" class="partner-link">
            <div class="link-title">{{ this.$t('referralProgram.link') }}</div>
            <div class="link-content">{{ referralLink }}</div>
            <div class="link-copy" v-tooltip="tooltipOptions" @click="copyLink">
              <icon name="copy"/>
              <span>{{ this.$t('referralProgram.copyLink') }}</span>
            </div>
          </div>
        </div>
        <div class="program-info-image">
          <img :src="$asAppAsset('/images/banners/meme-alerts/logo-with-bg.png')"/>
        </div>
      </div>

      <div class="referral-program-income referral-block">
        <div class="block-header">
          <div class="header-title">{{ this.$t('referralProgram.profit') }}</div>
          <div class="header-period">
            {{
              this.$t('referralProgram.period')
            }}&nbsp;<span>{{ this.$t('referralProgram.periodThisYear') }}</span>
          </div>
        </div>
        <div class="block-body">
          <reactive-chart class="chart" :values="chartData" :tooltipLabels="profitByMonthsFormatted"/>
        </div>
      </div>

      <div class="referral-program-stats referral-block">
        <div class="block-header">
          <div class="header-title">{{ this.$t('referralProgram.stats') }}</div>
          <div class="header-period">
            {{
              this.$t('referralProgram.period')
            }}&nbsp;<span>{{ this.$t('referralProgram.periodAllTime') }}</span>
          </div>
        </div>
        <div class="block-body">
          <div class="program-stats">
            <div class="stats-block income">
              <div class="stats-amount">{{ totalProfitFormatted }}</div>
              <div class="stats-label">{{ this.$t('referralProgram.profit') }}</div>
            </div>
            <div class="stats-block referrals">
              <div class="stats-amount">{{ referralsNumber }}</div>
              <div class="stats-label">{{ this.$t('referralProgram.referralsNumber') }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import ReactiveChart from '@components/BaseComponents/ReactiveChart'
import Title from '@components/BaseComponents/Title'
import currency from 'currency.js'
import { copyToClipboard } from '@utils/utils'

const formatCurrency = amount => currency(Math.abs(amount), {
  separator: ' ',
  decimal: ',',
}).format()

export default {
  name: 'ReferralProgramPage',
  components: { Title, ReactiveChart },
  props: {
    program: {
      type: String,
      required: true,
    },
  },
  metaInfo() {
    const title = [
      this.$t('dashboard.pages.referralProgram.memeAlerts'),
      this.$t('dashboard.pages.referralProgram.referralProgram'),
    ]

    return {
      title: title.join(' - '),
    }
  },
  data() {
    return {
      loadingState: {
        data: true,
      },

      profitByMonths: [],
      totalProfit: 0,
      profitCurrency: 'RUB',

      referralsNumber: 0,

      referralLink: null,

      tooltipVisible: false,
      tooltipTimeout: null,
    }
  },
  computed: {
    ...mapGetters('currencies', ['getAmountWithCurrency']),

    tooltipOptions() {
      return {
        placement: 'top',
        trigger: 'manual',
        show: this.tooltipVisible,
        content: this.$t('global.widgetLink.linkCopied'),
      }
    },

    chartData() {
      const _date = new Date()
      return _.range(1, 12).map(r => ({
        label: dates.format(dates.setMonth(_date, r), 'LLLL').substring(0, 3),
        value: this.profitByMonths[r - 1],
      }))
    },

    profitByMonthsFormatted() {
      return this.profitByMonths.map(value => this.getAmountWithCurrency(formatCurrency(value), this.profitCurrency))
    },

    totalProfitFormatted() {
      return this.getAmountWithCurrency(formatCurrency(this.totalProfit), this.profitCurrency)
    },
  },
  methods: {
    copyLink() {
      clearTimeout(this.tooltipTimeout)
      copyToClipboard(this.referralLink)
      this.tooltipVisible = true
      setTimeout(() => {
        this.tooltipVisible = false
      }, 3000)
    },

    fetchData() {
      return api2.get('/referral/profit')
        .then(({ data }) => {
          const { by_months, total, currency } = data.stats.profits

          this.profitByMonths = by_months
          this.totalProfit = total
          this.profitCurrency = currency

          this.referralsNumber = 14

          this.referralLink = data.settings.referral_link
        })
        .finally(() => {
          this.loadingState.data = false
        })
    },
  },
  created() {
    this.fetchData()
  },
}
</script>
