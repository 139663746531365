<style scoped lang="scss">
  .file-manager-modal {

  }
</style>

<template>
  <modal v-model="modalOpened" class="file-manager-modal">
    <file-manager-form v-if="formVisible" slot="body"/>
  </modal>
</template>

<script>

import FileManagerForm from './FileManagerForm'

export default {
  name: 'FileManagerModal',
  components: { FileManagerForm },
  computed: {
    ...mapState('fileManager', ['fileManagerOpened', 'fileManagerWidgetElement']),

    formVisible() {
      return !!this.fileManagerWidgetElement
    },

    modalOpened: {
      get() {
        return this.fileManagerOpened
      },
      set(value) {
        if (!value) {
          this.closeFileManager()
        }
      },
    },
  },
  methods: {
    ...mapActions('fileManager', ['closeFileManager']),
  },
}
</script>
