<style scoped lang="scss">
  .category-card {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    transition: opacity .2s;
    position: relative;

    &:not(.enabled) {
      opacity: .4;
    }

    .category-header {
      display: flex;
      align-items: center;

      .category-name {
        font-size: 16px;
        font-weight: 500;
      }

      .category-controls {
        margin-left: auto;
        white-space: nowrap;

        .button + .button {
          margin-left: 10px;
        }
      }
    }

    .category-settings {
      flex: 1 0 auto;
      display: flex;
      flex-direction: column;
      margin-top: 15px;

      .category-cards-group {
        margin-bottom: 20px;
      }

      .category-rarity-group {
        margin-top: auto;
      }
    }

    .loader-container {
      @include position-all(absolute, 0);
      z-index: 15;
      background: rgba($additional-3, .9);
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .category-chance {
      display: flex;
      align-items: center;

      .icon {
        margin-left: 10px;
        color: $warm-grey;
      }
    }
  }
</style>

<template>
  <div v-if="category" class="category-card" :class="cardClasses">
    <transition name="fade">
      <div v-if="loadingState.update" class="loader-container">
        <loader size="lg"/>
      </div>
    </transition>
    <div class="category-header">
      <div class="category-name">{{ category.name }}</div>
      <div class="category-controls">
        <btn
          v-if="category.enabled"
          icon-left="eye"
          v-tooltip="tooltips.disable"
          @click="disableCategory"/>
        <btn
          v-else
          icon-left="eye-crossed"
          v-tooltip="tooltips.enable"
          @click="enableCategory"/>
        <btn
          icon-left="gear"
          v-tooltip="tooltips.edit"
          @click="editCategory"/>
      </div>
    </div>
    <div class="category-settings">
      <form-group
        v-if="category.useCards"
        :label="$t('roulette.category.cards')"
        class="category-cards-group">
        <div class="category-cards-text">{{ cardsText }}</div>
      </form-group>
      <form-group
        :label="$t('roulette.category.rarity')"
        class="category-rarity-group">
        <colored-select
          v-tooltip="tooltips.rarity"
          v-model="rarity"
          :options="categoryRarityOptions"/>
      </form-group>
      <form-group
        :label="$t('roulette.category.chance')">
        <div class="category-chance">
          {{ chance }}%
          <icon name="question-circle" v-tooltip="tooltips.chance"/>
        </div>
      </form-group>
    </div>
  </div>
</template>

<script>

import ColoredSelect from '@components/BaseComponents/Select/ColoredSelect'
import { cardRarity } from '@src/config/roulette/helper'

export default {
  name: 'CategoryCard',
  components: {
    ColoredSelect,
  },
  props: {
    categoryId: {
      type: [Number, String],
      required: true,
    },
    chance: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      loadingState: {
        update: false,
      },
    }
  },
  computed: {
    ...mapGetters('roulette', ['getCategory']),

    categoryRarityOptions() {
      return cardRarity.map(rarity => ({
        ...rarity,
        label: this.$t(`roulette.category.rarities.${rarity.value}`),
      }))
    },

    tooltips() {
      return {
        rarity: this.$tooltip(this.$t('roulette.category.rarityTooltip'), 'bottom'),
        chance: this.$tooltip(this.$t('roulette.category.chanceTooltip'), 'bottom'),
        enable: this.$tooltip(this.$t('roulette.category.enableTooltip')),
        disable: this.$tooltip(this.$t('roulette.category.disableTooltip')),
        edit: this.$tooltip(this.$t('roulette.category.editTooltip')),
      }
    },

    category() {
      return this.getCategory(this.categoryId)
    },

    rarity: {
      get() {
        return this.category.rarity
      },
      set(value) {
        this.update({ rarity: value })
      },
    },

    cardsText() {
      return this.category.cards.split(',').map(cardText => cardText.trim()).join(', ')
    },

    cardClasses() {
      return {
        enabled: this.category.enabled,
      }
    },
  },
  watch: {
    category: {
      handler(category) {
        if (this.categoryRarity !== category.rarity) {
          this.categoryRarity = category.rarity ?? 'common'
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions('roulette', {
      updateCategory: 'updateCategory',
    }),

    disableCategory() {
      this.update({ enabled: false })
    },

    enableCategory() {
      this.update({ enabled: true })
    },

    update(data) {
      this.loadingState.update = true

      this.updateCategory({
        id: this.categoryId,
        data,
      })
        .finally(() => {
          this.loadingState.update = false
        })
    },

    editCategory() {
      this.$emit('edit')
    },
  },
}
</script>
