<script setup>
import { ref } from 'vue'

const poppoverOppened = ref(false)

const hidePopover = () => poppoverOppened.value = false
const togglePopover = () => poppoverOppened.value = !poppoverOppened.value
</script>

<template>
  <div class="header-dropdown-section">
    <div class="header-dropdown" v-click-outside="hidePopover">
      <div class="header-dropdown-button" @click="togglePopover">
        <slot name="btn" />
        <icon class="button-icon" name="down"/>
      </div>
      <transition name="fade-up">
        <div v-show="poppoverOppened" class="header-dropdown-popover">
          <slot name="content" />
        </div>
      </transition>
    </div>
  </div>
</template>

<style scoped lang="scss">
  .header-dropdown-section {

    .header-dropdown {
      position: relative;

      .header-dropdown-button {
        height: 40px;
        background-color: $additional-2;
        border-radius: 8px;
        display: flex;
        align-items: center;
        @include padding-x(14px);
        font-size: 15px;
        white-space: nowrap;
        color: $gray;
        cursor: pointer;

        &:hover {
          background-color: $additional-1;
        }

        .button-icon {
          margin-left: 8px;
          font-size: 8px;
          color: $bright;
        }
      }

      .header-dropdown-popover {
        position: absolute;
        margin-top: 8px;
        right: 0;
        width: 200px;
        padding: 10px 14px;
        background: #303030;
        border: 1px solid #464646;
        border-radius: 8px;
      }
    }
  }
</style>
