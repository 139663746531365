<style lang="scss" scoped>
  .vertical-tabs {
    width: 200px;
    min-width: 200px;
    display: flex;
    flex-direction: column;


    .tab {
      display: flex;
      align-items: center;
      position: relative;
      @include padding-y(15px);
      padding-left: 20px;
      cursor: pointer;
      white-space: nowrap;
      background-color: $additional-3;

      .tab-icon {
        fill: url(#theme_svg_gradient);
        font-size: 16px;
      }

      .tab-icon + .tab-title {
        margin-left: 10px;
      }

      .tab-title {
        font-weight: 600;
        text-transform: uppercase;
      }


      &:first-child {
        border-radius: 5px 5px 0 0;
      }

      &:last-child {
        border-radius: 0 0 5px 5px;
      }

      &.active {
        background-color: rgba(black, .1);

        .tab-title {
          color: var(--theme-color-additional);
        }
      }

      &:hover:not(.active) {
        background-color: rgba(black, .01);

        .tab-title {
          color: var(--theme-color-additional);
        }
      }
    }
  }
</style>

<template>
  <div class="vertical-tabs">
    <div
      v-for="(tab, index) in tabs"
      :key="index"
      class="tab"
      :class="tab.classes" @click="onTabClick(tab.key)">
      <icon v-if="tab.icon" class="tab-icon" :name="tab.icon"/>
      <div v-if="tab.title" class="tab-title">{{tab.title}}</div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'VerticalTabs',
  props: {
    value: {
      type: [Number, String],
      default: -1,
    },
    list: {
      type: Array,
      required: true,
    },
  },
  computed: {
    tabs() {
      return this.list.map((tab, index) => {
        const key = tab.key || index

        return {
          ...tab,
          key,
          classes: {
            active: this.value === key,
          },
        }
      })
    },
  },
  methods: {
    onTabClick(key) {
      this.$emit('input', key)
    },
  },
}
</script>
