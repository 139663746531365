<style scoped lang="scss">
  .button-balance {
    color: $bright;
  }
</style>

<script>
import HeaderDropdown from '@components/BaseComponents/HeaderDropdown.vue'
import UserBalances from '@components/Dashboard/NewDashboard/UserBalances'

export default {
  name: 'HeaderDonationSection',
  components: { HeaderDropdown, UserBalances },
  computed: {
    ...mapGetters('profile', ['getUserBalance', 'getUserBalances']),
    balances: (vm) => vm.getUserBalances('adv_balances'),
    balance: (vm) => vm.getUserBalance('adv_balance_in_main_currency'),
    balanceAmount: (vm) => vm.balance?.amount
  },
}
</script>

<template>
  <HeaderDropdown v-if="balanceAmount !== null">
    <template #btn>
      <div class="button-text">{{ $t('dashboard.user.adv') }}&nbsp;</div>
      <div class="button-balance">{{ balance.amountWithCurrency }}</div>
    </template>

    <template #content>
      <user-balances :balance="balance" :balances="balances" balanceKey="adv"/>
    </template>
  </HeaderDropdown>
</template>

<style scoped lang="scss">
.button-text {
  @include media(max, md) {
    display: none;
  }
}
</style>