export const state = {
  currencies: {},

  mainCurrency: 'RUB',
}

export const getters = {
  currenciesList: state => Object.values(state.currencies),

  getCurrency: state => code => state.currencies[code],

  getMainCurrency: (state, getters) => getters.getCurrency(state.mainCurrency),

  getAmountWithCurrency: (state, getters) => (amount, currencyCode = null) => {
    const currency = getters.getCurrency(currencyCode ?? state.mainCurrency)

    if (!currency) {
      return null
    }

    let text = [amount, currency.symbol]

    if (currency.symbolFirst) {
      text = text.reverse()
    }

    return text.join(' ')
  },
}

export const mutations = {
  SET_MAIN_CURRENCY(state, currency) {
    state.mainCurrency = currency
  },

  SET_CURRENCIES(state, currencies) {
    state.currencies = _.keyBy(currencies, 'code')
  },
}

export const actions = {
  fetchCurrencies({ commit }) {
    return api.get('/currencies')
      .then(({ data }) => {
        return commit('SET_CURRENCIES', data.map(currency => ({
          name: currency.title,
          code: currency.key,
          symbol: currency.symbol,
          symbolFirst: currency.is_symbol_first,
          payIn: currency.is_payable,
        })))
      })
  },
}
