<style scoped lang="scss">
  .roulette-settings {
    .settings-content {
      padding: 15px 20px;
      background-color: $additional-3;
      border-radius: 5px;
    }

    .settings-area {
      @include media(max, md) {
        grid-template-columns: auto !important;
      }
    }

    h2 {
      margin-bottom: 20px;
    }
  }
</style>

<template>
  <div class="roulette-settings">
    <div class="settings-content">
      <grid columns="2" gap="40" class="settings-area">
        <div>
          <h2>{{ $t('roulette.settings.rouletteWidget') }}</h2>

          <form-group inline :label="$t('roulette.settings.rouletteMode')">
            <base-switch v-model="rouletteMode" :options="rouletteModeOptions"/>
          </form-group>
          <form-group inline :label="$t('roulette.settings.rouletteWidget')">
            <widget-link :link="links.roulette"/>
          </form-group>
          <form-group>
            <info-box class="small" :show-icon="false">
              <tag-replacer :text="prompt.rouletteWidget"/>
            </info-box>
          </form-group>
          <form-group inline :label="$t('roulette.settings.roulettePanel')">
            <widget-link :link="links.panel"/>
          </form-group>
          <form-group>
            <info-box class="small" :show-icon="false">
              <tag-replacer :text="prompt.panelWidget"/>
            </info-box>
          </form-group>

          <div class="errors-box" v-if="errors.length">
            <info-box
              v-for="(error, errorIndex) in errors"
              :key="errorIndex"
              variant="error">{{ error.message }}
            </info-box>
          </div>
        </div>
        <div>
          <template v-if="rouletteMode === 'goal'">
            <h2>{{ $t('roulette.settings.goalWidget') }}</h2>
            <form-group inline :label="$t('roulette.settings.goalSettings')">
              <btn icon-left="gear" @click="goalSettingsModalOpened = true">
                {{ $t('roulette.settings.customizeGoal') }}
              </btn>
            </form-group>
            <form-group inline :label="$t('roulette.settings.goalDesign')">
              <btn icon-left="palette" @click="goalVisualSettingsModalOpened = true">
                {{ $t('roulette.settings.customizeDesign') }}
              </btn>
            </form-group>
            <form-group inline :label="$t('roulette.settings.goalWidget')">
              <widget-link :link="links.goal"/>
            </form-group>

            <goal-settings-modal v-model="goalSettingsModalOpened"/>
            <goal-visual-settings-modal v-model="goalVisualSettingsModalOpened"/>
          </template>
        </div>
      </grid>

      <hr>

      <div class="settings-controls">
        <btn @click="save" :is-loading="loadingState.save" variant="primary-action">
          {{ $t('global.common.save') }}
        </btn>
      </div>
    </div>

  </div>
</template>

<script>

import GoalSettingsModal from './GoalSettings/GoalSettingsModal'
import GoalVisualSettingsModal from './GoalSettings/GoalVisualSettingsModal'
import WidgetLink from '@components/Dashboard/WidgetLink'

export default {
  name: 'RouletteSettings',
  components: {
    WidgetLink,
    GoalVisualSettingsModal,
    GoalSettingsModal,
  },
  data() {
    return {
      loadingState: {
        save: false,
      },

      rouletteMode: 'manual',

      goalSettingsModalOpened: false,
      goalVisualSettingsModalOpened: false,

      errors: [],
    }
  },
  computed: {
    ...mapState('roulette', ['rouletteSettings']),
    ...mapGetters('profile', ['getCurrentProfile']),

    prompt() {
      return {
        rouletteWidget: this.$t('roulette.settings.rouletteTextPrompt'),
        panelWidget: this.$t('roulette.settings.panelTextPrompt'),
      }
    },

    rouletteModeOptions() {
      return [
        'manual',
        'goal',
      ].map(option => ({
        value: option,
        label: this.$t(`roulette.settings.rouletteModes.${option}`),
      }))
    },

    links() {
      if (!this.getCurrentProfile) {
        return {
          roulette: null,
          goal: null,
          panel: null,
        }
      }

      const createWidgetLink = (widget) => {
        const link = `${window.location.origin}/widget/${widget}`

        const args = [
          `token=${this.getCurrentProfile.token}`,
        ]

        return [link, args.join('&')].join('?')
      }

      return {
        roulette: createWidgetLink('roulette'),
        goal: createWidgetLink('roulette-goal'),
        panel: createWidgetLink('roulette-panel'),
      }
    },

    currency() {
      return 'РУБ'
    },

    output() {
      return {
        rouletteMode: this.rouletteMode,
      }
    },
  },
  methods: {
    ...mapActions('roulette', [
      'updateRouletteSettings',
    ]),

    save() {
      this.loadingState.save = true

      this.updateRouletteSettings(this.output)
        .catch(({ errors }) => {
          this.errors = errors
        })
        .finally(() => {
          this.loadingState.save = false
        })
    },

    reset() {
      const {
        rouletteMode,
      } = _.cloneDeep(this.rouletteSettings)

      this.rouletteMode = rouletteMode
    },
  },
  watch: {
    rouletteSettings: {
      handler: 'reset',
      immediate: true,
    },
  },
}
</script>
