import { defineStore } from 'pinia'
import { ref } from 'vue'
import usePromise from '@composables/use-promise'
import cookies from '@services/cookies'

import { bannersPromise } from '@state/modules/dashboard'

export const useSteosVoicesStore = defineStore('steosVoices', () => {
  const steosVoices = ref([])

  const fetchSteosVoicesPromise = usePromise(() => api2.get('/steos'))

  const fetchSteosVoices = async () => {
    await fetchSteosVoicesPromise.exec()
    const { data } = fetchSteosVoicesPromise.data.value
    steosVoices.value = data
      .filter(voice => !!voice.preview_url)
      .map(voice => ({
        name: voice.displayed_name,
        url: voice.preview_url,
      }))
    return data
  }

  const activateSteosVoicesPromise = usePromise(() => api2.post('/steos'))

  const activateSteosVoices = () => {
    return activateSteosVoicesPromise.exec()
  }

  const steosVoicesBannerAllowed = ref(false)
  const steosVoicesModalOpened = ref(false)

  bannersPromise.then(banners => {
    steosVoicesBannerAllowed.value = banners?.steos_voice?.show || false
  })

  const hideSteosVoiceModal = () => {
    steosVoicesModalOpened.value = false

    cookies.setItem('da.dashboard.hideModal.steosVoice', 1, Infinity, '/')
  }

  const showSteosVoiceModal = () => {
    steosVoicesModalOpened.value = true

    cookies.removeItem('da.dashboard.hideModal.steosVoice', '/')
  }

  return {
    steosVoices,
    fetchSteosVoices,
    steosVoicesLoading: fetchSteosVoicesPromise.pending,
    activateSteosVoices,
    steosVoicesActivating: activateSteosVoicesPromise.pending,
    steosVoicesModalOpened,
    hideSteosVoiceModal,
    showSteosVoiceModal,
    steosVoicesBannerAllowed,
  }
})