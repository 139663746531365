<style scoped lang="scss">
  .header-profile-section {
    position: relative;
    user-select: none;

    .profile-button {
      display: flex;
      align-items: center;
      cursor: pointer;

      .profile-avatar {
        flex-shrink: 0;

        img {
          width: 40px;
          height: 40px;
          border-radius: 8px;
        }
      }

      .profile-name {
        margin-left: 10px;
        font-size: 16px;

        @include media(max, sm) {
          display: none;
        }
      }

      .profile-dropdown-button {
        margin-left: 10px;
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $additional-2;
        border-radius: 4px;
        cursor: pointer;
      }

      &:hover {
        .profile-name {
          color: var(--theme-color-additional);
        }

        .profile-dropdown-button {
          //background-color: $additional-1;

          .icon {
            fill: url(#theme_svg_gradient);
          }
        }
      }
    }

    .profile-dropdown {
      position: absolute;
      z-index: 20;
      top: $dashboard-header-height;
      right: 0;
      background-color: $additional-3;
      padding: 16px;
      min-width: 240px;
      border-radius: $control-border-radius;

      &:before {
        content: '';
        display: block;
        position: absolute;
        top: -6px;
        right: 20px;
        width: 20px;
        height: 20px;
        transform: rotate(45deg);
        background-color: $additional-3;
      }

      @include media(max, sm) {
        width: 260px;
        position: fixed;
        bottom: 0;
        filter: none;
        border-radius: 0;
        margin-top: 1px;

        &:before {
          display: none;
        }
      }

      .mobile-profile-info {
        @include media(min, sm) {
          display: none;
        }

        .mobile-profile-name {
          font-weight: 500;
          color: $bright;
        }

        .mobile-profile-balance {
          margin-top: 8px;

          .balance-text {
            color: $gray;
          }

          .balance-amount {
            color: $bright;
          }
        }

        .user-balances {
          margin-top: 10px;
        }
      }

      .dropdown-list {
        .dropdown-link {
          display: flex;
          align-items: center;
          color: $bright;
          cursor: pointer;
          white-space: nowrap;

          .icon {
            font-size: 18px;
          }

          .link-text {
            margin-left: 10px;
            font-size: 14px;
            line-height: 20px;
          }

          &:hover {
            color: var(--theme-color-additional);

            .icon {
              fill: url(#theme_svg_gradient);
            }
          }
        }

        .dropdown-link + .dropdown-link {
          margin-top: 12px;
        }
      }
    }
  }
</style>

<template>
  <div
    v-if="profile"
    class="header-profile-section"
    v-click-outside="closeProfileDropdown">
    <div class="profile-button" @click="toggleProfileDropdown">
      <div class="profile-avatar">
        <img :src="profile.avatar"/>
      </div>
      <div v-if="getUserIsStreamer" class="profile-name">
        {{ profile.name }}
      </div>
      <div class="profile-dropdown-button">
        <icon name="caret-down"/>
      </div>
    </div>
    <transition name="fade-up">
      <div v-show="profileDropdownOpened" class="profile-dropdown">
        <div v-if="getUserIsStreamer" class="mobile-profile-info">
          <div class="mobile-profile-name">{{ profile.name }}</div>
          <div v-if="!isAstrumApp">
            <div class="mobile-profile-balance">
              <span class="link-text">{{ $t('dashboard.user.donations') }}</span>
            </div>
            <user-balances :balance="balance" :balances="balances"/>
            <div class="mobile-profile-balance">
              <span class="link-text">{{ $t('dashboard.user.adv') }}</span>
            </div>
            <user-balances :balance="advBalance" :balances="advBalances" balanceKey="adv"/>
          </div>
          <hr class="small">
        </div>
        <div class="dropdown-list">
          <template v-for="(link, index) in dropdownLinks">
            <router-link
              :key="index"
              :to="{name: link.route}"
              class="dropdown-link"
              @click.native="onMenuLinkClick({name: link.route})">
              <icon :name="link.icon"/>
              <div class="link-text">{{ link.text }}</div>
            </router-link>
          </template>
          <div class="dropdown-link" @click="logout">
            <icon name="exit"/>
            <div class="link-text">{{ $t('dashboard.user.menu.logout') }}</div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>

import UserBalances from '@components/Dashboard/NewDashboard/UserBalances'

export default {
  name: 'HeaderProfileSection',
  components: { UserBalances },
  data() {
    return {
      profileDropdownOpened: false,
    }
  },
  computed: {
    ...mapState('profile', ['profile']),
    ...mapGetters('profile', ['getUserIsStreamer', 'getUserIsViewer', 'getUserBalance', 'getUserBalances']),
    balances: (vm) => vm.getUserBalances('balances'),
    balance: (vm) => vm.getUserBalance('balance_in_main_currency'),
    advBalances: (vm) => vm.getUserBalances('adv_balances'),
    advBalance: (vm) => vm.getUserBalance('adv_balance_in_main_currency'),

    dropdownLinks() {
      const links = []

      if (!this.isAstrumApp) {
        if (this.getUserIsStreamer) {
          links.push({
            key: 'payouts',
            icon: 'finance',
            route: 'dashboard-payouts',
          })
        }

        if (this.getUserIsViewer || this.getUserIsStreamer) {
          links.push({
            key: 'generalSettings',
            icon: 'settings',
            route: 'dashboard-general-settings',
          })

          links.push({
            key: 'sentDonations',
            icon: 'question-circle',
            route: 'dashboard-sent-donations',
          })
        }
      }

      return links.map(link => ({
        ...link,
        text: this.$t(`dashboard.user.menu.${link.key}`),
      }))
    },
  },
  methods: {
    logout() {
      window.location.href = '/auth/logout'
    },

    toggleProfileDropdown() {
      this.profileDropdownOpened = !this.profileDropdownOpened

      if (this.profileDropdownOpened) {
        this.$ga.event({
          eventCategory: 'header',
          eventAction: 'header_profile_menu_open_event',
        })

        this.$tmr.goal({ goal: 'header_profile_menu_open_event' })
      }
    },

    onMenuLinkClick(route) {
      const nextRoute = this.$router.resolve(route)

      this.$ga.event({
        eventCategory: 'header',
        eventAction: 'header_profile_menu_item_click',
      }, {
        url: nextRoute ? nextRoute.href : undefined,
      })

      this.$tmr.goal({
        goal: 'header_profile_menu_item_click',
      }, {
        url: nextRoute ? nextRoute.href : undefined,
      })
    },

    closeProfileDropdown() {
      this.profileDropdownOpened = false
    },
  },
  watch: {
    $route: 'closeProfileDropdown',
  },
}
</script>
