<style scoped lang="scss">
  .widget-roulette {
    height: 100%;
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    .roulette-container {
      //overflow: hidden;
    }

    .roulette-box {
      @include margin-x(auto);
      padding: 100px;
      height: 400px;
      width: 1200px;
      position: relative;
      opacity: 0;

      .widget-roulette-background {
        @include position-all(absolute, 100px);
        z-index: 3;
      }

      .slots-box {
        height: 100%;
        overflow: hidden;
        position: relative;

        .slots-line {
          @include position(absolute, 0, auto, 0, 0);
          z-index: 4;
          display: flex;
          height: 100%;

          .slot {
            flex: 0 0 auto;
            min-width: 200px;

            transition: transform 500ms;

            &.small {
              transform: scale(.9);
            }

            &.big {
              transform: scale(1);
            }

            &.winner {
              z-index: 4;
            }
          }
        }
      }

      .widget-roulette-frame {
        @include position-all(absolute, 0);
        z-index: 5;
      }

      .widget-roulette-win-effect {
        @include position-all(absolute, 0);
        z-index: 6;
      }

      .widget-roulette-decor {
        @include position-all(absolute, 0);
        z-index: 7;
      }

      .widget-roulette-pin {
        position: absolute;
        width: 60px;
        height: 60px;
        top: 70px;
        left: 50%;
        margin-left: -30px;
        z-index: 8;
      }

      .widget-roulette-title {
        @include position-all(absolute, 0);
        z-index: 9;
      }
    }
  }
</style>

<template>
  <div class="widget-roulette">
    <div ref="roulette-container" class="roulette-container">
      <div v-if="settings" ref="roulette-box" class="roulette-box">
        <widget-roulette-background
          ref="background"
          :settings="backgroundSettings"
          :spin-time="behaviorSettings.spinTime"
          :spin="state.spin"
          :win="state.win"/>

        <div v-if="slotsLine && slotsLineSlots" ref="slots-box" class="slots-box" :style="slotsBox.styles">
          <div class="slots-line" ref="slots-line">
            <div
              v-for="(slot, slotsLineIndex) in slotsLineSlots"
              :key="slotsLineIndex"
              class="slot"
              :class="getSlotClasses(slotsLineIndex)"
              :style="getSlotStyles(slotsLineIndex)">
              <roulette-card
                :settings="slot.settings"
                :text="slot.text"
                :animated="getSlotIsAnimated(slotsLineIndex)"/>
            </div>
          </div>
        </div>

        <widget-roulette-frame
          ref="frame"
          :settings="frameSettings"/>
        <widget-roulette-win-effect
          v-if="winnerSlot"
          :settings="winnerSlotSettings.effectSettings"
          :offset="winnerOffset"
          @play-method-update="onWinEffectPlayMethodUpdate"/>
        <widget-roulette-decor
          ref="decor"
          :settings="decorSettings"/>
        <widget-roulette-pin
          ref="pin"
          :settings="pinSettings"/>
        <widget-roulette-title
          ref="title"
          :settings="titleSettings"/>
      </div>
    </div>
  </div>
</template>

<script>

import {
  gsap,
  killAnimation,
} from '@utils/animation'

import {
  playAudio,
  createAudio,
} from '@utils/audio'

import {
  rouletteBoxAnimations,
  generateRoulette,
} from '@src/config/roulette/helper'

import RouletteCard from './RouletteCard/RouletteCard'
import WidgetRoulettePin from './WidgetRoulettePin'
import WidgetRouletteTitle from './WidgetRouletteTitle'
import WidgetRouletteFrame from './WidgetRouletteFrame'
import WidgetRouletteDecor from './WidgetRouletteDecor'
import WidgetRouletteBackground from './WidgetRouletteBackground'
import WidgetRouletteWinEffect from './WidgetRouletteWinEffect'

export default {
  name: 'WidgetRoulette',
  components: {
    WidgetRouletteWinEffect,
    WidgetRouletteBackground,
    WidgetRouletteDecor,
    WidgetRouletteFrame,
    WidgetRouletteTitle,
    WidgetRoulettePin,
    RouletteCard,
  },
  props: {
    settings: {
      type: Object,
      default: null,
    },
    categories: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      state: {
        appear: false,
        disappear: false,
        show: false,
        spin: false,
        win: false,
      },

      // Animations
      appearAnimation: null,
      disappearAnimation: null,
      spinAnimation: null,
      showAnimation: null,
      spinCurveAnimation: null,
      pinAnimation: null,

      // Behavior
      winnerOffset: 0,
      animationEase: 'power3.out',

      // NEW
      slotsLineLength: 31,
      slots: {},

      winnerSlotId: null,
      slotsLine: null,

      currentSlotIndex: 2,

      // Audio
      discountAudio: null,
      appearAudio: null,
      disappearAudio: null,
      winAudio: null,
      spinAudios: [],

      // Settings
      backgroundSettings: null,
      frameSettings: null,
      decorSettings: null,
      pinSettings: null,
      titleSettings: null,
      behaviorSettings: null,
      soundSettings: null,

      playWinEffectMethod: null,
    }
  },
  computed: {
    visibleCategories() {
      return (this.categories ?? []).filter(category => category.enabled)
    },

    slotsBox() {
      const borderRadius = _.get(this.backgroundSettings, 'backgroundBorderRadius', 0)

      return {
        styles: {
          borderRadius: `${borderRadius}px`,
        },
      }
    },

    // NEW
    slotsLineSlots() {
      return this.slotsLine.map(slotId => this.slots[slotId])
    },

    winnerSlot() {
      return this.winnerSlotId ? this.slots[this.winnerSlotId] : null
    },

    winnerSlotSettings() {
      return this.winnerSlot ? this.winnerSlot.settings : null
    },

    sounds() {
      if (!this.soundSettings) {
        return null
      }

      const { appearSound, disappearSound, spinSound } = this.soundSettings

      return { appearSound, disappearSound, spinSound }
    },
  },
  mounted() {
    this.refresh()

    this.$root.$on('roulette.widget.appear', () => {
      this.appear()
    })
    this.$root.$on('roulette.widget.disappear', () => {
      this.disappear(true)
    })
    this.$root.$on('roulette.widget.roll', () => {
      this.roll(true)
    })
    this.$root.$on('roulette.widget.refresh', () => {
      this.refresh()
    })
    this.$root.$on('roulette.widget.full-cycle', () => {
      this.fullCycle()
    })
    this.$root.$on('roulette.widget.activate-discount', () => {
      playAudio(this.discountAudio)
    })
  },
  methods: {
    ...mapActions('fileManager', ['getOrFetchFile']),

    getSlotsLineElement() {
      return this.$refs['slots-line']
    },
    getRouletteBoxElement() {
      return this.$refs['roulette-box']
    },
    getPinElement() {
      return this.$refs['pin'].$el
    },
    onWinEffectPlayMethodUpdate(method) {
      this.playWinEffectMethod = method
    },
    setState(state, value) {
      this.$set(this.state, state, value)
    },

    generateRoulette() {
      const {
        slots,
        winnerSlotId,
        slotsLine,
      } = generateRoulette({
        categories: this.visibleCategories,
        lineLength: this.slotsLineLength,
        winnerPosition: this.slotsLineLength - 3,
      })

      this.slots = _.keyBy(slots, 'id')
      this.winnerSlotId = winnerSlotId
      this.slotsLine = slotsLine
    },

    getSlotIsWinner(slotsLineIndex) {
      return slotsLineIndex === this.slotsLineLength - 4
    },
    getSlotIsCurrent(slotsLineIndex) {
      return this.currentSlotIndex === slotsLineIndex
    },
    getSlotStyles(slotsLineIndex) {
      const {
        highlightMode = false,
        cardsRandomRotation = false,
        cardsRandomScale = false,
      } = this.behaviorSettings

      const randomTransform = []

      if (!highlightMode) {
        if (cardsRandomScale) {
          randomTransform.push(`scale(${_.random(.95, 1.05)})`)
        }

        if (cardsRandomRotation) {
          randomTransform.push(`rotate(${_.random(-3, 3)}deg)`)
        }
      }

      return {
        transform: randomTransform.join(' '),
      }
    },
    getSlotClasses(slotsLineIndex) {
      const {
        highlightMode = false,
      } = this.behaviorSettings

      return {
        winner: this.getSlotIsWinner(slotsLineIndex),
        big: highlightMode && this.getSlotIsCurrent(slotsLineIndex),
        small: highlightMode && !this.getSlotIsCurrent(slotsLineIndex),
      }
    },
    getSlotIsAnimated(slotsLineIndex) {
      return this.getSlotIsWinner(slotsLineIndex) && this.state.win
    },

    reshuffle() {
      this.generateRoulette()
    },

    appear() {
      this.refresh()

      this.createRouletteSounds()

      const {
        animationAppear,
        appearTime,
      } = this.behaviorSettings

      this.appearAnimation = gsap.timeline()
        .fromTo(this.getRouletteBoxElement(), {
          translateX: 0,
          translateY: 0,
          ...rouletteBoxAnimations[animationAppear],
          opacity: 0,
        }, {
          opacity: 1,
          translateX: 0,
          translateY: 0,
          duration: appearTime,
          ease: 'linear',
        })

      this.appearAnimation.eventCallback('onStart', () => {
        this.state.show = false
        this.state.appear = true
        playAudio(this.appearAudio)
      })
      this.appearAnimation.eventCallback('onComplete', () => {
        this.state.appear = false
        this.state.show = true
      })

      return this.appearAnimation
    },

    disappear(forced = false) {
      if (forced) {
        this.killSpinAnimations()
      }
      killAnimation(this.disappearAnimation)

      this.createRouletteSounds()

      const {
        animationDisappear,
        disappearTime,
      } = this.behaviorSettings

      this.disappearAnimation = gsap.timeline()
        .fromTo(this.getRouletteBoxElement(), {
          opacity: 1,
          translateX: 0,
          translateY: 0,
        }, {
          ...rouletteBoxAnimations[animationDisappear],
          opacity: 0,
          duration: disappearTime,
          ease: 'linear',
        })

      this.disappearAnimation.eventCallback('onStart', () => {
        this.state.show = false
        this.state.disappear = true
        playAudio(this.disappearAudio)
      })
      this.disappearAnimation.eventCallback('onComplete', () => {
        this.state.disappear = false
      })

      return this.disappearAnimation
    },

    killSpinAnimations() {
      killAnimation(this.spinAnimation)
      killAnimation(this.showAnimation)
      killAnimation(this.spinCurveAnimation)
      killAnimation(this.pinAnimation)
    },

    async roll(force = false) {
      this.killSpinAnimations()
      this.state.spin = false
      this.state.win = false

      if (force) {
        this.reshuffle()
      }

      await new Promise(resolve => setTimeout(resolve, 50))

      if (!this.state.show) {
        return this.appear()
          .then(() => {
            return this.roll()
          })
      }

      return this.spin()
    },

    spin() {
      this.createRouletteSounds()

      const {
        showTime,
        spinTime,
        slotMachineMode,
      } = this.behaviorSettings

      const {
        pinAnimation,
      } = this.pinSettings

      const cardWidth = 200
      const rouletteWidth = this.slotsLineLength * cardWidth
      this.winnerOffset = slotMachineMode ? 0 : _.random(-99, 99)
      const finalPosition = -(rouletteWidth - 6 * cardWidth) + this.winnerOffset
      const pinEl = this.getPinElement()
      const slotsLineEl = this.getSlotsLineElement()

      if (pinEl && pinAnimation) {
        if (pinAnimation === 'shake') {
          this.pinAnimation = gsap
            .timeline({
              defaults: {
                ease: this.animationEase,
              },
            })
            .fromTo(pinEl, {
              rotate: 0,
            }, {
              rotate: 30,
              duration: .2,
            })
            .to(pinEl, {
              rotate: 0,
              duration: .6,
            })
        }
        if (pinAnimation === 'spin') {
          this.pinAnimation = gsap.timeline()
            .to(pinEl, {
              rotate: 360 * 20,
              duration: spinTime,
              ease: this.animationEase,
            })
        }
      }

      const spinCurve = { currentPosition: 0 }
      let currentPosition = 0
      let spinAudioIndex = 0
      this.spinCurveAnimation = gsap.timeline()
        .to(spinCurve, {
          currentPosition: -finalPosition,
          duration: spinTime,
          ease: this.animationEase,
        })

      this.spinCurveAnimation.eventCallback('onUpdate', () => {
        if (currentPosition < spinCurve.currentPosition - 100) {
          currentPosition += 200
          this.currentSlotIndex = Math.round(spinCurve.currentPosition / 200) + 2
          playAudio(this.spinAudios[spinAudioIndex++])
          if (pinAnimation === 'shake') {
            this.pinAnimation.restart()
          }
        }
      })

      this.spinAnimation = gsap.timeline()
        .to(slotsLineEl, {
          translateX: finalPosition,
          duration: spinTime,
          ease: this.animationEase,
        })

      this.spinAnimation.eventCallback('onStart', () => {
        this.state.spin = true
      })
      this.spinCurveAnimation.eventCallback('onComplete', () => {
        this.state.spin = false
        this.state.win = true
        playAudio(this.winAudio)
        this.$emit('win', this.winnerSlot.winText)

        if (this.playWinEffectMethod) {
          this.playWinEffectMethod()
        }
      })

      this.showAnimation = gsap.timeline()
        .to({}, {
          duration: spinTime + showTime,
        })

      return this.showAnimation
    },

    refresh() {
      this.currentSlotIndex = 2

      this.state.appear = false
      this.state.disappear = false
      this.state.show = false
      this.state.spin = false
      this.state.win = false

      killAnimation(this.appearAnimation)

      this.killSpinAnimations()

      this.reshuffle()
    },

    fullCycle() {
      this.appear()
        .then(() => {
          this.roll()
            .then(() => {
              this.disappear()
                .then(() => {
                  this.$emit('end')
                })
            })
        })
    },

    createRouletteSounds() {
      this.createDiscountSound()
      this.createAppearSound()
      this.createDisappearSound()
      this.createSpinSound()
      this.createWinSound()
    },

    async createDiscountSound() {
      const {
        discountSound,
        discountSoundVolume = 100,
        rouletteSoundVolume = 100,
      } = this.soundSettings

      const discountSoundFile = await this.getOrFetchFile(discountSound)

      this.discountAudio = discountSoundFile
        ? createAudio(
          discountSoundFile.url,
          discountSoundVolume,
          rouletteSoundVolume,
        ) : null
    },
    async createAppearSound() {
      const {
        appearSound,
        appearSoundVolume = 100,
        rouletteSoundVolume = 100,
      } = this.soundSettings

      const appearSoundFile = await this.getOrFetchFile(appearSound)

      this.appearAudio = appearSoundFile
        ? createAudio(
          appearSoundFile.url,
          appearSoundVolume,
          rouletteSoundVolume,
        ) : null
    },
    async createDisappearSound() {
      const {
        disappearSound,
        disappearSoundVolume = 100,
        rouletteSoundVolume = 100,
      } = this.soundSettings

      const disappearSoundFile = await this.getOrFetchFile(disappearSound)

      this.disappearAudio = disappearSoundFile
        ? createAudio(
          disappearSoundFile.url,
          disappearSoundVolume,
          rouletteSoundVolume,
        ) : null
    },
    async createSpinSound() {
      const {
        spinSound,
        spinSoundVolume = 100,
        rouletteSoundVolume = 100,
      } = this.soundSettings

      const spinSoundFile = await this.getOrFetchFile(spinSound)

      if (spinSoundFile) {
        this.spinAudios = _.range(0, this.slotsLineLength).map(() => {
          return createAudio(spinSoundFile.url, spinSoundVolume, rouletteSoundVolume)
        })
      } else {
        this.spinAudios = []
      }
    },
    async createWinSound() {
      if (!this.soundSettings || !this.winnerSlot) {
        return
      }

      const {
        rouletteSoundVolume = 100,
      } = this.soundSettings

      const {
        winSound,
        winSoundVolume = 100,
      } = this.winnerSlotSettings.soundSettings

      const winSoundFile = await this.getOrFetchFile(winSound)

      this.winAudio = winSoundFile
        ? createAudio(
          winSoundFile.url,
          winSoundVolume,
          rouletteSoundVolume,
        ) : null
    },
  },
  watch: {
    categories: {
      handler: 'generateRoulette',
      immediate: true,
    },
    winnerSlotSettings() {
      this.createWinSound()
    },
    sounds(value) {
      if (value) {
        this.createRouletteSounds()
      }
    },
    settings: {
      handler(settings) {
        if (settings) {
          Object.keys(settings).forEach(key => {
            const localVersion = _.get(this[key], '_version', null)
            const externalVersion = _.get(settings[key], '_version', null)
            let different = true

            if (externalVersion && externalVersion === localVersion) {
              different = false
            }

            if (different) {
              this[key] = settings[key] ?? this[key]
            }
          })
        }
      },
      immediate: true,
    },
  },
}
</script>
