<style scoped lang="scss">
  .category-effect-settings {
    .effect-preview {
      @include margin-y(20px);
      position: relative;
      background: rgba(black, .1);
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;

      .effect-preview-video {
        max-width: 100%;
        max-height: 200px;
      }
    }
  }
</style>

<template>
  <div class="category-effect-settings">
    <form-group inline :label="$t('roulette.category.visualSettings.effects.winEffect')">
      <file-manager v-model="winEffect" widget-element="roulette_card_win_effect" file-type="image"/>
    </form-group>

    <template v-if="winEffect && effectImage">
      <div v-tooltip="$tooltip($t('roulette.category.visualSettings.effects.winEffectTooltip'))" class="effect-preview">
        <video
          class="effect-preview-video"
          :key="effectPreview.src"
          :src="effectPreview.src"
          :style="effectPreview.styles"
          autoplay
          loop
          muted/>
      </div>
      <form-group inline :label="$t('global.settings.labels.hueRotation')">
        <base-range :min="0" :max="360" v-model="winEffectHue" input input-addon="°"/>
      </form-group>
      <form-group inline :label="$t('global.settings.labels.saturate')">
        <base-range :min="0" :max="300" v-model="winEffectSaturate" input input-addon="%"/>
      </form-group>
    </template>
  </div>
</template>

<script>

import { createCssFilters } from '@utils/utils'
import FileManager from '@components/BaseComponents/FileManager/FileManager'

export default {
  name: 'CategoryEffectSettings',
  components: { FileManager },
  props: {
    value: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      winEffect: null,
      winEffectHue: 0,
      winEffectSaturate: 100,
    }
  },
  computed: {
    ...mapGetters('fileManager', ['getFile']),

    effectImage() {
      this.fetchFile(this.winEffect)

      return this.getFile(this.winEffect)
    },

    effectPreview() {
      return {
        src: this.effectImage ? this.effectImage.url : null,
        styles: {
          filter: createCssFilters({
            hue: this.winEffectHue,
            saturate: this.winEffectSaturate,
          }),
        },
      }
    },

    output() {
      return {
        _version: Math.random().toString(36).slice(2),
        winEffect: this.winEffect,
        winEffectHue: this.winEffectHue,
        winEffectSaturate: this.winEffectSaturate,
      }
    },
  },
  created() {
    if (this.value) {
      Object.keys(this.value).forEach(key => {
        this[key] = this.value[key] ?? this[key]
      })
    }
  },
  watch: {
    output(value) {
      this.$emit('input', value)
    },
    winEffect() {
      this.winEffectHue = 0
      this.winEffectSaturate = 100
    },
  },
}
</script>
