<style scoped lang="scss">
  .cebc-message {
    position: fixed;
    z-index: 100;
    right: 20px;
    bottom: 20px;
    display: flex;
    width: 400px;
    padding: 20px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    border-radius: 8px;
    border: 1px solid #464646;
    background: #303030;
    color: #F1F1F1;

    @include media(max, sm) {
      width: auto;
      left: 20px;
    }

    .message-icon img {
      width: 64px;
      height: 64px;
    }

    .message-title {
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: -0.5px;
    }

    .message-text {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      opacity: .6;
    }

    .message-close-button {
      position: absolute;
      font-size: 18px;
      line-height: 18px;
      top: 12px;
      right: 12px;
      color: #838383;
      cursor: pointer;

      &:hover {
        color: white;
      }
    }
  }
</style>

<template>
  <div v-if="displayMessage" class="cebc-message">
    <div class="message-icon">
      <img :src="$asAppAsset('/images/banners/cebc/exclamation-mark.png')"/>
    </div>
    <div class="message-title">{{ $t('misc.cebcMessage.title') }}</div>
    <div class="message-text">{{ $t('misc.cebcMessage.text') }}</div>
    <div class="message-controls">
      <btn variant="primary-action" @click="hideMessage('confirm')">{{
          $t('misc.cebcMessage.button')
        }}
      </btn>
    </div>
    <div class="message-close-button" @click="hideMessage('close')">
      <icon name="times"/>
    </div>
  </div>
</template>

<script>

import cookies from '@services/cookies'
import frontConfig from '@services/frontConfig'

export default {
  name: 'CebcMessage',
  data() {
    return {
      messageHidden: !!cookies.getItem('da.hideCebcMessage'),
    }
  },
  computed: {
    displayMessage() {
      return frontConfig.getDASetting('show_cebc_message') && !this.messageHidden
    },
  },
  methods: {
    hideMessage(initiatedBy) {
      this.messageHidden = true

      cookies.setItem('da.hideCebcMessage', 1, Infinity, '/')

      this.$tmr.goal({ goal: 'juridical_modal_closed' }, {
        initiated_by: initiatedBy,
      })
    },
  },
  mounted() {
    if (this.displayMessage) {
      this.$tmr.goal({ goal: 'juridical_modal_showed' })
    }
  },
}
</script>
