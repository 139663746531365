export const state = {
  isLoading: false,
  offers: [],
  advConfirmed: true,
  code: null
}

export const getters = {
  isLoading: ({ isLoading }) => isLoading,
  offers: ({ offers }) => offers,
  advConfirmed: ({ advConfirmed }) => advConfirmed,
  code: ({ code }) => code
}

export const mutations = {
  startLoading(state) {
    state.isLoading = true
  },

  endLoading(state) {
    state.isLoading = false
  },

  setOffers(state, { data }) {
    state.offers = data
  },

  setCode(state, { code }) {
    state.code = code
  },

  clearOffers(state) {
    state.offers = []
  },

  refuteAdvConfirmed(state) {
    state.advConfirmed = false
  },
}

export const actions = {
  async fetchOffers({ state, commit }, offer_status) {
    commit('startLoading')

    try {
      const res = await api.get('/advoffer', { params: { offer_status } })

      if (res.data) {
        commit('setCode', { code: null })
        commit('setOffers', { data: res.data })
      } else {
        throw new Error()
      }

      commit('endLoading')
    } catch (err) {
      const { code } = err
      
      commit('setOffers', { data: [] })

      if (code) {
        commit('setCode', { code })
        switch(code) {
          case 1509:
            commit('refuteAdvConfirmed')
            break
        }
      }

      commit('endLoading')
    }
  },

  clearOffers({ commit }) {
    commit('clearOffers')
  },
}
