<style scoped lang="scss">
  .category-text-settings {

  }
</style>

<template>
  <div class="category-text-settings">
    <form-group inline :label="$t('roulette.category.visualSettings.text.cardText')">
      <text-styler v-model="textStyles" class="left"/>
    </form-group>
    <form-group
      inline
      :label="$t('roulette.category.visualSettings.text.blockWidth')">
      <base-range v-tooltip="tooltips.textWidth" :min="10" :max="200" v-model="textWidth" input input-addon="px"/>
    </form-group>
    <form-group inline :label="getOffsetText('Y')">
      <base-range :min="-100" :max="100" v-model="textOffset" input input-addon="px"/>
    </form-group>
    <form-group inline :label="getOffsetText('X')">
      <base-range :min="-100" :max="100" v-model="textOffsetX" input input-addon="px"/>
    </form-group>
  </div>
</template>

<script>
import TextStyler from '@components/BaseComponents/TextStyler/TextStyler'

export default {
  name: 'CategoryTextSettings',
  components: {
    TextStyler,
  },
  props: {
    value: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      textStyles: {
        fontFamily: 'Comfortaa',
        fontSize: 16,
        lineHeight: 21,
        letterSpacing: 0,
        wordSpacing: 0,
        color: '#f507c9',
        textTransform: 'normal',
        textAlign: 'center',
        fontWeight: 'bold',
        fontStyle: 'normal',
        textDecoration: 'none',
        textShadow: '0px 0px 6px rgba(245,7,201,0.6)',
      },

      textOffset: 0,
      textOffsetX: 0,

      textWidth: 140,
    }
  },
  computed: {
    tooltips() {
      return {
        textWidth: this.$tooltip(this.$t('roulette.category.visualSettings.text.blockWidthTooltip')),
      }
    },

    output() {
      return {
        _version: Math.random().toString(36).slice(2),
        textStyles: this.textStyles,
        textOffset: this.textOffset,
        textOffsetX: this.textOffsetX,
        textWidth: this.textWidth,
      }
    },
  },
  methods: {
    getOffsetText(axis){
      return `${this.$t('roulette.category.visualSettings.text.textPosition')} (${axis})`
    },
  },
  created() {
    if (this.value) {
      Object.keys(this.value).forEach(key => {
        this[key] = this.value[key] ?? this[key]
      })
    }
  },
  watch: {
    output(value) {
      this.$emit('input', value)
    },
  },
}
</script>
