<style scoped lang="scss">
  .widget-bft-actions {
    position: relative;

    .widget-frame {
      @include position-all(absolute, 0);
      z-index: 7;
      pointer-events: none;
    }

    .widget-title {
      @include padding-x(15px);
      @include padding-y(10px);
    }

    .widget-body {
      @include padding-x(15px);
      @include padding-y(10px);

      .body-wrapper {
        overflow: hidden;
        position: relative;
      }

      .actions-slide {
        display: flex;

        .actions-list {
          flex: 0 0;
          min-width: 200px;
          max-width: 200px;
        }

        .actions-list + .actions-list {
          margin-left: 15px;
        }
      }

      .action-separator {
        height: 1px;
        @include margin-y(7px);
        background: rgba(255, 255, 255, 0.2);
      }

      .action {
        display: flex;
        align-items: center;

        .action-name {
          width: 100%;
          margin-right: 10px;
          font-weight: 400;
          font-size: 10.2452px;
          line-height: 15px;
        }

        .action-price {
          margin-left: auto;
          white-space: nowrap;
          font-weight: 600;
          font-size: 10.2452px;
          line-height: 15px;
        }
      }
    }
  }

  .slider-fade {
    &-enter {
      opacity: 0;
    }

    &-leave {
      position: absolute;
    }

    &-leave-to {
      opacity: 0;
      position: absolute;
    }

    &-enter-active, &-leave-active {
      transition: opacity 300ms;
    }
  }

  .slider-slide {
    &-enter {
      transform: translateX(120%);
    }

    &-leave {
      transform: translateX(0);
      position: absolute;
    }

    &-leave-to {
      transform: translateX(-120%);
      position: absolute;
    }

    &-enter-active, &-leave-active {
      transition: 1000ms;
    }
  }
</style>

<template>
  <div class="widget-bft-actions" ref="widget" :style="backgroundStyles">
    <div class="widget-frame">
      <svg-gradient
        v-if="frame.gradient"
        :name="frame.gradient.name"
        :colors="frame.gradient.colors"
        :angle="frame.gradient.angle"
        :hard="frame.gradient.hard"/>
      <svg class="widget-frame-svg" :style="frame.svgStyles">
        <rect
          :key="renderKey"
          :x="frame.rectStyles.x"
          :y="frame.rectStyles.y"
          :rx="frame.rectStyles.rx"
          :ry="frame.rectStyles.ry"
          :stroke="frame.rectStyles.stroke"
          :stroke-width="frame.rectStyles.strokeWidth"
          :width="frame.rectStyles.width"
          :height="frame.rectStyles.height"/>
      </svg>
    </div>
    <div
      v-if="currentSlide && currentSlide.title && currentSlide.title.length"
      class="widget-title"
      :style="titleBackgroundStyles">
      <div class="widget-title-text" :style="titleTextStyles">
        {{ currentSlide.title }}
      </div>
    </div>
    <div class="widget-body">
      <div class="body-wrapper" :style="{height: `${slideHeight}px`}">
        <transition-group tag="div" :name="slider.animation">
          <div
            v-for="(slide, slideIndex) in slides"
            :key="`slide_${slideIndex}`"
            ref="slides"
            v-show="currentSlideIndex === slideIndex"
            class="actions-slide">
            <div
              v-for="(column, columnIndex) in slide.columns"
              :key="`${slideIndex}_${columnIndex}`"
              class="actions-list">
              <template v-for="(action, actionIndex) in column">
                <div
                  :key="`${action.id}_${slideIndex}_${columnIndex}`"
                  class="action">
                  <div class="action-name" :style="actionTextStyles">
                    {{ action.name }}
                  </div>
                  <div class="action-price" :style="priceTextStyles">
                    {{ action.price }} ₽
                  </div>
                </div>
                <div
                  v-if="actionIndex < column.length - 1"
                  :key="`separator_${actionIndex}_${slideIndex}_${columnIndex}`"
                  class="action-separator"
                  :style="separatorStyles"/>
              </template>
            </div>
          </div>
        </transition-group>
      </div>
    </div>
  </div>
</template>

<script>

import { createCssFilters, createGradientString, numbersToUnits } from '@utils/utils'
import SvgGradient from '@components/BaseComponents/SvgGradient'
import { getInRange } from '@utils/utils'

export default {
  name: 'WidgetBftActions',
  components: { SvgGradient },
  props: {
    settings: {
      type: Object,
      required: true,
    },
    categories: {
      type: Array,
      required: true,
    },
    actions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      currentSlideIndex: 0,

      renderKey: null,

      sliderTimeout: null,

      slideHeight: 0,
    }
  },
  computed: {
    layout() {
      const { columns = 1, rows = 4, groupActions = true } = this.settings.layoutSettings ?? {}

      return {
        columns: getInRange(columns, [1, 4]),
        rows: getInRange(rows, [1, 10]),
        groupActions,
      }
    },

    slider() {
      const { slideDuration = 10, animation = 'fade' } = this.settings.sliderSettings ?? {}

      return {
        slideDuration: getInRange(slideDuration, [1, 15]),
        animation: `slider-${animation}`,
      }
    },

    slides() {
      const categorizedActions = _.groupBy(this.actions, 'categoryId')

      const slideActionsNumber = this.layout.columns * this.layout.rows

      const slides = []

      if (this.layout.groupActions) {
        this.categories.forEach(category => {
          _.chunk(categorizedActions[category.id], slideActionsNumber).forEach(actionsChunk => {
            const columns = _.chunk(actionsChunk, this.layout.rows)

            slides.push({
              title: category.name,
              columns: [
                ...columns,
                ...Array.from({ length: this.layout.columns - columns.length }),
              ],
            })
          })
        })
      } else {
        const title = _.get(this.settings, 'titleSettings.text')

        _.chunk(this.actions, this.layout.columns * this.layout.rows).forEach(actionsChunk => {
          const columns = _.chunk(actionsChunk, this.layout.rows)

          slides.push({
            title,
            columns: [
              ...columns,
              ...Array.from({ length: this.layout.columns - columns.length }),
            ],
          })
        })
      }

      return slides
    },

    currentSlide() {
      return this.slides[this.currentSlideIndex]
    },

    titleBackgroundStyles() {
      if (!_.get(this.settings, 'titleSettings', null)) {
        return null
      }

      const { backgroundColor = {} } = this.settings.titleSettings

      const { color = null, gradient = {}, useGradient = false } = backgroundColor
      const { colors = [], angle = 0, hard = false } = gradient

      const styles = {
        borderRadius: `${this.backgroundBorderRadius}px ${this.backgroundBorderRadius}px 0 0`,
      }

      if (useGradient) {
        styles.backgroundImage = `linear-gradient(${angle}deg, ${createGradientString(colors, hard)})`
      } else {
        styles.backgroundColor = color
      }

      return styles
    },

    titleTextStyles() {
      if (!_.get(this.settings, 'titleSettings.textStyles')) {
        return null
      }

      const { titleSettings } = this.settings
      const { textStyles } = titleSettings
      const { strokeWidth, strokeColor } = textStyles

      return {
        ...numbersToUnits(textStyles, 'px'),
        '-webkit-text-stroke': strokeWidth ? `${strokeWidth}px ${strokeColor}` : null,
        textStroke: strokeWidth ? `${strokeWidth}px ${strokeColor}` : null,
        fontFamily: `"${textStyles.fontFamily}"`,
      }
    },

    actionTextStyles() {
      if (!_.get(this.settings, 'actionsSettings.actionTextStyles')) {
        return null
      }

      const { actionsSettings } = this.settings
      const { actionTextStyles } = actionsSettings

      const { strokeWidth, strokeColor } = actionTextStyles

      return {
        ...numbersToUnits(actionTextStyles, 'px'),
        '-webkit-text-stroke': strokeWidth ? `${strokeWidth}px ${strokeColor}` : null,
        textStroke: strokeWidth ? `${strokeWidth}px ${strokeColor}` : null,
        fontFamily: `"${actionTextStyles.fontFamily}"`,
      }
    },

    priceTextStyles() {
      if (!_.get(this.settings, 'actionsSettings.priceTextStyles')) {
        return null
      }

      const { actionsSettings } = this.settings
      const { priceTextStyles } = actionsSettings

      const { strokeWidth, strokeColor } = priceTextStyles

      return {
        ...numbersToUnits(priceTextStyles, 'px'),
        '-webkit-text-stroke': strokeWidth ? `${strokeWidth}px ${strokeColor}` : null,
        textStroke: strokeWidth ? `${strokeWidth}px ${strokeColor}` : null,
        fontFamily: `"${priceTextStyles.fontFamily}"`,
      }
    },

    backgroundBorderRadius() {
      const _renderKey = this.renderKey

      if (!_.get(this.settings, 'backgroundSettings')) {
        return null
      }

      const { borderRadius } = this.settings.backgroundSettings

      return getInRange(borderRadius, [0, 20])
    },

    backgroundStyles() {
      if (!_.get(this.settings, 'backgroundSettings', null)) {
        return null
      }

      const { backgroundColor = {}, shadow } = this.settings.backgroundSettings

      const { color = null, gradient = {}, useGradient = false } = backgroundColor
      const { colors = [], angle = 0, hard = false } = gradient

      const styles = {
        borderRadius: `${this.backgroundBorderRadius}px`,
        filter: createCssFilters({
          shadow: shadow,
        }),
      }

      if (useGradient) {
        styles.backgroundImage = `linear-gradient(${angle}deg, ${createGradientString(colors, hard)})`
      } else {
        styles.backgroundColor = color
      }

      return styles
    },

    frame() {
      if (!_.get(this.settings, 'backgroundSettings', null)) {
        return null
      }

      const { borderWidth, borderColor = {} } = this.settings.backgroundSettings
      const { color = null, gradient = {}, useGradient = false } = borderColor
      const { colors = [], angle = 0, hard = false } = gradient

      const gradientName = `_goal_frame_${this.$componentId}`
      const gradientLink = `url(#${gradientName})`

      const frame = {
        svgStyles: {
          marginTop: `-${borderWidth / 2}px`,
          marginLeft: `-${borderWidth / 2}px`,
          width: `calc(100% + ${borderWidth}px)`,
          height: `calc(100% + ${borderWidth}px)`,
          fill: 'transparent',
          position: 'absolute',
        },
        rectStyles: {
          width: `calc(100% - ${borderWidth}px)`,
          height: `calc(100% - ${borderWidth}px)`,
          x: borderWidth / 2,
          y: borderWidth / 2,
          ry: this.backgroundBorderRadius,
          rx: this.backgroundBorderRadius,
          stroke: useGradient ? gradientLink : color,
          strokeWidth: borderWidth,
          fill: 'transparent',
          position: 'absolute',
        },
      }

      if (useGradient) {
        frame.gradient = {
          name: gradientName,
          colors,
          angle,
          hard,
        }
      }

      return frame
    },

    separatorStyles() {
      if (!_.get(this.settings, 'separatorSettings', null)) {
        return null
      }

      const { backgroundColor = {}, size } = this.settings.separatorSettings

      const { color = null, gradient = {}, useGradient = false } = backgroundColor
      const { colors = [], angle = 0, hard = false } = gradient

      const styles = {
        height: `${size}px`,
      }

      if (useGradient) {
        styles.backgroundImage = `linear-gradient(${angle}deg, ${createGradientString(colors, hard)})`
      } else {
        styles.backgroundColor = color
      }

      return styles
    },
  },
  methods: {
    calculateBorderRadius(el, borderRadius) {
      let finalBorderRadius = borderRadius

      if (el && el.getBoundingClientRect) {
        const {
          width: elWidth,
          height: elHeight,
        } = el.getBoundingClientRect()
        finalBorderRadius = _.min([elWidth / 2, elHeight / 2]) * (borderRadius / 100)
      }

      return Math.floor(finalBorderRadius)
    },

    generateRenderKey() {
      this.$nextTick(() => {
        this.renderKey = this.$timestamp + _.random(1, 100000)
      })
    },

    processSlider() {
      clearTimeout(this.sliderTimeout)
      this.regenerateRender()

      this.sliderTimeout = setTimeout(() => {
        if (this.currentSlideIndex >= this.slides.length - 1) {
          this.currentSlideIndex = 0
        } else {
          this.currentSlideIndex++
        }

        this.processSlider()
      }, this.slider.slideDuration * 1000)
    },

    processSlideHeight() {
      this.$nextTick(() => {
        this.slideHeight = 0

        if(this.$refs.slides) {
          this.$refs.slides.forEach(slideRef => {
            slideRef.offsetHeight

            if (this.slideHeight < slideRef.offsetHeight) {
              this.slideHeight = slideRef.offsetHeight
            }
          })
        }
      })
    },

    regenerateRender() {
      this.$nextTick(() => {
        this.processSlideHeight()

        this.generateRenderKey()
      })
    },
  },
  mounted() {
    this.regenerateRender()
  },
  watch: {
    $appWidth: 'generateRenderKey',
    $appHeight: 'generateRenderKey',
    slides: {
      handler(value) {
        this.currentSlideIndex = 0

        this.processSlider()

        this.regenerateRender()
      },
      immediate: true,
    },
    settings: {
      handler(value) {
        this.currentSlideIndex = 0

        this.processSlider()

        this.regenerateRender()
      },
      immediate: true,
    },
  },
}
</script>
