<style lang="scss" scoped>
  .general-settings-apps-page {

  }
</style>

<template>
  <div class="general-settings-apps-page">
    <transition name="fade-up">
      <div v-show="!loadingState.page" ref="legacy"/>
    </transition>
  </div>
</template>

<script>

import $ from 'jquery'

import { loadLegacyPage } from '@src/services/legacyLoader'

export default {
  name: 'GeneralSettingsAppsPage',
  data() {
    return {
      loadingState: {
        page: true,
      },
    }
  },
  metaInfo() {
    return {
      title: [
        this.$t('dashboard.pages.generalSettings.apps'),
        this.$t('dashboard.pages.generalSettings.generalSettings'),
      ].join(' - '),
    }
  },
  mounted() {
    loadLegacyPage('/dashboard/old-page-general', this.$refs.legacy)
      .then(() => {
        $('#_1337block-connections').hide()
        $('#_1337block-black-list').hide()
        $('#settings_general').hide()
        $('#_1337block-account-settings').hide()
        $('#delete-account').hide()
      })
      .finally(() => {
        this.loadingState.page = false
      })
  },
}
</script>
