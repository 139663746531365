<style scoped lang="scss">
  @mixin tag() {
    position: absolute;
    top: 0;
    margin-left: 5px;
    color: var(--theme-color-additional);
    font-size: 8px;
    line-height: 1em;
    font-weight: 500;
  }

  .dashboard-menu {
    user-select: none;

    .menu-chunk + .menu-chunk {
      margin-top: 5px;

      &:before {
        content: '';
        display: block;
        margin-bottom: 5px;
        @include margin-x(8px);
        border-top: 1px solid rgba(white, .05);
        border-bottom: 1px solid rgba(black, .1);
      }
    }

    .menu-item {
      .menu-item-link {
        border-radius: 8px;
        display: flex;
        align-items: center;
        padding: 10px;
        cursor: pointer;
        color: $bright;

        .menu-item-icon {
          flex-shrink: 0;
          font-size: 16px;
        }

        &.router-link-active {
          background-color: #191919;

          .menu-item-icon {
            fill: url(#theme_svg_gradient);
          }
        }

        &:hover:not(.router-link-active) {
          background-color: #303030;
        }

        .menu-item-text {
          flex-shrink: 0;
          font-weight: 500;
          line-height: 16px;
          position: relative;
        }

        .menu-item-icon + .menu-item-text {
          margin-left: 12px;
        }

        &.tag-beta .menu-item-text:after {
          content: "BETA";
          @include tag();
        }

        &.tag-new .menu-item-text:after {
          content: "NEW";
          @include tag();
        }
      }

      .menu-item-body {
        padding-left: 38px;
        padding-bottom: 5px;
        display: flex;
        flex-direction: column;

        .menu-child-item {
          @include padding-y(6px);
          font-size: 13px;
          font-weight: 500;
          color: rgba($bright, .6);
          position: relative;
          display: flex;
          align-items: center;

          .menu-child-item-icon {
            flex-shrink: 0;
            font-size: 16px;
          }

          &.router-link-active {
            .menu-child-item-icon {
              fill: url(#theme_svg_gradient);
            }

            .menu-child-item-text {
              color: var(--theme-color-additional);
            }
          }

          &:hover:not(.router-link-active) {
            color: $bright;
          }

          .menu-child-item-text {
            position: relative;
          }

          .menu-child-item-icon + .menu-child-item-text {
            margin-left: 8px;
          }

          &.tag-beta .menu-child-item-text:after {
            content: "BETA";
            @include tag();
          }

          &.tag-new .menu-child-item-text:after {
            content: "NEW";
            @include tag();
          }
        }
      }
    }
  }
</style>

<template>
  <div class="dashboard-menu">
    <div
      v-for="(menuChunk, chunkIndex) in menuChunks"
      :key="`chunk_${chunkIndex}`"
      class="menu-chunk">
      <div
        v-for="(menuItem, itemIndex) in menuChunk"
        :key="`item_${itemIndex}`"
        class="menu-item">
        <template v-if="menuItem.href">
          <a :href="menuItem.href" target="_blank" class="menu-item-link">
            <icon
              v-if="menuItem.icon"
              class="menu-item-icon"
              :name="menuItem.icon"/>
            <span class="menu-item-text">{{ menuItem.title }}</span>
          </a>
        </template>
        <template v-else>
          <router-link
            :to="{name: menuItem.route, params: menuItem.params}"
            class="menu-item-link"
            :class="menuItem.classes"
            :exact="menuItem.exact"
            @click.native="onMenuItemClick({name: menuItem.route, params: menuItem.params})">
            <icon
              v-if="menuItem.icon"
              class="menu-item-icon"
              :name="menuItem.icon"/>
            <span class="menu-item-text">{{ menuItem.title }}</span>
          </router-link>
          <div v-if="menuItem.children && menuItem.children.length" class="menu-item-body">
            <router-link
              v-for="(menuChildItem, childItemIndex) in menuItem.children"
              :key="`${itemIndex}_${childItemIndex}`"
              :to="{name: menuChildItem.route , params: menuChildItem.params}"
              class="menu-child-item"
              :class="menuChildItem.classes"
              @click.native="onMenuItemClick({name: menuItem.route, params: menuItem.params})">
              <icon
                v-if="menuChildItem.icon"
                class="menu-child-item-icon"
                :name="menuChildItem.icon"/>
              <span class="menu-child-item-text">{{ menuChildItem.title }}</span>
            </router-link>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'DashboardMenu',
  data() {
    return {}
  },
  computed: {
    ...mapState('dashboard', ['menuSettings']),

    menuChunks() {
      const translate = i => ({
        ...i,
        title: this.$t(`dashboard.pages.${i.title}`),
      })

      return [
        [
          {
            title: 'dashboard',
            icon: 'dashboard',
            route: 'dashboard',
            available: this.menuSettings.dashboard,
            exact: true,
          },
          {
            title: 'activityFeed.activityFeed',
            icon: 'activity-list',
            route: 'dashboard-activity-feed',
            available: this.menuSettings.activityFeed,
          },
        ],
        [
          {
            title: 'payouts',
            icon: 'finance',
            route: 'dashboard-payouts',
            available: this.menuSettings.payouts,
          },
          {
            title: 'offers.offers',
            icon: 'monetization',
            route: 'dashboard-offers',
            available: this.menuSettings.offers,
            classes: ['tag-beta'],
          },
          {
            title: 'partnership',
            icon: 'monetization',
            route: 'dashboard-legacy-partnership',
            available: this.menuSettings.partnership,
          },
          {
            title: 'referralProgram.referralProgram',
            icon: 'share',
            route: 'dashboard-referral-programs',
            available: this.menuSettings.referralProgramMemeAlerts,
            children: [
              {
                title: 'referralProgram.memeAlerts',
                route: 'dashboard-referral-program',
                params: { program: 'meme-alerts' },
                available: this.menuSettings.referralProgramMemeAlerts,
                classes: ['tag-new'],
              },
            ]
          },
          {
            title: 'merchandise',
            icon: 'market',
            route: 'dashboard-merchandise',
            available: this.menuSettings.merchandise,
          },
          {
            title: 'giveaways',
            icon: 'raffle',
            route: 'dashboard-giveaways',
            available: this.menuSettings.giveaways,
          },
        ],
        [
          {
            title: 'donationsSettings.donationsSettings',
            icon: 'settings',
            route: 'dashboard-donations-settings',
            available: this.menuSettings.donationsSettings,
          },
          {
            title: 'donationPageSettings',
            icon: 'page',
            route: 'dashboard-donation-page-settings',
            available: this.menuSettings.donationPageSettings,
          },
          {
            title: 'partners',
            icon: 'thirdparty',
            route: 'dashboard-partners',
            available: this.menuSettings.bft,
            children: [
              {
                title: 'bft.bft',
                icon: 'joystick',
                route: 'dashboard-chaostricks',
                available: this.menuSettings.bft,
                classes: ['tag-new'],
              },
            ]
          },
        ],
        [
          {
            title: 'widgets.widgets',
            icon: 'widgets',
            route: 'dashboard-widgets',
            available: this.menuSettings.widgetAlerts,
            children: [
              {
                title: 'widgets.alerts',
                route: 'dashboard-widget-alerts',
                available: this.menuSettings.widgetAlerts,
              },
              {
                title: 'widgets.streamStats',
                route: 'dashboard-widget-stream-stats-list',
                available: this.menuSettings.widgetStreamStats,
                classes: ['tag-new'],
              },
              {
                title: 'widgets.streamStatsLegacy',
                route: 'dashboard-widget-stream-stats-legacy',
                available: this.menuSettings.widgetStreamStats,
              },
              {
                title: 'widgets.streamCredits',
                route: 'dashboard-widget-stream-credits',
                available: this.menuSettings.widgetStreamCredits,
                classes: ['tag-new'],
              },
              {
                title: 'widgets.donationGoal',
                route: 'dashboard-widget-donation-goal',
                available: this.menuSettings.widgetDonationGoal,
              },
              {
                title: 'widgets.media',
                route: 'dashboard-widget-media',
                available: this.menuSettings.widgetMedia,
              },
              {
                title: 'widgets.poll',
                route: 'dashboard-widget-poll',
                available: this.menuSettings.widgetPoll,
              },
              {
                title: 'widgets.stickers',
                route: 'dashboard-widget-stickers',
                available: this.menuSettings.widgetStickers,
              },
              {
                title: 'widgets.corona',
                route: 'dashboard-widget-corona',
                available: this.menuSettings.widgetCorona,
              },
              {
                title: 'widgets.roulette',
                route: 'dashboard-widget-roulette',
                available: this.menuSettings.widgetRoulette,
              },
              {
                title: 'widgets.newYear',
                route: 'dashboard-widget-new-year',
                available: this.menuSettings.widgetNewYear,
              },
            ],
          },
        ],
        [
          {
            title: 'chatBot.chatBot',
            icon: 'chat',
            route: 'dashboard-chat-bot',
            available: this.menuSettings.chatBotAccounts,
            children: [
              {
                title: 'chatBot.accounts',
                route: 'dashboard-chat-bot-section',
                params: { section: 'accounts' },
                available: this.menuSettings.chatBotAccounts,
              },
              {
                title: 'chatBot.multichat',
                route: 'dashboard-chat-bot-section',
                params: { section: 'multichat' },
                available: this.menuSettings.chatBotMultichat,
              },
              {
                title: 'chatBot.games',
                route: 'dashboard-chat-bot-section',
                params: { section: 'games' },
                available: this.menuSettings.chatBotGames,
                classes: ['tag-new'],
              },
              {
                title: 'chatBot.timers',
                route: 'dashboard-chat-bot-section',
                params: { section: 'timers' },
                available: this.menuSettings.chatBotTimers,
              },
              {
                title: 'chatBot.moderate',
                route: 'dashboard-chat-bot-section',
                params: { section: 'moderate' },
                available: this.menuSettings.chatBotModerate,
              },
              {
                title: 'chatBot.commands',
                route: 'dashboard-chat-bot-section',
                params: { section: 'commands' },
                available: this.menuSettings.chatBotCommands,
              },
              {
                title: 'chatBot.polls',
                route: 'dashboard-chat-bot-section',
                params: { section: 'polls' },
                available: this.menuSettings.chatBotPolls,
              },
              {
                title: 'chatBot.kappagen',
                route: 'dashboard-chat-bot-section',
                params: { section: 'kappagen' },
                available: this.menuSettings.chatBotKappagen,
              },
              {
                title: 'chatBot.ticker',
                route: 'dashboard-chat-bot-section',
                params: { section: 'ticker' },
                available: this.menuSettings.chatBotTicker,
              },
            ],
          },
        ],
        [
          {
            title: 'help',
            icon: 'help',
            href: '/help',
            available: this.menuSettings.help,
          },
        ],
      ]
        .map(chunk => {
          return chunk
            .filter(item => item.available)
            .map(item => {
              if (item.children) {
                item.children = item.children
                  .filter(item => item && (item.available))
                  .map(translate)
              }

              return translate(item)
            })
        })
        .filter(chunk => !!chunk.length)
    },
  },
  methods: {
    onMenuItemClick(route) {
      const nextRoute = this.$router.resolve(route)

      this.$ga.event({
        eventCategory: 'sidebar',
        eventAction: 'nav_item_click',
      }, {
        url: nextRoute ? nextRoute.href : undefined,
      })

      this.$tmr.goal({
        goal: 'nav_item_click',
      }, {
        url: nextRoute ? nextRoute.href : undefined,
      })
    },
  },
}
</script>
