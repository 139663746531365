<style lang="scss" scoped>
  .file-manager {
    display: flex;
    align-items: center;

    .clear-button {
      margin-left: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: rgba(white, .2);
      cursor: pointer;

      &:hover {
        color: rgba(white, .5);
      }
    }
  }
</style>

<template>
  <div class="file-manager">
    <btn
      :icon-left="button.icon"
      @click="showFileManager"
      :is-loading="loadingState.files">{{ button.text }}
    </btn>
    <div
      v-if="value"
      class="clear-button"
      @click="clearFile">
      <icon name="cross"/>
    </div>
  </div>
</template>

<script>

export default {
  name: 'FileManager',
  props: {
    value: {
      type: [String, Number],
      default: null,
    },
    widgetElement: {
      type: String,
      required: true,
    },
    fileType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loadingState: {
        files: false,
      },
    }
  },
  computed: {
    ...mapGetters('fileManager', ['getFile']),

    file() {
      const file = this.getFile(this.value)

      if (!file) {
        this.fetchFile(this.value)
      }

      return this.getFile(this.value)
    },

    button() {
      const fileTypeIcons = {
        image: 'image',
        video: 'image',
        vector: 'vector-square',
        sound: 'music',
      }

      return {
        icon: fileTypeIcons[this.fileType],
        text: this.file ? this.file.name : this.$t(`global.fileManager.choose.${this.fileType}`),
      }
    },
  },
  methods: {
    ...mapActions('fileManager', ['openFileManager', 'fetchFiles', 'fetchFile']),

    showFileManager() {
      this.loadingState.files = true

      this.fetchFiles(this.widgetElement)
        .then(() => {
          this
            .openFileManager({
              widgetElement: this.widgetElement,
              fileType: this.fileType,
              fileId: this.value,
            })
            .then(fileId => {
              this.$emit('input', fileId)
            })
        })
        .finally(() => {
          this.loadingState.files = false
        })
    },

    clearFile() {
      this.$emit('input', null)
    },
  },
}
</script>
