import * as Sentry from '@sentry/browser'
import session from '@services/session'
import currency from 'currency.js'

const STORAGE_AUTH_TOKEN = 'da.auth.token'

export const state = {
  socketToken: window.token_centrifugo_connect,

  profile: null,

  profileTimezone: window.document_timezone,

  astrumAgreementAccepted: false,
  advAgreementAccepted: true,
}

export const getters = {
  getCurrentProfile: state => state.profile,

  getUserCurrency: state => _.get(state.profile, 'main_currency', 'RUB'),

  getUserRoles: state => _.get(state.profile, 'roles', []),
  getUserHasRole: (state, getters) => role => getters.getUserRoles.includes(role),
  getUserIsStreamer: (state, getters) => getters.getUserHasRole('streamer'),
  getUserIsViewer: (state, getters) => getters.getUserHasRole('viewer'),

  getUserBalance: (state, getters, rootState, rootGetters) => {
    return balanceKey => {
      const balanceAmount = _.get(state.profile, balanceKey, 0)

      const amountFormatted = currency(balanceAmount, {
        separator: ' ',
        decimal: ',',
      }).format()

      return {
        amount: balanceAmount,
        amountFormatted,
        currency: getters.getUserCurrency,
        amountWithCurrency: rootGetters['currencies/getAmountWithCurrency'](amountFormatted, getters.getUserCurrency),
      }
    }
  },

  getUserBalances: (state, getters, rootState, rootGetters) => {
    return balanceKey => {
      const balances = _.get(state.profile, balanceKey, [])

      return balances.map(balance => {
        return {
          amount: balance.balance,
          amountFormatted: currency(balance.balance, {
            separator: ' ',
            decimal: ',',
          }).format(),
          currency: balance.currency,
        }
      })
    }
  },

  getCredentials: state => ({
    userId: state.profile.id,
    token: session.getApiToken(),
    centrifugoToken: state.socketToken,
  }),

  getBlackListWords: state => state.profile.black_list_words ?? [],

  getTimezone: state => state.profileTimezone || dates.DEFAULT_TIME_ZONE,
}

export const mutations = {
  SET_PROFILE(state, data) {
    state.profile = data

    state.profileTimezone = data.timezone

    if (data && data.id) {
      _tmr.push({ type: 'setUserID', userid: data.id })
    }
  },

  SET_ASTRUM_AGREEMENT_STATUS(state, value) {
    state.astrumAgreementAccepted = value
  },

  SET_ADV_AGREEMENT_STATUS(state, value) {
    state.advAgreementAccepted = value
  },
}

export const actions = {
  fetchWidgetProfile({ commit }) {
    return api.get('/user/widget')
      .then(({ data }) => {
        commit('SET_PROFILE', data)

        Sentry.setUser({ username: data.code, id: data.id })

        return data
      })
  },

  fetchCurrentProfile({ commit, state }) {
    return api.get('/user')
      .then(({ data }) => {
        commit('SET_PROFILE', data)

        // LEGACY ---> TODO: перевод на реальный SPA
        // window.is_twofactor_user = false
        // <--- LEGACY

        Sentry.setUser({ username: data.code, id: data.id })

        return data
      })
  },

  fetchAstrumAgreementStatus({ commit }) {
    return api.get('/accept-advert-da-agreement')
      .then(({ data }) => {
        commit('SET_ASTRUM_AGREEMENT_STATUS', !!data.status)
      })
  },

  updateAstrumAgreementStatus({ commit }) {
    return api.post('/accept-advert-da-agreement')
      .then(({ data }) => {
        commit('SET_ASTRUM_AGREEMENT_STATUS', !!data.int_value)
      })
  },

  fetchAdvInfoStatus({ commit }) {
    return api.get('/accept-ord-agreement')
      .then(({ data }) => {
        commit('SET_ADV_AGREEMENT_STATUS', !!data.status)
      })
  },

  updateAdvInfoStatus({ commit }) {
    return api.put('/accept-ord-agreement')
      .then(({ data }) => {
        cookies.setItem('adv-agreement', !!data.status)
        commit('SET_ADV_AGREEMENT_STATUS', !!data.int_value)
      })
  },

  openAdvInfoModal({ commit }) {
    commit('SET_ADV_AGREEMENT_STATUS', false)
  },
}
