<template>
  <div class="brand__container" >
    <div class="brand__col">
      <div class="brand__title">{{$t('offers.attributes.advertiser')}}</div>
      <div class="brand__content">{{ brandName }}</div>
    </div>

    <div class="brand__col">
      <div class="brand__title">{{$t('offers.attributes.dates')}}</div>
      <div class="brand__content">{{ dates }}</div>
    </div>

    <div class="brand__col">
      <div class="brand__title">{{$t('offers.attributes.reward')}}</div>
      <div class="brand__content">
        <Reward v-if="bonusType === 'action'" icon="cursor" :text="$t('offers.rewards.costPerAction')" />
        <Reward v-if="bonusType === 'fix'" icon="bag" :text="$t('offers.rewards.fixedAmount')" />
        <Reward v-if="bonusType === 'sale'" icon="cart" :text="$t('offers.rewards.merchandiseSales')" />
        <Reward v-if="bonusType === 'activation'" icon="cursor" :text="$t('offers.rewards.perPromocode')" />
      </div>
    </div>
  </div>
</template>


<script>
import Reward from './Reward'

export default {

  name: 'DetailsBrandInfo',

   components: {
    Reward,
  },

  props: {
    brandName: {
      type: String,
      default: null,
    },
    dates: {
      type: String,
      default: null,
    },
    bonusType: {
      type: String,
      default: null,
    },
  },
  
}
</script>


<style scoped lang="scss">
.brand__container {
  position: relative;
  background-color: #262626;
  border-radius: 5px;
  padding: 10px;
  font-size: 14px;
  cursor: default;
  margin: 20px 0;

  @media (min-width: 720px) {
    max-width: 600px;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
  }
}

.brand__col {
  padding: 10px;
  border-top: 1px solid #1F1F1F;

  &:first-child {
    border-top: 0;
  }

  @media (min-width: 720px) {
    padding: 10px 10px 10px 20px;
    border-top: 0;
    border-left: 1px solid #1F1F1F;
    flex: 1 1 auto;

    &:first-child {
      padding: 10px;
      border-left: 0;
    }
  }
}

.brand__title {
  color: #838383;
  margin-bottom: 10px;
}

.brand__content {
  color: #F1F1F1;
}
</style>