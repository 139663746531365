<style scoped lang="scss">
  .goal-indicator-settings {
  }
</style>

<template>
  <div class="goal-indicator-settings">
    <form-group inline :label="$t('roulette.goal.visualSettings.indicator.indicatorBackgroundColor')">
      <color-gradient-picker v-model="indicatorBackgroundColor"/>
    </form-group>
    <form-group inline :label="$t('roulette.goal.visualSettings.indicator.indicatorColor')">
      <color-gradient-picker v-model="indicatorFillerColor"/>
    </form-group>
    <form-group inline :label="$t('global.settings.labels.borderRadius')">
      <base-range :min="0" :max="100" v-model="indicatorBorderRadius" input input-addon="%"/>
    </form-group>
    <form-group inline :label="$t('global.settings.labels.borderWidth')">
      <base-range :min="0" :max="10" :step=".1" v-model="indicatorBorderWidth" input input-addon="px"/>
    </form-group>
    <form-group v-if="indicatorBorderWidth" inline :label="$t('global.settings.labels.borderColor')">
      <color-gradient-picker v-model="indicatorBorderColor"/>
    </form-group>
    <form-group inline :label="$t('global.settings.labels.shadow')">
      <shadow-styler v-model="indicatorShadow"/>
    </form-group>
  </div>
</template>

<script>

import ColorGradientPicker from '@components/BaseComponents/ColorPicker/ColorGradientPicker'
import ShadowStyler from '@components/BaseComponents/ShadowStyler/ShadowStyler'

export default {
  name: 'GoalIndicatorSettings',
  components: {
    ShadowStyler,
    ColorGradientPicker,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      indicatorBackgroundColor: {},
      indicatorFillerColor: {},
      indicatorBorderWidth: 0,
      indicatorBorderColor: {},
      indicatorBorderRadius: 0,
      indicatorShadow: null,
    }
  },
  computed: {
    output() {
      return {
        indicatorBackgroundColor: this.indicatorBackgroundColor,
        indicatorFillerColor: this.indicatorFillerColor,
        indicatorBorderWidth: this.indicatorBorderWidth,
        indicatorBorderColor: this.indicatorBorderColor,
        indicatorBorderRadius: this.indicatorBorderRadius,
        indicatorShadow: this.indicatorShadow,
      }
    },
  },
  created() {
    if (this.value) {
      Object.keys(this.value).forEach(key => {
        this[key] = this.value[key] ?? this[key]
      })
    }
  },
  watch: {
    output(value) {
      this.$emit('input', value)
    },
  },
}
</script>
