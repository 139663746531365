import Vue from 'vue'

Vue.config.productionTip = false

let componentIdIncremental = 1

Vue.mixin({
  data() {
    return {
      componentMounted: false,
      $componentId: null,
    }
  },
  computed: {
    ...mapState('application', {
      $timestamp: 'timestamp',
      $appWidth: 'appWidth',
      $appHeight: 'appHeight',
      $scrollTop: 'scrollTop',
    }),
    ...mapGetters('application', {
      $parsedDate: 'parsedDate',
      $appSize: 'getAppSize',
      $appIsSM: 'appIsSM',
      $appIsMD: 'appIsMD',
      $appIsLG: 'appIsLG',
    }),
    ...mapGetters('fileManager', ['getFile']),

    ...mapState('application', ['isAstrumApp']),

    // $getFile() {
    //   return id => {
    //     if (!id) {
    //       return null
    //     }
    //
    //     const file = this.getFile(id)
    //
    //     if (!file && !cachedFiles[id]) {
    //       cachedFiles[id] = this.fetchFile(id)
    //     }
    //
    //     return file
    //   }
    // },
  },
  methods: {
    ...mapActions('fileManager', ['fetchFile']),

    $asAppAsset(url) {
      return `${process.env.VUE_APP_ASSETS_PATH}${url}`
    },

    $tooltip(content, placement = 'top', show = false, trigger) {
      return {
        content,
        placement,
        show,
        trigger,
      }
    },
  },
  mounted() {
    this.componentMounted = true
  },
  created() {
    this.$componentId = ++componentIdIncremental
  },
})
