<style scoped lang="scss">
  .roulette-sound-settings {
    
    .settings-area {
      @include media(max, md) {
        grid-template-columns: auto !important;
      }
    }

  }
</style>

<template>
  <div class="roulette-sound-settings">
    <grid columns="2" gap="40" class="settings-area">
      <div>
        <form-group inline :label="$t('roulette.visualSettings.sounds.appearSound')">
          <file-manager
            v-model="appearSound"
            widget-element="roulette_sound"
            file-type="sound"/>
        </form-group>
        <form-group v-if="appearSound" inline :label="$t('roulette.visualSettings.sounds.appearVolume')">
          <base-range v-model="appearSoundVolume" :min="0" :max="100" :step="1" input input-addon="%"/>
        </form-group>
        <form-group inline :label="$t('roulette.visualSettings.sounds.disappearSound')">
          <file-manager
            v-model="disappearSound"
            widget-element="roulette_sound"
            file-type="sound"/>
        </form-group>
        <form-group v-if="disappearSound" inline :label="$t('roulette.visualSettings.sounds.disappearVolume')">
          <base-range v-model="disappearSoundVolume" :min="0" :max="100" :step="1" input input-addon="%"/>
        </form-group>
        <hr>
        <form-group
          v-tooltip="tooltips.discountSound"
          inline :label="$t('roulette.visualSettings.sounds.discountSound')">
          <file-manager
            v-model="discountSound"
            widget-element="roulette_sound"
            file-type="sound"/>
        </form-group>
        <form-group v-if="discountSound" inline :label="$t('roulette.visualSettings.sounds.discountVolume')">
          <base-range v-model="discountSoundVolume" :min="0" :max="100" :step="1" input input-addon="%"/>
        </form-group>
      </div>
      <div>
        <form-group inline :label="$t('roulette.visualSettings.sounds.spinSound')">
          <file-manager v-model="spinSound" widget-element="roulette_sound" file-type="sound"/>
        </form-group>
        <form-group v-if="spinSound" inline :label="$t('roulette.visualSettings.sounds.spinVolume')">
          <base-range v-model="spinSoundVolume" :min="0" :max="100" :step="1" input input-addon="%"/>
        </form-group>
        <hr>
        <form-group inline label="Общая громкость">
          <base-range v-model="rouletteSoundVolume" :min="0" :max="100" :step="1" input input-addon="%"/>
        </form-group>
        <form-group>
          <info-box class="small" :show-icon="false">
            {{ $t('roulette.visualSettings.sounds.soundText') }}
          </info-box>
        </form-group>
      </div>
    </grid>
  </div>
</template>

<script>
import ShadowStyler from '@components/BaseComponents/ShadowStyler/ShadowStyler'


import ColorGradientPicker from '@components/BaseComponents/ColorPicker/ColorGradientPicker'


export default {
  name: 'RouletteSoundSettings',
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      appearSound: null,
      appearSoundVolume: 80,
      disappearSound: null,
      disappearSoundVolume: 80,
      discountSound: null,
      discountSoundVolume: 80,
      spinSound: null,
      spinSoundVolume: 80,
      rouletteSoundVolume: 100,
    }
  },
  computed: {
    tooltips() {
      return {
        discountSound: this.$tooltip(this.$t('roulette.visualSettings.sounds.discountSoundTooltip')),
      }
    },

    output() {
      return {
        _version: Math.random().toString(36).slice(2),
        spinSound: this.spinSound,
        spinSoundVolume: this.spinSoundVolume,
        appearSound: this.appearSound,
        appearSoundVolume: this.appearSoundVolume,
        disappearSound: this.disappearSound,
        disappearSoundVolume: this.disappearSoundVolume,
        rouletteSoundVolume: this.rouletteSoundVolume,
        discountSound: this.discountSound,
        discountSoundVolume: this.discountSoundVolume,
      }
    },
  },
  created() {
    const storedValue = _.cloneDeep(this.value)

    if (storedValue) {
      Object.keys(storedValue).forEach(key => {
        this[key] = storedValue[key] ?? this[key]
      })
    }
  },
  watch: {
    output(value) {
      this.$emit('input', value)
    },
  },
}
</script>
