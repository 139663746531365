<script setup>
import { computed, nextTick, onMounted, ref } from 'vue'

import BaseInput from './BaseInput.vue'
import DaButton from '../BaseButton.vue'

const props = defineProps({
  value: {
    type: String,
    default: '',
  },
  maskedVal: {
    type: String,
    default: ''
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  immediatelyChange: {
    type: Boolean,
    default: false,
  },
  changeText: {
    type: String,
  },
  saveText: {
    type: String,
  },
  cancelText: {
    type: String,
  },
})

const emit = defineEmits(['input'])

const input = ref(null)
const editMode = ref(false)
const inputVal = ref(props.value)

const updateInputField = (val) => {
  inputVal.value = val

  if(props.immediatelyChange) emit('input', inputVal.value)
}

const changeMode = (edditable = false) => {
  editMode.value = edditable

  nextTick(() => {
    const inputEl = input.value.$refs.input
    inputEl.focus()
  })
}

const updateField = () => {
  emit('input', inputVal.value)
  editMode.value = false
}

const cancelUpdateField = () => {
  inputVal.value = props.value
  emit('input', props.value)
  editMode.value = false
}

const inputDisabled = computed(() => props.disabled || !editMode.value)
const masked = computed(() => props.maskedVal)
const mask = computed(() => props.value ? props.maskedVal: props.value)
const showMaskedInput = computed(() => masked.value && !editMode.value)
const showEditControlls = computed(() => editMode.value && !props.immediatelyChange)

onMounted(() => {
  if(props.immediatelyChange) {
    if(!props.value) editMode.value = true
  }
})
</script>

<template>
  <div class="editable-input">
    <base-input
      v-if="showMaskedInput"
      :value="mask"
      :disabled="true"
    />
    <base-input
      v-else
      :value="inputVal"
      @input="updateInputField"
      :disabled="inputDisabled"
      ref="input"
    />
    <div class="input-controls code-controls">
      <template v-if="showEditControlls">
        <da-button
          icon-left="check"
          class="btn-check"
          variant="secondary"
          v-tooltip="$tooltip($t('global.common.save'))"
          @click="updateField()"
        />
        <da-button
          v-tooltip="$tooltip($t('global.common.cancel'))"
          icon-left="times"
          variant="secondary"
          @click="cancelUpdateField()"
        />
      </template>
      <da-button
        v-if="!editMode"
        v-tooltip="$tooltip($t('global.common.edit'))"
        icon-left="pencil"
        @click="changeMode(true)"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.editable-input {
  width: 100%;
  display: flex;

  .input-controls {
    margin-left: 10px;
  }
}

.code-controls {
  display: flex;

  & .btn-check {
    margin-right: 10px;
  }
}
</style>
