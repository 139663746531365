import dataTypeSettings from './dataTypeSettings'

export const defaultWidgetDesign = () => ({
  verticalAlign: 'middle',
  mainRowTextStyles: {
    color: '#f1f1f1',
    textTransform: 'none',
    textAlign: 'center',
    fontWeight: 'bold',
    fontStyle: 'normal',
    textDecoration: 'none',
    textShadow: '0px 0px 3px #000000',
    strokeWidth: 0,
    strokeColor: '#ffffff',
    fontFamily: 'Rubik',
    fontSize: 24,
    lineHeight: 1.27,
    letterSpacing: 1,
    wordSpacing: 0,
    animationStyle: null,
    animationVariant: null,
  },
  additionalRowTextStyles: {
    color: '#f1f1f1',
    textTransform: 'none',
    textAlign: 'center',
    fontWeight: 'regular',
    fontStyle: 'normal',
    textDecoration: 'none',
    textShadow: '0px 0px 3px #000000',
    strokeWidth: 0,
    strokeColor: '#ffffff',
    fontFamily: 'Rubik',
    fontSize: 18,
    lineHeight: 1.27,
    letterSpacing: 1,
    wordSpacing: 1,
  },
})

export const dataTypeTemplates = Object.keys(dataTypeSettings)
  .reduce((acc, key) => ({
    ...acc, [key]: {
      template: dataTypeSettings[key].templatePlaceholder,
      additionalTemplate: dataTypeSettings[key].additionalTemplatePlaceholder,
    },
  }), {})

export const defaultWidgetSettings = () => {
  const defaultDataType = 'lastDonations'

  const { template, additionalTemplate } = dataTypeTemplates[defaultDataType]

  return {
    dataType: defaultDataType,
    dataPeriod: 'allTime',
    customPeriod: {
      'from': 0,
      'to': 0,
    },
    template,
    additionalTemplate,
    rowsNumber: 5,
    displayMode: 'list',
    widgetSpeed: 80,
    subscribersTypes: {
      twitchFollower: true,
      twitchSubscriber: true,
      twitchPrimeSubscriber: true,
      twitchGiftSubscriber: true,
      twitchGiftRenewalSubscriber: true,
      youtubeSubscriber: true,
      youtubeSponsor: true,
      vkSubscriber: true,
      boostySubscriber: true,
    },
    subscribersNumberSource: 'twitchFollower',
    viewersNumberSource: 'vkLiveViewer',
  }
}
