export const state = {
  sort: 'desc',
  isLoading: false,
  data: [],
  pagination: {
    page: 1,
    pagesCount: null,
    perPage: null,
    total: null,
  },
}

export const getters = {
  sort: ({ sort }) => sort,
  isLoading: ({ isLoading }) => isLoading,
  data: ({ data }) => data,
  pagination: ({ pagination }) => pagination,
}

export const mutations = {
  startLoading(state) {
    state.isLoading = true
  },

  endLoading(state) {
    state.isLoading = false
  },

  setBalanceHistory(state, { data }) {
    state.data = data
  },

  setPagination(state, pagination) {
    state.pagination = pagination
  },

  setSort(state, newValue) {
    state.sort = newValue
  },
}

export const actions = {
  async fetchBalanceHistory({ state, commit }, params = {}) {
    commit('startLoading')

    try {
      const { data, links, meta } = await api.get('/userbalancelog', { 
        params,
      })

      commit('setBalanceHistory', { data })
      commit('setPagination', {
        page: meta.current_page,
        pagesCount: meta.last_page,
        perPage: meta.per_page,
        total: meta.total,
      })
      commit('endLoading')
    } catch (error) {
      commit('endLoading')
      throw new Error(error)
    }
  },

  async toggleSort({ state, commit, dispatch }) {
    if (state.sort === 'desc') {
      commit('setSort', 'asc')
    } else {
      commit('setSort', 'desc')
    }

    dispatch('fetchBalanceHistory')
  },



  // fetchGoals({ dispatch }, {
  //   page = 1,
  //   perPage,
  //   isActive = null,
  // } = {}) {
  //   return api.get('/donationgoal', {
  //     params: {
  //       page,
  //       per_page: perPage,
  //       is_active: isActive,
  //     },
  //   })
  //     .then(async ({ data, meta }) => {
  //       const goals = await dispatch('storeGoals', data)

  //       return {
  //         goals: goals.map(goal => goal.id),
  //         pagination: {
  //           page: meta.current_page,
  //           pagesCount: meta.last_page,
  //           perPage: meta.per_page,
  //           total: meta.total,
  //         },
  //       }
  //     })
  // },




  // async fetchOffers({ state, commit }) {
  //   commit('startLoading')

  //   try {
  //     const { data } = await api.get(`/advoffer?offer_status=active`)
  
  //     commit('setOffers', { data })
  //     commit('endLoading')
  //   } catch (error) {
  //     commit('endLoading')
  //     throw new Error(error)
  //   }
  // },

  // clearOffers({ commit }) {
  //   commit('clearOffers')
  // },
}