<template>
  <modal v-model="modalOpened">
    <template slot="title">
      <template v-if="!!preset">{{$t('offers.advertisersAccount.editPreset')}}</template>
      <template v-else>{{$t('offers.advertisersAccount.newPreset')}}</template>
    </template>
    <template slot="body-content">
      <div>
        <form-group
          :label="$t('offers.advertisersAccount.name') + '*'"
          inline
        >
          <base-input v-model="title" />
        </form-group>

        <form-group
          :label="$t('offers.advertisersAccount.image') + '*'"
          inline
        >
          <file-manager v-model="image" widget-element="alerts_image" file-type="image"/>
        </form-group>

        <form-group
          :label="$t('offers.advertisersAccount.audio')"
          inline
        >
          <file-manager v-model="sound" widget-element="alerts_sound" file-type="sound"/>
        </form-group>

        <form-group
          :label="$t('offers.advertisersAccount.duration')"
          inline
        >
          <base-range
            v-model="duration"
            :min="5"
            :max="10"
            :input-addon="$t('offers.advertisersAccount.sec')"
            input
          />
        </form-group>

        <div class="debug" v-if="false">
          <div>title: <span>{{ title }}</span></div>
          <div>image: <span>{{ image }}</span></div>
          <div>sound: <span>{{ sound }}</span></div>
          <div>duration: <span>{{ duration }}</span></div>
        </div>

        <div class="button-group">
          <base-button 
            variant="primary-action" 
            @click="!!preset ? updatePresetHandler() : createPresetHandler()"
            :isLoading="isLocalLoading"
            :disabled="!title.length"
          >{{ !!preset ? $t('offers.save') : $t('offers.create') }}</base-button>

          <base-button 
            variant="secondary-action" 
            @click="undoClickHandler"
          >{{$t('offers.undo')}}</base-button>
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
import Modal from '@components/BaseComponents/Modal'
import FormGroup from '@components/BaseComponents/Form/FormGroup'
import BaseButton from '@components/BaseComponents/BaseButton'
import BaseInput from '@components/BaseComponents/Form/BaseInput'
import BaseRange from '@components/BaseComponents/BaseRange'

export default {
  components: {
    Modal,
    FormGroup,
    BaseButton,
    BaseInput,
    BaseRange,
  },

  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    preset: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      title: '',
      image: '',
      sound: '',
      duration: '',
    }
  },

  watch: {
    preset: function (newState, prevState) {
      if (newState) {
        this.title = newState.title
        this.image = newState.json.image || ''
        this.sound = newState.json.sound || ''
        this.duration = newState.json.duration || ''
      }
    }
  },

  computed: {
    ...mapGetters('sendOffers/presets', [
      'isLocalLoading',
    ]),

    modalOpened: {
      get() { return this.isShow },
      set(value) { return !value && this.undoClickHandler() },
    },
  },

  methods: {
    ...mapActions('sendOffers/presets', ['fetchNewPreset', 'fetchUpdatePreset']),

    undoClickHandler() {
      this.title = ''
      this.image = ''
      this.sound = ''
      this.duration = ''

      this.$emit('toggleModal') ;
    },

    createPresetHandler() {
      this.fetchNewPreset({
        title: this.title,
        image: this.image,
        sound: this.sound,
        duration: this.duration,
      })

      this.undoClickHandler()
    },

    updatePresetHandler() {
      this.fetchUpdatePreset({
        id: this.preset.id,
        title: this.title,
        image: this.image,
        sound: this.sound,
        duration: this.duration,
      })
    },
  },
}
</script>

<style scoped lang="scss">
.button-group {
  padding-bottom: 20px;
  margin-top: 40px;

  button {
    margin-right: 10px;
    border-radius: 8px;
  }
}

.debug {
  margin: 20px 0 10px 0;
  padding: 10px;
  border-radius: 8px;
  border: 1px dashed #838383;
  color: #838383;

  & > div {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  span {
    color: #f1f1f1;
    font-weight: bold;
  }
}
</style>