<style scoped lang="scss">
  .chat-bot-game-page {
    min-width: 100%;
    min-height: 100%;
  }
</style>

<template>
  <div class="chat-bot-game-page">
    <chat-bot-wrapper>
      <chat-bot-game :game="game"/>
    </chat-bot-wrapper>
  </div>
</template>

<script>

import ChatBotWrapper from '@pages/Dashboard/ChatBot/ChatBotWrapper'

export default {
  name: 'ChatBotGamePage',
  components: { ChatBotWrapper },
  props: {
    game: {
      type: String,
      required: true,
    },
  },
  metaInfo() {
    const title = [
      this.game,
      this.$t(`dashboard.pages.chatBot.games`),
      this.$t('dashboard.pages.chatBot.chatBot'),
    ]

    return {
      title: title.join(' - '),
    }
  },
}
</script>
