<template>
  <span class="time-left">{{time}}</span>
</template>

<script>

const getWithZero = num => num > 9 ? num : `0${num > 0 ? num : 0}`

export default {
  name: 'TimeLeft',
  props: {
    to: {
      type: [String, Number],
      default: null,
    },
    format: {
      type: String,
      default: 'mm:ss',
    },
  },
  data() {
    return {
      dateTo: null,
    }
  },
  computed: {
    time() {
      if (!this.dateTo) {
        return ''
      }

      let diff = dates.getTimeLeft(+this.$timestamp.toFixed(0), this.dateTo.toFixed(0))

      const timeIsOut = diff.days + diff.hours + diff.minutes + diff.seconds <= 0

      if (timeIsOut) {
        diff = {
          days: 0,
          hours: 0,
          minutes: 0,
          seconds: 0,
        }
      }

      const variants = {
        d: diff.days.toString(),
        dd: getWithZero(diff.days),
        h: diff.hours.toString(),
        hh: getWithZero(diff.hours),
        m: diff.minutes.toString(),
        mm: getWithZero(diff.minutes),
        s: diff.seconds.toString(),
        ss: getWithZero(diff.seconds),
      }

      return this.format.replace(/(\w+)/g, key => variants[key] || key)
    },
  },
  watch: {
    to: {
      handler() {
        this.dateTo = /[^\d.-]/g.test(this.to) ? 0 : this.to
      },
      immediate: true,
    },
  },
}
</script>
