<style scoped lang="scss">
  .send-offers-page {
  }
</style>

<template>
  <div class="send-offers-page">
    <div>
      <Title>{{$t('offers.advertisersAccount.title')}}</Title>

      <Tabs
        v-model="currentTab"
        :list="tabs.map(el => {
            el.title = $t(el.title)
            return el
          })"
      />
    </div>

    <div v-if="currentTab === 'presets'">
      <SendOffersPresets/>
    </div>

    <div v-if="currentTab === 'offers'">
      <SendOffersList/>
    </div>
  </div>
</template>

<script>
import Title from '@components/BaseComponents/Title'
import Tabs from '@components/BaseComponents/Tabs/Tabs'

import SendOffersPresets from './SendOffersPresets'
import SendOffersList from './SendOffersList'

export default {
  name: 'SendOffersPage',

  components: {
    Title,
    Tabs,
    SendOffersPresets,
    SendOffersList,
  },

  metaInfo() {
    return {
      title: this.$t('dashboard.pages.sendOffers'),
    }
  },

  data() {
    return {
      currentTab: 'presets',
      tabs: [
        {
          key: 'presets',
          title: 'offers.advertisersAccount.presets',
          icon: 'palette',
        },
        {
          key: 'offers',
          title: 'offers.advertisingCampaigns',
          icon: 'livefeed',
        },
      ],
    }
  },
}
</script>
