<style scoped lang="scss">
  .roulette-title-settings {
    
    .settings-area {
      @include media(max, md) {
        grid-template-columns: auto !important;
      }
    }

  }
</style>

<template>
  <div class="roulette-title-settings">
    <grid columns="2" gap="40" class="settings-area">
      <div>
        <form-group inline :label="$t('roulette.visualSettings.title.displayTitle')">
          <base-switch v-model="titleEnabled"/>
        </form-group>
        <template v-if="titleEnabled">
          <form-group inline :label="$t('roulette.visualSettings.title.title')">
            <base-input v-model="titleText"/>
          </form-group>
          <form-group inline :label="$t('global.settings.labels.textStyle')">
            <text-styler v-model="textStyles" class="top left"/>
          </form-group>
        </template>
      </div>
      <div>
        <template v-if="titleEnabled">
          <form-group inline :label="$t('roulette.visualSettings.title.positionX')">
            <base-range :min="-600" :max="600" v-model="titleOffsetX" input input-addon="px"/>
          </form-group>
          <form-group inline :label="$t('roulette.visualSettings.title.positionY')">
            <base-range :min="-200" :max="200" v-model="titleOffsetY" input input-addon="px"/>
          </form-group>
        </template>
      </div>
    </grid>
  </div>
</template>

<script>

import TextStyler from '@components/BaseComponents/TextStyler/TextStyler'

export default {
  name: 'RouletteTitleSettings',
  components: { TextStyler },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      titleEnabled: true,
      titleText: 'They see me rolling',
      textStyles: {},
      titleOffsetX: 0,
      titleOffsetY: 0,
    }
  },
  computed: {
    output() {
      return {
        _version: Math.random().toString(36).slice(2),
        titleEnabled: this.titleEnabled,
        titleText: this.titleText,
        textStyles: this.textStyles,
        titleOffsetX: this.titleOffsetX,
        titleOffsetY: this.titleOffsetY,
      }
    },
  },
  created() {
    const storedValue = _.cloneDeep(this.value)

    if (storedValue) {
      Object.keys(storedValue).forEach(key => {
        this[key] = storedValue[key] ?? this[key]
      })
    }
  },
  watch: {
    output(value) {
      this.$emit('input', value)
    },
  },
}
</script>
