<template>
  <div>
    <offers-error v-if="code" :code="code" />

    <loader
      size="md"
      v-if="isLoading"
      style="margin: 10vh auto;"
    />

    <div
      v-if="!isLoading && !offers.length && !code"
      style="text-align: center;"
    >
      <Title
        type='5'
        style="margin: 10vh auto;"
      >{{$t('offers.noData')}}</Title>
    </div>

    <data-grid
      v-if="!isLoading && offers.length"
    >
      <data-grid-header>
        <data-grid-col size="50">{{$t('offers.attributes.advertiser')}}</data-grid-col>
        <data-grid-col size="20">{{$t('offers.attributes.reward')}}</data-grid-col>
        <data-grid-col size="15">{{$t('offers.attributes.endDate')}}</data-grid-col>
        <data-grid-col size="15">{{$t('offers.attributes.status')}}</data-grid-col>
      </data-grid-header>

      <data-grid-row
        v-for="offer in offers"
        :key="offer.id"
      >
        <data-grid-col>
          <Advertiser
            :image="offer.brand.image"
            :title="offer.brand.title + ' - ' + offer.title"
            :linkTo="makeOffersLink(offer.id)"
          >
            {{ offer.description }}
          </Advertiser>
        </data-grid-col>

        <data-grid-col>
          <Reward v-if="offer.bonus_type === 'action'" icon="cursor" :text="$t('offers.rewards.costPerAction')" />
          <Reward v-if="offer.bonus_type === 'fix'" icon="bag" :text="$t('offers.rewards.fixedAmount')" />
          <Reward v-if="offer.bonus_type === 'sale'" icon="cart" :text="$t('offers.rewards.merchandiseSales')" />
          <Reward v-if="offer.bonus_type === 'activation'" icon="cursor" :text="$t('offers.rewards.perPromocode')" />
          <Reward v-if="offer.bonus_type === 'note'" icon="adv" :text="$t('offers.rewards.salesNotifications')" />
        </data-grid-col>

        <data-grid-col>
          <span>{{ offer.end_at_ts ? transformDate(offer.end_at_ts) : '—' }}</span>
        </data-grid-col>

        <data-grid-col>
          <div>
            <Status
              style="margin-bottom: 10px;"
              :type="offer.offer_user[0].status"
            />

            <router-link
              class="btn_link"
              :to="makeOffersLink(offer.id)"
            >
              <span>{{$t('offers.details')}}</span>
            </router-link>
          </div>
        </data-grid-col>
      </data-grid-row>
    </data-grid>


  </div>
</template>

<script>
import fromUnixTime from 'date-fns/fromUnixTime'

import Title from '@components/BaseComponents/Title'
import DG from '@components/Dashboard/DataGrid'
import Advertiser from '@components/Dashboard/Offers/Advertiser'

import Reward from '@components/Dashboard/Offers/Reward'
import Status from '@components/Dashboard/Offers/Status'

import OffersError from '@components/Dashboard/Offers/OffersError.vue'

export default {
  name: 'OffersArchivedPage',

  components: {
    Title,
    Reward,
    Status,
    Advertiser,
    OffersError,
    DataGrid: DG.DataGrid,
    DataGridHeader: DG.DataGridHeader,
    DataGridRow: DG.DataGridRow,
    DataGridCol: DG.DataGridCol,
  },

  metaInfo() {
    return {
      title: this.$t('dashboard.pages.offers.archived'),
    }
  },
  created() {
    this.fetchOffers('archived')
  },

  methods: {
    ...mapActions('offers/main', ['fetchOffers']),

    transformDate(val) {
      const timeZone = this.getTimezone

      const result = dates.unixToFormat(dates.utcToZonedTime(fromUnixTime(val), timeZone) / 1000, dates.DEFAULT_TIME_FORMAT)

      return result.split(' ')[0]
    },

    makeOffersLink(id) {
      if (this.newDashboard) {
        return {
          name: 'dashboard-offers-details',
          params: {
            id,
          },
        }
      } else {
        return {
          path: `/dashboard/offers-v2/details/${id}`,
        }
      }
    },
  },

  computed: {
    ...mapState('dashboard', ['newDashboard']),

    ...mapGetters('profile', [
      'getTimezone',
    ]),

    ...mapGetters('offers/main', [
      'isLoading',
      'offers',
      'code'
    ]),
  },
}
</script>

<style scoped lang="scss">
.btn_link {
  color: var(--theme-color-main);
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}
</style>
