<style scoped lang="scss">
  .category-presets {
    display: flex;

    .vertical-tabs {
      align-self: flex-start;
    }

    .presets-list {
      width: 100%;
      padding: 20px;
      margin-left: 20px;
      background-color: $additional-3;
      border-radius: 5px;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-auto-rows: 200px;
      grid-column-gap: 20px;
      grid-row-gap: 20px;
    }

    .preview-box {
      background-color: $dark;
      border-radius: 5px;
      overflow: hidden;
      background-size: cover;
      position: relative;
      display: flex;
      justify-content: center;

      .widget-roulette-background {
        position: absolute;
        width: 1000px;
        height: 200px;
      }

      &:hover {
        .controls {
          opacity: 1;
        }
      }

      .controls {
        opacity: 0;
        position: absolute;
        bottom: 10px;
        left: 0;
        width: 100%;
        display: flex;
        justify-content: center;
      }
    }
  }
</style>

<template>
  <div class="category-presets">
    <vertical-tabs v-model="currentTab" :list="tabs"/>
    <div v-if="categoryPresets.length" class="presets-list">
      <div
        v-for="(preset, index) in categoryPresets"
        :key="index"
        class="preview-box"
        @mouseenter="hoveredPresetIndex = index"
        @mouseleave="hoveredPresetIndex = null">
        <widget-roulette-background :settings="rouletteVisualSettings.backgroundSettings"/>
        <roulette-card
          :settings="preset.settings"
          :text="preset.previewText"
          :animated="hoveredPresetIndex === index"/>
        <div class="controls">
          <btn @click="setPresetSettings(index)" size="sm">{{ $t('global.common.apply') }}</btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import RouletteCard from '@components/Widgets/WidgetRoulette/RouletteCard/RouletteCard'
import VerticalTabs from '@components/BaseComponents/Tabs/VerticalTabs'
import WidgetRouletteBackground from '@components/Widgets/WidgetRoulette/WidgetRouletteBackground'

export default {
  name: 'CategoryPresets',
  components: {
    WidgetRouletteBackground,
    VerticalTabs,
    RouletteCard,
  },
  data() {
    return {
      currentTab: 'common',
      hoveredPresetIndex: null,
    }
  },
  computed: {
    ...mapState('roulette', { rouletteVisualSettings: 'rouletteVisualSettingsLocal' }),
    ...mapGetters('widgets/presets', ['getPresetsByType']),

    previewText() {
      return this.$t('roulette.category.cardPreviewText')
    },

    tabs() {
      return [
        {
          key: 'common',
          title: 'common',
        },
        {
          key: 'social',
          title: 'social',
        },
      ].map(tab => ({
        ...tab,
        title: this.$t(`roulette.category.presets.tabs.${tab.title}`),
      }))
    },

    presets() {
      return this.getPresetsByType('roulette-category')
    },

    categoryPresets() {
      return this.presets
        .reduce((acc, preset) => {
          const { json, meta = {} } = preset

          if (meta && meta.category === this.currentTab) {
            const useCards = meta.useCards ?? true

            acc.push({
              ...preset,
              previewText: useCards ? this.previewText : null,
              useCards,
              settings: json,
            })
          }

          return acc
        }, [])
    },
  },
  methods: {
    setPresetSettings(index) {
      const { settings, useCards, id } = this.categoryPresets[index]

      this.$emit('select', {
        useCards,
        settings,
        presetId: id,
      })
    },
  },
}
</script>
