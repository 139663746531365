<style lang="scss" scoped>
  .donation-page-settings-page {

  }
</style>

<template>
  <div class="donation-page-settings-page">
    <transition name="fade-up">
      <div v-show="!loadingState.page" ref="legacy"/>
    </transition>
  </div>
</template>

<script>
import $ from 'jquery'
import { loadLegacyPage } from '@src/services/legacyLoader'

export default {
  name: 'DonationPageSettingsPage',
  data() {
    return {
      loadingState: {
        page: false,
      },
    }
  },
  metaInfo() {
    return {
      title: this.$t('dashboard.pages.donationPageSettings'),
    }
  },
  mounted() {
    this.loadingState.page = true

    loadLegacyPage('/dashboard/old-page-donation-page', this.$refs.legacy)
      .then(() => {
        $('#_1337block-audio-donations').hide()
        $('#_1337block-text-donations').hide()
        $('#_1337block-minimal-amounts').hide()
        $('.b-btn[type=submit]').css('margin-top', '30px')
      })
      .finally(() => {
        this.loadingState.page = false
      })
  },
}
</script>
