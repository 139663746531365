import { computed, ref } from 'vue'

export default (promise) => {
  const pending = ref(false)
  const data = ref(null)
  const error = ref(null)
  const errorCode = ref(null)
  const errorMessage = ref(null)

  const exec = (...args) => {
    pending.value = true
    error.value = null
    errorCode.value = null
    errorMessage.value = null

    return promise(...args).then((response) => {
      data.value = response
    }).catch((e) => {
      error.value = e?.errors || e
      errorCode.value = e?.code || null
      errorMessage.value = e?.message
    }).finally(() => {
      pending.value = false
    })
  }

  const parsedErrors = computed(() => (error.value?.reduce((errors,error) => ({...errors, [error.param]: error.message}), {})))
  
  return {
    pending,
    data,
    error,
    parsedErrors,
    errorCode,
    errorMessage,
    exec
  }
}
