<template>
  <div style="margin-top: 10px;" >
    <loader
      size="md"
      v-if="isGlobalLoading"
    />

    <div v-else >
      <div class="list__header">
        <div>
          <span class="list__header-count" v-if="presets.length">{{$t('offers.advertisersAccount.presetsFound')}}: {{ presets.length }}</span>
          <span class="list__header-count" v-else>{{$t('offers.advertisersAccount.noPresetsFound')}}</span>
        </div>
      </div>

      <div class="list">
        <div class="list__item" @click="toggleModal">
          <new-preset-button />
        </div>

        <div
          v-for="preset in presets"
          :key="preset.id"
          class="list__item"
        ><preset-card
          :preset="preset"
          @toggleModal="toggleModal"
        /></div>
      </div>

      <preset-modal
        :isShow="showModal"
        :preset="currentPreset"
        @toggleModal="toggleModal"
      />
    </div>
  </div>
</template>

<script>
import PresetCard from '@components/Dashboard/SendOffers/PresetCard'
import NewPresetButton from '@components/Dashboard/SendOffers/NewPresetButton'
import PresetModal from '@components/Dashboard/SendOffers/PresetModal'

export default {
  components: {
    PresetCard,
    NewPresetButton,
    PresetModal,
  },

  data() {
    return {
      showModal: false,
      currentPreset: null,
    }
  },

  created() {
    this.fetchPresets('alerts')
  },

  computed: {
    ...mapGetters('sendOffers/presets', [
      'isGlobalLoading',
      'presets',
    ]),
  },

  methods: {
    ...mapActions('sendOffers/presets', ['fetchPresets']),

    toggleModal(id) {
      if (id) {
        if (this.showModal) {
          this.currentPreset = null
          this.showModal = false
        } else {
          this.currentPreset = this.presets.filter(p => p.id === id)[0]
          this.showModal = true
        }
      } else {
        this.currentPreset = null
        this.showModal = !this.showModal
      }
    },
  },
}
</script>

<style scoped lang="scss">
.list {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;

  &__header {
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 14px;

    &-count {
      color: #838383;
    }
  }

  &__item {
    padding: 10px;
    width: 20%;
  }
}
</style>
