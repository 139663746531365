<style lang="scss" scoped>
  .pagination {
    display: flex;
    align-items: center;
    user-select: none;
    font-size: $control-font-size;

    &.disabled {
      opacity: .5;
      pointer-events: none;
    }

    .pagination-pages {
      display: flex;
      align-items: center;
    }

    .pagination-block {
      min-width: 35px;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $bright;
      background-color: $additional-3;
    }

    .pagination-divider {
      &:before {
        content: '…';
      }
    }

    .pagination-button {
      border-radius: 3px;
      outline: none;
      cursor: pointer;

      &:hover {
        color: var(--theme-color-additional);
      }

      &.active {
        background-color: $additional-1;
        pointer-events: none;
      }

      &.disabled, &.disabled:hover {
        cursor: default;
        pointer-events: none;
        color: $additional-1;
      }

      &.prev, &.next {
        @include padding-x(12px);

        @include media(max, md) {
          display: none;
        }
      }
    }

    .pagination-block + .pagination-block {
      margin-left: 6px;
    }
  }
</style>

<template>
  <div class="pagination" :class="paginationClasses">
    <div
      class="pagination-block pagination-button prev"
      :class="controlsClasses.prev"
      @click="changePage(currentPage - 1)">{{$t('global.common.back')}}
    </div>
    <template v-for="(page,index) in pages">
      <div
        v-if="page.isDivider"
        :key="`${index}_dots`"
        class="pagination-block pagination-divider"></div>
      <div
        v-else
        :key="`${index}_page`"
        class="pagination-block pagination-button"
        :class="{active: page.isCurrent}"
        @click="changePage(page.num)">{{page.num}}
      </div>
    </template>
    <div
      :class="controlsClasses.next"
      class="pagination-block pagination-button next"
      @click="changePage(currentPage + 1)">{{$t('global.common.forward')}}
    </div>
  </div>
</template>

<script>

export default {
  name: 'Pagination',
  model: {
    prop: 'currentPage',
    event: 'input',
  },
  props: {
    currentPage: {
      type: Number,
      required: true,
    },
    pagesCount: {
      type: Number,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {}
  },
  methods: {
    changePage(page) {
      this.$emit('input', page)
    },
  },
  computed: {
    paginationClasses() {
      return {
        disabled: this.disabled,
      }
    },

    controlsClasses() {
      return {
        prev: {
          disabled: this.currentPage === 1,
        },
        next: {
          disabled: this.currentPage === this.pagesCount,
        },
      }
    },

    pages() {
      const { currentPage, pagesCount } = this

      const pages = [
        {
          isCurrent: currentPage === 1,
          num: 1,
        },
      ]

      if (currentPage - 2 > 1) {
        pages.push({
          isDivider: true,
        })
      }

      for (let i = currentPage - 1; i <= currentPage + 1; i += 1) {
        if (i > 1 && i < pagesCount) {
          pages.push({
            isCurrent: currentPage === i,
            num: i,
          })
        }
      }

      if (currentPage + 2 < pagesCount) {
        pages.push({
          isDivider: true,
        })
      }

      if (pagesCount !== 1) {
        pages.push({
          isCurrent: currentPage === pagesCount,
          num: pagesCount,
        })
      }

      return pages
    },
  },
}
</script>
