<style lang="scss" scoped>

</style>

<template>
  <div class="form-group" :class="classes">
    <label
      v-if="label && label.length"
      class="form-group-label"
      style="width: 240px;"
      @click="onLabelClick">{{label}}</label>
    <div class="form-group-content">
      <div ref="content" class="content">
        <slot/>
      </div>
      <div v-if="$slots.helper" class="helper">
        <slot name="helper"/>
      </div>
      <div
        v-if="messagesVisible"
        class="messages">
        <div
          v-if="validMessageVisible"
          class="form-group-message valid-message">{{validFeedback}}
        </div>
        <div
          v-if="invalidMessageVisible"
          class="form-group-message invalid-message">{{invalidFeedback}}
        </div>
        <div
          v-if="description"
          class="form-group-message description-message">{{description}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    label: {
      type: String,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    inline: {
      type: Boolean,
      default: false,
    },
    description: {
      type: String,
      default: null,
    },
    state: {
      type: Boolean,
      default: null,
    },
    invalidFeedback: {
      type: String,
      default: null,
    },
    validFeedback: {
      type: String,
      default: null,
    },
  },
  computed: {
    isValid() {
      return !!this.state
    },

    isInvalid() {
      return !!this.state === false
    },

    validMessageVisible() {
      return this.isValid && this.validFeedback
    },

    invalidMessageVisible() {
      return this.isInvalid && this.invalidFeedback
    },

    messagesVisible() {
      return this.validMessageVisible || this.invalidMessageVisible || !!this.description
    },

    classes() {
      return {
        invalid: this.invalidMessageVisible,
        valid: this.isValid,
        required: this.required,
        inline: this.inline,
      }
    },
  },
  methods: {
    onLabelClick() {
      // const focusTarget = this.$refs.content.querySelector('input, textarea, select')
      //
      // if (focusTarget) {
      //   focusTarget.focus()
      // }
    },
  },
}
</script>
