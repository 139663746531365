<style scoped lang="scss">
  .chat-bot-page {
    margin-top: -29px;
    margin-bottom: -20px;

    ::v-deep {
      .botSectionWrapper .sectionContent {
        @include padding-x(0);
      }

      .chatSectionWrapper {
        height: calc(100vh - #{$dashboard-header-height} - 1px);
        position: sticky;
        top: calc(#{$dashboard-header-height} + 1px);
      }
    }
  }
</style>

<template>
  <div class="chat-bot-page" :class="pageClasses">
    <chat-bot-wrapper>
      <component v-if="component" :is="component"/>
    </chat-bot-wrapper>
  </div>
</template>

<script>
import ChatBotWrapper from '@pages/Dashboard/ChatBot/ChatBotWrapper'

export default {
  name: 'ChatBotPage',
  components: { ChatBotWrapper },
  props: {
    section: {
      type: String,
      required: true,
    },
  },
  metaInfo() {
    const title = [
      this.$t(`dashboard.pages.chatBot.${this.section}`),
      this.$t('dashboard.pages.chatBot.chatBot'),
    ]

    return {
      title: title.join(' - '),
    }
  },
  computed: {
    component() {
      const components = {
        accounts: 'chat-bot-accounts',
        commands: 'chat-bot-commands',
        games: 'chat-bot-games',
        kappagen: 'chat-bot-kappagen',
        moderate: 'chat-bot-moderate',
        multichat: 'chat-bot-multichat',
        polls: 'chat-bot-polls',
        ticker: 'chat-bot-ticker',
        timers: 'chat-bot-timers',
      }

      return components[this.section]
    },

    pageClasses() {
      return {
        [`page-${this.section}`]: true,
      }
    },
  },
}
</script>
