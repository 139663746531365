<style lang="scss" scoped>
  .gradient-settings-popover {
    border-radius: 3px;
    box-shadow: 0 2px 19px rgba(0, 0, 0, .35);
    background-color: $additional-3;
  }
</style>

<template>
  <div class="gradient-settings-popover">
    <gradient-picker v-bind="$attrs" v-on="$listeners"/>
  </div>
</template>

<script>

export default {
  name: 'GradientSettingsPopover',
}
</script>
