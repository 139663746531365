import axios from 'axios'
import frontConfig from '@services/frontConfig'

import { useCssVar } from '@vueuse/core'

const STORAGE_APP_FONTS = 'da.app.fonts'

export const state = {
  // Application
  appWidth: window.innerWidth,
  appHeight: window.innerHeight,
  scrollTop: window.pageYOffset || document.documentElement.scrollTop,

  // DateTime
  timestamp: dates.timestamp(),

  // Fonts
  fonts: storage.getItem(STORAGE_APP_FONTS) || {},

  isAstrumApp: false,
}

export const getters = {
  // DateTime
  parsedDate: state => dates.parseTs(state.timestamp),

  // Fonts
  getFonts: state => Object.values(state.fonts),

  // App size
  getAppSize: (state) => {
    const sizes = [
      'sm',
      'md',
      'lg',
    ]

    const containers = sizes.reduce((acc, size) => {
      const sizeWidth = useCssVar(`--grid-${size}-breakpoint`).value ?? '0'

      acc[size] = sizeWidth.replace(/[^\d.-]/g, '')

      return acc
    }, {})

    let appSize = 'lg'

    sizes.reverse().forEach(size => {
      if (state.appWidth < containers[size]) {
        appSize = size
      }
    })

    return appSize
  },
  appIsSM: (state, getters) => getters.getAppSize === 'sm',
  appIsMD: (state, getters) => getters.getAppSize === 'md',
  appIsLG: (state, getters) => getters.getAppSize === 'lg',
}

export const mutations = {
  SET_APP_DIMENSIONS(state) {
    state.appWidth = window.innerWidth
    state.appHeight = window.innerHeight
  },

  SET_SCROLL_TOP(state) {
    state.scrollTop = window.pageYOffset || document.documentElement.scrollTop
  },

  SET_TIME(state) {
    state.timestamp = dates.timestamp()
  },

  SET_FONTS(state, fonts) {
    state.fonts = _.keyBy(fonts, 'family')
    storage.setItem(STORAGE_APP_FONTS, state.fonts)
  },

  SET_IS_ASTRUM_APP(state, value){
    state.isAstrumApp = value
  }
}

export const actions = {
  init({ state, commit, dispatch }) {
    setInterval(() => {
      commit('SET_TIME')
    }, 1000)

    const dimensionsUpdate = () => {
      commit('SET_APP_DIMENSIONS')
    }

    const scrollUpdate = () => {
      commit('SET_SCROLL_TOP')
    }

    window.addEventListener('resize', dimensionsUpdate)
    window.addEventListener('scroll', scrollUpdate)

    dispatch('fetchFonts')
  },

  async fetchFonts({ commit }) {
    return frontConfig.onReady().then(() => {
      return axios.get(`${frontConfig.getFontsUrl()}?t=${dates.timestamp()}`)
        .then(({ data }) => {
          const fonts = data.data

          commit('SET_FONTS', fonts)

          const urlFonts = []

          fonts.forEach(font => {
            urlFonts.push(`${font.family.replace(/ /g, '+')}:400,700`)
          })

          const cssUrl = `https://fonts.googleapis.com/css?family=${urlFonts.join('|')}&display=swap&subset=cyrillic`

          const link = document.createElement('link')
          link.href = cssUrl
          link.type = 'text/css'
          link.rel = 'stylesheet'
          link.media = 'screen,print'

          document.head.appendChild(link)

          return fonts
        })
    })
  },
}
