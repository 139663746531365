import axios from 'axios'

class FrontConfig {
  constructor() {
    this.onConfigReady = new Promise((resolve, reject) => {
      this.resolveConfig = resolve

      console.log('[Config] Config resolved')
    })

    this.fetchConfig()
  }

  async fetchConfig() {
    const response = await axios.get('/api/v1/env/front')

    const {
      app_cdn,
      centrifugo,
      donationalerts,
      fonts_url,
      sentry,
      counters,
      services,
    } = response.data.data

    this.CDN = app_cdn
    this.centrifugoConfig = {
      endpoint: centrifugo.endpoint,
      subscribeEndpoint: centrifugo.subscribe_endpoint,
    }
    this.DA = donationalerts
    this.fontsUrl = fonts_url
    this.sentryConfig = sentry
    this.services = services

    // LEGACY --->
    window.api_config = {
      twitch: {
        client_id: services.twitch.client_id,
      },
    }
    window.APP_URL = donationalerts.app_url
    window.STATIC_FILES_PATH = app_cdn
    // <--- LEGACY

    window._tmr_da_tracker_id = _.get(counters, 'tmr.donationalerts')
    window._tmr_astrum_tracker_id = _.get(counters, 'tmr.advertda')
    window._tmr_global_tracker_id = _.get(counters, 'tmr.global')

    this.resolveConfig()

    return this.onConfigReady
  }

  getFontsUrl() {
    return this.fontsUrl
  }

  getCDN() {
    return this.CDN
  }

  getCentrifugoConfig() {
    return this.centrifugoConfig
  }

  getSentryDsn(app) {
    return _.get(this.sentryConfig, `dsn.${app}`)
  }

  getDASetting(key) {
    return this.DA[key]
  }

  getAppUrl() {
    return this.DA.app_url
  }

  getChatBotConfig() {
    return this.DA.chatbot
  }

  onReady() {
    return this.onConfigReady
  }
}

export default new FrontConfig()
