<style scoped lang="scss">
  .button-balance {
    color: $bright;
  }
</style>

<template>
  <HeaderDropdown v-if="balanceAmount !== null">
    <template #btn>
      <div class="button-text">{{ $t('dashboard.user.donations') }}&nbsp;</div>
      <div class="button-balance">{{ balance.amountWithCurrency }}</div>
    </template>

    <template #content>
      <user-balances :balance="balance" :balances="balances"/>
    </template>
  </HeaderDropdown>
</template>

<script>
import HeaderDropdown from '@components/BaseComponents/HeaderDropdown.vue'
import UserBalances from '@components/Dashboard/NewDashboard/UserBalances'

export default {
  name: 'HeaderBalanceSection',
  components: { UserBalances, HeaderDropdown },
  props: {
    legacy: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      balancePopoverOpened: false,
    }
  },
  computed: {
    ...mapGetters('profile', ['getUserBalance', 'getUserBalances']),
    balances: (vm) => vm.getUserBalances('balances'),
    balance: (vm) => vm.getUserBalance('balance_in_main_currency'),
    balanceAmount: (vm) => vm.balance?.amount
  },
}
</script>
