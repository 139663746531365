<style lang="scss" scoped>
  .donations-settings-page {
    .tabs-content {
      margin-top: 25px;
    }
  }
</style>

<template>
  <div class="donations-settings-page">
    <tabs v-model="currentTab" :list="tabs"/>

    <div class="tabs-content">
      <transition name="fade-up">
        <div v-show="!loadingState.page" ref="legacy"/>
      </transition>
    </div>
  </div>
</template>

<script>

import { loadLegacyPage } from '@services/legacyLoader'
import $ from 'jquery'

export default {
  name: 'DonationsSettingsPage',
  props: {
    section: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loadingState: {
        page: false,
      },
    }
  },
  metaInfo() {
    const title = [
      this.$t(`dashboard.pages.donationsSettings.${this.section}`),
      this.$t('dashboard.pages.donationsSettings.donationsSettings'),
    ]

    return {
      title: title.join(' - '),
    }
  },
  computed: {
    currentTab: {
      get() {
        return this.section
      },
      set(value) {
        this.$router.push({
          name: 'dashboard-donations-settings-section',
          params: { section: value },
        })
      },
    },

    tabs() {
      return [
        {
          icon: 'audio',
          key: 'synthesis',
        },
        {
          icon: 'coins',
          key: 'amounts',
        },
        {
          icon: 'chat',
          key: 'messages',
        },
        {
          icon: 'filter',
          key: 'blacklist',
        },
        {
          icon: 'activity-list',
          key: 'moderation',
        },
      ].map(tab => ({
        ...tab,
        title: this.$t(`dashboard.pages.donationsSettings.${tab.key}`),
      }))
    },
  },
  methods: {
    loadContent() {
      this.loadingState.page = true

      if (this.currentTab === 'synthesis') {
        loadLegacyPage('/alert-variations/edit/1', this.$refs.legacy)
          .then(() => {
            $('.b-tabs__triggers').hide()
            $('.b-tabs__content').hide()
            $('#_1337block-speech-synthesis').removeClass('b-tabs__content').show()
            $('.b-alerts__group').css('background-color', 'transparent')
            $('.s-close-variation-form').hide()
          })
          .finally(() => {
            this.loadingState.page = false
          })
      }

      if (this.currentTab === 'amounts') {
        loadLegacyPage('/dashboard/old-page-donation-page', this.$refs.legacy)
          .then(() => {
            $('#_1337block-donation-page-settings').hide()
            $('#_1337block-audio-donations').hide()
            $('#_1337block-text-donations').hide()
            $('.b-btn[type=submit]').css('margin-top', '30px')
            $('.b-donation-page__sums-title').css('margin-top', '0')
          })
          .finally(() => {
            this.loadingState.page = false
          })
      }

      if (this.currentTab === 'messages') {
        loadLegacyPage('/dashboard/old-page-donation-page', this.$refs.legacy)
          .then(() => {
            $('#_1337block-donation-page-settings').hide()
            $('#_1337block-minimal-amounts').hide()
            $('#_1337block-text-donations .b-donation-page__design-title').css('margin-top', '0')
          })
          .finally(() => {
            this.loadingState.page = false
          })
      }

      if (this.currentTab === 'blacklist') {
        loadLegacyPage('/dashboard/old-page-general', this.$refs.legacy)
          .then(() => {
            $('.b-general-settings__block').hide()
            $('#_1337block-authorized-apps').hide()
            $('#_1337block-black-list').show()
          })
          .finally(() => {
            this.loadingState.page = false
          })
      }

      if (this.currentTab === 'moderation') {
        loadLegacyPage('/dashboard/old-page-lastdonations-widget', this.$refs.legacy)
          .then(() => {
            $('#_1337block-last-events').hide()
            $('#_1337block-moderation').css('margin-top', '0')
          })
          .finally(() => {
            this.loadingState.page = false
          })
      }
    },
  },
  mounted() {
    this.loadContent()
  },
  watch: {
    currentTab: 'loadContent',
  },
}
</script>
