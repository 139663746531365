import currency from 'currency.js'

const formatCurrency = amount => currency(Math.abs(amount), {
  separator: ' ',
  decimal: ',',
}).format()

export const state = {
  games: {},
  actions: {},
}

export const getters = {
  getGamesList: state => Object.values(state.games),
  getActionsList: state => Object.values(state.actions),
  getAction: state => id => state.actions[id],
}

export const mutations = {
  SET_GAMES(state, games) {
    state.games = _.keyBy(games, 'id')
  },

  SET_ACTIONS(state, actions) {
    state.actions = _.keyBy(actions, 'id')
  },
}

export const actions = {
  fetchActionsHistory({ commit, rootGetters }, {
    page = 1,
    perPage = 10,
    filter = {
      gameId: null,
      actionId: null,
      period: 'allTime',
    },
    sort = null,
  } = {}) {
    const currentTS = Number(dates.timestamp()).toFixed(0)

    const dateFrom = {
      allTime: 0,
      day: currentTS - (24 * 60 * 60),
      week: currentTS - (7 * 24 * 60 * 60),
      month: currentTS - (31 * 24 * 60 * 60),
    }[filter.period]

    return api.get('/bft/auth/donations', {
      params: {
        page: page,
        per_page: perPage,
        ext_game_id: filter.gameId,
        ext_action_id: filter.actionId,
        date_to_ts: dateFrom ? currentTS : undefined,
        date_from_ts: dateFrom ? dateFrom : undefined,
        sort,
      },
    })
      .then(({ data, meta }) => {
        const rows = []

        const timeZone = rootGetters['profile/getTimezone']

        data.forEach(row => {
          const { ext_game_id: gameId, title: gameTitle } = row.game

          rows.push({
            actions: row.actions.map(action => ({
              id: action.ext_action_id,
              title: action.title,
              count: action.count,
            })),
            game: { id: gameId, title: gameTitle },
            date: dates.unixToFormatTZ(row.created_at_ts, dates.DEFAULT_TIME_FORMAT, timeZone),
            amount: {
              alert: {
                gross: formatCurrency(row.amount.alert.gross),
                commission: formatCurrency(row.amount.alert.commission),
              },
              bft: {
                gross: formatCurrency(row.amount.bft.gross),
                commission: formatCurrency(row.amount.bft.commission),
              },
              total: {
                gross: formatCurrency(row.amount.total.gross),
                commission: formatCurrency(row.amount.total.commission),
              },
            },
          })
        })

        commit('SET_GAMES', meta.filters.games.map(game => ({
          id: game.ext_game_id,
          title: game.title,
        })))

        commit('SET_ACTIONS', meta.filters.actions.map(action => ({
          id: action.ext_action_id,
          gameId: action.ext_game_id,
          title: action.title,
        })))

        return {
          rows,
          pagination: {
            page: meta.current_page,
            pagesCount: meta.last_page,
            perPage: meta.per_page,
            total: meta.total,
          },
        }
      })
  },
}
