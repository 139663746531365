<style scoped lang="scss">
  .goal-background-settings {
  }
</style>

<template>
  <div class="goal-background-settings">
    <form-group inline :label="$t('global.settings.labels.backgroundColor')">
      <color-gradient-picker v-model="backgroundColor"/>
    </form-group>
    <form-group inline :label="$t('roulette.goal.visualSettings.background.backgroundShadow')">
      <shadow-styler v-model="backgroundShadow"/>
    </form-group>
    <form-group inline :label="$t('global.settings.labels.borderRadius')">
      <base-range :min="0" :max="100" v-model="borderRadius" input input-addon="%"/>
    </form-group>
    <form-group inline :label="$t('global.settings.labels.borderWidth')">
      <base-range :min="0" :max="20" :step=".1" v-model="borderWidth" input input-addon="px"/>
    </form-group>
    <template v-if="borderWidth">
      <form-group inline :label="$t('global.settings.labels.borderColor')">
        <color-gradient-picker v-model="borderColor"/>
      </form-group>
      <form-group inline :label="$t('roulette.goal.visualSettings.background.borderShadow')">
        <shadow-styler v-model="borderShadow"/>
      </form-group>
    </template>
  </div>
</template>

<script>

import ColorGradientPicker from '@components/BaseComponents/ColorPicker/ColorGradientPicker'
import ShadowStyler from '@components/BaseComponents/ShadowStyler/ShadowStyler'

export default {
  name: 'GoalBackgroundSettings',
  components: {
    ShadowStyler,
    ColorGradientPicker,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      backgroundColor: {},
      borderWidth: 0,
      borderRadius: 0,
      borderColor: {},
      backgroundShadow: null,
      borderShadow: null,
    }
  },
  computed: {
    output() {
      return {
        backgroundColor: this.backgroundColor,
        borderWidth: this.borderWidth,
        borderRadius: this.borderRadius,
        borderColor: this.borderColor,
        backgroundShadow: this.backgroundShadow,
        borderShadow: this.borderShadow,
      }
    },
  },
  created() {
    if (this.value) {
      Object.keys(this.value).forEach(key => {
        this[key] = this.value[key] ?? this[key]
      })
    }
  },
  watch: {
    output(value) {
      this.$emit('input', value)
    },
  },
}
</script>
