<script setup>
import { computed, onMounted, ref, getCurrentInstance } from 'vue'

import BaseButton from '@components/BaseComponents/BaseButton.vue'
import BaseCheckbox from '@components/BaseComponents/BaseCheckbox.vue'
import EdditableInput from '@components/BaseComponents/Form/EdditableInput'

import { useOffersStore } from '@store/offers'
import { useGlobalStore } from '@store/global'

import { maskPhone } from '@utils/mask'
import { isNumberBtn } from '@utils/utils'

import { BUSINESS_TYPE_INDIVIDUAL, BUSINESS_TYPE_INDIVIDUAL_ENTREPRENEUR, LAST_NAME, PHONE_NUMBER, FIRST_NAME } from '@constants/user'
import { RU_REGION_ID } from '@constants/regions'

import usePromise from '@composables/use-promise'

const offers = useOffersStore()
const { advConfirm } = offers

const global = useGlobalStore()
const { fetchCountries } = global

const { exec, parsedErrors, pending: confirming } = usePromise(advConfirm)

const { $store } = getCurrentInstance().proxy

const agreeChecked = ref(false)
const countries = ref([])
const regionId = ref(null)
const businessType = ref(null)
const tin = ref(null)
const personalData = ref({
  last_name: null,
  first_name: null,
  middle_name: null,
  phone_number: null,
})

const countriesOptions = computed(() => countries.value.map(option => ({
  value: option.region_id,
  label: option.name,
})))

const userCountry = computed(() => $store.getters['profile/getCurrentProfile'].suggested_country_id || null)
const advertDa = computed(() => $store.state.application.isAstrumApp)
const adverDaIncorrectData = computed(() => advertDa.value && (!filledPersonalData.value || !businessType.value || notValidTin.value))
const agreeDisabled = computed(() => !regionId.value || !agreeChecked.value || adverDaIncorrectData.value)
const maskedPhone = computed(() => maskPhone(personalData.value.phone_number))
const isIndividualEntBusinessType = computed(() => businessType.value === BUSINESS_TYPE_INDIVIDUAL_ENTREPRENEUR)
const tinDescription = computed(() => `${tin.value ? String(tin.value)?.length : 0}/12`)
const notValidTin = computed(() => businessType.value === BUSINESS_TYPE_INDIVIDUAL_ENTREPRENEUR && (!tin.value || String(tin.value)?.length !== 12))

const resetTin = () => {
  tin.value = null
}

const resetBusinessType = () => {
  resetTin()
  businessType.value= null
}

const filledPersonalData = computed(() => {
  let filledData = true

  const keys = [ LAST_NAME, PHONE_NUMBER, FIRST_NAME ]

  keys.forEach((key) => {
    if(!personalData.value[key]) filledData = false
  })

  return filledData
})

const businessTypeOptions = computed(() => (
  [
    { label: BUSINESS_TYPE_INDIVIDUAL, value: BUSINESS_TYPE_INDIVIDUAL },
    { label: BUSINESS_TYPE_INDIVIDUAL_ENTREPRENEUR, value: BUSINESS_TYPE_INDIVIDUAL_ENTREPRENEUR },
  ]
))

const businessTypesOptionsFiltered = computed(() => regionId.value === RU_REGION_ID 
      ? businessTypeOptions.value
      : businessTypeOptions.value.filter(item => item.value === BUSINESS_TYPE_INDIVIDUAL))

const prepareAdvPayload = () => {
  const payload = {
    country_id: regionId.value,
    ...personalData.value
  }

  if(advertDa.value) {
    payload.business_entity_type = businessType.value
    payload.tin = tin.value
  }

  return payload
}

const confirmAdv = () => exec(prepareAdvPayload())

onMounted(async () => {
  countries.value = await fetchCountries()

  regionId.value = userCountry.value
})
</script>

<template>
  <div>
    <form-group
      :label="$t('offers.settings.country')"
      inline
      style="margin-bottom: 15px;"
    >
      <dropdown-select
        class="country-select"
        v-model="regionId"
        :options="countriesOptions"
        @input="resetBusinessType"
      />
    </form-group>

    <form-group
      v-if="advertDa"
      :label="$t('offers.settings.businessType')"
      inline
      style="margin-bottom: 15px;"
    >
      <!-- TODO: Убрать map функцию когда $t будет доступно в setup -->
      <dropdown-select
        class="country-select"
        v-model="businessType"
        :options="businessTypesOptionsFiltered.map(({ value, label }) => ({ value, label: $t(`offers.settings.businessTypes.${label}`) }))"
        @input="resetTin"
      />
    </form-group>

    <form-group
      v-if="isIndividualEntBusinessType"
      :description="tinDescription"
      :label="$t('offers.settings.tin')"
      :invalid-feedback="parsedErrors.tin"
      inline
      style="margin-bottom: 15px;"
    >
      <base-input
        class="tin-input"
        v-model="tin"
        maxlength="12"
        @keypress="isNumberBtn($event)"
      />
    </form-group>

    <Title v-if="advertDa">
      <span>{{ $t('offers.settings.personalData') }}</span>
    </Title>

    <div v-if="advertDa" class="personal-data">
      <form-group
        :label="$t('offers.settings.lastName')"
        :invalid-feedback="parsedErrors.last_name"
        inline
        style="margin-bottom: 15px;"
      >
        <edditable-input v-model="personalData.last_name" maskedVal="***" immediately-change />
      </form-group>

      <form-group
        :label="$t('offers.settings.firstName')"
        :invalid-feedback="parsedErrors.first_name"
        inline
        style="margin-bottom: 15px;"
      >
        <edditable-input v-model="personalData.first_name" maskedVal="***" immediately-change />
      </form-group>

      <form-group
        :label="$t('offers.settings.middleName')"
        :invalid-feedback="parsedErrors.middle_name"
        inline
        style="margin-bottom: 15px;"
      >
        <edditable-input v-model="personalData.middle_name" maskedVal="***" immediately-change />
      </form-group>

      <form-group
        :label="$t('offers.settings.telephone')"
        :invalid-feedback="parsedErrors.phone_number"
        inline
        style="margin-bottom: 15px;"
      >
        <edditable-input v-model="personalData.phone_number" :maskedVal="maskedPhone" immediately-change />
      </form-group>
    </div>

    <p>
      <span class="styled-label">{{ $t('offers.confirmed.label') }}
        <a href='/terms-of-service/advertising' target="_blank">{{ $t('offers.confirmed.link') }}</a>
      </span>

      <BaseCheckbox v-model="agreeChecked" class="styled-checkbox" />
    </p>

    <BaseButton
        variant="primary-action"
        @click="confirmAdv"
        class="da-button"
        :disabled="agreeDisabled"
        :is-loading="confirming"
    >
      {{ $t('offers.confirmed.button') }}
    </BaseButton>
  </div>
</template>

<style scoped lang="scss">
.country-select {
  max-width: 340px;
  margin-bottom: 20px;
}

.tin-input {
  max-width: 340px;
}

.da-button {
  border-radius: 20px;
  margin-top: 20px;
}

.styled-label {
  margin-right: 10px;
}

.styled-checkbox {
  display: inline-block;
  vertical-align: middle;
}

.personal-data {
  width: 550px;
}
</style>
