import { ref, computed, watch } from 'vue'
import { defineStore } from 'pinia'
import axios from 'axios'
import frontConfig from '@services/frontConfig'
import { i18n, languages, setLocale } from '@src/localization/config'
import { useCssVar, useWindowSize, useWindowScroll, useStorage } from '@vueuse/core'

const appSizes = ['sm', 'md', 'lg', 'xl'].reduce((acc, size) => {
  acc[size] = useCssVar(`--grid-${size}-breakpoint`)
  return acc
}, {})

const locales = Object.keys(languages)
  .reduce((acc, key) => ({
    ...acc, [key]: {
      code: key,
      name: languages[key],
    },
  }), {})

export const useApplicationStore = defineStore('application', () => {
  const { width: windowWidth, height: windowHeight } = useWindowSize()
  const { x: windowScrollX, y: windowScrollY } = useWindowScroll()

  const appGrid = computed(() => {
    return Object.keys(appSizes).reduce((acc, size) => {
      const sizeWidth = appSizes[size].value

      if (sizeWidth) {
        acc[size] = Number(sizeWidth.replace(/[^\d.-]/g, ''))
      }

      return acc
    }, {})
  })
  const appSize = computed(() => {
    return Object.keys(appGrid.value)
      .reverse()
      .reduce((acc, size) => windowWidth.value < appGrid.value[size] ? size : acc, 'xl')
  })
  const appSizeIs = size => size === appSize.value

  const fonts = useStorage('da2.app.fonts', {})
  const fontsList = computed(() => Object.values(fonts.value))

  frontConfig.onReady().then(() => {
    return axios.get(`${frontConfig.getFontsUrl()}?t=${dates.timestamp()}`)
      .then(({ data }) => {
        const fonts = data.data

        fonts.value = _.keyBy(fonts)

        const urlFonts = fonts.map(font => `${font.family.replace(/ /g, '+')}:400,700`)

        const link = document.createElement('link')
        link.href = `https://fonts.googleapis.com/css?family=${urlFonts.join('|')}&display=swap&subset=cyrillic`
        link.type = 'text/css'
        link.rel = 'stylesheet'
        link.media = 'screen,print'

        document.head.appendChild(link)

        return fonts
      })
  })

  const appLocales = computed(() => Object.values(locales))
  const appLocale = ref(window.document_language || i18n.locale)

  watch(appLocale, setLocale, { immediate: true })

  return {
    windowWidth,
    windowHeight,
    windowScrollX,
    windowScrollY,

    appSize,
    appSizeIs,

    fontsList,

    appLocale,
    appLocales,
  }
})
