export const OFFER_WORK_STATUS = 'work'
export const OFFER_BAN_STATUS = 'ban'
export const OFFER_NOT_APPROVED_STATUS = 'not_approved'
export const OFFER_ON_APPROVAL_STATUS = 'on_approval'
export const OFFER_PROCESSING_STATUS = 'processing'
export const OFFER_OK_STATUS = 'ok'
export const OFFER_FAIL_STATUS = 'fail'

export const OFFER_ACTIVATION_TYPE = 'activation'
export const OFFER_ACTION_TYPE = 'action'
export const OFFER_NOTE_TYPE = 'note'
export const OFFER_SALE_TYPE = 'sale'
export const OFFER_FIX_TYPE = 'fix'

export const OFFER_DESC_TAB = 'desc'
export const OFFER_STATS_TAB = 'stats'
export const OFFER_LINKS_TAB = 'links'
export const OFFER_PLATFORMS_TAB = 'platforms'
