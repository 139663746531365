<style scoped lang="scss">
  .indicator-settings-form {
    .filler-style-form {
      margin-top: 20px;
    }
  }
</style>

<template>
  <div class="indicator-settings-form">
    <progress-bar-style-form v-model="progressBarStyles"/>
    <filler-style-form v-model="fillerStyles"/>
  </div>
</template>

<script>
import FillerStyleForm from '@components/Dashboard/GoalSettings/DesignSettings/FillerStyleForm'
import ProgressBarStyleForm from '@components/Dashboard/GoalSettings/DesignSettings/ProgressBarStyleForm'

export default {
  name: 'IndicatorSettingsForm',
  components: { ProgressBarStyleForm, FillerStyleForm },
  computed: {
    ...mapState('goal/presets', [
      'widgetStyles',
    ]),

    ...[
      'filler',
      'progressBar',
    ].reduce((acc, block) => {
      acc[`${block}Styles`] = {
        get() {
          return _.cloneDeep(this.widgetStyles[block])
        },
        set(style) {
          this.setBlockStyle({
            block,
            style,
          })
        },
      }
      return acc
    }, {}),
  },
  methods: {
    ...mapMutations('goal/presets', {
      'setBlockStyle': 'SET_BLOCK_STYLE',
    }),
  },
}
</script>
