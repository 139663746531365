export const state = {
  isLoading: false,
  saveLoading: false,
  settings: null,
  advConfirmed: true,
  advConfirmedIsLoading: false,
}

export const getters = {
  isLoading: ({ isLoading }) => isLoading,
  saveLoading: ({ saveLoading }) => saveLoading,
  settings: ({ settings }) => settings,
  advConfirmed: ({ advConfirmed }) => advConfirmed,
  advConfirmedIsLoading: ({ advConfirmedIsLoading }) => advConfirmedIsLoading,
}

export const mutations = {
  startLoading(state) {
    state.isLoading = true
  },

  endLoading(state) {
    state.isLoading = false
  },

  startSaveLoading(state) {
    state.saveLoading = true
  },

  endSaveLoading(state) {
    state.saveLoading = false
  },

  setSettings(state, { data }) {
    state.settings = data
  },

  refuteAdvConfirmed(state) {
    state.advConfirmed = false
  },

  startAdvConfirmedLoading(state) {
    state.advConfirmedIsLoading = true
  },

  endAdvConfirmedLoading(state) {
    state.advConfirmedIsLoading = false
  },
}

export const actions = {
  async fetchSettings({ commit }) {
    commit('startLoading')

    try {
      const res = await api.get(`/advusersettings`)

      if (res.data) {
        commit('setSettings', { data: res.data })
      } else {
        throw new Error()
      }

      commit('endLoading')
    } catch (err) {
      if (err.code) {
        switch(err.code) {
          case 1509:
            commit('refuteAdvConfirmed')
            break
        }
      }

      commit('endLoading')
    }
  },

  async saveSettings({ commit }, { secretCode, price, currency, country_id, last_name, first_name, middle_name, phone_number, business_entity_type, tin }) {
    commit('startSaveLoading')

    try {
      const { data } = await api.put(`/advusersettings`, {
        igromarket_code: secretCode,
        adv_note_price: price,
        adv_note_price_currency: currency,
        country_id,
        last_name,
        first_name,
        middle_name,
        phone_number,
        business_entity_type,
        tin
      })

      commit('setSettings', { data })
      commit('endSaveLoading')
      return {}
    } catch (error) {
      if([1100, 1101].includes(error.code)) {
        commit('endSaveLoading')
        return error
      }

      commit('endSaveLoading')
      window.addStatusMessage('error', error.message)
    }
  },

  async advConfirm({ commit }) {
    commit('startAdvConfirmedLoading')

    try {
      await api.post(`/accept-adv-agreement`)

      commit('endAdvConfirmedLoading')

      window.location.reload()
    } catch (error) {
      commit('endAdvConfirmedLoading')
    }
  },
}