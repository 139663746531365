import store from '@state/store'


const DA_LOCALE_STRINGS = 'da.locale.strings'

const defaultLocale = 'en_US'

export const state = {
  locale: defaultLocale,
  strings: storage.getItem(DA_LOCALE_STRINGS) || {
    [defaultLocale]: {},
  },
}

export const getters = {
  getString: state => key => {
    return state.locale === defaultLocale ? key : (state.strings[state.locale][key] || key)
  },
}

export const mutations = {
  UPDATE_LOCALE(state, locale) {
    state.locale = locale

    if (!state.strings[locale]) {
      state.strings = {
        ...state.strings,
        [locale]: {},
      }
    }
  },

  UPDATE_STRINGS(state, strings) {
    state.strings[state.locale] = {
      ...state.strings[state.locale],
      ...strings,
    }

    storage.setItem(DA_LOCALE_STRINGS, state.strings)
  },
}

let stringsToFetch = {}

const _fetchStrings = _.debounce(() => {
  const strings = Object.keys(stringsToFetch).reduce((acc, key) => {
    acc.push(`string[]=${encodeURI(key)}`)
    return acc
  }, []).join('&')
  stringsToFetch = {}

  return api.post('/localization', strings, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
    .then(({ data: strings, last_modified: lastModified }) => {
      // console.log(strings)
      store.commit('localization/UPDATE_STRINGS', strings)
    })
}, 70)

export const actions = {
  init({ commit }) {
    if (window.document_language) {
      commit('UPDATE_LOCALE', window.document_language)
    }
  },

  fetchStrings({ state, commit }, keys) {
    if (!keys.length || state.locale === defaultLocale) {
      return
    }

    const filtered = keys.filter(key => !state.strings[state.locale][key])

    if (filtered.length) {
      filtered.forEach(key => {
        stringsToFetch[key] = true
      })

      _fetchStrings()
    }
  },
}
