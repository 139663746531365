<template>
  <div class="radio">
    <input type="radio" :id="option" :value="option" v-model="model">
    <label :for="option"><slot></slot></label>
  </div>
</template>


<script>
export default {

  name: 'Radio',

  props: {
    value: {
      type: String,
      default: '',
    },
    option: {
      type: String,
      default: '',
    },
  },

  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    },
  },

}
</script>


<style scoped lang="scss">
.radio {
  label {
    display: block;
    color: #fff;
    background: #303030;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    padding: 4px 10px;
    border-radius: 15px;
    margin: 0 5px;
    border: 1px solid transparent;
    cursor: pointer;
    user-select: none;
  }

  input {
    display: none;

    &:checked + label {
      border-color: var(--theme-color-main);
    }
  }

  &:hover {
    label {
      border-color: #838383;
    }
  }
}
</style>
