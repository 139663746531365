<style scoped lang="scss">
  .goal-preview {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;

    .preview-background {
      @include position-all(absolute, 0);
      z-index: 1;
      background-image: appAsset('/images/goal-preview-bg.jpg');
      background-size: cover;
      background-position: center center;

      @include media(max, md) {
        border-radius: 3px;
      }
    }

    .preview-widget-wrapper {
      position: absolute;
      z-index: 2;
      transform-origin: left top;
      top: 50%;
      left: 50%;
    }

    .preview-title {
      @include position(absolute, 20px, auto, auto, 20px);
      z-index: 2;
      font-size: 16px;
      font-weight: 500;
    }

    .preset-id {
      @include position(absolute, 5px, auto, auto, 10px);
      z-index: 2;
      font-size: 13px;
      font-weight: 500;
      color: rgba($bright, .3);
    }

    .preview-controls {
      @include position(absolute, auto, 0, 14px, 0);
      z-index: 2;
      display: flex;
      justify-content: center;
      text-transform: uppercase;

      .background-toggle {
        border-radius: 16px;
        background-color: $additional-3;
        padding: 6px 6px 6px 10px;
        display: flex;
        align-items: center;
        font-size: 13px;
        font-weight: 600;

        .base-switch {
          margin-left: 10px;
        }
      }
    }
  }
</style>

<template>
  <div class="goal-preview">
    <transition name="fade">
      <div v-if="backgroundVisible" class="preview-background"></div>
    </transition>
    <div v-if="current" class="preview-title">{{$t('goals.goalPreview.widgetPreview')}}</div>
    <div v-if="preset && !current" class="preset-id">
      <template v-if="presetId === 'user'">{{$t('goals.goalPreview.yourSettings')}}</template>
      <template v-else>#{{presetId}}</template>
    </div>
    <div
      v-if="preset && componentMounted"
      class="preview-widget-wrapper"
      :style="previewWrapperStyles">
      <widget-goal
        :goal-data="goalData"
        :config="widgetConfig"/>
    </div>
    <div v-if="current" class="preview-controls">
      <div class="background-toggle">
        {{$t('goals.goalPreview.gamingBackground')}}
        <base-switch v-model="backgroundVisible" small/>
      </div>
    </div>
  </div>
</template>

<script>
import WidgetGoal from '@components/Widgets/WidgetGoal/WidgetGoal'

export default {
  name: 'GoalPreview',
  components: {
    WidgetGoal,
  },
  props: {
    presetId: {
      type: [Number, String],
      default: null,
    },
    current: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      backgroundVisible: false,
    }
  },
  computed: {
    ...mapGetters('goal/presets', ['getPreset', 'getCurrentStyles', 'getCurrentSettings']),
    ...mapGetters('goal/settings', ['getPreviewGoal']),

    goalData() {
      return this.getPreviewGoal
    },

    preset() {
      return this.getPreset(this.presetId)
    },

    widgetConfig() {
      if (!this.current) {
        return {
          ...this.preset,
          isPreview: true,
        }
      }

      return {
        ...this.preset,
        settings: this.getCurrentSettings,
        styles: this.getCurrentStyles,
      }
    },

    previewScale() {
      if (!this.preset) {
        return 1
      }

      const positionInfo = this.$el.getBoundingClientRect()

      return (positionInfo.width / this.$appWidth) * 1.2
    },

    previewWrapperStyles() {
      return {
        transform: [
          `scale(${this.previewScale})`,
          'translate(-50%, -50%)',
        ].join(' '),
      }
    },
  },
  created() {
    this.backgroundVisible = this.current
  },
}
</script>
