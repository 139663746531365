<style lang="scss" scoped>
  .shadow-settings-form {
    .form-group + .form-group {
      margin-top: 10px;
    }
  }
</style>

<template>
  <div class="shadow-settings-form">
    <form-group inline :label="$t('global.textStyler.labels.shadowColor')">
      <color-picker v-model="shadowColor"/>
    </form-group>
    <form-group inline :label="$t('global.textStyler.labels.horizontalOffset')">
      <base-range
        v-model="shadowOffsetX"
        :min="minMaxes.shadowOffsetX[0]"
        :max="minMaxes.shadowOffsetX[1]"
        :step="step"
        input
        :input-addon="units"/>
    </form-group>
    <form-group inline :label="$t('global.textStyler.labels.verticalOffset')">
      <base-range
        v-model="shadowOffsetY"
        :min="minMaxes.shadowOffsetY[0]"
        :max="minMaxes.shadowOffsetY[1]"
        :step="step"
        input
        :input-addon="units"/>
    </form-group>
    <form-group inline :label="$t('global.textStyler.labels.shadowBlur')">
      <base-range
        v-model="shadowBlurRadius"
        :max="minMaxes.shadowBlurRadius[1]"
        :step="step"
        input
        :input-addon="units"/>
    </form-group>
    <form-group>
      <info-box class="small" :show-icon="false">
        {{ $t('global.shadowStyler.shadowHelper') }}
      </info-box>
    </form-group>
  </div>
</template>

<script>

export default {
  name: 'ShadowSettingsForm',
  props: {
    value: {
      type: String,
      default: null,
    },
    units: {
      type: String,
      default: 'px',
    },
  },
  data() {
    return {
      shadowColor: '#000000',
      shadowOffsetX: 0,
      shadowOffsetY: 0,
      shadowBlurRadius: 0,
    }
  },
  computed: {
    step() {
      return {
        px: 1,
        vw: .1,
      }[this.units]
    },

    minMaxes() {
      return {
        px: {
          shadowOffsetX: [-15, 15],
          shadowOffsetY: [-15, 15],
          shadowBlurRadius: [0, 15],
        },
        vw: {
          shadowOffsetX: [-1.5, 1.5],
          shadowOffsetY: [-1.5, 1.5],
          shadowBlurRadius: [0, 1.5],
        },
      }[this.units]
    },

    output() {
      if (this.shadowOffsetX === 0 && this.shadowOffsetY === 0 && this.shadowBlurRadius === 0) {
        return null
      }

      return [
        `${this.shadowOffsetX}${this.units}`,
        `${this.shadowOffsetY}${this.units}`,
        `${this.shadowBlurRadius}${this.units}`,
        this.shadowColor,
      ].join(' ')
    },
  },
  created() {
    if (this.value && this.value.length) {
      const shadow = this.value.replace(/([^,])\s/g, '$1%%').split('%%')

      const colorIndex = shadow.findIndex(p => p.includes('rgb') || p.includes('#'))
      const color = shadow.splice(colorIndex, 1)[0]

      const [offsetX, offsetY, blurRadius] = shadow.map(p => Number(p.replace(/[^\d.-]/g, '')))

      this.shadowColor = color
      this.shadowOffsetX = offsetX
      this.shadowOffsetY = offsetY
      this.shadowBlurRadius = blurRadius
    }
  },
  watch: {
    output: {
      handler(value) {
        this.$emit('input', value)
      },
    },
  },
}
</script>
