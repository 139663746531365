<style lang="scss" scoped>

</style>

<template>
  <svg class="svg-image-external" v-html="svgXml"/>
</template>

<script>
import axios from 'axios'

const cachedResources = {}

export default {
  name: 'SvgImageExternal',
  props: {
    src: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      svgXml: null,
    }
  },
  methods: {
    getResource() {
      if (this.src) {
        if (!cachedResources[this.src]) {
          cachedResources[this.src] = axios.get(this.src)
        }

        cachedResources[this.src]
          .then(({ data }) => {
            this.svgXml = data
          })
      }
    },
  },
  watch: {
    src: {
      handler: 'getResource',
      immediate: true,
    },
  },
}
</script>
