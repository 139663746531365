<template>
  <div class="card__wrapper">
    <div class="card__pins">
      <pin type="new" v-if="offer.status === 'available'" />
      <pin type="active" v-if="offer.status === 'active'" />
      <pin type="closed" v-if="offer.status === 'close'" />
    </div>

    <div class="card__image">
      <img
        v-if="offer.brand.image"
        :src="offer.brand.image"
      >

      <icon v-else name="image" class="no-image"/>
    </div>

    <div class="card__title">
      <span>{{ offer.title }}</span>
    </div>

    <div class="card__description">
      <ul>
        <li>{{$t('offers.advertisersAccount.brand')}}: <span class="card__description-val">{{ offer.brand.title }}</span></li>
        <li>{{$t('offers.advertisersAccount.dateOfEvent')}}: <span class="card__description-val">
          <span v-if="offer.start_at_ts && !offer.end_at_ts">с </span>
          <span v-if="!offer.start_at_ts && offer.end_at_ts">до </span>

          <span v-if="offer.start_at_ts">{{ transformDate(offer.start_at_ts) }}</span>
          <span v-if="offer.end_at_ts"> - </span>
          <span v-if="offer.end_at_ts">{{ transformDate(offer.end_at_ts) }}</span>
        </span></li>
        <!-- <li>Сумма сообщения: <span class="card__description-val card__description-val_big">от 100₽</span></li> -->
      </ul>
    </div>

    <div class="card__btn-group">
      <!-- <base-button variant="secondary-action" iconLeft="pencil">Редактировать</base-button> -->
      <base-button
        v-if="offer.status === 'active'"
        variant="secondary-action"
        iconLeft="user"
        @click="toggleUsersHandler"
      >{{$t('offers.advertisersAccount.users')}}</base-button>
      <!-- <base-button variant="secondary-action" iconLeft="cross" style="color: #D63A3A;">Завершить</base-button> -->
    </div>

  </div>
</template>

<script>
import fromUnixTime from 'date-fns/fromUnixTime'

import BaseButton from '@components/BaseComponents/BaseButton'
import Pin from './Pin'

export default {
  components: {
    BaseButton,
    Pin,
  },

  data() {
    return {}
  },

  props: {
    offer: {
      type: Object,
      default: () => {},
    }
  },

  methods: {
    toggleUsersHandler() {
      this.$emit('toggleModal', this.offer.id) ;
    },

    transformDate(val) {
      const timeZone = this.getTimezone

      const result = dates.unixToFormat(dates.utcToZonedTime(fromUnixTime(val), timeZone) / 1000, dates.DEFAULT_TIME_FORMAT)

      return result.split(' ')[0]
    },
  }
}
</script>

<style scoped lang="scss">
.card {
  &__wrapper {
    background: #262626;
    border-radius: 8px;
    padding: 10px;
    color: #F1F1F1;
    height: 100%;
    cursor: default;
    position: relative;
  }

  &__image {
    margin: 10px 10px 20px 10px;
    overflow: hidden;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      display: block;
      max-width: 100%;
      max-height: 100%;
    }
  }

  &__title {
    margin: 10px;
    font-size: 16px;
    font-weight: bold;

    span {
      display: block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  &__description {
    margin: 10px;
    font-size: 12px;

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
    }

    li {
      color: #838383;
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &-val {
      display: inline-block;
      color: #F1F1F1;

      &_big {
        display: block;
        width: 100%;
        color: var(--theme-color-main);
        font-size: 16px;
        font-weight: bold;
        // margin-top: 10px;
      }
    }
  }

  &__btn-group {
    margin: 20px 10px 10px 10px;

    button {
      width: 100%;
      border-radius: 8px;
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__pins {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0 10px;
    display: flex;
  }
}

.no-image {
  font-size: 60px;
  opacity: .1;
}
</style>
