<style scoped lang="scss">
  .widget-roulette-decor {
    display: flex;
    align-items: center;
    justify-content: center;

    .roulette-decor-place {
      height: 0;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      .roulette-decor-image {
        position: absolute;
        bottom: 0;
        transform: translateY(50%);
        max-width: 1200px;
        max-height: 400px;
      }
    }
  }
</style>

<template>
  <div v-if="decor" class="widget-roulette-decor">
    <div class="roulette-decor-place">
      <template v-if="decor.type === 'image' && decor.image">
        <video
          v-if="decor.image.type === 'webm'"
          class="roulette-decor-image"
          autoplay
          loop
          muted
          :src="decor.image.src"
          :key="decor.image.src"
          :style="decor.image.styles"/>
        <img
          v-else
          class="roulette-decor-image"
          :src="decor.image.src"
          :style="decor.image.styles"/>
      </template>
    </div>
  </div>
</template>

<script>

import { createCssFilters } from '@utils/utils'

export default {
  name: 'WidgetRouletteDecor',
  props: {
    settings: {
      type: Object,
      default: null,
    },
  },
  computed: {
    ...mapGetters('fileManager', ['getFile']),

    decorImage() {
      const {
        decorImage,
      } = this.settings

      this.fetchFile(decorImage)

      return this.getFile(decorImage)
    },

    decor() {
      if (!_.get(this.settings, 'decorType', null)) {
        return null
      }

      const {
        decorType,
        decorImageHue,
        decorImageSaturate,
        decorSize,
        decorShadow,
      } = this.settings

      const decor = {
        type: decorType,
      }

      if (decorType === 'image' && this.decorImage) {
        decor.image = {
          type: this.decorImage.ext,
          src: `${this.decorImage.url}`,
          styles: {
            filter: createCssFilters({
              shadow: decorShadow,
              hue: decorImageHue,
              saturate: decorImageSaturate,
            }),
            maxWidth: `${decorSize}px`,
            maxHeight: `${decorSize}px`,
          },
        }
      }

      return decor
    },
  },
}
</script>
