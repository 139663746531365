export const state = {
  isStatisticsLoading: false,
  statistics: null,
  pagination: {},
  sort: 'desc',
}

export const getters = {
  isStatisticsLoading: ({ isStatisticsLoading }) => isStatisticsLoading,
  statistics: ({ statistics }) => statistics,
  pagination: ({ pagination }) => pagination,
  sort: ({ sort }) => sort,
}

export const mutations = {
  startStatisticsLoading(state) {
    state.isStatisticsLoading = true
  },

  endStatisticsLoading(state) {
    state.isStatisticsLoading = false
  },

  setStatistics(state, { data }) {
    state.statistics = data
  },

  setPagination(state, pagination) {
    state.pagination = pagination
  },

  setSort(state, newValue) {
    state.sort = newValue
  },
}

export const actions = {
  async getStatistics({ commit }, opt = {}) {
    const params = {
      'sort[]': 'total_count,' + state.sort,
      'page': opt.page && opt.page > 1 ? opt.page : 1,
    }

    try {
      commit('startStatisticsLoading')

      const { data, meta } = await api.get(`/bft/auth/stat`, { params })
      commit('setStatistics', { data })
      commit('setPagination', meta)
      commit('endStatisticsLoading')
    } catch (err) {
      commit('endStatisticsLoading')
    }
  },

  async toggleSort({ state, commit, dispatch }) {
    if (state.sort === 'desc') {
      commit('setSort', 'asc')
    } else {
      commit('setSort', 'desc')
    }

    dispatch('getStatistics')
  },
}
