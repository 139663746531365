import { defineStore } from 'pinia'

export const useOffersStore = defineStore('offers', {
  state: () => ({}),
  
  actions: {
    async advConfirm(payload) {
      await api.post(`/accept-adv-agreement`, {}, { params:  payload })
      window.location.reload()
    },
  },
})