<style lang="scss" scoped>
  .calendar {
    padding: 20px;
    background-color: $additional-2;
    border-radius: 3px;
    user-select: none;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.11);

    .calendar-header {
      display: flex;
      align-items: center;

      .calendar-month {
        font-size: 15px;
        font-weight: 500;
        text-transform: uppercase;
      }

      .calendar-controls {
        display: flex;
        margin-left: auto;

        .calendar-control + .calendar-control {
          margin-left: 5px;
        }

        .calendar-control {
          font-size: 8px;
          color: var(--theme-color-additional);
          cursor: pointer;
          background-color: $greyish-brown-two;
          width: 30px;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 3px;

          &:hover {
            background-color: $greyish-brown-three;
          }

          &.prev .icon {
            transform: rotate(-270deg);
          }

          &.next .icon {
            transform: rotate(270deg);
          }
        }
      }
    }

    .calendar-body {
      margin-top: 15px;

      .calendar-grid {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        grid-row-gap: 16px;
        grid-column-gap: 16px;

        .grid-cell {
          text-align: center;

          .weekday {
            color: $additional-1;
            font-weight: bold;
            text-transform: uppercase;
          }

          .day-picker {
            display: flex;
            align-items: center;
            justify-content: center;
            color: $additional-1;
            font-size: 13px;
            font-weight: 500;
            cursor: pointer;
            position: relative;

            &:hover:not(.selected) {
              color: var(--theme-color-additional);
            }

            &.current-month {
              color: $bright;
            }

            &.disabled {
              color: $additional-1;
              pointer-events: none;
            }

            &.selected {
              &:before {
                content: '';
                position: absolute;
                background-image: linear-gradient(220deg, var(--theme-color-main), var(--theme-color-additional));
                border-radius: 50%;
                width: 28px;
                height: 28px;
                z-index: 1;
              }

              span {
                position: relative;
                z-index: 2;
              }
            }
          }
        }
      }

      .calendar-clock {
        margin-top: 20px;
        display: flex;
        align-items: center;

        .base-select {
          flex: 1 1 auto;
          width: 100%;

          &.minutes {
            margin-left: 5px;
          }
        }
      }
    }

    .calendar-footer {
      margin-top: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .calendar-button {
        text-transform: uppercase;
        color: $bright;
        cursor: pointer;
        font-weight: 600;

        &:hover {
          color: var(--theme-color-additional);
        }
      }
    }
  }
</style>

<template>
  <div
    class="calendar">
    <div class="calendar-header">
      <div class="calendar-month">
        {{calendarTitle}}
      </div>

      <div class="calendar-controls">
        <div class="calendar-control prev" @click="prevMonth">
          <icon name="down"/>
        </div>
        <div class="calendar-control next" @click="nextMonth">
          <icon name="down"/>
        </div>
      </div>
    </div>

    <div class="calendar-body">
      <div class="calendar-grid">
        <div
          v-for="day in weekDays"
          :key="day"
          class="grid-cell">
          <div class="weekday">{{day}}</div>
        </div>
        <div
          v-for="(day, index) in calendarDays"
          :key="index"
          :class="{picker: !!day}"
          class="grid-cell">
          <div
            class="day-picker"
            :class="day.classes"
            @click="pickDate(day.ts)"><span>{{day.text}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const getWithZero = num => num > 9 ? num : `0${num > 0 ? num : 0}`

const tMonths = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

const tWeekDays = [
  'Mon',
  'Tue',
  'Wed',
  'Thu',
  'Fri',
  'Sat',
  'Sun',
]

const createDate = (_date) => new Date(_date.year, _date.month, _date.day)

export default {
  name: 'Calendar',
  props: {
    value: {
      type: Number,
      default: null,
    },
    allowed: {
      type: Object,
    },
  },
  data() {
    return {
      pickedDateTS: null,
      calendarDateTS: null,

      months: _.range(0, 12)
        .map(r => tMonths[r])
        .map((name, id) => ({
          name,
          id,
        })),
    }
  },
  computed: {
    calendarDate: {
      set(_date) {
        this.calendarDateTS = dates.timestamp(createDate({
          ...this.calendarDate,
          ..._date,
        }))
      },
      get() {
        return dates.parseTs(this.calendarDateTS)
      },
    },

    calendarDays() {
      const { year, month } = this.calendarDate

      const date = (override) => createDate({
        year,
        month,
        day: 1,
        ...override,
      })

      const currentMonth = date()
      const lastMonth = date({
        day: 1,
        month: month - 1,
      })

      const currentMonthFirstDay = dates.getWeekDay(currentMonth) || 7
      const currentMonthLength = dates.getDaysInMonth(currentMonth)
      const lastMonthLength = dates.getDaysInMonth(lastMonth)

      const lastMonthDays = _.range(lastMonthLength - currentMonthFirstDay + 2, lastMonthLength + 1)
        .map(day => ({
          number: day,
          currentMonth: false,
          date: date({
            day: day,
            month: month - 1,
          }),
        }))

      const currentMonthDays = _.range(1, currentMonthLength + 1).map(day => {
        return {
          number: day,
          currentMonth: true,
          currentDay: this.calendarMonthIsPicked && day === this.pickedDate.day,
          date: date({ day: day }),
        }
      })

      const nextMonthDays = _.range(1, 43 - (lastMonthDays.length + currentMonthDays.length)).map(day => ({
        number: day,
        currentMonth: false,
        date: date({
          day: day,
          month: month + 1,
        }),
      }))

      return [
        ...lastMonthDays,
        ...currentMonthDays,
        ...nextMonthDays,
      ].map(day => {
        let isDisabled = false

        if (this.allowed.to && dates.isAfter(day.date, this.allowed.to)) isDisabled = true
        if (this.allowed.from && dates.isBefore(day.date, this.allowed.from)) isDisabled = true

        return {
          ...day,
          text: getWithZero(day.number),
          ts: dates.timestamp(day.date),
          classes: {
            selected: day.currentDay,
            'current-month': day.currentMonth,
            'disabled': isDisabled,
          },
        }
      })
    },

    calendarMonthIsPicked() {
      return this.calendarDate.year === this.pickedDate.year && this.calendarDate.month === this.pickedDate.month
    },

    calendarTitle() {
      return [
        this.$t(`global.calendar.${this.months[this.calendarDate.month].name}`),
        this.calendarDate.year,
      ].join(' ')
    },

    pickedDate: {
      set(_date) {
        this.pickedDateTS = dates.timestamp(createDate({
          ...this.pickedDate,
          ..._date,
        }))
      },
      get() {
        return dates.parseTs(this.pickedDateTS)
      },
    },
    weekDays() {
      return _.range(0, 7).map(r => this.$t(`global.calendar.${tWeekDays[r]}`))
    },
  },
  watch: {
    value: {
      handler(value) {
        const currentTS = dates.timestamp()

        this.pickedDateTS = value || currentTS
        this.calendarDateTS = value || currentTS
      },
      immediate: true,
    },
  },
  methods: {

    close() {
      this.$emit('close')
    },

    nextMonth() {
      if (this.calendarDate.month < 11) {
        this.calendarDate = {
          month: this.calendarDate.month + 1,
        }
      } else {
        this.calendarDate = {
          year: this.calendarDate.year + 1,
          month: 0,
        }
      }
    },

    pickDate(ts) {
      this.pickedDateTS = ts
      this.calendarDateTS = ts

      this.save()
    },
    prevMonth() {
      if (this.calendarDate.month > 0) {
        this.calendarDate = {
          month: this.calendarDate.month - 1,
        }
      } else {
        this.calendarDate = {
          year: this.calendarDate.year - 1,
          month: 11,
        }
      }
    },

    save() {
      this.$emit('input', this.pickedDateTS)
      this.close()
    },
  },
}
</script>
