<style lang="scss" scoped>
  .notifications-feed {
    height: 100%;
    display: flex;
    flex-direction: column;

    .notifications-feed-title {
      display: flex;
      align-items: center;
      height: 22px;

      .feed-title {
        font-weight: 400;
        font-size: 16px;
        color: $bright;
        white-space: nowrap;

        .notifications-count {
          font-size: 13px;
          padding: 1px 7px;
          background: $additional-2;
          border-radius: 100px;
        }
      }

      .read-all-link {
        margin-left: auto;
        color: rgba($bright, .5);
        font-size: 13px;
        cursor: pointer;
        white-space: nowrap;

        &:hover {
          color: var(--theme-color-additional);
        }
      }
    }

    .notifications-feed-list-wrapper {
      margin-top: 20px;
      position: relative;
      min-height: 0;
      height: 100%;

      .notifications-feed-loader {
        @include position-all(absolute, 0);
        background-color: rgba($additional-3, .5);
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .notifications-feed-list {
        height: 100%;
        overflow-y: scroll;
        margin-right: -15px;
        padding-right: 8px;
        padding-bottom: 16px;

        .notification + .notification {
          margin-top: 12px;
        }

        .notification {
          border-radius: $control-border-radius;
          background-color: $additional-2;
          padding: 14px 16px 14px 18px;
          position: relative;
          overflow: hidden;

          &:before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            width: 4px;
            background-color: $additional-1;
          }

          &.unseen {
            &:before {
              background-color: var(--theme-color-additional);
            }
          }

          .notification-content {
            font-size: 13px;
            line-height: 18px;
            color: $bright;
          }

          .notification-date {
            margin-top: 10px;
            font-size: 13px;
            color: $gray;
          }
        }
      }
    }
  }
</style>

<template>
  <div class="notifications-feed">
    <div class="notifications-feed-title">
      <div class="feed-title">{{ $t('dashboard.header.notifications') }}
        <span
          v-if="unseenNotificationsCount"
          class="notifications-count">{{ unseenNotificationsCount }}</span>
      </div>
      <div
        v-if="unseenNotificationsCount"
        class="read-all-link"
        @click="readAllNotifications">
        {{ $t('dashboard.header.markAsRead') }}
      </div>
    </div>
    <div class="notifications-feed-list-wrapper">
      <transition name="fade">
        <div v-if="loadingState.notifications" class="notifications-feed-loader">
          <loader size="lg"/>
        </div>
      </transition>
      <div
        v-if="notifications.length"
        ref="notifications-feed-list"
        class="notifications-feed-list"
        @scroll="onNotificationsListScroll">
        <div
          v-for="(notification, index) in notifications"
          :key="index"
          class="notification"
          :class="{unseen: !notification.isRead}">
          <div class="notification-content" v-html="notification.text"/>
          <div class="notification-date">
            <time-diff :from="notification.notificationDate">
              <template slot="after">назад</template>
            </time-diff>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'NotificationsFeed',
  data() {
    return {
      loadingState: {
        notifications: true,
      },

      notifications: [],
      page: 1,
      perPage: 10,
      total: 0,
      pagesCount: 0,

      newsPopoverOpened: false,
    }
  },
  computed: {
    ...mapState('dashboard', ['dashboardInfo']),

    unseenNotificationsCount() {
      return this.dashboardInfo.unseenNotificationsCount
    },
  },
  methods: {
    ...mapActions('dashboard', [
      'fetchUserNotifications',
      'markNotificationsAsRead',
      'readAllNotifications',
      'fetchDashboardInfo',
    ]),

    onNotificationsListScroll(event) {
      const { scrollHeight, scrollTop, offsetHeight } = event.target

      if (scrollTop >= scrollHeight - offsetHeight) {
        this.nextPage()
      }
    },

    nextPage() {
      if (!this.loadingState.notifications) {
        if (this.total > this.page * this.perPage) {
          this.page = this.page + 1
          this.fetchNotifications()
            .then(() => {
              this.$nextTick(() => {
                const notificationsFeedListEl = this.$refs['notifications-feed-list']

                notificationsFeedListEl.scrollTo({
                  top: notificationsFeedListEl.scrollTop + 100,
                  behavior: 'smooth',
                })
              })
            })
        }
      }
    },

    fetchNotifications() {
      this.loadingState.notifications = true

      return this
        .fetchUserNotifications({ page: this.page, perPage: this.perPage })
        .then(({ pagination, notifications }) => {
            this.notifications = [
              ...this.notifications,
              ...notifications,
            ]

            this.total = pagination.total
            this.pagesCount = pagination.pagesCount

            this.markNotificationsAsRead(notifications.map(n => n.id))
              .then(() => {
                this.fetchDashboardInfo()
              })
          },
        )
        .finally(() => {
          this.loadingState.notifications = false
        })
    },

    readAllNotifications() {
      this.loadingState.notifications = true

      this.markAllNotificationsAsRead()
        .finally(() => {
          this.loadingState.notifications = false
        })
    },
  },
  created() {
    this.fetchNotifications()
  },
}
</script>
