<template>
  <div class="settings__container">
    <loader
      size="md"
      v-if="loading"
      style="margin: 10vh auto;"
    />

    <div class="settings" v-else>
      <form-group
        :label="$t('offers.settings.country')"
        inline
        style="margin-bottom: 15px;"
      >
        <dropdown-select
          class="country-select"
          v-model="countryId"
          :options="countriesOptions"
        />
      </form-group>


      <form-group
        :label="$t('offers.settings.secretCodeLabel')"
        :description="$t('offers.settings.secretCodeDesc')"
        inline
        style="margin-bottom: 15px;"
      >
        <base-input v-model="secretCode" />
      </form-group>

      <Title>
        <span>{{ $t('offers.settings.priceTitle') }}</span>
      </Title>

      <p class="price-text">{{ $t('offers.settings.priceText') }}</p>

      <form-group
        :label="$t('offers.settings.priceLabel')"
        inline
      >
        <input-addon v-model="price" :addon="currency || getUserCurrency"/>
      </form-group>

      <base-button
        variant="primary-action"
        class="send-button"
        @click="() => saveСhanges()"
        :isLoading="saveLoading"
        :disabled="!secretCode || secretCode.length === 0 || price.length === 0 || (settings && (secretCode === settings.igromarket_code && price == settings.adv_note_price && countryId === userCountry))"
      >{{ $t('offers.settings.save') }}</base-button>
    </div>
  </div>
</template>

<script>
import Title from '@components/BaseComponents/Title'
import FormGroup from '@components/Dashboard/Offers/FormGroup'
import BaseInput from '@components/BaseComponents/Form/BaseInput'
import InputAddon from '@components/BaseComponents/Form/InputAddon'
import BaseButton from '@components/BaseComponents/BaseButton'

import { mapActions as _mapActions } from 'pinia'

import { useGlobalStore } from '@store/global'

export default {
  name: 'OffersSettingsPage',

  components: {
    Title,
    FormGroup,
    BaseInput,
    InputAddon,
    BaseButton,
  },

  data() {
    return {
      secretCode: '',
      price: '0',
      currency: null,
      countries: [],
      countryId: null,
      fetchingData: false
    }
  },

  watch: {
    settings(nextVal) {
      if (nextVal) {
        this.secretCode = nextVal.igromarket_code
        this.price = nextVal.adv_note_price
        this.currency = nextVal.adv_note_price_currency
      }
    },
  },
  metaInfo() {
    return {
      title: this.$t('dashboard.pages.offers.settings'),
    }
  },

  async created() {
    this.fetchingData = true
    this.countries = await this.fetchCountries()
    await this.fetchSettings()
    this.countryId = this.userCountry
    this.fetchingData = false
  },

  methods: {
    ...mapActions('offers/settings', ['fetchSettings', 'saveSettings']),
    ..._mapActions(useGlobalStore, ['fetchCountries']),

    saveСhanges() {
      const { secretCode, price, currency, getUserCurrency, countryId } = this

      this.saveSettings({ secretCode, price, currency: (currency || getUserCurrency), country_id: countryId })
    },
  },

  computed: {
    ...mapGetters('profile', [
      'getUserCurrency',
      'getCurrentProfile'
    ]),

    ...mapGetters('offers/settings', [
      'isLoading',
      'saveLoading',
      'settings',
    ]),

    countriesOptions: (vm) => vm.countries.map(option => ({
      value: option.region_id,
      label: option.name,
    })),

    userCountry: (vm) => vm.settings?.country?.id || null,
    loading: (vm) => vm.fetchingData || vm.isLoading
  },
}
</script>

<style scoped lang="scss">
.settings {
  max-width: 640px;

  &__container {
    padding-top: 10px;
  }

  label.form-group-label {
    border: 1px solid red;
  }

  h1 {
    margin-bottom: 15px !important;
    font-size: 20px;
  }
}

.price-text {
  color: #838383;
  font-size: 13px;
  margin-bottom: 15px;
}

.send-button {
  border-radius: 8px;
  margin-top: 20px;
  text-transform: uppercase;
}
</style>
