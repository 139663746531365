<style scoped lang="scss">
  .active-goal {
    height: 100%;
    padding: 20px;
    display: grid;
    grid-template-columns: 80px 380px 1fr;
    border-radius: 3px;
    background-color: $additional-3;
    position: relative;

    @include media(max, md) {
      display: block;
      padding: 15px;
    }

    .grid-cell {
      display: flex;
      align-items: center;
    }

    .goal-progress {
      position: relative;
      justify-content: center;

      @include media(max, md) {
        display: none;
      }

      .progress-circle {
        @include position(absolute, 0, auto, 0);
        font-size: 80px;
      }

      .progress-percentage {
        font-weight: 600;
      }
    }

    .goal-info {
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      padding-left: 20px;

      @include media(max, md) {
        padding-left: 0;
      }

      .goal-title {
        font-size: 16px;
        font-weight: 600;
      }

      .goal-raised {
        margin-top: 10px;
      }

      .goal-expires-at {
        margin-top: 6px;
      }
    }

    .goal-mobile-controls {
      display: none;
      @include position(absolute, 0, 15px);

      @include media(max, md) {
        display: block;
      }
    }

    .goal-controls {
      min-width: 0;

      .goal-widget-link {
        min-width: 0;
        margin-right: 30px;

        @include media(max, md) {
          margin-right: 0;
          margin-top: 20px;
        }

        .widget-link {
          display: flex;
          align-items: center;
        }
      }

      .goal-buttons {
        margin-left: auto;
        flex-shrink: 0;

        @include media(max, md) {
          display: none;
        }

        .button + .button {
          margin-left: 25px;
        }
      }

      @include media(max, xl) {
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;

        .goal-buttons {
          margin-top: auto;
          margin-left: 0;
        }
      }

      @include media(max, md) {
        justify-content: flex-start;
        align-items: flex-start;
      }
    }
  }
</style>

<template>
  <div v-if="goal" class="active-goal">
    <div class="goal-mobile-controls">
      <dropdown-select class="options-right" @select="onActionSelect" :options="actionsOptions" action/>
    </div>
    <div class="grid-cell goal-progress">
      <progress-circle :percent="goal.cappedProgressPercentage"/>
      <div class="progress-percentage">{{ goal.cappedProgressPercentage }}%</div>
    </div>
    <div class="grid-cell goal-info">
      <div class="goal-title">{{ goal.title }}</div>
      <div class="goal-raised">
        <span class="helper-text">{{ $t('goals.activeGoal.labelRaised') }}</span>
        {{ goal.raisedAmount }} / {{ goal.goalAmount }} {{ goal.currency }}
      </div>
      <div v-if="goal.expiresAt" class="goal-expires-at">
        <span class="helper-text">{{ $t('goals.activeGoal.labelFinish') }}</span>
        {{ goal.expiresAtFormatted }}
      </div>
    </div>
    <div class="grid-cell goal-controls">
      <div class="goal-widget-link">
        <widget-link :link="goalLink"/>
      </div>
      <div class="goal-buttons">
        <btn
          class="supreme"
          variant="link"
          icon-left="play"
          @click="openGoalWindow">
          {{ $t('goals.activeGoal.buttonLaunch') }}
        </btn>
        <btn
          class="supreme"
          :is-loading="loadingState.stop"
          variant="link"
          icon-left="stop"
          @click="stop">
          {{ $t('goals.activeGoal.buttonFinish') }}
        </btn>
        <btn
          class="supreme"
          variant="link"
          size="lg"
          icon-left="pencil"
          @click="editGoal"/>
      </div>
    </div>
  </div>
</template>

<script>
import ProgressCircle from '@components/BaseComponents/ProgressCircle'
import WidgetLink from '@components/Dashboard/WidgetLink'

import { openWindow } from '@utils/utils'

export default {
  name: 'ActiveGoal',
  components: {
    WidgetLink,
    ProgressCircle,
  },
  props: {
    goalId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      loadingState: {
        stop: false,
      },

      actionsDropdownVisible: false,
      actionsOptionsData: [
        {
          label: 'buttonFinish',
          value: 'stop',
        },
        {
          label: 'buttonEdit',
          value: 'edit',
        },
      ],
    }
  },
  computed: {
    ...mapGetters('goal/goals', ['getGoal']),
    ...mapGetters('goal/presets', ['getUserWidgetId']),
    ...mapGetters('profile', ['getCurrentProfile']),

    actionsOptions() {
      return this.actionsOptionsData.map(option => ({
        ...option,
        label: this.$t(`goals.activeGoal.${option.label}`),
      }))
    },

    goal() {
      return this.getGoal(this.goalId)
    },

    goalLink() {
      if (!this.getCurrentProfile) {
        return null
      }

      const link = `${window.location.origin}/widget/goal/${this.goalId}`

      const args = [
        `token=${this.getCurrentProfile.token}`,
      ]

      return [link, args.join('&')].join('?')
    },
  },
  methods: {
    ...mapActions('goal/settings', [
      'openGoalModal',
      'loadActiveGoals',
      'loadPastGoals',
    ]),
    ...mapActions('goal/goals', ['stopGoal']),

    onActionSelect(action) {
      switch (action) {
        case 'stop':
          this.stop()
          break
        case 'edit':
          this.editGoal()
          break
        default:
          break
      }
    },

    editGoal() {
      this.openGoalModal(this.goalId)
    },

    stop() {
      this.loadingState.stop = true

      if (confirm(this.$t('goals.activeGoal.finishConfirmation'))) {
        this.stopGoal(this.goalId)
          .then(() => {
            this.loadActiveGoals()
            this.loadPastGoals()
          })
          .catch(() => {
            this.loadingState.stop = false
          })
      } else {
        this.loadingState.stop = false
      }
    },

    openGoalWindow() {
      openWindow({
        url: this.goalLink,
        name: '_donation_goal_window',
        params: {
          left: 200,
          top: 200,
          width: 800,
          height: 600,
        },
      })
    },
  },
}
</script>
