<style scoped lang="scss">
  .roulette-behavior-settings {
    
    .settings-area {
      @include media(max, md) {
        grid-template-columns: auto !important;
      }
    }

  }
</style>

<template>
  <div class="roulette-behavior-settings">
    <grid columns="2" gap="40" class="settings-area">
      <div>
        <form-group inline :label="$t('roulette.visualSettings.behavior.spinTime')">
          <base-range v-model="spinTime" :min="5" :max="20" :step=".1" input :input-addon="$t('global.settings.helpers.seconds')"/>
        </form-group>
        <form-group inline :label="$t('roulette.visualSettings.behavior.showTime')">
          <base-range v-model="showTime" :min="3" :max="10" :step=".1" input :input-addon="$t('global.settings.helpers.seconds')"/>
        </form-group>
        <form-group
          v-tooltip="tooltips.delayTime"
          inline
          :label="$t('roulette.visualSettings.behavior.delayTime')">
          <base-range v-model="delayTime" :min="0" :max="120" :step="1" input :input-addon="$t('global.settings.helpers.seconds')"/>
        </form-group>
        <hr>
        <form-group
          v-tooltip="tooltips.slotMachineMode"
          inline :label="$t('roulette.visualSettings.behavior.slotMachineMode')">
          <base-switch v-model="slotMachineMode"/>
        </form-group>
        <form-group
          v-tooltip="tooltips.highlightMode"
          inline :label="$t('roulette.visualSettings.behavior.highlightMode')">
          <base-switch v-model="highlightMode"/>
        </form-group>
        <template v-if="!highlightMode">
          <form-group
            v-tooltip="tooltips.cardsRandomRotation"
            inline :label="$t('roulette.visualSettings.behavior.randomRotation')">
            <base-switch v-model="cardsRandomRotation"/>
          </form-group>
          <form-group
            v-tooltip="tooltips.cardsRandomScale"
            inline :label="$t('roulette.visualSettings.behavior.randomScale')">
            <base-switch v-model="cardsRandomScale"/>
          </form-group>
        </template>
      </div>
      <div>
        <form-group inline :label="$t('roulette.visualSettings.behavior.appearAnimation')">
          <dropdown-select v-model="animationAppear" :options="animationAppearOptions"/>
        </form-group>
        <form-group v-if="animationAppear" inline :label="$t('roulette.visualSettings.behavior.appearTime')">
          <base-range v-model="appearTime" :min=".5" :max="5" :step=".1" input :input-addon="$t('global.settings.helpers.seconds')"/>
        </form-group>
        <form-group inline :label="$t('roulette.visualSettings.behavior.disappearAnimation')">
          <dropdown-select v-model="animationDisappear" :options="animationDisappearOptions"/>
        </form-group>
        <form-group v-if="animationDisappear" inline :label="$t('roulette.visualSettings.behavior.disappearTime')">
          <base-range v-model="disappearTime" :min=".5" :max="5" :step=".1" input :input-addon="$t('global.settings.helpers.seconds')"/>
        </form-group>
        <form-group>
          <info-box class="small" :show-icon="false">
            {{ $t('roulette.visualSettings.behavior.effectsText') }}
          </info-box>
        </form-group>
      </div>
    </grid>
  </div>
</template>

<script>

export default {
  name: 'RouletteBehaviorSettings',
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      spinTime: 8,
      showTime: 3,
      delayTime: 0,
      slotMachineMode: false,
      highlightMode: false,
      cardsRandomRotation: false,
      cardsRandomScale: false,

      animationAppear: 'slide-from-left',
      appearTime: .5,

      animationDisappear: 'slide-to-right',
      disappearTime: .5,
    }
  },
  computed: {
    tooltips() {
      return {
        slotMachineMode: this.$tooltip(this.$t('roulette.visualSettings.behavior.slotMachineModeTooltip')),
        highlightMode: this.$tooltip(this.$t('roulette.visualSettings.behavior.highlightModeTooltip')),
        cardsRandomRotation: this.$tooltip(this.$t('roulette.visualSettings.behavior.randomRotationTooltip')),
        cardsRandomScale: this.$tooltip(this.$t('roulette.visualSettings.behavior.randomScaleTooltip')),
        delayTime: this.$tooltip(this.$t('roulette.visualSettings.behavior.delayTooltip')),
      }
    },

    animationAppearOptions() {
      return [
        {
          value: null,
          icon: 'cross',
        },
        {
          value: 'opacity',
          icon: 'shadow',
        },
        {
          value: 'slide-from-left',
          icon: 'arrow-right',
        },
        {
          value: 'slide-from-right',
          icon: 'arrow-left',
        },
        {
          value: 'slide-from-bottom',
          icon: 'arrow-up',
        },
        {
          value: 'slide-from-top',
          icon: 'arrow-down',
        },
      ].map(option => ({
        ...option,
        label: option.value ? this.$t(`roulette.visualSettings.behavior.appearOptions.${option.value}`) : null,
      }))
    },

    animationDisappearOptions() {
      return [
        {
          label: 'Нет',
          value: null,
          icon: 'cross',
        },
        {
          value: 'opacity',
          icon: 'shadow',
        },
        {
          value: 'slide-to-left',
          icon: 'arrow-left',
        },
        {
          value: 'slide-to-right',
          icon: 'arrow-right',
        },
        {
          value: 'slide-to-bottom',
          icon: 'arrow-down',
        },
        {
          value: 'slide-to-top',
          icon: 'arrow-up',
        },
      ].map(option => ({
        ...option,
        label: option.value ? this.$t(`roulette.visualSettings.behavior.disappearOptions.${option.value}`) : null,
      }))
    },

    output() {
      return {
        _version: Math.random().toString(36).slice(2),
        spinTime: this.spinTime,
        showTime: this.showTime,
        delayTime: this.delayTime,
        slotMachineMode: this.slotMachineMode,
        highlightMode: this.highlightMode,
        cardsRandomRotation: this.cardsRandomRotation,
        cardsRandomScale: this.cardsRandomScale,
        animationAppear: this.animationAppear,
        appearTime: this.appearTime,
        animationDisappear: this.animationDisappear,
        disappearTime: this.disappearTime,
      }
    },
  },
  created() {
    const storedValue = _.cloneDeep(this.value)

    if (storedValue) {
      Object.keys(storedValue).forEach(key => {
        this[key] = storedValue[key] ?? this[key]
      })
    }
  },
  watch: {
    output(value) {
      this.$emit('input', value)
    },
  },
}
</script>
