import { Line, mixins } from 'vue-chartjs'
const { reactiveData } = mixins

export default {

  extends: Line,
  mixins: [reactiveData],
  props: ['values', 'label'],

  data() {
    return {
      datacollection: {
        labels: [],
        datasets: [
          {
            label: '',
            borderColor: '#f68d07',
            fill: true,
            backgroundColor: "rgba(244, 144, 128, 0.8)",
            pointBackgroundColor: '#f68d07',
            pointBorderColor: '#f68d07',
            pointRadius: 4,
            pointBorderWidth: 4,
            pointHoverRadius: 6,
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: '#f68d07',
            pointHoverBorderWidth: 4,
            data: [],
          }
        ]
      },

      options: {
        elements: {
          line: {
            tension: 0
          }
        },
        legend: { display: false },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [{ gridLines: { color: 'rgb(53, 53, 53)', borderDash: [4, 2] } }],
          yAxes: [{ gridLines: { color: 'rgb(53, 53, 53)' } }]
        },
        tooltips: {
          enabled: false,

          custom: function(tooltipModel) {
            var tooltipEl = document.getElementById('chartjs-tooltip');

            // Create element on first render
            if (!tooltipEl) {
                tooltipEl = document.createElement('div');
                tooltipEl.id = 'chartjs-tooltip';
                tooltipEl.innerHTML = '<table></table>';
                document.body.appendChild(tooltipEl);
                tooltipEl.classList.add('custom-stats-tooltip');
            }

            // Hide if no tooltip
            if (tooltipModel.opacity === 0) {
                tooltipEl.style.opacity = 0;
                return;
            }

            // Set caret Position
            tooltipEl.classList.remove('above', 'below', 'no-transform');
            if (tooltipModel.yAlign) {
                tooltipEl.classList.add(tooltipModel.yAlign);
            } else {
                tooltipEl.classList.add('no-transform');
            }

            function getBody(bodyItem) {
                return bodyItem.lines;
            }

            // Set Text
            if (tooltipModel.body) {
                var titleLines = tooltipModel.body.map(getBody);
                var bodyLines = tooltipModel.title || [];

                var innerHtml = '<thead>';

                titleLines.forEach(function(title) {
                    var style = 'font-size: 16px';
                    innerHtml += '<tr><th><span style="' + style + '">' + title + '</span></th></tr>';
                });
                innerHtml += '</thead><tbody>';

                bodyLines.forEach(function(body, i) {
                    var colors = tooltipModel.labelColors[i];
                    var style = 'background:' + colors.backgroundColor;
                    style += '; border-color:' + colors.borderColor;
                    style += '; border-width: 0';
                    style += '; color: #838383; font-size: 12px';
                    var span = '<span style="' + style + '"></span>';
                    innerHtml += '<tr><td>' + span + body + '</td></tr>';
                });
                innerHtml += '</tbody>';

                innerHtml += '<div style="position: absolute; border-top: 8px solid #fff; border-bottom: 8px solid transparent; border-left: 10px solid transparent; border-right: 10px solid transparent; left: 50%; bottom: -16px; transform: translateX(-50%)"></div>';

                var tableRoot = tooltipEl.querySelector('table');
                tableRoot.innerHTML = innerHtml;
            }

            // `this` will be the overall tooltip
            var position = this._chart.canvas.getBoundingClientRect();

            // Display, position, and set styles for font
            tooltipEl.style.opacity = 1;
            tooltipEl.style.position = 'absolute';
            tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
            tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY - 85 + 'px';
            tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
            tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
            tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
            tooltipEl.style.padding = '10px' // tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
            tooltipEl.style.pointerEvents = 'none';
            tooltipEl.style.background = '#fff';
            tooltipEl.style.borderRadius = '8px';
            tooltipEl.style.transform = 'translateX(-50%)';
          },
        },
      },
    }

  },

  mounted () {
    const gradient = this.$refs.canvas.getContext('2d').createLinearGradient(0, 0, 0, 450)
    gradient.addColorStop(0, 'rgba(246, 141, 7, .2)')
    gradient.addColorStop(1, 'rgba(0, 0, 0, 0.001)')
    this.datacollection.datasets[0]['backgroundColor'] = gradient

    this.datacollection.labels = this.values.map(el => el.label)
    this.datacollection.datasets[0].data = this.values.map(el => el.value)
    this.datacollection.datasets[0].label = this.label

    this.renderChart(this.datacollection, this.options)
  },

  watch: {
    values(next, prev) {
      this.datacollection.labels = next.map(el => el.label)
      this.datacollection.datasets[0].data = next.map(el => el.value)
      this.datacollection.datasets[0].label = this.label

      this.renderChart(this.datacollection, this.options)
    }
  },

}
