<style lang="scss">
  .stream-stats-list-page {
    flex: 1 0 auto;
    min-height: 100%;
  }
</style>

<template>
  <div class="stream-stats-list-page">
    <stream-stats-list/>
  </div>
</template>

<script>

import StreamStatsList from '@components/Dashboard/StreamStats/StreamStatsList'

export default {
  name: 'StreamStatsListPage',
  metaInfo() {
    const title = [
      this.$t('dashboard.pages.widgets.streamStats'),
      this.$t('dashboard.pages.widgets.widgets'),
    ]

    return {
      title: title.join(' - '),
    }
  },
  components: { StreamStatsList },
}
</script>
