<template>
  <div>
    <loader
      size="md"
      v-if="statsLoading"
      style="margin: 10vh auto;"
    />

    <div v-if="!statsLoading">
      <div class="group-toggle" style="margin-bottom: 15px; margin-top: 30px">
        <span>{{$t('offers.statistics.statistics')}}</span>

        <Radio v-model="period" option='day'>{{$t('offers.statistics.forToday')}}</Radio>
        <Radio v-model="period" option='week'>{{$t('offers.statistics.for7Days')}}</Radio>
        <Radio v-model="period" option='month'>{{$t('offers.statistics.for30Days')}}</Radio>
        <Radio v-model="period" option='year'>{{$t('offers.statistics.forTheYear')}}</Radio>
        <Radio v-model="period" option='manual'>{{$t('offers.statistics.chooseDate')}}</Radio>
      </div>

      <div
        v-if="period === 'manual'"
        class="date-range"
      >
        <div class="date-range-from">
          <span>{{$t('offers.statistics.from')}}</span>
          <date-picker
            v-model="statsFrom"
            :placeholder="formatPlaceholder(offer.offer_user[0].created_at_ts)"
            :allowed="{from: new Date(offer.offer_user[0].created_at_ts * 1000 - (24*60*60*1000)), to: new Date()}"
          />
        </div>

        <div class="date-range-to">
          <span>{{$t('offers.statistics.to')}}</span>
          <date-picker
            v-model="statsTo"
            :placeholder="formatPlaceholder(Math.floor(Date.now() / 1000))"
            :allowed="{from: new Date(offer.offer_user[0].created_at_ts * 1000 - (24*60*60*1000)), to: new Date()}"
          />
        </div>
      </div>


      <div class="chart-options">
        <div class="chart-option" v-if="offer.bonus_type === 'action' && landingOptions">
          <span>{{$t('offers.landingPage')}}:</span>
          <dropdown-select
            v-model="landing"
            :options="landingOptions"
            style="width: 250px"
          />
        </div>

        <div class="chart-option">
          <span>{{$t('offers.typeOfData')}}:</span>
          <dropdown-select
            v-model="dataType"
            :options="dataTypeOptions"
            style="width: 250px"
          />
        </div>

        <div class="chart-option">
          <span>{{$t('offers.show')}}:</span>
          <dropdown-select
            v-model="rate"
            :options="rateOptions"
            style="width: 200px"
          />
        </div>
      </div>

      <div v-if="stats && !stats.length">
        <span class="empty-data">{{$t('offers.noData')}}</span>
      </div>

      <reactive-chart
        v-if="stats && stats.length && stats.filter(el => el.adv_event_id === dataType).length"
        :values="stats.filter(el => el.adv_event_id === dataType).map(el => ({label: el[rate], value: el.value}))"
        style="width: 100%; height: 400px;"
      />

      <data-grid
        style="margin-bottom: 30px; margin-top: 30px; max-width: 730px;"
        v-if="stats && stats.length && stats.filter(el => el.adv_event_id === dataType).length"
      >
        <data-grid-header>
          <data-grid-col
            v-if="stats[0].month || stats[0].week || stats[0].day || stats[0].hour"
          >{{$t('offers.period')}}</data-grid-col>

          <data-grid-col>{{$t('offers.count')}}</data-grid-col>
        </data-grid-header>

        <data-grid-row
          v-for="item in stats.filter(el => el.adv_event_id === dataType)"
          :key="item.id"
        >
          <data-grid-col style="color: #838383">
            <span v-if="item.day">{{ item.day }}</span>
            <span v-if="item.hour">{{ item.hour }}</span>
            <span v-if="item.month">{{ item.month }}</span>
            <span v-if="item.week">{{ item.week }}</span>
          </data-grid-col>
          <data-grid-col>{{ item.value }}</data-grid-col>
        </data-grid-row>
      </data-grid>

      <div v-else style="max-width: 730px;">
        <span class="empty-data">{{$t('offers.noData')}}</span>
      </div>
    </div>
  </div>
</template>

<script>
import subDays from 'date-fns/subDays'
import currency from 'currency.js'

import DG from '@components/Dashboard/DataGrid'

import ReactiveChart from '@components/Dashboard/Offers/ReactiveChart'
import Radio from '@components/Dashboard/Offers/Radio'

export default {
  name: 'OffersDetailsStatsPage',

  components: {
    DataGrid: DG.DataGrid,
    DataGridHeader: DG.DataGridHeader,
    DataGridRow: DG.DataGridRow,
    DataGridCol: DG.DataGridCol,
    ReactiveChart,
    Radio,
  },

  data() {
    return {
      period: null,
      landing: null,
      dataType: null,

      statsFrom: null,
      statsTo: null,
      rate: 'day',
    }
  },

  created() {
    this.period = 'month'
    this.dataType = Object.values(this.offer.options.displayable_stats)[0].id
    if (this.offer.bonus_type === 'action')
      this.landing = this.offer.options ? this.offer.offer_user[0].options.links.filter(landing => landing.is_show_in_statistics)[0].entry_point_id : null
  },

  watch: {
    period(next, prev) {
      let date, rawDate

      switch (next) {
        case 'day':
          rawDate = subDays(new Date(), 0)
          date = new Date(rawDate).toISOString().substr(0, 10) + ' ' + new Date(rawDate).toISOString().substr(11, 8)
          this.statsFrom = new Date(date).getTime() / 1000
          break;
        case 'week':
          rawDate = subDays(new Date(), 7)
          date = new Date(rawDate).toISOString().substr(0, 10) + ' 00:00:00'
          this.statsFrom = new Date(date).getTime() / 1000
          break;
        case 'month':
          rawDate = subDays(new Date(), 30)
          date = new Date(rawDate).toISOString().substr(0, 10) + ' 00:00:00'
          this.statsFrom = new Date(date).getTime() / 1000
          break;
        case 'year':
          rawDate = subDays(new Date(), 365)
          date = new Date(rawDate).toISOString().substr(0, 10) + ' 00:00:00'
          this.statsFrom = new Date(date).getTime() / 1000

          if (this.rate === 'hour') this.rate = 'day'
          break;
        default:
          //
      }
    },

    statsFrom(next, prev) {
      if (next !== prev) {
        this.getStatsByParams()
      }
    },

    statsTo(next, prev) {
      if (next !== prev) {
        this.getStatsByParams()
      }
    },

    rate(next, prev) {
      if (next !== prev) {
        if (next === 'hour' && this.period === 'year') this.period = 'day'

        this.getStatsByParams()
      }
    },

    landing(next, prev) {
      if (this.offer.bonus_type === 'action' && next !== prev) {
        this.getStatsByParams()
      }
    },
  },

  methods: {
    ...mapActions('offers/details', ['getStats']),
    ...mapActions('profile/getTimezone', ['getTimezone']),

    formatPlaceholder(date) {
      return dates.unixToFormat(date, 'dd.MM.yyyy')
    },

    getStatsByParams() {
      this.getStats({
        id: this.offer.offer_user[0].id,
        from: this.statsFrom ? dates.unixToFormat(this.statsFrom, 'yyyy-MM-dd') : null,
        to: this.statsTo ? dates.unixToFormat(this.statsTo, 'yyyy-MM-dd') : null,
        group: this.rate || null,
        landing: this.landing || null,
      })
    },

    transformAmount(val) {
      return currency(Math.abs(val), {
        separator: ' ',
        decimal: ','
      }).format()
    },
  },

  computed: {
    ...mapGetters('offers/details', [
      'offer',
      'stats',
      'statsLoading'
    ]),

    landingOptions() {
      if (this.offer.bonus_type !== 'action' && !this.offer.offer_user[0].options) return

      const options = this.offer.options ? this.offer.offer_user[0].options.links.filter(landing => landing.is_show_in_statistics) : []

      return options
        .map(option => ({
          value: option.entry_point_id,
          label: option.title,
        }))
    },

    dataTypeOptions() {
      return this.offer.options.displayable_stats.map(opt => ({
        value: opt.id,
        label: opt.title,
      }))
    },

    rateOptions() {
      const translate = {
        month: 'offers.statistics.byMonth',
        week: 'offers.statistics.byWeek',
        day: 'offers.statistics.byDays',
        hour: 'offers.statistics.byHours',
      }

      return ['month', 'week', 'day', 'hour']
        .map(option => ({
          value: option,
          label: this.$t(translate[option]),
        }))
    },
  },
}
</script>

<style scoped lang="scss">
.group-toggle {
  display: flex;
  align-items: center;

  span {
    display: block;
    line-height: 20px;
  }
}

.chart-options {
  display: flex;
  margin: 20px 0 30px 0;
  // justify-content: start;
}

.chart-option {
  margin-right: 15px;

  span {
    display: block;
    margin-bottom: 10px;
  }
}

.date-range {
  display: flex;

  span {
    display: block;
    margin-bottom: 10px;
  }

  .date-range-from,
  .date-range-to,
  .date-range-btn {
    margin-right: 15px;
  }
}

.cost {
  color: var(--theme-color-main);
}

.custom-stats-tooltip {
  &:before {
    content: '';
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    border-top: 10px solid #fff;
    border-right: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 10px solid #fff;
  }
}

.empty-data {
  display: block;
  margin: 30px 0;
  font-weight: bold;
  text-align: center;
}
</style>
