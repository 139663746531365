<style lang="scss" scoped>
  .grid {
    display: grid;
  }
</style>

<template>
  <div class="grid" :style="styles">
    <slot/>
  </div>
</template>

<script>

export default {
  props: {
    columns: {
      type: [Number, String],
      default: null,
    },
    rows: {
      type: [Number, String],
      default: null,
    },
    gap: {
      type: [Number, String],
      default: null,
    },
  },
  computed: {
    styles() {
      const columns = Number(this.columns)
      const rows = Number(this.rows)
      const gap = Number(this.gap)

      return {
        gridTemplateColumns: columns ? `repeat(${columns}, minmax(0, 1fr))` : null,
        gridTemplateRows: rows ? `repeat(${rows}, minmax(0, 1fr))` : null,
        gridColumnGap: gap ? `${gap}px` : null,
        gridRowGap: gap ? `${gap}px` : null,
      }
    },
  },
}
</script>
