<style scoped lang="scss">
  .layout-settings {

  }
</style>

<template>
  <div class="layout-settings">
    <form-group inline :label="$t('bft.widget.labels.groupActions')">
      <base-switch v-model="groupActions"/>
    </form-group>
    <form-group inline :label="$t('bft.widget.labels.columnsNumber')">
      <base-range v-model="columns" :min="1" :max="4"/>
    </form-group>
    <form-group inline :label="$t('bft.widget.labels.rowsNumber')">
      <base-range v-model="rows" :min="1" :max="10"/>
    </form-group>
  </div>
</template>

<script>

import { defaultLayoutSettings } from '@src/config/bft/defaultValues'

const { layoutSettings } = defaultLayoutSettings()

export default {
  name: 'LayoutSettings',
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      groupActions: layoutSettings.groupActions,
      columns: layoutSettings.columns,
      rows: layoutSettings.rows,
    }
  },
  computed: {
    output() {
      return {
        groupActions: this.groupActions,
        columns: this.columns,
        rows: this.rows,
      }
    },
  },
  watch: {
    output: {
      handler(value) {
        this.$emit('input', value)
      },
      immediate: true,
    },

    value: {
      handler(value) {
        if (value) {
          Object.keys(value).forEach(key => {
            this[key] = value[key] ?? this[key]
          })
        }
      },
      immediate: true,
    },
  },
}
</script>
