<template>
  <div class="card__wrapper">
    <div class="card__image">
      <img 
        v-if="!!preset.preview"
        :src="preset.preview"
      >

      <icon v-else name="image" class="no-image"/>
    </div>

    <div class="card__title">
      <span>{{ preset.title }}</span>
    </div>

    <div class="card__btn-group">
      <base-button
        v-if="!!preset.is_editable"
        variant="secondary-action" 
        iconLeft="pencil"
        @click="editClickHandler"
      >{{$t('offers.edit')}}</base-button>
    </div>

  </div>
</template>

<script>
import BaseButton from '@components/BaseComponents/BaseButton'

export default {
  components: {
    BaseButton,
  },

  data() {
    return {}
  },

  props: {
    preset: {
      type: Object,
      default: () => {},
    }
  },

  methods: {
    editClickHandler() {
      this.$emit('toggleModal', this.preset.id) ;
    },
  }
}
</script>

<style scoped lang="scss">
.card {
  &__wrapper {
    background: #262626;
    border-radius: 8px;
    padding: 10px;
    color: #F1F1F1;
    height: 100%;
    cursor: default;
    position: relative;
  }

  &__image {
    margin: 10px 10px 20px 10px;
    overflow: hidden;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      display: block;
      max-width: 100%;
      max-height: 100%;
    }
  }

  &__title {
    margin: 10px;
    font-size: 16px;
    font-weight: bold;

    span {
      display: block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  &__btn-group {
    margin: 20px 10px 10px 10px;

    button {
      width: 100%;
      border-radius: 8px;
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

}

.no-image {
  font-size: 60px;
  opacity: .1;
}
</style>