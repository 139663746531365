<template>
<div>
  <Label
    v-if="type === 'work'"
    :text="$t('offers.status.inProgress')"
    type="yellow"
  />

  <Label
    v-if="type === 'ban'"
    :text="$t('offers.status.suspdended')"
    type="red"
  />

  <Label
    v-if="type === 'ok'"
    :text="$t('offers.status.completed')"
    type="green"
  />

  <Label
    v-if="type === 'fail'"
    :text="$t('offers.status.uncompleted')"
    type="red"
  />

  <Label
    v-if="type === 'on_approval'"
    :text="$t('offers.status.onApproval')"
    type="yellow"
  />

  <Label
    v-if="type === 'not_approved'"
    :text="$t('offers.status.notApproved')"
    type="red"
  />
</div>
</template>

<script>
import Label from './Label'

export default {

  name: 'Status',

  components: {
    Label,
  },

  props: {
    type: {
      type: String,
      default: '',
    }
  },

}

</script>

<style scoped lang="scss">
</style>
