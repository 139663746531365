<style scoped lang="scss">
  .separator-settings {

  }
</style>

<template>
  <div class="separator-settings">
    <form-group inline :label="$t('global.settings.labels.size')">
      <base-range v-model="size" :min="0" :max="10" input input-addon="px"/>
    </form-group>
    <form-group inline :label="$t('global.settings.labels.color')">
      <color-gradient-picker v-model="backgroundColor"/>
    </form-group>
  </div>
</template>

<script>

import ColorGradientPicker from '@components/BaseComponents/ColorPicker/ColorGradientPicker'

export default {
  name: 'SeparatorSettings',
  components: { ColorGradientPicker },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      size: 1,
      backgroundColor: null,
    }
  },
  computed: {
    output() {
      return {
        size: this.size,
        backgroundColor: this.backgroundColor,
      }
    },
  },
  watch: {
    output(value) {
      this.$emit('input', value)
    },

    value: {
      handler(value) {
        if (value) {
          Object.keys(value).forEach(key => {
            this[key] = value[key] ?? this[key]
          })
        }
      },
      immediate: true,
    },
  },
}
</script>
