<style scoped lang="scss">
  .svg-gradient {
    height: 0;
    width: 0;
    position: fixed;
  }
</style>

<template>
  <svg class="svg-gradient">
    <defs>
      <linearGradient
        :id="name"
        :x1="points.x1"
        :x2="points.x2"
        :y1="points.y1"
        :y2="points.y2">
        <stop
          v-for="(stop, index) in colorStops"
          :key="index"
          :offset="stop.offset"
          :stop-color="stop.color"/>
      </linearGradient>
    </defs>
  </svg>
</template>

<script>

import { createGradient } from '@utils/utils'

function angleToPoints(angle) {
  const segment = Math.floor(angle / Math.PI * 2) + 2
  const diagonal = (1 / 2 * segment + 1 / 4) * Math.PI
  const op = Math.cos(Math.abs(diagonal - angle)) * Math.sqrt(2)
  const x = op * Math.cos(angle)
  const y = op * Math.sin(angle)

  return {
    x1: x < 0 ? 1 : 0,
    y1: y < 0 ? 1 : 0,
    x2: x >= 0 ? x : x + 1,
    y2: y >= 0 ? y : y + 1,
  }
}

function toRadians(degrees) {
  return degrees / 180 * Math.PI
}

export default {
  name: 'SvgGradient',
  props: {
    name: {
      type: [String, Number],
      required: true,
    },
    colors: {
      type: Array,
      required: true,
    },
    angle: {
      type: [String, Number],
      default: 0,
    },
    hard: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    points() {
      return angleToPoints(toRadians(this.angle - 180))
    },

    colorStops() {
      return createGradient(this.colors, this.hard)
    },
  },
}
</script>
