<style lang="scss" scoped>
  .text-styler {
    display: inline-flex;
    justify-content: center;
    flex-direction: column;
    position: relative;

    @include media(max, md) {
      display: flex;
    }

    .text-settings-popover {
      @include position(absolute, 100%, auto, auto, 0);
      z-index: 50;
      width: 520px;
      padding: 0 20px 20px 20px;

      @include media(max, md) {
        width: 100%;
        padding: 0 $gutter-mobile $gutter-mobile $gutter-mobile;
      }
    }

    &.left {
      .text-settings-popover {
        left: auto;
        right: 0;
      }
    }

    &.top {
      .text-settings-popover {
        top: auto;
        bottom: 100%;
      }
    }
  }
</style>

<template>
  <div class="text-styler" :class="stylerClasses" v-click-outside="hidePopover">
    <btn icon-left="fonts" @click="togglePopover">{{ $t('global.textStyler.customizeFont') }}</btn>
    <transition name="fade-up">
      <text-settings-popover
        v-if="popoverVisible"
        :with-animation="withAnimation"
        @close="hidePopover"
        :value="value"
        @input="onInput"
        :units="units"/>
    </transition>
  </div>
</template>

<script>
import TextSettingsPopover from './TextSettingsPopover'

export default {
  name: 'TextStyler',
  components: { TextSettingsPopover },
  props: {
    value: {
      type: Object,
      default: null,
    },
    popoverPosition: {
      type: String,
      default: 'bottom',
    },
    withAnimation: {
      type: Boolean,
      default: false,
    },
    units: {
      type: String,
      default: 'px',
    },
  },
  data() {
    return {
      popoverVisible: false,
    }
  },
  computed: {
    stylerClasses() {
      return {
        [this.popoverPosition]: !!this.popoverPosition,
      }
    },
  },
  methods: {
    onInput(value) {
      this.$emit('input', value)
    },

    togglePopover() {
      this.popoverVisible = !this.popoverVisible
    },

    hidePopover() {
      this.popoverVisible = false
    },
  },
}
</script>
