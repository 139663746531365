<style lang="scss" scoped>
  .widget-stream-stats-legacy-page {
    .stream-stats-helper {
      margin-top: 16px;
      max-width: 700px;
    }
  }
</style>

<template>
  <div class="widget-stream-stats-legacy-page">
    <info-box class="stream-stats-helper">
      {{ $t('streamStats.legacyMessage1') }}
      <br><br>{{ $t('streamStats.legacyMessage2') }}
    </info-box>
    <hr>
    <div ref="legacy"/>
  </div>
</template>

<script>
import { loadLegacyPage } from '@src/services/legacyLoader'

export default {
  name: 'WidgetStreamStatsLegacyPage',
  metaInfo() {
    const title = [
      this.$t('dashboard.pages.widgets.streamStatsLegacy'),
      this.$t('dashboard.pages.widgets.widgets'),
    ]

    return {
      title: title.join(' - '),
    }
  },
  mounted() {
    loadLegacyPage('/dashboard/old-page-instream-stats', this.$refs.legacy)
  },
}
</script>
