<template>
  <span class="reward-type">
    <icon
      v-if="icon"
      :name="icon"
    />

    <span>{{ text }}</span>
  </span>
</template>

<script>
export default {

  name: 'Reward',

  props: {
    icon: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: ''
    },
  },

}

</script>

<style scoped lang="scss">
.reward-type {

  & > svg {
    margin-right: 10px;
    vertical-align: middle;
    fill: url(#theme_svg_gradient);
  }

  & > span {
    vertical-align: middle;
  }
}
</style>
