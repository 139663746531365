import storage from '@services/storage'

window.dataLayer = window.dataLayer || []

const debug = storage.getItem('da.dashboard.debug.ga')

class GA {
  send(hitType = 'event', options, data = {}) {
    if (window.ga) {
      window.ga('send', {
        hitType,
        ...options,
      }, {
        source_url: window.location.pathname,
        ...data,
      })
    }

    if (debug) {
      console.log('send', {
        hitType,
        ...options,
      }, {
        source_url: window.location.pathname,
        ...data,
      })
    }
  }

  event(options, data = {}) {
    this.send('event', options, data)
  }

  pageView(options, data = {}) {
    this.send('pageview', {
      page: window.location.pathname,
      ...options,
    }, data)
  }
}

export const ga = new GA()

export default {
  install: Vue => {
    Vue.ga = ga
    Vue.prototype.$ga = Vue.ga
  },
}
