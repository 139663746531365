import router from '../../../router/dashboard'

export const state = {
  linksLoading: false,
  offerLoading: false,
  statsLoading: false,
  acceptBtnLoading: false,
  offer: null,
  links: null,
  stats: null,

  separatorLoading: false,
  separatorNew: null,

  isShowOnApprovalMessage: false,

  errorMessageModal: null,

  advConfirmed: true,
}

export const getters = {
  linksLoading: ({ linksLoading }) => linksLoading,
  offerLoading: ({ offerLoading }) => offerLoading,
  statsLoading: ({ statsLoading }) => statsLoading,
  acceptBtnLoading: ({acceptBtnLoading}) => acceptBtnLoading,
  offer: ({offer}) => offer,
  userOffer: ({ offer }) => offer?.offer_user?.[0] || null,
  userOfferId: (state, { userOffer }) => userOffer?.id || null,
  links: ({links}) => links,
  stats: ({stats}) => stats,

  separatorLoading: ({ offerLoadingseparatorLoading }) => offerLoadingseparatorLoading,
  separatorNew: ({ separatorNew }) => separatorNew,

  isShowOnApprovalMessage: ({ isShowOnApprovalMessage }) => isShowOnApprovalMessage,

  errorMessageModal: ({ errorMessageModal }) => errorMessageModal,

  advConfirmed: ({ advConfirmed }) => advConfirmed,
}

export const mutations = {
  setStats(state, { data }) {
    state.stats = data
  },

  startStatsLoading(state) {
    state.statsLoading = true
  },

  endStatsLoading(state) {
    state.statsLoading = false
  },

  setLinks(state, data) {
    const landings = {}
    
    state.offer.offer_user[0].options.links.forEach(l => {
      landings[l.id] = l.title
    })

    state.links = data.map(l => ({
      ...l,
      landing: landings[l.parent_id],
    }))
  },

  startLinksLoading(state) {
    state.linksLoading = true
  },

  endLinksLoading(state) {
    state.linksLoading = false
  },

  startAcceptBtnLoading(state) {
    state.acceptBtnLoading = true
  },

  endAcceptBtnLoading(state) {
    state.acceptBtnLoading = false
  },

  startOfferLoading(state) {
    state.offerLoading = true
  },

  endOfferLoading(state) {
    state.offerLoading = false
  },

  setOfferData(state, { data }) {
    state.offer = data
  },

  clearOfferData(state) {
    state.offer = null
    state.stats = null
  },

  startSeparatorLoading(state) {
    state.separatorLoading = true
  },

  endSeparatorLoading(state) {
    state.separatorLoading = false
  },

  setSeparatorNew(state, { data }) {
    state.separatorNew = data
  },

  clearSeparatorNew(state) {
    state.separatorNew = null
  },

  updateSeparatorLink(state, { data }) {
    state.links = state.links.map(l => {
      if (l.id !== data.id) return l

      return {
        ...l,
        title: data.title,
      }
    })
  },

  showOnApprovalMessage(state) {
    state.isShowOnApprovalMessage = true
  },

  hideOnApprovalMessage(state) {
    state.isShowOnApprovalMessage = false
  },

  setErrorMessageModal(state, { code }) {
    state.errorMessageModal = code
  },

  clearErrorMessageModal(state) {
    state.errorMessageModal = null
  },

  refuteAdvConfirmed(state) {
    state.advConfirmed = false
  },
}

export const actions = {
  async fetchOffer({ commit }, id) {
    commit('startOfferLoading')

    try {
      const res = await api.get('/advoffer/' + id)

      if (res.data) {
        commit('setOfferData', { data: res.data })
      } else {
        throw new Error()
      }

      commit('endOfferLoading')
    } catch (err) {
      if (err.code) {
        switch(err.code) {
          case 1509:
            commit('refuteAdvConfirmed')
            break
          
          default:
            router.push('/dashboard/offers-v2')
        }

        commit('endOfferLoading')
      } else {
        router.push('/dashboard/offers-v2')
      }
    }
  },

  clearOffer({ commit }) {
    commit('clearOfferData')
  },

  async toAcceptOffer({ commit }, payload) {
    commit('startAcceptBtnLoading')

    try {
      const { data } = await api.post('/advuser', { ...payload })

      commit('endAcceptBtnLoading')

      if (data.status === 'on_approval') {
        commit('showOnApprovalMessage')
      } else {
        window.location.reload()
      }
    } catch (error) {
      commit('endAcceptBtnLoading')

      switch(error.code) {
        case 1101:
        case 2001:
        case 2002:
        case 2003:
          commit('setErrorMessageModal', { code: error.code })
          break

        default:
          window.addStatusMessage('error', window.translateString('offer_is_no_longer_available'))
          setTimeout(() => {
            router.push('/dashboard/offers-v2')
          }, 2000)
      }
    }
  },

  clearErrorMessageModal({ commit }) {
    commit('clearErrorMessageModal')
  },

  async getStats({ commit }, {id, from, to, group, landing}) {
    commit('startStatsLoading')

    let url = `/advuser/statistics?offer_user_id=${ id }`

    if (from) url += `&date_from=${ from }`
    if (to) url += `&date_to=${ to }`
    if (group) url += `&group_by=${ group }`
    if (landing) url += `&entry_point_id=${ landing }`

    try {
      const { data } = await api.get(url)

      commit('setStats', { data })
      commit('endStatsLoading')
    } catch (error) {
      setTimeout(() => {
        commit('endStatsLoading')
        window.location.reload()
      }, 2000)
      throw new Error(error)
    }
  },

  async separateLink({ commit }, { parent, title, value }) {
    commit('startSeparatorLoading')

    try {
      const res = await api.post('/advcpauserlink/separator', {
        parent_id: parent,
        title: title,
        sub_id_2: value,
      })

      commit('setSeparatorNew', res)
      commit('endSeparatorLoading')
    } catch (error) {
      if (error.message === 'The sub id 2 format is invalid.')
        window.addStatusMessage('error', 'Значение для разбиения: Разрешено использовать только буквы латинского алфавита и точку. Пробелы, подчеркивания и другие символы не допускаются. Общая длина не должна быть более 32-х символов"')
      commit('endSeparatorLoading')
      throw new Error(error)
    }
  },

  clearSeparatorNew({ commit }) {
    commit('clearSeparatorNew')
  },

  async updateLink({ commit }, { id, title, value }) {
    try {
      const res = await api.put('/advcpauserlink/separator/' + id, {
        title: title,
      })

      commit('updateSeparatorLink', res)
    } catch (error) {
      throw new Error(error)
    }
  },

  async fetchLinks({ commit }, { id, params = '' }) {
    commit('startLinksLoading')

    try {
      const { data } = await api.get('/advcpauserlink/separator?adv_user_id=' + id + `${ params }`)

      commit('setLinks', data)
      commit('endLinksLoading')
    } catch (error) {
      throw new Error(error)
    }
  },
}