<style scoped lang="scss">
  .actions-settings {

  }
</style>

<template>
  <div class="actions-settings">
    <form-group inline :label="$t('bft.widget.labels.actionTextStyles')">
      <text-styler v-model="actionTextStyles"/>
    </form-group>
    <form-group inline :label="$t('bft.widget.labels.priceTextStyles')">
      <text-styler v-model="priceTextStyles"/>
    </form-group>
  </div>
</template>

<script>

import ColorGradientPicker from '@components/BaseComponents/ColorPicker/ColorGradientPicker'
import TextStyler from '@components/BaseComponents/TextStyler/TextStyler'

export default {
  name: 'ActionsSettings',
  components: { TextStyler, ColorGradientPicker },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      actionTextStyles: {},
      priceTextStyles: {},
    }
  },
  computed: {
    output() {
      return {
        actionTextStyles: this.actionTextStyles,
        priceTextStyles: this.priceTextStyles,
      }
    },
  },
  watch: {
    output(value) {
      this.$emit('input', value)
    },

    value: {
      handler(value) {
        if (value) {
          Object.keys(value).forEach(key => {
            this[key] = value[key] ?? this[key]
          })
        }
      },
      immediate: true,
    },
  },
}
</script>
