<style scoped lang="scss">
  .roulette-card-text {
    display: flex;
    align-items: center;
    justify-content: center;

    .card-text-place {
      height: 0;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      .card-text {
        position: absolute;
        bottom: 0;
        transform: translateY(50%);
      }
    }
  }
</style>

<template>
  <div v-if="textVisible" class="roulette-card-text">
    <div class="card-text-place" :style="text.placeStyles">
      <div class="card-text" :style="text.textStyles">
        {{ text.content }}
      </div>
    </div>
  </div>
</template>

<script>
import {
  numbersToUnits,
} from '@utils/utils'

export default {
  name: 'RouletteCardText',
  props: {
    settings: {
      type: Object,
      default: null,
    },
    content: {
      type: String,
      default: null,
    },
  },
  computed: {
    textVisible() {
      return this.settings && this.content && this.content.length
    },

    text() {
      const {
        textStyles,
        textWidth,
        textOffset = 0,
        textOffsetX = 0,
      } = this.settings

      const { strokeWidth, strokeColor } = textStyles

      return {
        content: this.content,
        textStyles: {
          ...numbersToUnits(textStyles, 'px'),
          fontFamily: `"${textStyles.fontFamily}"`,
          width: `${textWidth}px`,
          '-webkit-text-stroke': strokeWidth ? `${strokeWidth}px ${strokeColor}` : null,
          textStroke: strokeWidth ? `${strokeWidth}px ${strokeColor}` : null,
        },
        placeStyles: {
          transform: `translateY(${textOffset}px) translateX(${textOffsetX}px)`,
        },
      }
    },
  },
}
</script>
