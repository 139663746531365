<style scoped lang="scss">
  .goal-settings {
    .settings-content {
      margin-top: 20px;
      width: 600px;
    }
  }
</style>

<template>
  <div class="goal-settings">
    <tabs v-model="currentTab" :list="tabs"/>
    <div class="settings-content">
      <div v-show="currentTab === 'main'" class="main-settings-tab">
        <form-group
          inline
          :label="$t('roulette.goal.settings.initialGoal')"
          :description="$t('roulette.goal.settings.initialGoalHelper')">
          <input-addon v-model="baseGoalAmount" :addon="currency"/>
        </form-group>
        <form-group inline :label="$t('roulette.goal.settings.initiallyRaised')">
          <input-addon v-model="baseRaisedAmount" :addon="currency"/>
        </form-group>
        <form-group
          inline
          :label="$t('roulette.goal.settings.incrementStep')"
          :description="$t('roulette.goal.settings.incrementStepHelper')">
          <input-addon v-model="amountStep" :addon="currency"/>
        </form-group>
      </div>
      <div v-show="currentTab === 'discount'" class="discount-settings-tab">
        <form-group inline :label="$t('roulette.goal.settings.discount')">
          <base-switch v-model="discountEnabled"/>
        </form-group>
        <form-group>
          <info-box class="small" :show-icon="false">
            {{ $t('roulette.goal.settings.discountText') }}
          </info-box>
        </form-group>
        <template v-if="discountEnabled">
          <form-group inline :label="$t('roulette.goal.settings.discountSize')">
            <base-range v-model="discount" :min="1" :max="99" :step="1" input input-addon="%"/>
          </form-group>
          <form-group inline :label="$t('roulette.goal.settings.discountInterval')">
            <base-range v-model="discountTimeout" :min="1" :max="60" :step="1" input :input-addon="$t('global.settings.helpers.minutes')"/>
          </form-group>
          <form-group inline :label="$t('roulette.goal.settings.discountDuration')">
            <base-range v-model="discountDuration" :min="1" :max="60" :step="1" input :input-addon="$t('global.settings.helpers.minutes')"/>
          </form-group>
          <form-group inline :label="$t('roulette.goal.settings.discountChance')">
            <base-range v-model="discountChance" :min="1" :max="100" :step="1" input input-addon="%"/>
          </form-group>
        </template>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'GoalSettings',
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      currentTab: 'main',

      baseGoalAmount: 500,
      baseRaisedAmount: 0,
      amountStep: 100,

      discountEnabled: true,
      discount: 50,
      discountDuration: 10,
      discountChance: 100,
      discountTimeout: 10,
    }
  },
  computed: {
    ...mapGetters('profile', ['getUserCurrency']),

    currency() {
      return this.getUserCurrency
    },

    tabs() {
      return [
        {
          key: 'main',
          title: 'main',
          icon: 'gear',
        },
        {
          key: 'discount',
          title: 'discount',
          icon: 'percent',
        },
      ].map(tab => ({
        ...tab,
        title: this.$t(`roulette.goal.settings.tabs.${tab.title}`),
      }))
    },

    output() {
      return {
        baseGoalAmount: this.baseGoalAmount,
        baseRaisedAmount: this.baseRaisedAmount,
        amountStep: this.amountStep,

        discountEnabled: this.discountEnabled,
        discount: this.discount,
        discountDuration: this.discountDuration,
        discountChance: this.discountChance,
        discountTimeout: this.discountTimeout,
      }
    },
  },
  created() {
    const storedValue = _.cloneDeep(this.value)

    if (storedValue) {
      Object.keys(storedValue).forEach(key => {
        this[key] = storedValue[key] ?? this[key]
      })
    }
  },
  watch: {
    output(value) {
      this.$emit('input', value)
    },
  },
}
</script>
