<style lang="scss" scoped>
  .audio-button {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;

    background-color: $greyish-brown-three;
    border-radius: 100%;

    &:not(.playing) {
      .icon {
        margin-left: 3px;
      }
    }

    &:hover {
      color: white;

      .icon {
        fill: url(#theme_svg_gradient);
      }
    }
  }
</style>

<template>
  <div
    class="audio-button"
    @mouseenter="playAudio"
    @mouseleave="stopAudio"
    :class="{playing}">
    <icon :name="buttonIcon"/>
  </div>
</template>

<script>

import {
  stopAudio,
  playAudio,
  createAudio,
} from '@utils/audio'

let lastAudio

export default {
  name: 'AudioButton',
  props: {
    src: {
      type: String,
      required: true,
    },
    volume: {
      type: [Number, String],
      default: 100,
    },
  },
  data() {
    return {
      playing: false,

      audio: null,
    }
  },
  computed: {
    buttonIcon() {
      return this.playing ? 'volume' : 'play'
    },
  },
  methods: {
    toggleAudio() {
      if (this.playing) {
        this.stopAudio()
      } else {
        this.playAudio()
      }
    },

    createAudio() {
      this.audio = createAudio(this.src, this.volume)

      // this.audio.onended = this.audio.onpause = () => {
      //   this.playing = false
      // }
      // this.audio.onplay = () => {
      //   this.playing = true
      // }
    },

    playAudio() {
      this.playing = true
      // if (lastAudio) {
      //   stopAudio(lastAudio)
      // }

      if (!this.audio) {
        this.createAudio()
      }

      playAudio(this.audio, this.volume)

      // lastAudio = this.audio
    },

    stopAudio() {
      stopAudio(this.audio)
      this.playing = false
    },
  },
}
</script>
