<style scoped lang="scss">
  .title-settings {

  }
</style>

<template>
  <div class="title-settings">
    <form-group inline :label="$t('global.settings.labels.title')">
      <base-input
        @input="(value) => {text = value}"
        :value="titleInputValue"
        :placeholder="titleInputPlaceholder"
        :disabled="titleInputDisabled"/>
    </form-group>
    <form-group v-if="groupActions">
      <info-box class="small" :show-icon="false">
        {{ $t('bft.widget.labels.titleHelper') }}
      </info-box>
    </form-group>
    <form-group inline :label="$t('global.settings.labels.backgroundColor')">
      <color-gradient-picker v-model="backgroundColor"/>
    </form-group>
    <form-group inline :label="$t('global.settings.labels.textStyle')">
      <text-styler v-model="textStyles"/>
    </form-group>
  </div>
</template>

<script>

import ColorGradientPicker from '@components/BaseComponents/ColorPicker/ColorGradientPicker'
import TextStyler from '@components/BaseComponents/TextStyler/TextStyler'

export default {
  name: 'TitleSettings',
  components: {
    TextStyler,
    ColorGradientPicker,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    groupActions: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      text: null,
      backgroundColor: null,
      textStyles: {},
    }
  },
  computed: {
    titleInputPlaceholder() {
      return this.groupActions
        ? this.$t('bft.widget.labels.groupActionsPlaceholder')
        : this.$t('global.settings.labels.title')
    },

    titleInputValue() {
      return this.groupActions
        ? ''
        : this.text
    },

    titleInputDisabled() {
      return this.groupActions
    },

    output() {
      return {
        text: this.text,
        backgroundColor: this.backgroundColor,
        textStyles: this.textStyles,
      }
    },
  },
  watch: {
    output(value) {
      this.$emit('input', value)
    },

    value: {
      handler(value) {
        if (value) {
          Object.keys(value).forEach(key => {
            this[key] = value[key] ?? this[key]
          })
        }
      },
      immediate: true,
    },
  },
}
</script>
