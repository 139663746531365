<style lang="scss" scoped>
  .general-settings-links-page {

  }
</style>

<template>
  <div class="general-settings-links-page">
    <template v-if="section">
      <div class="page-controls">
        <back-btn :route="{name: 'dashboard-general-settings-links'}"/>
      </div>
      <template v-if="section === 'vk'">
        <vk-link-settings/>
      </template>
      <template v-if="section === 'boosty'">
        <boosty-link-settings/>
      </template>
    </template>
    <div v-show="!section">
      <transition name="fade-up">
        <div v-show="!loadingState.page" ref="legacy"/>
      </transition>
    </div>
  </div>
</template>

<script>

import $ from 'jquery'

import { loadLegacyPage } from '@src/services/legacyLoader'
import VkLinkSettings from '@components/Dashboard/NewDashboard/GeneralSettings/VkLinkSettings'
import BackBtn from '@components/Dashboard/Offers/BackBtn'
import BoostyLinkSettings
  from '@components/Dashboard/NewDashboard/GeneralSettings/BoostyLinkSettings'

export default {
  name: 'GeneralSettingsLinksPage',
  components: { BoostyLinkSettings, BackBtn, VkLinkSettings },
  props: {
    section: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      loadingState: {
        page: false,
      },
    }
  },
  metaInfo() {
    return {
      title: [
        this.$t('dashboard.pages.generalSettings.links'),
        this.$t('dashboard.pages.generalSettings.generalSettings'),
      ].join(' - '),
    }
  },
  mounted() {
    this.loadingState.page = true

    loadLegacyPage('/dashboard/old-page-general', this.$refs.legacy, () => {
      $('#_1337block-authorized-apps').remove()
    })
      .then(() => {
        $('#settings_general').hide()
        $('#_1337block-account-settings').hide()
        $('#delete-account').hide()
      })
      .finally(() => {
        this.loadingState.page = false
      })

    window.vueDashboardLinksGoTo = this.globalGoTo
  },
  methods: {
    globalGoTo(section) {
      this.$router.push({ name: 'dashboard-general-settings-links-section', params: { section } })
    },
  },
  created() {
    if (this.section && !['vk', 'boosty'].includes(this.section)) {
      this.$router.replace({ name: 'dashboard-general-settings-links' })
    }
  },
}
</script>
