<style lang="scss">
  .stream-stats-config-page {
    flex: 1 0 auto;
    min-height: 100%;

    .stream-stats-config {
      margin-top: 10px;
    }
  }
</style>

<template>
  <div class="stream-stats-config-page">
    <div class="page-controls">
      <btn
        class="page-controls-button"
        icon-left="chevron-left"
        size="sm"
        :to="backLink">
        {{ $t('global.common.back') }}
      </btn>
    </div>
    <stream-stats-config :id="id"/>
  </div>
</template>

<script>

import StreamStatsConfig from '@components/Dashboard/StreamStats/StreamStatsConfig'

export default {
  name: 'StreamStatsConfigPage',
  metaInfo() {
    const title = [
      this.$t('dashboard.pages.widgets.streamStats'),
      this.$t('dashboard.pages.widgets.widgets'),
    ]

    return {
      title: title.join(' - '),
    }
  },
  components: { StreamStatsConfig },
  props: {
    id: {
      type: [Number, String],
      default: null,
    },
  },
  computed: {
    ...mapState('dashboard', ['newDashboard']),

    backLink() {
      return this.newDashboard ? {
        name: 'dashboard-widget-stream-stats-list',
      } : {
        name: 'stream-stats',
      }
    },
  },
}
</script>
