export const state = {
  isLoading: false,
  groupUrl: null,
  verificationURL: null,
  isVerifyed: false,
}

export const getters = {
  isLoading: ({ isLoading }) => isLoading,
  groupUrl: ({ groupUrl }) => groupUrl,
  verificationURL: ({ verificationURL }) => verificationURL,
  isVerifyed: ({ isVerifyed }) => isVerifyed,
}

export const mutations = {
  startLoading(state) {
    state.isLoading = true
  },

  endLoading(state) {
    state.isLoading = false
  },

  setGroupUrl(state, groupUrl) {
    state.groupUrl = groupUrl
  },

  setVerificationURL(state, url) {
    state.verificationURL = url
  },

  verify(state) {
    state.isVerifyed = true
  },

  clearData(state) {
    state.groupUrl = null
    state.verificationURL = null
    state.isVerifyed = false
  }
}

export const actions = {
  async fetchStatus({ commit }) {
    commit('startLoading')
    try {
      const { data } = await api.get(`/usersocnetgroup?platform=vk-user`)
  
      if (!!data.url) commit('setGroupUrl', data.url)
      if (!!data.verification_link) commit('setVerificationURL', data.verification_link)
      if (!!data.verified_at) commit('verify')

      commit('endLoading')
    } catch (error) {
      commit('endLoading')

      throw new Error(error)
    }
  },

  async connect({ commit }, { groupID }) {
    try {
      const { data } = await api.post(`/usersocnetgroup`, { "group_id": groupID, platform: 'vk-user' })

      commit('setGroupUrl', data.url)
      commit('setVerificationURL', data.verification_link)
    } catch (error) {
      window.addStatusMessage('error', error.message)

      throw new Error(error)
    }
  },

  async disconnect({ commit }) {
    try {
      await api.delete(`/usersocnetgroup?platform=vk-user`)

      commit('clearData')
    } catch (error) {
      window.addStatusMessage('error', error.message)

      throw new Error(error)
    }
  },
  
  async verify({ commit }) {
    try {
      const { data } = await api.post(`/usersocnetgroup/verify`, {
        platform: 'vk-user',
      })
  
      if (!!data.completed) {
        commit('verify')
      } else {
        throw new Error()
      }
    } catch (error) {
      if (error.code && error.code === 1220) {
        window.addStatusMessage('error', window.translateString('The verification link couldn\'t be found at the provided group.'))
      } else {
        window.addStatusMessage('error', error.message)
      }
    }
  },

  clearData({ commit }) {
    commit('clearData')
  },
}