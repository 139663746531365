import { mapFields, mapValue } from '@utils/utils'

export const dataTypeMap = {
  lastDonations: 'latest_donations',
  topDonations: 'top_donations',
  topDonators: 'top_donators',
  overallDonationsAmount: 'total_raised',
  lastSubscribers: 'latest_subscribers',
  subscriptionsNumber: 'subscriptions_number',
  subscribersNumber: 'subscribers_number',
  viewersNumber: 'viewers_number',
  currentMediaName: 'current_media',
  productsSoldNumber: 'merchandise_sold',
}

export const dataPeriodMap = {
  currentStream: 'current-stream',
  lastStream: 'last-stream',
  allTime: 'all-time',
  today: 'current-day',
  last24hours: 'day',
  thisWeek: 'current-week',
  last7days: 'week',
  thisMonth: 'current-month',
  last30days: 'month',
  thisYear: 'current-year',
  custom: 'custom',
}

export const subscribersNumberSourceMap = {
  twitchFollower: 'twitch_follow',
  twitchSubscriber: 'twitch_subscription',
  vkLiveFollower: 'vklive_follow',
  vkLiveSubscriber: 'vklive_subscription',
}

export const viewersNumberSourceMap = {
  vkLiveViewer: 'vklive_viewer',
}

export const subscribersTypesMap = {
  twitchFollower: '6',
  twitchSubscriber: '4',
  twitchPrimeSubscriber: '15',
  twitchGiftSubscriber: '13',
  twitchGiftRenewalSubscriber: '14',
  youtubeSubscriber: '7',
  youtubeSponsor: '10',
  vkSubscriber: '3',
  boostySubscriber: '20'
}

export const mapWidgetSettings = (settings, sourceIsForeign = false) => {
  return {
    ...settings,
    dataType: mapValue(settings.dataType, dataTypeMap, sourceIsForeign),
    dataPeriod: mapValue(settings.dataPeriod, dataPeriodMap, sourceIsForeign),
    subscribersNumberSource: mapValue(settings.subscribersNumberSource, subscribersNumberSourceMap, sourceIsForeign),
    viewersNumberSource: mapValue(settings.viewersNumberSource, viewersNumberSourceMap, sourceIsForeign),
  }
}
