<style lang="scss" scoped>
  .color-gradient-picker {
    display: inline-flex;
    flex-wrap: wrap;
    align-items: center;

    .gradient-picker-container {
      position: relative;

      .gradient-picker-button {
        width: 100%;
      }

      .gradient-settings-popover {
        @include position(absolute, 100%, auto, auto, 0);
        z-index: 50;
        width: 310px;
        //margin-left: -155px;
        padding: 20px;

        @include media(max, md) {
          width: 100%;
          padding: 0 $gutter-mobile $gutter-mobile $gutter-mobile;
        }
      }

      &.top {
        .gradient-settings-popover {
          top: auto;
          bottom: 100%;
        }
      }

      &.left {
        .gradient-settings-popover {
          left: auto;
          right: 0;
        }
      }
    }

    .gradient-helper {
      padding: 5px 0 5px 15px;
    }
  }
</style>

<template>
  <div class="color-gradient-picker" v-click-outside="hidePopover">
    <template v-if="useGradient">
      <div ref="container" class="gradient-picker-container" :class="containerClasses">
        <transition name="fade-up">
          <gradient-settings-popover
            v-if="popoverVisible"
            ref="dropdown"
            @close="hidePopover"
            v-model="gradient"/>
        </transition>
        <btn @click="togglePopover" class="gradient-picker-button" icon-left="palette">
          {{ $t('global.colorPicker.adjustGradient') }}
        </btn>
      </div>
      <div class="gradient-helper">
        <div class="text-link action-link" @click="toggleGradient">
          {{ $t('global.colorPicker.color') }}
        </div>
      </div>
    </template>
    <template v-else>
      <color-picker v-model="color"/>
      <div class="gradient-helper">
        <div class="text-link action-link" @click="toggleGradient">
          {{ $t('global.colorPicker.gradient') }}
        </div>
      </div>
    </template>
  </div>
</template>

<script>

import GradientSettingsPopover from '@components/BaseComponents/ColorPicker/GradientSettingsPopover'

export default {
  name: 'ColorGradientPicker',
  components: { GradientSettingsPopover },
  props: {
    value: {
      type: Object,
      default: null,
    },
    config: {
      type: Object,
      default: () => ({
        labels: {
          color: 'Цвет',
          gradient: 'Градиент',
        },
      }),
    },
  },
  data() {
    return {
      useGradient: false,
      color: '#ffffff',
      gradient: {
        colors: ['#ffffff', '#009dff'],
        angle: 0,
      },

      popoverVisible: false,
      dropdownPosition: 'bottom',
    }
  },
  computed: {
    containerClasses() {
      return {
        [this.dropdownPosition]: !!this.dropdownPosition,
      }
    },

    output() {
      return {
        useGradient: this.useGradient,
        color: this.color,
        gradient: this.gradient,
      }
    },
  },
  created() {
    if (this.value) {
      Object.keys(this.value).forEach(key => {
        this[key] = this.value[key] ?? this[key]
      })
    }
  },
  watch: {
    output(value) {
      this.$emit('input', value)
    },
    $scrollTop: 'calculateDropdownPosition',
    popoverVisible: 'calculateDropdownPosition',
  },
  methods: {
    calculateDropdownPosition() {
      this.$nextTick(() => {
        if (this.popoverVisible && this.$refs.dropdown) {
          const dropdownRect = this.$refs.dropdown.$el.getBoundingClientRect()
          const selectRect = this.$refs.container.getBoundingClientRect()

          if (this.dropdownPosition === 'bottom') {
            this.dropdownPosition = window.innerHeight - dropdownRect.bottom < 0 ? 'top' : 'bottom'
          } else {
            this.dropdownPosition = window.innerHeight - dropdownRect.bottom < dropdownRect.height + selectRect.height + 20 ? 'top' : 'bottom'
          }
        }
      })
    },

    toggleGradient() {
      this.useGradient = !this.useGradient
    },

    togglePopover() {
      this.popoverVisible = !this.popoverVisible
    },

    hidePopover() {
      this.popoverVisible = false
    },
  },
}
</script>
