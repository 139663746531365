export const state = {
  isGlobalLoading: false,
  offers: [],
}

export const getters = {
  isGlobalLoading: ({ isGlobalLoading }) => isGlobalLoading,
  offers: ({ offers }) => offers,
}

export const mutations = {
  startGlobalLoading(state) {
    state.isGlobalLoading = true
  },

  endGlobalLoading(state) {
    state.isGlobalLoading = false
  },

  setOffers(state, { data }) {
    state.offers = data.filter(item => item.bonus_type === 'note')
  },

  clearOffers(state) {
    state.offers = []
  },
}

export const actions = {
  async fetchOffers({ state, commit }) {
    commit('startGlobalLoading')

    try {
      const { data } = await api.get(`/advoffer?as=advertiser`)

      commit('setOffers', { data })
      commit('endGlobalLoading')
    } catch (error) {
      commit('endGlobalLoading')
      throw new Error(error)
    }
  },

  clearOffers({ commit }) {
    commit('clearOffers')
  },
}