import { i18n } from '@src/localization/config'

export const state = {
  goalModalOpened: false,
  openedGoalId: null,

  // Goals lists
  activeGoalsLoading: false,
  pastGoalsLoading: false,
  activeGoalsList: [],
  pastGoalsList: [],
}

export const getters = {
  getPreviewGoal: (state, getters, rootState, rootGetters) => {
    const firstActiveGoalId = state.activeGoalsList[0]

    const goal = rootGetters['goal/goals/getGoal'](firstActiveGoalId) || {
      title: i18n.t('goals.goalPlaceholder'),
      currency: 'RUB',
      goalAmount: 100000,
      expiresAt: dates.timestamp() + 1000000,
    }

    const raisedAmount = Math.round(goal.goalAmount / 2)

    const percentage = raisedAmount / goal.goalAmount * 100

    return {
      ...goal,
      raisedAmount,
      progressPercentage: percentage < 1 ? (percentage > 0 ? 1 : 0) : Math.floor(percentage),
    }
  },
}

export const mutations = {
  SET_GOAL_MODAL_OPENED(state, {
    id, opened,
  }) {
    state.openedGoalId = id
    state.goalModalOpened = opened
  },

  SET_ACTIVE_GOALS_LOADING(state, value) {
    state.activeGoalsLoading = value
  },

  SET_PAST_GOALS_LOADING(state, value) {
    state.pastGoalsLoading = value
  },

  SET_ACTIVE_GOALS_LIST(state, value) {
    state.activeGoalsList = value
  },

  SET_PAST_GOALS_LIST(state, value) {
    state.pastGoalsList = value
  },
}

export const actions = {
  openGoalModal({ commit }, id) {
    commit('SET_GOAL_MODAL_OPENED', {
      id,
      opened: true,
    })
  },

  closeGoalModal({ commit }) {
    commit('SET_GOAL_MODAL_OPENED', {
      id: null,
      opened: false,
    })
  },

  loadActiveGoals({ dispatch, commit }) {
    commit('SET_ACTIVE_GOALS_LOADING', true)

    return dispatch('goal/goals/fetchGoals', { isActive: true }, { root: true })
      .then((data) => {
        commit('SET_ACTIVE_GOALS_LIST', data.goals)

        return data
      })
      .finally(() => {
        commit('SET_ACTIVE_GOALS_LOADING', false)
      })
  },

  loadPastGoals({ dispatch, commit }, props) {
    commit('SET_PAST_GOALS_LOADING', true)

    return dispatch('goal/goals/fetchGoals', {
      ...props,
      isActive: false,
    }, { root: true })
      .then((data) => {
        commit('SET_PAST_GOALS_LIST', data.goals)

        return data
      })
      .finally(() => {
        commit('SET_PAST_GOALS_LOADING', false)
      })
  },
}
