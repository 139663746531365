<style scoped lang="scss">
  .bft-download-link {

  }
</style>

<template>
  <btn
    variant="link"
    icon-left="download"
    class="primary bft-download-link"
    @click="downloadApp">
    {{ $t('bft.settings.stubButton') }}
  </btn>
</template>

<script>
export default {
  name: 'BftDownloadLink',
  methods: {
    downloadApp() {
      this.$tmr.goal({ goal: 'chaostricks_download_app_clicked' })

      const appLink = 'https://chaostricks.com/files/ChaosTricksSetup_ru.exe'

      window.open(appLink, '_blank')
    }
  }
}
</script>


