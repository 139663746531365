<style scoped lang="scss">
  .widget-roulette-background {
    transition-timing-function: cubic-bezier(0.25, 1, 0.5, 1);
    background-repeat: repeat;

    &.animated {
      &.parallax {
        background-position-x: right;
      }
    }
  }
</style>

<template>
  <div
    v-if="background"
    class="widget-roulette-background"
    :style="background.styles"
    :class="background.classes"/>
</template>

<script>

import {
  createCssFilters,
  createGradientString,
} from '@utils/utils'

export default {
  name: 'WidgetRouletteBackground',
  props: {
    settings: {
      type: Object,
      default: null,
    },
    spinTime: {
      type: Number,
      default: 10,
    },
    spin: {
      type: Boolean,
      default: false,
    },
    win: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('fileManager', ['getFile']),

    backgroundImage() {
      const {
        backgroundImage,
      } = this.settings

      this.fetchFile(backgroundImage)

      return this.getFile(backgroundImage)
    },

    background() {
      if (!_.get(this.settings, 'backgroundType', null)) {
        return null
      }

      const {
        backgroundImageParallax,
        backgroundImageHue,
        backgroundImageSaturate,
        backgroundType,
        backgroundColor = {},
        backgroundShadow,
        backgroundBorderRadius,
      } = this.settings

      const { color = null, gradient = {}, useGradient = false } = backgroundColor
      const { colors = [], angle = 0, hard = false } = gradient

      const styles = {
        borderRadius: `${backgroundBorderRadius}px`,
        transitionDuration: this.spin ? `${this.spinTime}s` : null,
      }

      if (backgroundType === 'image' && this.backgroundImage) {
        styles.backgroundImage = `url(${this.backgroundImage.url})`
        styles.filter = createCssFilters({
          shadow: backgroundShadow,
          hue: backgroundImageHue,
          saturate: backgroundImageSaturate,
        })
      }
      if (backgroundType === 'color') {
        styles.backgroundImage = useGradient
          ? `linear-gradient(${angle}deg, ${createGradientString(colors, hard)})`
          : null
        styles.backgroundColor = useGradient ? null : color
        styles.filter = createCssFilters({
          shadow: backgroundShadow,
        })
      }

      return {
        styles,
        classes: {
          animated: this.spin || this.win,
          parallax: backgroundImageParallax,
        },
      }
    },
  },
}
</script>
