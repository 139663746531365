<style scoped lang="scss">
  .past-goals-list {
    position: relative;
    min-height: 120px;

    .loader-container {
      @include position-all(absolute, 0);
      z-index: 15;
      background: rgba($black, .9);
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 15px;
    }

    .goals-list {
      margin-top: 15px;

      .list-row {
        display: grid;
        grid-template-columns: 3fr 2fr 2fr;

        @include media(max, md) {
          display: block;
        }

        &.list-header {
          padding: 12px 20px;
          color: $warm-grey;

          @include media(max, md) {
            display: none;
          }
        }

        &.list-item {
          padding: 20px;
          min-height: 90px;

          @include media(max, md) {
            padding: 15px;
          }

          &:nth-child(even) {
            border-radius: 3px;
            background-color: $additional-3;
          }

          .goal-info {
            .goal-title {
              font-size: 16px;
              font-weight: 600;
            }

            .goal-raised {
              margin-top: 10px;

              .amount.reached {
                color: var(--theme-color-main);
              }
            }
          }

          .goal-started-at, .goal-expires-at {
            color: $warm-grey;

            .mobile-text {
              display: none;
              margin-bottom: 10px;
              line-height: 1;
            }

            @include media(max, md) {
              margin-top: 15px;

              .mobile-text {
                display: block;
              }

              .time {
                color: white;
              }
            }
          }
        }
      }
    }

    .pagination {
      margin-top: 30px;
      justify-content: center;
    }
  }
</style>

<template>
  <div class="past-goals-list">
    <div class="past-goals-count helper-text">{{goalsAmountText}}</div>
    <transition name="fade">
      <div v-if="pastGoalsLoading" class="loader-container">
        <loader size="lg"/>
      </div>
    </transition>
    <template v-if="!goalHistoryIsEmpty">
      <div class="goals-list">
        <div class="list-row list-header">
          <div class="goal-info">{{$t('goals.pastGoals.donationGoal')}}</div>
          <div class="goal-started-at">{{$t('goals.pastGoals.startDate')}}</div>
          <div class="goal-expires-at">{{$t('goals.pastGoals.endDate')}}</div>
        </div>
        <div
          v-for="goal in goals"
          :key="goal.id"
          class="list-row list-item">
          <div class="goal-info">
            <div class="goal-title">{{goal.title}}</div>
            <div class="goal-raised">
              <span class="helper-text">{{$t('goals.pastGoals.raised')}}</span>
              <span
                class="amount"
                :class="{reached: goal.raisedAmount >= goal.goalAmount}">
            {{goal.raisedAmount}} / {{goal.goalAmount}} {{goal.currency}}
          </span>
            </div>
          </div>
          <div v-if="goal.startedAt" class="goal-started-at">
            <div class="mobile-text">{{$t('goals.pastGoals.startDate')}}</div>
            <div class="time">{{goal.startedAtFormatted}}</div>
          </div>
          <div v-if="goal.expiresAt" class="goal-expires-at">
            <div class="mobile-text">{{$t('goals.pastGoals.endDate')}}</div>
            <div class="time">{{goal.expiresAtFormatted}}</div>
          </div>
        </div>
      </div>
      <pagination
        v-if="pagesCount > 1"
        v-model="page"
        :disabled="pastGoalsLoading"
        :current-page="page"
        :pages-count="pagesCount"/>
    </template>
  </div>
</template>

<script>
import Pagination from '@components/BaseComponents/Pagination'

export default {
  name: 'PastGoalsList',
  components: { Pagination },
  data() {
    return {
      page: 1,
      pagesCount: null,
    }
  },
  watch: {
    page: {
      handler() {
        this.loadGoalsList()
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions('goal/settings', ['loadPastGoals']),

    loadGoalsList() {
      return this.loadPastGoals({ page: this.page })
        .then(({ pagination }) => {
          this.pagesCount = pagination.pagesCount
          this.total = pagination.total
        })
    },
  },
  computed: {
    ...mapGetters('goal/goals', ['getGoal']),
    ...mapState('goal/settings', ['pastGoalsLoading', 'pastGoalsList']),

    goals() {
      return this.pastGoalsList.map(this.getGoal)
    },

    goalHistoryIsEmpty() {
      return !this.pastGoalsLoading && !this.goals.length
    },

    goalsAmountText() {
      const string = this.$tc('goals.pastGoals.goalsFound_plural', this.goals.length, { goals: this.goals.length })

      return this.pastGoalsLoading ? this.$t('goals.pastGoals.refreshingData')
        : (this.goalHistoryIsEmpty ? this.$t('goals.pastGoals.goalHistoryEmpty')
          : string || '')
    },
  },
}
</script>
