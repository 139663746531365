<style scoped lang="scss">
  .roulette-card-background {
    display: flex;
    align-items: center;
    justify-content: center;

    .card-background-image {
      max-width: 100%;
      max-height: 100%;
    }
  }
</style>

<template>
  <div v-if="background" class="roulette-card-background">
    <img
      v-if="background.type === 'image' && background.image"
      class="card-background-image"
      :src="background.image.src"
      :style="background.image.styles"/>
    <div
      v-if="background.type === 'color'"
      class="card-background-color"
      :style="background.color.styles">
    </div>
  </div>
</template>

<script>
import {
  createCssFilters,
  createGradientString,
} from '@utils/utils'

export default {
  name: 'RouletteCardBackground',
  props: {
    settings: {
      type: Object,
      default: null,
    },
  },
  computed: {
    ...mapGetters('fileManager', ['getFile']),

    backgroundImage() {
      const {
        backgroundImage,
      } = this.settings

      this.fetchFile(backgroundImage)

      return this.getFile(backgroundImage)
    },

    background() {
      if (!_.get(this.settings, 'backgroundType', null)) {
        return null
      }

      const {
        backgroundType,
        backgroundImageHue,
        backgroundImageSaturate,
        backgroundColor = {},
        backgroundShadow,
        backgroundSize,
        backgroundBorderRadius,
      } = this.settings

      const { color = null, gradient = {}, useGradient = false } = backgroundColor
      const { colors = [], angle = 0, hard = false } = gradient

      const background = {
        type: backgroundType,
      }

      if (backgroundType === 'image' && this.backgroundImage) {
        background.image = {
          styles: {
            filter: createCssFilters({
              shadow: backgroundShadow,
              hue: backgroundImageHue,
              saturate: backgroundImageSaturate,
            }),
            borderRadius: `${backgroundBorderRadius}px`,
            maxWidth: `${backgroundSize}px`,
            maxHeight: `${backgroundSize}px`,
          },
          src: this.backgroundImage.url,
        }
      }

      if (backgroundType === 'color') {
        const styles = {
          filter: createCssFilters({
            shadow: backgroundShadow,
          }),
          borderRadius: `${backgroundBorderRadius}px`,
          width: `${backgroundSize}px`,
          height: `${backgroundSize}px`,
        }

        if(useGradient){
          styles.backgroundImage = `linear-gradient(${angle}deg, ${createGradientString(colors, hard)})`
        } else {
          styles.backgroundColor = color
        }

        background.color = {
          styles
        }
      }

      return background
    },
  },
}
</script>
