<style lang="scss" scoped>
  .general-settings-page {
    .tabs-content {
      margin-top: 25px;
    }
  }
</style>

<template>
  <div class="general-settings-page">
    <tabs v-model="currentTab" :list="tabs"/>

    <div class="tabs-content">
      <router-view/>
    </div>
  </div>
</template>

<script>

export default {
  name: 'GeneralSettingsPage',
  data() {
    return {
      loadingState: {
        page: false,
      },

      currentTab: 'account',
    }
  },
  metaInfo() {
    return {
      title: this.$t('dashboard.pages.generalSettings.generalSettings'),
    }
  },
  computed: {
    ...mapGetters('profile', ['getUserIsStreamer', 'getUserIsViewer']),

    tabs() {
      const tabs = [
        {
          icon: 'person',
          key: 'account',
          title: this.$t('dashboard.pages.generalSettings.account'),
        },
      ]

      if (this.getUserIsStreamer) {
        tabs.push({
          icon: 'link',
          key: 'links',
          title: this.$t('dashboard.pages.generalSettings.links'),
        })
        tabs.push({
          icon: 'widgets',
          key: 'apps',
          title: this.$t('dashboard.pages.generalSettings.apps'),
        })
      }

      return tabs
    },
  },
  watch: {
    currentTab(value) {
      const { tab } = this.$route.meta

      if (value !== tab) {
        this.$router.push({ name: `dashboard-general-settings-${value}` })
      }
    },
    $route: {
      handler(value) {
        const { tab } = value.meta
        this.currentTab = tab
      },
      immediate: true,
    },
  },
}
</script>
