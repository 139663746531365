<template>
  <div>
    <div
      v-if="offer.brand.image"
      style="margin: 20px 0;"
    >
      <div class="image">
        <img :src="offer.brand.image">
      </div>
    </div>

    <div
      v-if="offer.offer_user && offer.offer_user[0] && !offer.offer_user[0].options && offer.offer_user[0].status === 'processing'"
      class="content-text processing-box"
    >
      <span>Обрабатываем вашу заявку. Обновите страницу через пару минут.</span><br>
      <span>При возникновении неполадок обратитесь в <a target="_blank" href="/help">техподдержку</a>.</span>
    </div>

    <DetailsBrandInfo
      :brandName="offer.brand.title"
      :dates="transformDate(offer.start_at_ts) + ' - ' + (offer.end_at_ts ? transformDate(offer.end_at_ts) : '—')"
      :bonusType="offer.bonus_type"
    />

    <div
      class="offer-info"
      style="margin: 20px 0;"
      v-if="offer.offer_user && offer.offer_user[0] && offer.bonus_type === 'activation' && offer.offer_user[0].options && offer.offer_user[0].options.dispatchable"
    >
      <span class="offer-info__title">{{ $t('offers.attributes.affiliatePromocode') }}</span>

      <div class="link-container">
        <div class="link">
          <div
            class="link__form"
            @click="copyLink(offer.offer_user[0].options.dispatchable)"
          >{{ offer.offer_user[0].options.dispatchable }}
          </div>

          <div class="link__button" @click="copyLink(offer.offer_user[0].options.dispatchable)">
            <icon name="copy"/>
          </div>
        </div>
      </div>
    </div>

    <DetailsPartnerInfo
      v-model="selectedLandingId"
      :landingOptions="landingOptions"
      :currentOffer="filteredLandings.filter(l => l.id === selectedLandingId)[0]"
      :offerOptions="offer.options"
      v-if="offer.offer_user && offer.offer_user[0] && offer.bonus_type === 'action' && offer.offer_user[0].options && filteredLandings.length && selectedLandingId && offer.offer_user[0].status !== 'processing'"
    />

    <div
      v-if="offer.requirements"
      style="margin-bottom: 20px;"
    >
      <Title type="5">{{$t('offers.attributes.requirements')}}</Title>

      <div
        class="content-text"
        v-html="offer.requirements"
      />
    </div>

    <div
      v-if="offer.oferta || offer.description"
      style="margin-bottom: 20px;"
    >
      <Title type="5">{{$t('offers.attributes.description')}}</Title>

      <div
        class="content-text"
        v-if="offer.oferta"
        v-html="offer.oferta"
      />

      <div
        v-if="!offer.oferta"
      >{{ offer.description }}
      </div>
    </div>

    <div
      style="margin-bottom: 20px;"
      v-if="offer.links && offer.links.length"
    >
      <Title type="5">{{$t('offers.attributes.links')}}</Title>

      <div class="content-text">
        <ul>
          <li
            v-for="(value, i) in offer.links"
            :key="i"
          ><a :href="value">{{ value }}</a></li>
        </ul>
      </div>
    </div>

    <div
      style="margin-bottom: 20px;"
      v-if="offer.files.length"
    >
      <Title type="5">{{$t('offers.attributes.additionalMaterial')}}</Title>

      <div>
        <File
          v-for="(file, i) in offer.files"
          :key="i"
          :link="file.link"
          :size="file.size"
          :name="$t('offers.download')"
          style="margin-right: 10px;"
        />
      </div>
    </div>

    <div
      v-if="!(offer.offer_user && offer.offer_user[0])"
      style="margin-top: 30px;"
    >
      <label class="checkbox">
        <input type="checkbox" v-model="terms">
        <span class="checkbox__controll">
          <icon name="check"/>
        </span>
        <span class="checkbox__text">{{ $t('offers.acceptTheTerms') }}</span>
      </label>

    </div>

    <div
      v-if="!(offer.offer_user && offer.offer_user[0])"
      class="accept-button-group"
    >
      <Button
        variant="primary-action"
        :isLoading="acceptBtnLoading"
        @click="toAccept"
        :disabled="!terms"
        class="tmp-btn"
      >
        <span v-if="offer.is_premoderated">{{ $t('offers.sendApplication') }}</span>
        <span v-else>{{ $t('offers.attributes.acceptTheOffer') }}</span>
      </Button>

      <div class="button-sign" v-if="offer.is_premoderated" v-html="$t('offers.applicationSentDesc').split('%s').join('<br>')"></div>
    </div>

    <modal v-model="isShowOnApprovalMessage" @close="closeAcceptPopup">
      <template slot="body-content">
        <div class="accept_message">
          <div class="img"></div>

          <h2>{{ $t('offers.applicationSentSuccessfully') }}</h2>

          <p v-html="$t('offers.applicationSentWait').split('%s').join('<br>')"></p>

          <Button
            class="tmp-btn"
            variant="secondary-action"
            @click="closeAcceptPopup"
          >{{ $t('offers.oku') }}
          </Button>
        </div>
      </template>
    </modal>

    <modal v-model="errorMessageModalOpened">
      <template slot="body-content">
        <div class="accept_message">
          <icon
            name="warning-red"
            style="font-size: 64px; margin: 20px 0;"
          />

          <p v-if="errorMessageModal === 2001">{{ $t('offers.acceptError.allSettings') }}</p>
          <p v-if="errorMessageModal === 2002">{{ $t('offers.acceptError.codeSettings') }}</p>
          <p v-if="errorMessageModal === 2003">{{ $t('offers.acceptError.costSettings') }}</p>

          <Button
            variant="primary-action"
            @click="goToSettings"
            class="tmp-btn"
            iconLeft="gear"
          >
            <span>{{ $t('offers.acceptError.settingsButton') }}</span>
          </Button>
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
import fromUnixTime from 'date-fns/fromUnixTime'
import { copyToClipboard } from '@utils/utils'

import Title from '@components/BaseComponents/Title'
import BaseButton from '@components/BaseComponents/BaseButton'
import Reward from '@components/Dashboard/Offers/Reward'
import Status from '@components/Dashboard/Offers/Status'
import File from '@components/Dashboard/Offers/File'
import Modal from '@components/BaseComponents/Modal'

import DetailsBrandInfo from '@components/Dashboard/Offers/DetailsBrandInfo'
import DetailsPartnerInfo from '@components/Dashboard/Offers/DetailsPartnerInfo'


export default {
  name: 'OffersDetailsDescPage',

  components: {
    Title,
    Button: BaseButton,
    Reward,
    Status,
    File,
    Modal,

    DetailsBrandInfo,
    DetailsPartnerInfo,
  },

  data() {
    return {
      selectedLandingId: null,
      terms: false,
    }
  },

  created() {
    if (this.offer.offer_user && this.offer.bonus_type === 'action' && this.offer.offer_user[0].options) {
      this.selectedLandingId = this.offer.offer_user[0].options.links.filter(landing => landing.is_show_in_description)[0].id
    }
  },

  methods: {
    ...mapActions('offers/details', [
      'toAcceptOffer',
      'hideOnApprovalMessage',
      'clearErrorMessageModal',
    ]),

    transformDate(val) {
      const timeZone = this.getTimezone

      const result = dates.unixToFormat(dates.utcToZonedTime(fromUnixTime(val), timeZone) / 1000, dates.DEFAULT_TIME_FORMAT)

      return result.split(' ')[0]
    },

    toAccept() {
      this.toAcceptOffer({ offer_id: this.offer.id })
    },

    copyLink(text) {
      copyToClipboard(text)
    },

    closeAcceptPopup() {
      this.hideOnApprovalMessage()

      window.location.reload()
    },

    goToSettings() {
      this.clearErrorMessageModal()
      if (this.newDashboard) {
        this.$router.push({ name: 'dashboard-offers-settings' })
      } else {
        this.$router.push({ path: '/dashboard/offers-v2/settings' })
      }
    },
  },

  computed: {
    ...mapState('dashboard', ['newDashboard']),

    ...mapGetters('profile', [
      'getTimezone',
    ]),

    ...mapGetters('offers/details', [
      'acceptBtnLoading',
      'offer',
      'isShowOnApprovalMessage',
      'errorMessageModal',
    ]),

    filteredLandings() {
      if (this.offer.bonus_type === 'action') {
        return this.offer.offer_user[0].options.links.filter(landing => landing.is_show_in_description)
      }

      return this.offer.offer_user[0].options.links
    },

    landingOptions() {
      if (this.offer.bonus_type !== 'action' && !this.offer.offer_user[0].options) {
        return
      }

      const options = this.offer.options ? this.offer.offer_user[0].options.links.filter(landing => landing.is_show_in_description) : []

      return options
        .map(option => ({
          value: option.id,
          label: option.title,
        }))
    },

    errorMessageModalOpened: {
      get() {
        return !!this.errorMessageModal
      },
      set(value) {
        if (!value) {
          this.clearErrorMessageModal()
        }
      },
    },
  },
}
</script>

<style scoped lang="scss">
  .image {
    overflow: hidden;
    background-color: #262626;
    width: 300px;
    border-radius: 5px;

    img {
      display: block;
      width: 100%;
    }
  }

  .processing-box {
    position: relative;
    background-color: #262626;
    background-color: rgba(#f59c07, .2);
    border-radius: 5px;
    padding: 10px;
    font-size: 14px;
    cursor: default;
    margin: 20px 0;

    @media (min-width: 720px) {
      max-width: 600px;
    }
  }

  .list {
    list-style: none;
    padding: 0;

    &__item {
      padding-left: 20px;
      position: relative;
      margin-bottom: 5px;

      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 6px;
        left: 0;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: var(--theme-color-main);
      }
    }
  }

  .alert-box {
    border-radius: 5px;
    font-size: 14px;
    // display: inline-block;
    padding: 15px 20px;
    max-width: 640px;

    span {
      cursor: default;
    }

    p {
      margin-top: 10px;
      opacity: .8;
    }

    &_warning {
      background-color: rgba(#ffbc00, .1);
      color: #ffbc00;
    }

    &_block {
      background-color: rgba(#d63a3a, .1);
      color: #d63a3a;
    }
  }

  .content-text {
    color: #ffffff;
    font-size: 14px;

    a {
      color: var(--theme-color-main);
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    ul {
      list-style: none;
      padding: 0;

      li {
        padding-left: 20px;
        position: relative;
        margin-bottom: 5px;

        &:before {
          content: '';
          display: block;
          position: absolute;
          top: 6px;
          left: 0;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background-color: var(--theme-color-main);
        }
      }
    }
  }

  .link {
    margin: 10px;
    display: inline-block;
    overflow: hidden;
    position: relative;
    max-width: 90%;

    &__form {
      display: block;
      width: 100%;
      line-height: 20px;
      padding: 0 30px 0 0;
      background-color: transparent;
      border: 0;
      color: var(--theme-color-main);
      white-space: nowrap;
    }

    &__button {
      position: absolute;
      top: 0;
      right: 0;
      background-image: linear-gradient(90deg, transparent, #262626);
      padding-left: 15px;

      & > svg {
        display: block;
        fill: url(#theme_svg_gradient);
        height: 20px !important;
        width: 17px !important;
        cursor: pointer;
      }
    }
  }

  .offer-info {
    position: relative;
    background-color: #262626;
    border-radius: 5px;
    // overflow: hidden;
    padding: 10px;
    @media (min-width: 720px) {
      max-width: 600px;
    }

    hr {
      display: block;
      height: 1px;
      background-color: #1f1f1f;
      margin-right: -10px;
      margin-left: -10px;
      margin-top: 10px;
      margin-bottom: 10px;
      border: 0;
    }

    &__title {
      display: block;
      color: #f1f1f1;
      font-size: 14px;
      font-weight: 500;
      padding: 10px;
    }

    &__box {
      padding: 10px;

      &__title {
        color: #838383;
        display: block;
        margin-bottom: 10px;
      }

      &__content {
        font-size: 14px;
        font-weight: 500;
        color: #ffffff;
      }
    }
  }

  @media (min-width: 720px) {
    .state-container {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      &_left {
        justify-content: flex-start;
      }
    }


    .brand-container {
      display: flex;
      justify-content: space-between;
      align-items: stretch;
    }
  }

  .brand-container {
    .offer-info__box {
      border-bottom: 1px solid #1f1f1f;

      &:last-child {
        border-bottom: 0;
      }

      @media (min-width: 720px) {
        border-bottom: 0;
        border-right: 1px solid #1f1f1f;
        padding: 10px;
        flex-grow: 1;

        &:last-child {
          border-right: 0;
        }
      }
    }
  }

  .cost {
    color: var(--theme-color-main);
  }

  .landing-desc {
    padding: 0 10px 10px 10px;
    color: #838383;
  }

  .checkbox {
    display: inline-block;
    line-height: 18px;

    input {
      display: none;
    }

    &__controll {
      width: 18px;
      height: 18px;
      border-radius: 4px;
      background: #464646;
      margin-right: 10px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      float: left;

      svg {
        display: none;
      }
    }

    &:hover {
      .checkbox__controll {
        background: #7a7777;
      }

      .checkbox__text {
        color: var(--theme-color-main);
      }
    }

    input:checked + .checkbox__controll {
      background: var(--theme-color-main);

      svg {
        display: block;
      }
    }
  }

  .accept-button-group {
    margin-top: 30px;
    display: flex;
    align-items: center;

    .button-sign {
      color: #838383;
      font-size: 12px;
      line-height: 16px;
      margin-left: 24px;
      cursor: default;
    }
  }

  button.tmp-btn {
    border-radius: 8px;
  }

  .accept_message {
    text-align: center;
    padding: 30px;

    .img {
      display: inline-block;
      width: 107px;
      height: 103px;
      background-image: appAsset('/images/icons/offer_accept_popup.png');
      background-position: center center;
      background-size: 100%;
      margin-bottom: 20px;
    }

    h2 {
      color: #F1F1F1;
      font-size: 24px;
      line-height: 30px;
      margin-bottom: 15px;
    }

    p {
      color: #919191;
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 30px;
    }
  }
</style>
