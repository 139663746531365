<style scoped lang="scss">
  .background-settings {

  }
</style>

<template>
  <div class="background-settings">
    <form-group inline :label="$t('global.settings.labels.backgroundColor')">
      <color-gradient-picker v-model="backgroundColor"/>
    </form-group>
    <form-group inline :label="$t('global.settings.labels.borderRadius')">
      <base-range v-model="borderRadius" :min="0" :max="20" input input-addon="px"/>
    </form-group>
    <form-group inline :label="$t('global.settings.labels.borderWidth')">
      <base-range v-model="borderWidth" :min="0" :max="10" input input-addon="px"/>
    </form-group>
    <form-group v-if="borderWidth" inline :label="$t('global.settings.labels.borderColor')">
      <color-gradient-picker v-model="borderColor"/>
    </form-group>
    <form-group inline :label="$t('global.settings.labels.shadow')">
      <shadow-styler v-model="shadow"/>
    </form-group>
  </div>
</template>

<script>

import ColorGradientPicker from '@components/BaseComponents/ColorPicker/ColorGradientPicker'
import ShadowStyler from '@components/BaseComponents/ShadowStyler/ShadowStyler'

export default {
  name: 'BackgroundSettings',
  components: {
    ShadowStyler,
    ColorGradientPicker,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      backgroundColor: null,
      borderRadius: 20,
      borderWidth: 0,
      borderColor: null,
      shadow: null,
    }
  },
  computed: {
    output() {
      return {
        backgroundColor: this.backgroundColor,
        borderRadius: this.borderRadius,
        borderWidth: this.borderWidth,
        borderColor: this.borderColor,
        shadow: this.shadow,
      }
    },
  },
  watch: {
    output(value) {
      this.$emit('input', value)
    },

    value: {
      handler(value) {
        if (value) {
          Object.keys(value).forEach(key => {
            this[key] = value[key]
          })
        }
      },
      immediate: true,
    },
  },
}
</script>
