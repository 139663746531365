<style scoped lang="scss">
  .goal-form {
    width: 550px;
    position: relative;
    padding-bottom: 30px;

    @include media(max, md) {
      width: 100%;
    }

    .loader-container {
      @include position-all(absolute, 0);
      z-index: 15;
      background: $black;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .form-controls {
      margin-top: 50px;
      display: flex;
      align-items: center;

      .button + .button {
        margin-left: 20px;
      }
    }

    .info-box {
      margin-top: 20px;
    }
  }
</style>

<template>
  <div class="goal-form">
    <transition name="fade">
      <div v-if="loadingState.data" class="loader-container">
        <loader size="lg"/>
      </div>
    </transition>
    <form-group
      inline
      :label="$t('goals.goalForm.goalTitle')">
      <base-input v-model="title"/>
    </form-group>
    <form-group
      inline
      :label="$t('goals.goalForm.goalAmount')">
      <input-addon v-model="goalAmount" :addon="goalCurrency" @change="onChange"/>
    </form-group>
    <form-group
      inline
      :label="$t('goals.goalForm.startAmount')">
      <input-addon v-model="startAmount" :addon="goalCurrency" @change="onChange"/>
    </form-group>
    <form-group
      inline
      :label="$t('goals.goalForm.expiresAt')">
      <date-time-picker v-model="expiresAt" clocks/>
    </form-group>

    <info-box v-if="error" variant="error">{{error}}</info-box>

    <div class="form-controls">
      <btn
        v-if="goalId"
        variant="primary-action"
        icon-left="check"
        :is-loading="loadingState.update"
        @click="submitUpdate">{{$t('global.common.save')}}
      </btn>
      <btn
        v-else
        variant="primary-action"
        icon-left="check"
        :is-loading="loadingState.create"
        @click="submitCreate">{{$t('global.common.add')}}
      </btn>
      <btn
        :disabled="loadingState.create || loadingState.update"
        variant="secondary-action"
        @click="close">{{$t('global.common.cancel')}}
      </btn>
    </div>
  </div>
</template>

<script>

export default {
  name: 'GoalForm',
  data() {
    return {
      loadingState: {
        data: false,
        create: false,
        update: false,
      },

      title: null,
      goalAmount: 10000,
      startAmount: 0,
      expiresAt: null,
      currency: null,

      error: null,
      errors: {},
    }
  },
  created() {
    if (this.goalId) {
      this.loadingState.data = true

      this.fetchGoal(this.goalId)
        .then(() => {
          this.title = this.goal.title
          this.goalAmount = this.goal.goalAmount
          this.startAmount = this.goal.startAmount
          this.expiresAt = this.goal.expiresAt
          this.currency = this.goal.currency
        })
        .finally(() => {
          this.loadingState.data = false
        })
    }
  },
  computed: {
    ...mapGetters('goal/goals', ['getGoal']),
    ...mapGetters('profile', ['getUserCurrency']),
    ...mapState('goal/settings', { goalId: 'openedGoalId' }),

    goal() {
      return this.getGoal(this.goalId)
    },

    goalCurrency() {
      return this.currency || this.getUserCurrency
    },

    preparedData() {
      return {
        title: this.title,
        goalAmount: this.goalAmount,
        startAmount: this.startAmount,
        expiresAt: this.expiresAt,
        currency: this.goalCurrency,
      }
    },
  },
  methods: {
    ...mapActions('goal/goals', [
      'fetchGoal',
      'createGoal',
      'updateGoal',
    ]),
    ...mapActions('goal/settings', [
      'closeGoalModal',
      'loadActiveGoals',
      'loadPastGoals',
    ]),

    close() {
      this.closeGoalModal()
    },

    emitSuccess() {
      this.loadActiveGoals()
      this.loadPastGoals()
      //TODO: emit alert
      this.close()
    },

    onChange() {
      if (!this.goalAmount) {
        this.goalAmount = 0
      }

      if (!this.startAmount) {
        this.startAmount = 0
      }
    },

    validateData() {
      this.error = null
      this.errors = {}
    },

    submitUpdate() {
      this.loadingState.update = true

      this.validateData()

      this.updateGoal({
        id: this.goalId,
        ...this.preparedData,
      })
        .then(() => {
          this.emitSuccess()
        })
        .catch(err => {
          this.error = err.message
        })
        .finally(() => {
          this.loadingState.update = false
        })
    },

    submitCreate() {
      this.loadingState.create = true

      this.validateData()

      this.createGoal({
        ...this.preparedData,
      })
        .then(() => {
          this.emitSuccess()
        })
        .catch(err => {
          this.error = err.message
        })
        .finally(() => {
          this.loadingState.create = false
        })
    },
  },
}
</script>
