import Vue from 'vue'
import { flatten } from 'flat'


const STORAGE_WIDGET_ID = 'da.goal.widget_id'

export const state = {
  userWidgetId: window.donation_goal_widget_id || storage.getItem(STORAGE_WIDGET_ID),

  designPresets: {},

  currentPresetId: null,

  widgetSettings: {
    positions: {},
  },

  widgetStyles: {
    filler: {},
    progressBar: {},
    title: {},
    progress: {},
    timeLeft: {},
    frames: {},
  },
}

export const getters = {
  getUserWidgetId: state => state.userWidgetId,

  getPreset: state => id => state.designPresets[id],

  getPresets: state => _.sortBy(Object.values(state.designPresets), preset => preset.id === 'user' || preset.id + 10),

  getCurrentPreset: (state, getters) => getters.getPreset(state.currentPresetId),

  getCurrentSettings: (state, getters) => {
    return {
      ...getters.getCurrentPreset.settings,
      ...state.widgetSettings,
    }
  },

  getCurrentDesign: (state, getters) => {
    if (!getters.getCurrentPreset) {
      return null
    }

    const gradientOrBackgroundColor = (styles) => {
      const { gradient, useGradient = null } = styles || {}
      const _useGradient = useGradient === null ? !!gradient : useGradient

      return {
        gradient: _useGradient ? styles.gradient : null,
        backgroundColor: _useGradient ? null : styles.backgroundColor,
        useGradient: undefined,
      }
    }

    const settings = state.widgetSettings
    const styles = state.widgetStyles

    const { title, progress, timeleft, frames } = getters.getCurrentPreset.styles

    return {
      scheme: getters.getCurrentPreset.scheme,
      settings,
      styles: {
        title: settings.positions.title ? styles.title : title,
        progress: settings.positions.progressText ? styles.progress : progress,
        timeLeft: settings.positions.timeLeft ? styles.timeLeft : timeleft,
        frames: settings.framesEnabled ? styles.frames : frames,
        filler: {
          ...styles.filler,
          ...gradientOrBackgroundColor(styles.filler),
        },
        progressBar: {
          ...styles.progressBar,
          ...gradientOrBackgroundColor(styles.progressBar),
        },
      },
    }
  },

  getCurrentStyles: (state, getters) => {
    return {
      ...getters.getCurrentPreset.styles,
      ...getters.getCurrentDesign.styles,
    }
  },
}

export const mutations = {
  SET_USER_WIDGET_ID(state, id) {
    state.userWidgetId = id
  },

  SET_SETTINGS(state, settings) {
    state.widgetSettings = settings
  },

  SET_WIDGET_STYLES(state, styles) {
    Vue.set(state, 'widgetStyles', styles)
  },

  SET_BLOCK_STYLE(state, { block, style }) {
    Vue.set(state.widgetStyles, block, style)
  },

  SET_CURRENT_DESIGN_PRESET(state, id) {
    state.currentPresetId = id
  },

  SET_PRESETS(state, presets) {
    state.designPresets = _.keyBy(presets, 'id')
  },

  SET_PRESET(state, preset) {
    state.designPresets = {
      ...state.designPresets,
      [preset.id]: preset,
    }
  },
}

export const actions = {
  setDesignPreset({ commit, getters }, id) {
    commit('SET_CURRENT_DESIGN_PRESET', id)

    const { styles, settings } = getters.getCurrentPreset

    settings.pageBackgroundColor = settings.pageBackgroundColor || '#00ff00'

    commit('SET_SETTINGS', settings)

    const timeLeftStyle = styles.timeLeft || styles.title || {}

    const widgetStyles = {
      filler: styles.filler,
      progressBar: styles.progressBar,
      title: styles.title || {},
      progress: styles.progress || {},
      timeLeft: timeLeftStyle,
      frames: styles.frames || timeLeftStyle,
    }

    commit('SET_WIDGET_STYLES', widgetStyles)

    return getters.getCurrentPreset
  },

  getChanges({ getters }) {
    const prepareValue = (value) => {
      return typeof value === 'string' ? value.replace(/ /g, '') : value || null
    }

    const presetStyles = flatten(getters.getCurrentPreset)
    const currentStyles = flatten(getters.getCurrentDesign)

    return Object.keys(currentStyles)
      .reduce((acc, key) => {
        if (prepareValue(currentStyles[key]) !== prepareValue(presetStyles[key])) {
          acc[key] = {
            preset: prepareValue(presetStyles[key]),
            design: prepareValue(currentStyles[key]),
          }
        }

        return acc
      }, {})
  },

  fetchUserSettings({ commit, dispatch }, withWidgetId = false) {
    const route = withWidgetId ? `/widgets/goal/${state.userWidgetId}` : '/widgets/goal'

    return api.get(route)
      .then(({ data, meta }) => {
        if (meta && meta.presets) {
          commit('SET_PRESETS', meta.presets.map(preset => {
            const scheme = _.get(preset.json, 'scheme', 'horizontal')

            _.set(preset.json, 'styles.progressBar.minHeight', {
              horizontal: 7,
              vertical: 29,
            }[scheme])

            return {
              ...preset.json,
              id: preset.id,
            }
          }))
        }

        return dispatch('storeUserSettings', data)
      })
  },

  storeUserSettings({ commit, dispatch }, data) {
    const userWidget = _.isArray(data) ? data[0] : data

    commit('SET_USER_WIDGET_ID', userWidget.id)

    const currentPresetId = _.get(userWidget, 'preset.id', 'user')

    if (userWidget.preset) {
      commit('SET_PRESET', {
        ...userWidget.preset.json,
        id: userWidget.preset.id,
      })
    } else if (userWidget.json) {
      commit('SET_PRESET', {
        ...userWidget.json,
        id: 'user',
      })
    }

    return dispatch('setDesignPreset', currentPresetId)
  },

  async updateUserSettings({ state, dispatch, getters }) {
    return api.put(`/widgets/goal/${state.userWidgetId}`, {
      json: getters.getCurrentDesign,
    })
      .then(() => {
        return dispatch('fetchUserSettings')
      })
  },
}
