<style lang="scss" scoped>
  .ui-kit-page {

  }
</style>

<template>
  <div class="ui-kit-page">
    <div>windowWidth: {{ windowWidth }}</div>
    <div>windowScrollY: {{ windowScrollY }}</div>
    <div>appSize: {{ appSize }}</div>
  </div>
</template>

<script>
import { storeToRefs } from 'pinia'
import { onMounted } from 'vue'

import { useApplicationStore } from '@store/application'

export default {
  name: 'UiKitPage',
  setup() {
    const appStore = useApplicationStore()

    const {
      windowWidth,
      windowScrollY,
      appSize,
    } = storeToRefs(appStore)

    return {
      windowWidth,
      windowScrollY,
      appSize,
    }
  },
}
</script>
