<style scoped lang="scss">
  .steos-voice-banner-player {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 14px;

    container-type: inline-size;
    container-name: steos-player;

    .player-visualizer {
      height: 51px;
      position: absolute;
      display: flex;
      align-items: center;
      gap: 4px;
      right: 0;

      .visualizer-bar {
        width: 3px;
        background-color: rgba(black, .1);
        border-radius: 3px;
        transition: height 64ms;
      }

      @container steos-player (min-width: 257px) {
        right: 20px;
      }

      @container steos-player (min-width: 160px) and (max-width: 256px) {
        right: auto;
      }
    }

    .player-info {
      position: relative;
      text-align: center;
      display: grid;
      width: 100%;
      grid-template:
              "c n" auto
              "c p" auto / 100px 230px;

      .player-track-name {
        grid-area: n;
        height: 22px;
        position: relative;
        overflow: hidden;
        padding: 0 20px;

        .track-name-text {
          font-size: 16px;
          line-height: 20px;
          font-style: normal;
          font-weight: 600;
          font-family: Manrope, sans-serif;
          white-space: nowrap;

          @container steos-player (max-width: 256px) {
            font-size: 14px;
          }

          &.ref {
            position: absolute;
            opacity: 0;
            pointer-events: none;
            padding: 0 20px;
          }
        }

        &.animated {
          mask-image: linear-gradient(90deg, transparent, white 20px, white calc(100% - 20px), transparent);

          .track-name-wrapper {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            animation: scroll 7s linear infinite;

            .track-name-text {
              padding-right: 120px;
            }
          }
        }

        @keyframes scroll {
          from {
            transform: translateX(0);
          }
          to {
            transform: translateX(-50%);
          }
        }
      }

      .player-controls {
        grid-area: c;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        @include padding-y(4px);

        .player-control {
          color: white;
          font-size: 18px;
          cursor: pointer;

          &.play, &.pause, &.loading {
            width: 34px;
            aspect-ratio: 1 / 1;
            background-color: white;
            color: #7268E9;
            border-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            @container steos-banner (max-width: 660px) {
              width: 32px;
            }
          }

          &.play {
            .icon {
              margin-left: 4px;
            }
          }

          &.loading {
            pointer-events: none;

            .loader {
              --main-color: #7268E9;
              --additional-color: #7268E9;
              --stroke-width: .4em;
            }
          }
        }
      }

      .player-powered {
        grid-area: p;
        color: rgba(white, .6);
        font-size: 12px;
        line-height: 20px;
        font-style: normal;
        font-weight: 400;
        font-family: Manrope, sans-serif;
        white-space: nowrap;

        @container steos-player (max-width: 256px) {
          font-size: 12px;
        }
      }

      @container steos-player (min-width: 257px) {
        grid-template:
              "c n" auto
              "c p" auto / 100px 1fr;
      }

      @container steos-player (min-width: 160px) and (max-width: 256px) {
        grid-template:
                "n" auto
                "c" auto
                "p" auto / auto;
      }

      @container steos-player (max-width: 160px) {
        grid-template:
                "n" auto
                "c" auto
                "p" auto / auto;
      }
    }
  }
</style>

<template>
  <div class="steos-voice-banner-player">
    <div class="player-visualizer">
      <div :ref="setBarRef" v-for="i in 32" class="visualizer-bar"></div>
    </div>
    <div class="player-info">
      <div ref="$trackWrapperEl" class="player-track-name" :class="{animated: animateTrackMarquee}">
        <div ref="$trackNameEl" class="track-name-text ref">{{ currentSound.name }}</div>
        <div class="track-name-wrapper">
          <div class="track-name-text">
            {{ currentSound.name }}
          </div>
          <div v-if="animateTrackMarquee" class="track-name-text">
            {{ currentSound.name }}
          </div>
        </div>
      </div>
      <div class="player-controls">
        <div
          v-tooltip="$t('dashboard.banners.steosVoice.prev')"
          class="player-control prev"
          @click="onPlayerPervClick">
          <icon name="prev"/>
        </div>

        <div
          v-if="!audioPlaying"
          v-tooltip="$t('dashboard.banners.steosVoice.play')"
          class="player-control play"
          @click="onPlayerClickPlay">
          <icon name="play"/>
        </div>
        <div
          v-else
          v-tooltip="$t('dashboard.banners.steosVoice.pause')"
          class="player-control pause"
          @click="onPlayerClickPause">
          <icon name="pause"/>
        </div>

        <div
          v-tooltip="$t('dashboard.banners.steosVoice.next')"
          class="player-control next"
          @click="onPlayerNextClick">
          <icon name="next"/>
        </div>
      </div>
      <div class="player-powered">powered by STEOSVOICE</div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, onBeforeUnmount, watch } from 'vue'

import { tmr } from '@src/plugins/tmr'
import { ga } from '@src/plugins/ga'

let audioCtx

const props = defineProps({
  voices: {
    type: Array,
    default: () => [],
  },
})

let audioSource, analyser
let visualizerInterval, marqueeInterval

let $barsEls = []
const setBarRef = el => {
  if (el) {
    $barsEls.push(el)
  }
}

const $audio = new Audio()
$audio.crossOrigin = 'anonymous'

const initAudioCtx = () => {
  audioCtx = audioCtx || new (window.AudioContext || window.webkitAudioContext)()
  audioSource = audioSource || audioCtx.createMediaElementSource($audio)
  analyser = analyser || audioCtx.createAnalyser()
  audioSource.connect(analyser)
  analyser.connect(audioCtx.destination)
  analyser.fftSize = 2 ** 8
  const bufferLength = analyser.frequencyBinCount
  const dataArray = new Uint8Array(bufferLength)

  clearInterval(visualizerInterval)
  visualizerInterval = setInterval(() => {
    analyser.getByteTimeDomainData(dataArray)
    let lastHeight = 0

    dataArray.forEach((d, i) => {
      lastHeight += d

      if (i % 4 === 0) {
        const index = i / 4

        if ($barsEls[index]) {
          const k = i === 0 ? 128 : 512
          $barsEls[index].style.height = `${Math.abs(k - lastHeight) / k * 61}px`
        }

        lastHeight = 0
      }
    })
  }, 64)
}

const $trackNameEl = ref(null)
const $trackWrapperEl = ref(null)

const animateTrackMarquee = ref(false)

onMounted(() => {
  marqueeInterval = setInterval(() => {
    const { width: wrapperWidth } = $trackWrapperEl.value.getBoundingClientRect()
    const { width: trackWidth } = $trackNameEl.value.getBoundingClientRect()

    animateTrackMarquee.value = trackWidth > wrapperWidth
  }, 100)
})

onBeforeUnmount(() => {
  clearInterval(marqueeInterval)
  clearInterval(visualizerInterval)
})

const currentSoundIndex = ref(0)

const currentSound = computed(() => {
  return props.voices[currentSoundIndex.value]
})

watch(currentSound, ({ url }) => {
  $audio.src = url
}, { immediate: true })

const autoPlay = ref(false)
const audioPlaying = ref(false)
const playOrder = ref('fw')

const playerPlay = () => {
  initAudioCtx()
  $audio.play()
}
const playerPause = () => {
  $audio.pause()
}
const playerNext = (loop = true) => {
  const onLastTrack = () => {
    tmr.goal({ goal: 'PlayerPausedAfterLastTrack' }, {
      banner: 'steos_voice',
    })

    ga.event({
      eventCategory: 'Banner_steos_voice',
      eventAction: 'PlayerPausedAfterLastTrack',
    })
  }

  if (playOrder.value === 'fw') {
    if (currentSoundIndex.value >= props.voices.length - 1) {
      if (loop) {
        currentSoundIndex.value = 0
      } else {
        return onLastTrack()
      }
    } else {
      currentSoundIndex.value += 1
    }
  } else {
    if (currentSoundIndex.value <= 0) {
      if (loop) {
        currentSoundIndex.value = props.voices.length - 1
      } else {
        return onLastTrack()
      }
    } else {
      currentSoundIndex.value -= 1
    }
  }

  setTimeout(playerPlay, 500)
}
const onPlayerClickPlay = () => {
  autoPlay.value = true
  playerPlay()

  tmr.goal({ goal: 'PlayerPlayClick' }, {
    banner: 'steos_voice',
  })

  ga.event({
    eventCategory: 'Banner_steos_voice',
    eventAction: 'PlayerPlayClick',
  })
}
const onPlayerClickPause = () => {
  playerPause()

  tmr.goal({ goal: 'PlayerPauseClick' }, {
    banner: 'steos_voice',
  })

  ga.event({
    eventCategory: 'Banner_steos_voice',
    eventAction: 'PlayerPauseClick',
  })
}

$audio.addEventListener('canplay', (event) => {
  autoPlay.value && playerPlay()
})

$audio.addEventListener('ended', (event) => {
  playerNext(false)

  tmr.goal({ goal: 'TrackAutoChanged' }, {
    banner: 'steos_voice',
  })

  ga.event({
    eventCategory: 'Banner_steos_voice',
    eventAction: 'TrackAutoChanged',
  })
})

$audio.addEventListener('play', (event) => {
  audioPlaying.value = true
  audioCtx.resume()
})

$audio.addEventListener('pause', (event) => {
  audioPlaying.value = false
})

const onPlayerPervClick = () => {
  playOrder.value = 'bw'
  playerNext()

  tmr.goal({ goal: 'PlayerPreviousClick' }, {
    banner: 'steos_voice',
  })

  ga.event({
    eventCategory: 'Banner_steos_voice',
    eventAction: 'PlayerPreviousClick',
  })
}
const onPlayerNextClick = () => {
  playOrder.value = 'fw'
  playerNext()

  tmr.goal({ goal: 'PlayerNextClick' }, {
    banner: 'steos_voice',
  })

  ga.event({
    eventCategory: 'Banner_steos_voice',
    eventAction: 'PlayerNextClick',
  })
}

</script>
